
<app-loader *ngIf="!loaded" [message]="'Carregando Interface...'"></app-loader>

<app-small-loader style="position: absolute; top: 0; left: 0; z-index: 1000; width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.85); color: white; display: flex; justify-content: center; align-items: center;" *ngIf="det.creating_report" [message]="'Confirmando relatório'"></app-small-loader>

<main *ngIf="loaded" class="main-container">
  <div *ngIf="cams.expanded_screen || cams.expanded_second_screen" class="opacity-layer"></div>

  <app-cameras-sidenav />

  <section class="controls-panel">
    <mat-tab-group class="tabs-group" [color]="'primary'" [(selectedIndex)]="interfaceTabsService.selectedTab" (selectedTabChange)="det.deactivateDetection()">
      <mat-tab>
        <ng-template mat-tab-label style="background: black;">
          <img class="tab-icon" src="assets/icons/new-icons/gps-light.svg" style="height: 24px; width: 24px;"/>
          <span style="margin-left: 5px; color: white;">Detecções</span>
        </ng-template>

        <div class="detections-img-wrapper">
          <app-detections [newInterface]="true"></app-detections>
        </div>

        <div class="detections-table-wrapper">
          <app-detections-table></app-detections-table>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <img class="tab-icon" src="assets/icons/new-icons/camera-light.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: 5px; color: white;">Controles</span>
        </ng-template>
        <div class="tab-content-container">
          <app-cam-options class='cam-options-wrapper'></app-cam-options>
        </div>
      </mat-tab>

      <mat-tab>
        <ng-template mat-tab-label>
          <img class="tab-icon" src="assets/icons/new-icons/manage-light.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: 5px; color: white;" id="configs-tab">Configurações</span>
        </ng-template>
        <div class="tab-content-container">
          <app-client-config class='cam-options-wrapper'></app-client-config>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>

  <section class="cameras-container">
    <div [ngClass]="cams.expanded_screen ? 'camera-wrapper-expanded' : 'camera-wrapper'" id="first-camera">
      <app-camera [ngClass]="streamingType" [cam_id]="cams.getFirstCamId()"></app-camera>
    </div>

    <div [ngClass]="cams.expanded_second_screen ? 'camera-wrapper-expanded' : 'camera-wrapper'" id="second-camera" *ngIf='det.secondCamEnabled'>
      <app-camera [ngClass]="streamingType" [cam_id]="cams.getSecondCamId()" [isSecondCam]="true"></app-camera>

      <div class='second-camera-btns'>
        <button
          *ngIf="det.autoTriangulando && det.showStopTriButton"
          (click)="det.pararAutoTri(cams.getSecondCamId())"
          matTooltip="Parar triangulação automática" matTooltipPosition="above"
        >
          <img src="assets/icons/new-icons/cancel-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
        </button>

        <button
          id='triangulate-btn'
          (click)="det.searchTriangulation()"
          matTooltip="Triangular manualmente" matTooltipPosition="above"
          *ngIf="!det.autoTriangulando"
        >
          <img src="assets/icons/new-icons/architeture-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
        </button>

        <button
          id='select-second-cam-btn'
          [matMenuTriggerFor]="triCamMenu"
          matTooltip="Selecionar câmera" matTooltipPosition="above"
        >
          <img src="assets/icons/new-icons/switch-video-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
        </button>
      </div>

      <mat-menu #triCamMenu="matMenu">
        <ng-container *ngFor="let c of cams.cameras">
          <button mat-menu-item
            *ngIf = 'cams.getFirstCamId() != c.id_cam'
            (click)="det.switchTriTower(c.id_cam)">
            <span>{{c.nome_local}}</span>
          </button>
        </ng-container>
      </mat-menu>
    </div>
  </section>
</main>
