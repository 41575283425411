import L from 'leaflet';
import { point, destination as turfDestination, ellipse as turfEllipse } from '@turf/turf';
import 'leaflet-arrowheads'
import domtoimage from 'dom-to-image';

import {Injectable} from '@angular/core';
import {CameraService} from './camera.service';
import {HttpService} from './http.service';
import {FireLocationService} from './fire-location.service';
import {UtilsService} from './utils.service';
import {StorageService} from './storage.service';
import {ScriptService} from './scripts.service';
import {SatelliteService} from './satellite.service';
import {BrigadesService} from './brigades.service';
import { GeoLayersService } from './geo-layers.service';
import { AmplitudeService } from './amplitude.service';
import { Subject } from 'rxjs';

import * as gs from "./geo.store";
import * as gf from "./geo.format";
import {getCamAngularViewDeg, zoom2GradientColor, zoom2DistanceFov, zoom2DistancePreset} from "./cam.store";
import { MatDialog } from '@angular/material/dialog';
import { InformationDialog } from '../shared/dialogs/information';
import { CustomErroHandlerService } from './custom-erro-handler.service';
const TRIANGULATION_WITH_CAMERAS_GEOLOC_ID = 1;
export const CLICK_ON_MAP_GEOLOC_ID = 2;
export const INPUT_LAT_LON_GEOLOC_ID = 4;
export const AUTOTRI_GEOLOC_ID = 6;
export const AUTO_GEOLOC_ID = 3;

const mapPinIcon = L.icon({
  iconUrl: `./assets/map_icons/map-pin-red.png`,
  iconSize: [32, 32],
  iconAnchor: [16, 32],
  popupAnchor: [0, -32]
});

@Injectable({
  providedIn: 'root'
})
export class GeoService {

  constructor(
    private http: HttpService,
    private cams: CameraService,
    private utils: UtilsService,
    private storage: StorageService,
    private sat: SatelliteService,
    private brig: BrigadesService,
    private fireLoc : FireLocationService,
    private layers: GeoLayersService,
    private script:ScriptService,
    private dialog:MatDialog,
    private amplitude: AmplitudeService,
    private customErrorHandler:CustomErroHandlerService
  ) {}

  public map: L.Map = null;

  public shp_layer: L.geoJSON;
  public layer_keys:any[];

  private clusterLayer: L.geoJSON;

  private brigadesLayer: L.geoJSON;

  private locais_ugm: L.geoJSON;

  public pontos_referencia: any[];
  public triangulationPts: any[];

  private porLayer;

  private fire_markers = []; // L.marker[] | L.polyline[]

  private presetsPolylines: L.polyline[] = [];

  private location_marker_temp: L.marker;
  private mapDrawings:any[] = []

  // pra enviar pra componentes que já usavam dessa forma
  public lastClickLatLng: any = {Lat: "", Lng: ""};

  public searchingFireLoc = false;
  public fireLocationClick = false;

  public snapping_map = false;

  public baseLayersList: gs.LayerObj[] = [];
  public layerGroupList: gs.LayerGroupObj[] = [];
  private baseMap;

  public showSearchBox:boolean=false;

  public poisUpdated = new Subject<any[]>();

  private leafletRuler: any;

  async createMap(id='map') {
    if (this.map){
      console.log("%cGeoService : Reset do Mapa", "color : red");
      this.map.off();
      this.map.remove();
    }

    if (!this.cams.cameras) {
      console.log("%cGeoService : Erro ao ler a lista de cameras", "color : red");
      return false;
    }

    // Obtendo geoJson do cliente
    await this.initShapes();
    let locais_planta = await this.http.get('locais_planta');

    this.locais_ugm = await this.createTowersGeoJson(locais_planta.locais);

    if (!this.shp_layer || !this.locais_ugm) {
      console.log("Erro ao ler o GeoJson do Cliente")
      return false;
    }

    // MAP INIT
    let map_bounds = this.shp_layer.getBounds();

    this.map = new L.map(id, {
      maxBounds: map_bounds.pad(2),
      center: map_bounds.getCenter(),
      minZoom: 8,
      maxZoom: 17,
      attributionControl: false,
      zoomControl: false
    });

    this.map.fitBounds(map_bounds);

    // DEFININDO CAMADAS

    this.baseLayersList = gs.createBaseLayersList();
    this.baseLayersList[0].active = true;
    this.baseLayersList[0].layer.addTo(this.map);

    let limitOverlayGroup = this.layers.createLimitLayerGroup(this.shp_layer)
    this.addLayerGroup(limitOverlayGroup)
    for (let limitLayer of limitOverlayGroup.layersList) {
      this.setLayer(limitLayer.layer, true)
    }

    let windOverlayGroup = this.layers.createWindLayerGroup();
    this.addLayerGroup(windOverlayGroup)

    let poiOverlayGroup = this.layers.createPoiLayerGroup(locais_planta.pontos_interesse, locais_planta.tipos_poi)
    if(poiOverlayGroup){
      this.addLayerGroup(poiOverlayGroup)
    }
    this.triangulationPts = locais_planta.pontos_interesse?.features?.filter(p => p.properties.id_tipo_poi == 7) || [];

    this.pontos_referencia = locais_planta.pontos_referencia || [];
    let porOverlayGroup = this.layers.createPorLayerGroup(this.pontos_referencia)
    if(porOverlayGroup){
      this.porLayer = porOverlayGroup.layersList[0].layer;
      this.addLayerGroup(porOverlayGroup)
    }

    this.addLayerGroup(this.layers.createViewshedLayerGroup(this.cams.cameras))

    this.map.addLayer(this.locais_ugm);


    // Adicionando controle de camadas e zoom
    await this.addControls();
    this.map.addLayer(this.shp_layer);

    // CLICK EVENT
    this.map.on('click', async e => this.mapOnClick(e));

    this.updateTowerIcons();
    // this.locais_ugm.eachLayer(layer => console.log(layer))
    return true;
  }

  async updatePORsState() {
    let locais_planta = await this.http.get('locais_planta');
    let pointsOfReference = locais_planta.pontos_referencia || []
    this.poisUpdated.next(pointsOfReference)
    return
  }


  async updateClusters(){

    let clusterLayerGroup = await this.sat.createClusters();
    if(clusterLayerGroup){
      try{
        this.map.removeLayer(this.clusterLayer);
      } catch (error) {
        this.customErrorHandler.handleError(error);
      }

      this.clusterLayer = clusterLayerGroup.layersList[0].layer;
      this.map.addLayer(this.clusterLayer);

      this.removeLayerGroup(4);
      this.addLayerGroup(clusterLayerGroup);

      console.log('...🛰️ update clusters');

    }
  }

  async updateBrigades(){
    let brigadesLayerGroup = await this.brig.createBrigadeGeoJson();
    if(brigadesLayerGroup){
      try{
        this.map.removeLayer(this.brigadesLayer);
      } catch (error) {
        this.customErrorHandler.handleError(error);
      }
      this.brigadesLayer = brigadesLayerGroup.layersList[0].layer;
      this.map.addLayer(this.brigadesLayer);

      this.removeLayerGroup(8);
      this.addLayerGroup(brigadesLayerGroup);

      console.log('...🚒 update brigades')
    }
  }

  async addControls(){

    let tagCtrl = L.control.attribution().setPrefix('<a>Pantera</a>');
    this.map.addControl(tagCtrl);

    await this.script.load('leaflet-ruler').then(data => {
      console.log('script loaded ', data);
    }).catch(error => {
      this.customErrorHandler.handleError(error);
      console.log(error)
    });
    this.leafletRuler = L.control.ruler({position: 'topright'}).addTo(this.map);

    L.control.scale({imperial: false, maxWidth: 300}).addTo(this.map);

    L.Control.Watermark = L.Control.extend({
      onAdd: function(map) {
        var img = L.DomUtil.create('img');
        img.src = 'assets/logos/pantera_head_negative.svg';
        img.style.width = '50px';
        return img;
      },
      onRemove: function(map) {}
    });

    L.control.watermark = function(opts) {
      return new L.Control.Watermark(opts);
    }

    L.control.watermark({ position: 'bottomleft' }).addTo(this.map);
  }


  /*
  Funcoes de obtencao dos GeoJson com Backend
  */
  async initShapes() {
    let polygons = await this.http.get('geo_planta', [], true);
    if (polygons === null) return;

    // futuramente geojson vira do banco somente com zoom level 1, o filtro ficara
    let shapes_features = polygons.features.filter(p => p.properties.zoom_level == 1) || [];
    if (!shapes_features.length) return null;

    let shapes = {
      type: 'FeatureCollection',
      features: shapes_features
    }

    this.shp_layer = L.geoJSON(shapes, {
      style: this.styleShapes,
      onEachFeature: (feature, layer) => {
        layer.on('click', e => {      
          if (this.leafletRuler._choice){
            layer.unbindPopup();
          } else {
            layer.bindPopup(gf.formatGeoInfo(feature)).openPopup();
            this.mapOnClick(e);
            this.highlightLayer(layer);
          };
        });
      },
      pane:'shadowPane',
    });

    let raw_layer_keys = Object.keys(this.shp_layer._layers);
    this.layer_keys = raw_layer_keys.map(x => {
      return {
        id: x,
        name: this.shp_layer._layers[x].feature.properties.nome_geom,
      };
    });

  }


  styleShapes(feature){

    return {
      "color": feature.properties.fill_color ?? '#96ff94',
      "weight": 1,
      "opacity": feature.properties.fill_opacity ?? 0.1
    }
  }



  createTowersGeoJson(locais) {
    return L.geoJSON(locais, {
      pointToLayer: (feature, latlng) => {

        let icon_path = './assets/map_icons/';

        switch (feature.properties.id_tipo_local) {
          case 1:
            icon_path += 'cco.png';
            break;
          case 2:
            icon_path += 'icon-tower-grey.png';
            break;
          case 4:
          // icon_path += 'icon-radio-tower.png';
          // break;
          case 3:
          case 5:
            return;
        }
        ;

        let icon = L.icon({
          iconSize: [42, 42],
          iconUrl: icon_path
        });

        var marker = new L.marker(latlng, {icon: icon});
        if (feature.properties.id_tipo_local == 2) {
          marker.bindTooltip(feature.properties.nome_local, {
            permanent: true,
            direction: 'bottom',
            className: "marker-label",
            offset: [0, 23],
            opacity: 0.8
          });
        }
        return marker;
      },
      onEachFeature: (feature, layer) => {
        layer.base_line = null;
        layer.id_camera = this.getCamIdFromLocal(feature.properties.id_local)
        feature.camera_link = this.getCamFromLocal(feature.properties.id_local);
        layer.on('click', e => {
          this.highlightCamera(feature.camera_link);
        });
      }
    });
  }



  /*
  Funcoes scheduled OU sobmudança do localStorage
    - atualizar direcoes e icones de torre
    - verificar se precisa triangular
    - verificar se precisa fornecer um snap do mapa
    - verifica se precisa desenhar icones de relatorios de incendio abertos
  */

  updateRoutine(){
    this.updateTowerIcons();
    this.updateCamDirection();
    this.checkFireLocation();
    this.checkAimingDetection()
  }


  updateCamDirection() {
    let cams_status = this.cams.getStatus();
    let id_first = this.cams.getFirstCamId();
    let id_second = this.cams.getSecondCamId();

    let camera_obj = this.cams.getCameraObj(id_first);
    const minFocalDistance = camera_obj.min_focal_distance_mm;
    const sensorWidth = camera_obj.sensor_width_mm;

    this.locais_ugm.eachLayer(layer => {

      let cam_log = cams_status.filter(c => c?.id_camera == layer.id_camera)[0];

      if (cam_log?.ptz.pan) {

        let color = 'black';
        if(layer.id_camera == id_first) {
          color = 'red'
        }else if(layer.id_camera == id_second){
          color = 'orange'
        };

        this.drawFov(layer, cam_log.ptz, color, minFocalDistance, sensorWidth);

        if (layer.feature.camera_link?.pantoOnClick) {
          this.drawDirectionTruf(layer, cam_log?.ptz.pan, color, 70);
          return
        }
        this.drawDirectionTruf(layer, cam_log?.ptz.pan, color);
      };
    });
  }


  drawDirectionTruf(layer, ang = 0, color = 'black', distance = 30) {
    let origin = point([layer._latlng.lng, layer._latlng.lat]);
    let destination = turfDestination(origin, distance, ang);

    var pts = [
      origin.geometry.coordinates.reverse(),
      destination.geometry.coordinates.reverse(),
    ]

    // aproveitar pra INIT on start olhando pro norte de cinza
    if (!layer.base_line) {
      layer.base_line = L.polyline(pts, {color: 'white', weight: 5, opacity: 0.5});
      layer.center_line = L.polyline(pts, {color: 'black', weight: 2});

      layer.base_line.addTo(this.map);
      layer.center_line.addTo(this.map);

    } else {
      layer.base_line.setLatLngs(pts);
      layer.center_line.setLatLngs(pts);
      layer.center_line.setStyle({color: color})
    };

  }

  drawFov(layer, camPTZ, color, minFocalDistance, sensorWidth){
    let zoom = Math.max(1, camPTZ.zoom)
    let camAngularView = getCamAngularViewDeg(zoom, minFocalDistance, sensorWidth);
    let zdistance = zoom2DistanceFov(zoom, minFocalDistance, sensorWidth)

    this.drawFovTurf(layer, camPTZ.pan, camAngularView, color, zdistance.end, zdistance.start);
  }

  drawFovTurf(layer, bearing, angle, color:string='white', distance_end:number=15, distance_start:number=0, weight=5, opacity=0.7){

    let origin = point([layer._latlng.lng, layer._latlng.lat]);

    let destination1 = turfDestination(origin, distance_end, bearing-angle/2);
    let destination2 = turfDestination(origin, distance_end, bearing+angle/2);

    if (distance_start==0){
      var pts = [origin.geometry.coordinates.reverse()]
    } else {
      let origin1 = turfDestination(origin, distance_start, bearing-angle/2);
      let origin2 = turfDestination(origin, distance_start, bearing+angle/2);
      var pts = [
        origin2.geometry.coordinates.reverse(),
        origin1.geometry.coordinates.reverse(),
      ]
    }

    pts.push(
      destination1.geometry.coordinates.reverse(),
      destination2.geometry.coordinates.reverse()
    )

    if (!layer.fov) {
      layer.fov = L.polygon(pts, {color: color, weight: weight, opacity: opacity});
      layer.fov.addTo(this.map)
    } else {
      layer.fov.setLatLngs(pts);
      layer.fov.setStyle({ color: color, stroke: true, weight: 1 })
    }
  }


  updateTowerIcons() {
    this.locais_ugm.eachLayer(layer => {
      if (layer.feature.properties.id_tipo_local === 2) {
        let icon_url = './assets/map_icons/';
        let icon = L.icon({iconUrl: icon_url + 'icon-tower-grey.png'});

        if (layer.feature.camera_link?.pantoOnClick) {
          icon = L.icon({iconUrl: icon_url + 'icon-tower-yellow.png'});
        }

        layer.setIcon(icon);
      }
    });
  }

  checkAimingDetection() {
    let aimJson = this.storage.getWaitingAimingDetection()
    if (aimJson) {
      console.log('aimJson', aimJson)
      const ZOOM_LEVEL = 12
      let cam_obj = this.cams.getCameraObj(aimJson.id_camera)

      let should_center_on_map = aimJson.should_center_on_map
      if (should_center_on_map) {
        this.centerOnCamera(cam_obj, ZOOM_LEVEL)
      }
      
      if (this.storage.getStoredUseEllipseValue()) {
        let lat = aimJson.lat
        let lon = aimJson.lon
        let d_km = aimJson.d_km
        let a_km = aimJson.a_km
        let b_km = aimJson.b_km
        let pan = aimJson.pan
        console.log('parametros ellipse:', lat, lon, d_km, pan, a_km, b_km)

        try {
          this.clearDrawings();
          this.drawEllipse(lat, lon, a_km, b_km, pan)
        }
        catch (error) {
          this.customErrorHandler.handleError(error);
          console.log('Erro ao desenhar elipse com localizacao aproximada da deteccao')
        }
      }

      this.storage.rmWaitingAimingDetection()
    }
  }

  drawEllipse(lat, lon, a, b, bearing){
    let center = [lon, lat];
    let options = {
      steps: 100,
      angle: bearing
    };
    let ellipse = turfEllipse(center, a, b, options);
    let ellipseGeojson = L.geoJSON(ellipse);
    ellipseGeojson.setStyle({ color: "#999999", stroke: true, weight: 1, fillOpacity: 0.3 })
    
    ellipseGeojson.addTo(this.map);
    this.mapDrawings.push(ellipseGeojson);
    return true;
  }

  // PRESETS -> parecido com FOV mas melhor n misturar as coisas
  drawPreset(cam, preset, maxZoom=15, minFocalDistance, sensorWidth){
    let zoom = Math.max(1, preset.zoom)

    let camAngularView = getCamAngularViewDeg(zoom, minFocalDistance, sensorWidth);
    let color = zoom2GradientColor(zoom, maxZoom)
    let zdistance = zoom2DistancePreset(zoom)

    this.drawPresetPolygon(cam.lat, cam.lon, preset.pan, camAngularView, color, zdistance.end, zdistance.start);
  }


  drawPresetPolygon(origin_lat, origin_lon, bearing, angle, color:string='white', distance_end:number=15, distance_start:number=0, unit:string='kilometers', weight=5, opacity=0.6){
    let d_unit: any = {units: unit};

    let origin = point([origin_lon, origin_lat]);

    var destination1 = turfDestination(origin, distance_end, bearing-angle/2, d_unit);
    var destination2 = turfDestination(origin, distance_end, bearing+angle/2, d_unit);

    if (distance_start==0){
      var pts = [origin.geometry.coordinates.reverse()]
    } else {
      var origin1 = turfDestination(origin, distance_start, bearing-angle/2, d_unit);
      var origin2 = turfDestination(origin, distance_start, bearing+angle/2, d_unit);
      var pts = [
        origin2.geometry.coordinates.reverse(),
        origin1.geometry.coordinates.reverse(),
      ]
    }

    pts.push(
      destination1.geometry.coordinates.reverse(),
      destination2.geometry.coordinates.reverse()
    )

    var pline = L.polygon(pts, {color: color, weight: weight, opacity: opacity}).addTo(this.map);
    this.presetsPolylines.push(pline)
  }


  clearPresets(){
    this.presetsPolylines.forEach(p => p.remove());
  }



  //  FIRE LOCATION
  checkFireLocation() {
    if (this.storage.getWaitingTriangulation()) {

      try{
        let tri_point = this.fireLoc.getCamsTriangulationPoint();
        let dados_localizacao = this.fireLoc.getDadosLoc(tri_point.lat, tri_point.lon, this.shp_layer, TRIANGULATION_WITH_CAMERAS_GEOLOC_ID);

        this.drawLocation(dados_localizacao);
        this.storage.setStoredFireLocation(dados_localizacao);
      } catch (error){
        this.customErrorHandler.handleError(error);
        console.log("Triangulation Error", error)
        this.dialog.open(InformationDialog , {
          data: { text: 'ERRO ao triangular. As linhas de visada das câmeras não se cruzam!' }
        });
      }

      this.storage.rmWaitingTriangulation()

    }

    // mudar pra enable barSearch
    if (this.storage.getSearchingLocOnMap()) {
      this.searchingFireLoc = true;
      this.storage.rmSearchingLocOnMap();
    }

    if (this.storage.getClearMapLoc()) {
      this.undrawTempLocation();
      this.clearDrawings();
      this.searchingFireLoc = false;
      this.storage.rmClearMapLoc();
    }

  }

  setFireLocationClick(){
    this.fireLocationClick = !this.fireLocationClick;
    L.DomUtil.addClass(this.map._container,'crosshair-cursor-enabled');
  }

  async getDadosLocByCoords(latlng){
    let dados_loc = await this.fireLoc.setFireLocation(latlng, this.shp_layer, INPUT_LAT_LON_GEOLOC_ID);
    return dados_loc
  }

  getMaxLatLng(){
    let bounds = this.shp_layer.getBounds().pad(0.4);
    let boundsObj = {
      minLat : bounds._southWest.lat,
      maxLat : bounds._northEast.lat,
      minLng : bounds._southWest.lng,
      maxLng : bounds._northEast.lng
    };
    return boundsObj;
  }

  async getMapSnap(coords) {
    if (this.snapping_map) { return; }

    this.map.closePopup();
    this.map.setView(new L.LatLng(coords.lat, coords.lon), 11);
    this.snapping_map = true;

    await this.utils.sleep(1000);
    return await this.mapDomToImg();
  };

  async mapDomToImg() {
    try {
      let map_node = document.getElementById('map');

      const base64_url = await domtoimage.toJpeg(map_node, {
        width: map_node.clientWidth,
        height: map_node.clientHeight,
        quality: 0.4
      })

      return base64_url;
    }
    catch (err)  {
      this.customErrorHandler.handleError(err);
      console.error('... map image error', err);
      return '';
    }
  }

  // Desenha os relatorios abertos
  drawFireReports() {
    let reports = this.storage.getStoredReports();

    if (!reports) return;
    this.fire_markers.forEach(x => x.remove());
    this.fire_markers = [];
    this.undrawTempLocation();
    for (let r of reports) {
      if (r.sem_risco) r.icon.color = "blue"
      if (r.is_test) r.icon.color = "black"
      this.drawReport(r);
    }
  }

  /*
  Interacoes do usuario
  */


  highlightCamera(cam_obj) {
    if (!cam_obj) return;
    this.cams.toggleControllingCameraOnMap(cam_obj.id_cam)
    this.updateTowerIcons();
  }

  centerOnCamera(cam_obj, zoomLevel=14){
    let camLatLng = [cam_obj.lat, cam_obj.lon];
    console.log(`%c Center on cam ${camLatLng}`, 'background:green');
    this.map.setView(camLatLng, zoomLevel);
  }

  /*
  Links entre Camera e Torre
  */

  getCamIdFromLocal(id_local) {
    return this.cams.cameras.filter(x => x.id_local == id_local)[0]?.id_cam || null;
  }

  getCamFromLocal(id_local) {
    return this.cams.cameras.filter(x => x.id_local == id_local)[0];
  }


  /*
  Click no mapa
  */
  async mapOnClick(event) {
    console.log('%c👆=>🗺️','background-color:green')
    console.log(`lat: %c${event.latlng.lat}`,'background-color:green')
    console.log(`lon: %c${event.latlng.lng}`,'background-color:green')
    this.lastClickLatLng = event.latlng;
    this.lastClickLatLng.lat = Number(this.lastClickLatLng.lat).toFixed(6)
    this.lastClickLatLng.lng = Number(this.lastClickLatLng.lng).toFixed(6)

    if (this.fireLocationClick) {
      let dados_loc = await this.fireLoc.setFireLocation(event.latlng, this.shp_layer, CLICK_ON_MAP_GEOLOC_ID);
      this.drawLocation(dados_loc);
      this.storage.setStoredFireLocation(dados_loc);
      this.searchingFireLoc = false;
      L.DomUtil.removeClass(this.map._container,'crosshair-cursor-enabled');
      this.fireLocationClick = false;
      return
    }

    this.camsPanToOnClick(event.latlng);
  }

  camsPanToOnClick(latlng){
    for (const camera of this.cams.cameras) {
      if (camera.pantoOnClick) {
        const pan = this.fireLoc.camPanToPoint(latlng, camera.id_cam);
        let absPTZ = { id: camera.id_cam, pan: pan};
        this.cams.sendAbsPan(absPTZ)
      }
    }
  }

  // Por enquanto para testes. No futuro para clicar no mapa e criar ocorrencia
  drawOnClick(event) {
    let marker = L.marker(event.latlng, {icon: mapPinIcon});
    marker.bindPopup('hello')
    if (this.location_marker_temp) {
      this.undrawTempLocation();
    }
    this.location_marker_temp = marker;
    marker.addTo(this.map);
  }


  /*
  Desenho e interacao com icones de fogo no mapa
  */

  drawLocation(dados_loc) {
    this.undrawTempLocation();

    let marker = L.marker(dados_loc, {icon: mapPinIcon});

    // marker.data = dados_loc;
    marker.bindPopup(gf.formatLocationInfo(dados_loc), {autoPan: false});

    // this.undrawTempLocation();
    this.location_marker_temp = marker;

    marker.addTo(this.map);
    marker.openPopup();
  }

  undrawTempLocation() {
    if (this.location_marker_temp) {
      this.location_marker_temp.remove();
      this.location_marker_temp = null;
    }
  }


  drawReport(r) {
    let icon = L.icon({
      iconUrl: './assets/map_icons/local_fire_department.svg',
      iconSize: [32,32],
      className: `${r.icon.color}-svg`
    });

    let marker = L.marker(r.dados_localizacao[0], {icon: icon});

    // TODO AVALIAR COMO FICA ISSO
    // if (r.dados_localizacao[0].uncertainty_m){
    //   console.log(r.dados_localizacao[0])
    //   L.circle(r.dados_localizacao[0], {radius: r.dados_localizacao[0].uncertainty_m}).addTo(this.map);
    //   }

    if(r.dados_meteo[0]?.['wind_deg']){
      this.drawWind(r.dados_localizacao[0], (r.dados_meteo[0]['wind_deg'] + 180) % 360)
    }

    marker.bindPopup(gf.formatLocationInfo(r.dados_localizacao[0], r.dados_meteo[0], r.n_relatorio_planta));

    marker.id_report = r.id_report;

    this.fire_markers.push(marker);
    marker.addTo(this.map);
  }

  drawWind(loc, wind_deg=0){
    try{
      let destination = turfDestination(
        [loc.lon, loc.lat], 1, wind_deg
      )
      let arrow = L.polyline([
        [loc.lat, loc.lon],
        destination.geometry.coordinates.reverse()
        ], { color: 'black', arrowheads: true }
      ).arrowheads();

      arrow.addTo(this.map);
      this.fire_markers.push(arrow);
    }
    catch(error) {
      console.log("error on drawWind", error)
    }
  }

  clearDrawings(){
    this.mapDrawings.forEach(p => p.remove());
  }

  // clickOnReport(id_report) {
    // import { ReportsService } from './reports.service';
    // marker.on('click', e => this.clickOnReport(e.target.id_report));
    // Dependencia circular..
    // this.reports.selectReport(id_report);
  // }

  openReportMarker(id_report) {
    let marker = this.fire_markers.filter(m => m.id_report == id_report)[0]
    if (marker) {
      marker.openPopup();
    }
  }

  openClusterPopUp(id){
    let layerList = Object.values(this.clusterLayer._layers);
    let layer:L.Layer = layerList.filter(m => m['pk_mv'] == id)[0]
    layer.openPopup();
  }

  closePopup() {
    this.map.closePopup();
  }


  // POR

  openPorMarker(uuid_por) {
    let layerList = Object.values(this.porLayer._layers);
    let layer:L.Layer = layerList.filter(m => m['uuid_por'] == uuid_por)[0]
    layer.openPopup();
  }

  /*
  Funcoes De features
  */

  highlightLayer(layer){
    const highlight_color = '#ff0000';
    this.shp_layer.resetStyle();

    if(!layer.highlighted){
      layer.setStyle({color: highlight_color});
      layer.highlighted = true;
    } else {
      layer.highlighted = false;
    }

  }

  setViewLayer(layer){
    let xy = layer.getBounds().getCenter();
    this.map.setView(xy, 13, {'zoom': {"animate": true}, 'pan':{"animate": true}});
  }

  setView(latlng, zoom=13){
    this.map.setView(latlng, zoom);
  }

  // Busca no mapa
  focusOnLayer(key){
    let layer = this.shp_layer._layers[key];
    this.highlightLayer(layer);
    this.setViewLayer(layer);
    // dava pra fazer sem o bind? testar
    layer.bindPopup(gf.formatGeoInfo(layer.feature));
    layer.openPopup();
  }

  setMapView(lat:number, lon:number){
    console.log(`%c Center on ${[lat, lon]}`, 'background:blue')
    let zoomlevel = Math.max(this.map.getZoom(), 13);
    this.map.setView([lat, lon], zoomlevel);
  }

  // TODO - Resolver isso em algum dia (Layr)
  removeDivisas() {
    // this.map.removeLayer(gs.Esri_Borders);
  }

  addDivisas() {
    // this.map.addLayer(gs.Esri_Borders);
  }

  // NOVO MENU DE CAMADAS

  addLayerGroup(layerGroup){
    this.layerGroupList.push(layerGroup)
  }

  removeLayerGroup(groupId){
    this.layerGroupList = this.layerGroupList.filter(l => l?.groupId != groupId);
  }

  setLayer(layer, show=true){
    console.log('setLayer', layer);
    if(show){
      this.map.addLayer(layer)
    } else {
      this.map.removeLayer(layer)
    }
  }

  setBaseMap(layer){
    this.map.addLayer(layer)
    try{
      this.map.removeLayer(this.baseMap)
    } catch (error) {
      this.customErrorHandler.handleError(error);
    }
    this.baseMap = layer

    let newArr = this.baseLayersList.map(this.setInactive);
    for (let base of newArr) {
      if (base.layer == layer) {
        base.active = true
      }
    }
    this.baseLayersList = newArr;
  }

  setInactive(base) {
    base.active = false
    return base
  }

  toggleSearchBox(){
    this.showSearchBox = !this.showSearchBox
    this.showSearchBox ? this.amplitude.sendEvent("Habilitou Pesquisar Local") : this.amplitude.sendEvent("Desabilitou Pesquisar Local")
  }

}
