<main [ngClass]="contentOnRight? '':'main-reverse'">
   <section class=sidebar>
      <div class="sidebar-header">
         <div class="sidebar-topheader">
            <img class='client-logo' [src]="user.getLogoUrl()">
         </div>
         <div class="sidebar-subheader" matTooltip="Lista de relatórios abertas no sistema" matTooltipPosition="above">
            <mat-icon style='color: darkred'>local_fire_department</mat-icon>
         </div>
      </div>

      <div class="report-btns-container">
         <div class="reports-list">
            <button
               *ngFor='let r of reports.reports_list'
               (click)="selectReport(r.id_report)"
               [ngClass]="reports.selected_report && (reports.selected_report?.id_report == r.id_report) ? 'selected' : 'not-selected'"
            >
               <span>
                 <mat-icon [ngStyle]="{'color': r.icon.color}">{{r.icon.src}}</mat-icon>
               </span>
               <span class="tower-name" *ngIf='!r.n_relatorio_planta'>
                  {{r.name}} - {{r.datetime_deteccao | date:'HH:mm:ss'}}
               </span>
               <span class="tower-name" *ngIf='r.n_relatorio_planta'>
                  {{r.n_relatorio_planta}} - {{r.name}}
               </span>
            </button>
         </div>
      </div>

      <div class='btns-container-sidenav' *ngIf="singleInterface">
         <button mat-fab
           (click)='toggleSat()'
           id='map-sat-btn-single'
           [matBadge]='sat.clustersLength'
           [matBadgeHidden]='!sat.clustersLength'
           [disabled]='!user.hasSatellite()'
           color="primary"
           matTooltip="Detecção Satelital">
           <mat-icon color="accent">satellite_alt</mat-icon>
         </button>

         <button mat-fab
           (click)='toggleWeather()'
           id='map-weather-btn-single'
           color="primary"
           matTooltip="Meteorologia">
           <mat-icon color="accent">thermostat</mat-icon>
         </button>
      </div>
   </section>

   <section class='report-info-container' *ngIf='reports.showTable' id="report-container">
      <div class="report-data-and-actions-header">
         <div class='left-side-report-header'>
           <button (click)="selectReport(reports.selected_report.id_report)" class="unselect-report-btn">
             <mat-icon>arrow_back</mat-icon>
           </button>

            <span *ngIf="reports.selected_report.n_relatorio_planta">
              Relatório {{ reports.selected_report.n_relatorio_planta }} - {{ reports.selected_report.datetime_deteccao | date: "dd/MM HH:mm" }}
            </span>

            <span *ngIf="!reports.selected_report.n_relatorio_planta && reports.selected_report.id_report">
              Relatório {{ reports.selected_report.id_report }}
            </span>
         </div>
      </div>

      <div class='report-image-wrapper' *ngIf="reports.imgs_loaded && reports.selected_report.images.length > 0">
         <img [src]="reports.getReportImageURL(reports.selectedImageIndex)" (load)="drawBboxOnReportImage()">

         <canvas id="detection-image-canvas"></canvas>

         <button class="change-report-image-btn" id="chevron-on-left" (click)="reports.previousImage()"><mat-icon [color]="'primary'">chevron_left</mat-icon></button>
         <button class="change-report-image-btn" id="chevron-on-right" (click)="reports.nextImage()"><mat-icon [color]="'primary'">chevron_right</mat-icon></button>

         <div class="metadata-over-image" id="metadata-left">
            {{reports.selected_report.images[reports.selectedImageIndex]?.img_details.pan}}° | {{reports.selected_report.images[reports.selectedImageIndex]?.img_details.zoom}}
         </div>
         <div class="metadata-over-image" id="metadata-right">
            {{reports.selected_report.images[reports.selectedImageIndex].img_details.nome}} - {{reports.selected_report.images[reports.selectedImageIndex].img_timestamp | date: "dd/MM/yy HH:mm:ss" }} - {{reports.selectedImageIndex + 1}}
         </div>
      </div>

      <div class="report-data-and-actions">
            <div class="cause-and-origin-row">
              <div class="first-row">
                <div class="button-and-tip-wrapper" matTooltip="Mais opções" matTooltipPosition="above">
                  <button class="enabled-btn" [matMenuTriggerFor]="menu" id="more-btn">
                    <mat-icon>more_horiz</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu" yPosition="above">
                     <button mat-menu-item (click)='finishReport()'>
                        <mat-icon>cloud_done</mat-icon>
                        <span>Finalizar relatório</span>
                     </button>

                     <button mat-menu-item (click)='reports.openAddImagesDialog()'>
                        <mat-icon>add_photo_alternate</mat-icon>
                        <span>Adicionar imagem</span>
                     </button>

                    <button mat-menu-item (click)='sendToTelegram()'>
                       <mat-icon>phonelink_ring</mat-icon>
                       <span>Enviar via Telegram</span>
                    </button>

                    <button mat-menu-item (click)='reports.aimReport()'>
                       <mat-icon>visibility</mat-icon>
                       <span>Apontar câmera</span>
                    </button>
                  </mat-menu>
                </div>
              </div>

            <div class="second-row">
              <mat-form-field appearance="outline" style="width: 50%;">
                <mat-label style="color: white; font-size: .8rem;">Origem da detecção</mat-label>
                <mat-select [(ngModel)]="reports.selected_report.id_origem_deteccao" (selectionChange)="reports.updateMaestro('id_origem_deteccao')" [disabled]="reports.selected_report.id_origem_deteccao === 1 || !user.hasFireManagement()">
                    <mat-option *ngFor='let o of origens' [value]="o.id_origem_deteccao">{{o.origem_deteccao}}</mat-option>
                </mat-select>
              </mat-form-field>

              <mat-form-field appearance="outline" style="width: 50%;">
                <mat-label style="color: white; font-size: .8rem;">Causa do incêndio</mat-label>
                <mat-select [(ngModel)]="reports.selected_report.id_causa_incendio" (selectionChange)="reports.updateMaestro('id_causa_incendio')" [disabled]="!user.hasFireManagement()">
                  <mat-option *ngFor='let c of causas' [value]="c.id_causa_incendio">{{c.causa_incendio}}</mat-option>
                </mat-select>
              </mat-form-field>
            </div>
         </div>

         <div class="report-data-box">
            <div class="data-box-header">
              <div class="header-title">
                <div class="button-and-tip-wrapper" matTooltip="Registrar acionamento" matTooltipPosition="above">
                  <button class="enabled-btn" [matMenuTriggerFor]="locationMenu">
                    <mat-icon>edit_location_alt</mat-icon>
                 </button>

                 <mat-menu #locationMenu="matMenu" yPosition="above">
                  <button mat-menu-item (click)='changeLocationByClick()'>
                    <mat-icon>map</mat-icon>
                    <span>Indicar local no mapa</span>
                  </button>

                  <button mat-menu-item (click)='changeLocationByCoords()'>
                    <mat-icon>edit_location</mat-icon>
                    <span>Alterar coordenadas</span>
                  </button>
                 </mat-menu>
                </div>
                <span (click)="toggleExpandLocationBox()">Informações do local</span>
              </div>
              <div (click)="toggleExpandLocationBox()" class="expansion-arrow-wrapper">
                <mat-icon *ngIf="!this.locationBoxExpanded" color="white">expand_more</mat-icon>
                <mat-icon *ngIf="this.locationBoxExpanded" color="white">expand_less</mat-icon>
              </div>
            </div>

            <div class="data-box-content" *ngIf="this.locationBoxExpanded">
              <div class="data-box-column">
                <div class="data-box-attribute-info" *ngFor="let i of reports.selected_report.dados_localizacao[0]?.dados_geo.atributos?.Local">
                  <span>{{i.key}}: {{i.value}}</span>
                </div>
              </div>

              <div class="data-box-column">
                <div class="data-box-attribute-info">
                  <mat-icon>
                    thermostat
                  </mat-icon>
                  <span>
                    Temperatura: {{reports.selected_report.dados_meteo?.[0]?.['temp']}}°
                  </span>
                </div>

                <div class="data-box-attribute-info">
                  <mat-icon>
                    water_drop
                  </mat-icon>
                  <span>
                    Umidade: {{reports.selected_report.dados_meteo?.[0]?.['humidity']}}%
                  </span>
                </div>

                <div class="data-box-attribute-info">
                  <mat-icon>
                    air
                  </mat-icon>
                  <span>
                    Vel. do vento: {{reports.selected_report.dados_meteo?.[0]?.['wind_speed']*3.6|number:'.2-2'}}km/h
                  </span>
                </div>

                <div class="data-box-attribute-info">
                  <mat-icon>
                    explore
                  </mat-icon>
                  <span>
                    Dir. do vento: {{reports.selected_report.dados_meteo?.[0]?.['wind_deg']}}°
                  </span>
                </div>

                <div class="data-box-attribute-info">
                  <mat-icon>
                    umbrella
                  </mat-icon>
                  <span>
                    Precipitação: {{reports.selected_report.dados_meteo?.[0]?.['rain']}}mm
                  </span>
                </div>
              </div>
            </div>
         </div>

         <div class="report-data-box" [ngClass]="reports.selected_report.acionamentos.length ? 'report-data-box' : 'report-data-box-empty'">
            <div class="data-box-header">
              <div class="header-title">
                <div class="button-and-tip-wrapper" matTooltip="Registrar acionamento" matTooltipPosition="above">
                  <button class="enabled-btn" (click)='addAcionamento()'>
                   <mat-icon>fire_truck</mat-icon>
                  </button>
                </div>
                <span (click)="toggleExpandTriggeringsBox()">
                  Acionamentos
                </span>
              </div>
              <div *ngIf="reports.selected_report.acionamentos.length" (click)="toggleExpandTriggeringsBox()" class="expansion-arrow-wrapper">
                <mat-icon *ngIf="!this.triggeringsBoxExpanded" color="white">expand_more</mat-icon>
                <mat-icon *ngIf="this.triggeringsBoxExpanded" color="white">expand_less</mat-icon>
              </div>
            </div>

            <div class="table-wrapper" *ngIf="this.triggeringsBoxExpanded && reports.selected_report.acionamentos.length">
              <table>
                <tr id="table-headers">
                  <th>Brigada</th>
                  <th>Acionamento</th>
                  <th>Combate</th>
                  <th>Rescaldo</th>
                </tr>

                <tr *ngFor="let acionamento of reports.selected_report.acionamentos" (click)="addAcionamento(acionamento)">
                  <td>{{acionamento.nome_brigada}}</td>
                  <td>{{acionamento.dt_acionamento | date:'HH:mm'}} - {{acionamento.dt_chegada | date:'HH:mm'}}</td>
                  <td>{{acionamento.dt_inicio_combate | date:'HH:mm'}} - {{acionamento.dt_fim_combate | date:'HH:mm'}}</td>
                  <td>{{acionamento.dt_inicio_rescaldo | date:'HH:mm'}} - {{acionamento.dt_fim_rescaldo | date:'HH:mm'}}</td>
                </tr>
              </table>
            </div>
          </div>

          <div class="report-data-box" [ngClass]="reports.selected_report.dados_perdas.length ? 'report-data-box' : 'report-data-box-empty'">
            <div class="data-box-header">
              <div class="header-title">
                <div class="button-and-tip-wrapper" matTooltip="Registrar danos" matTooltipPosition="above">
                  <button class="enabled-btn" (click)='addPerdas()'>
                   <mat-icon>trending_down</mat-icon>
                  </button>
                </div>
                <span (click)="toggleExpandDamageBox()">
                  Danos
                </span>
              </div>
              <div *ngIf="reports.selected_report.dados_perdas.length > 0" class="expansion-arrow-wrapper" (click)="toggleExpandDamageBox()">
                <mat-icon *ngIf="!this.damageBoxExpanded" color="white">expand_more</mat-icon>
                <mat-icon *ngIf="this.damageBoxExpanded" color="white">expand_less</mat-icon>
              </div>
            </div>

            <div class="damage-info" *ngIf="this.damageBoxExpanded && reports.selected_report.dados_perdas.length > 0">
              <div class="table-wrapper-damage">
                <table>
                  <tr id="table-headers-damage">
                    <th>Tipo</th>
                    <th>Valor</th>
                    <th>Unidade</th>
                  </tr>

                  <tr *ngFor="let damage of reports.selected_report.dados_perdas" (click)="deleteDamage(damage)">
                    <td>{{damage.tipo | titlecase}}</td>
                    <td>{{damage.valor}}</td>
                    <td>{{damage.unidade}}</td>
                  </tr>
                </table>
              </div>
            </div>
          </div>

          <div [ngClass]="reports.selected_report.comentarios ? 'report-data-box' : 'report-data-box-empty'">
            <div class="data-box-header">
              <div class="header-title">
                <div class="button-and-tip-wrapper" matTooltip="Registrar danos" matTooltipPosition="above">
                  <button class="enabled-btn" (click)='addComments()'>
                   <mat-icon>comment</mat-icon>
                  </button>
                </div>
                <span (click)="toggleExpandCommentsBox()">
                  Comentários
                </span>
              </div>
              <div *ngIf="reports.selected_report.comentarios" class="expansion-arrow-wrapper" (click)="toggleExpandCommentsBox()">
                <mat-icon *ngIf="!this.commentsBoxExpanded" color="white">expand_more</mat-icon>
                <mat-icon *ngIf="this.commentsBoxExpanded" color="white">expand_less</mat-icon>
              </div>
            </div>

            <div class="comment-wrapper" *ngIf="this.commentsBoxExpanded && reports.selected_report.comentarios">
              {{reports.selected_report.comentarios}}
            </div>
          </div>

          <div class="toggles">
            <mat-slide-toggle [checked]="reports.selected_report.is_test" (change)="toggleIsTest($event)" [color]="'warn'">
              <span style="color: white;">Relatório teste</span>
            </mat-slide-toggle>

            <mat-slide-toggle [checked]="reports.selected_report.sem_risco" (change)="toggleRisk($event)" [color]="'warn'">
              <span style="color: white;">Não oferece risco</span>
            </mat-slide-toggle>
          </div>
      </div>

   </section>

   <section class='sidenav-content-wrapper'  *ngIf="show">
      <app-sat *ngIf="show == 'sat' "></app-sat>
      <app-weather *ngIf="show == 'weather' "></app-weather>
   </section>
</main>
