import { Component, Input, OnInit } from '@angular/core';
import { GeoService } from 'src/app/services/geo.service';

@Component({
  selector: 'app-geoloc-menu',
  templateUrl: './geoloc-menu.component.html',
  styleUrls: ['./geoloc-menu.component.scss']
})
export class GeolocMenuComponent implements OnInit {

    public lat: number;
    public lon: number;
    @Input() public isSingle: boolean = false;

    constructor(
        public geo: GeoService,
    ) { }

    ngOnInit(): void {
      // o metodo abaixo era chamado quando o usuario escolhia como ia geolocalizar - por enquanto, como só tem click no mapa, o método veio para o onInit
      this.geo.setFireLocationClick()
    }
}