<div *ngIf="image">
  <div class="header" *ngIf='header && image?.img_details.nome'>    
    <div>
      {{image.img_details.nome}} <span *ngIf='image.img_details.dist'>: {{image.img_details.dist}} km</span>
    </div>
  </div>

  <div class="img-wrapper">
    <img *ngIf="!b64" [src]="src+image?.img_path | safe: 'url'" class='rep-img' (load)='drawRect()'/>
    <img *ngIf="b64" [src]="image?.img_64 | safe: 'url'" class='rep-img' (load)='drawRect()'/>

    <span class='text-wrapper ptz' *ngIf='image?.img_details.zoom'>
      {{image?.img_details.pan}}° | {{image?.img_details.zoom}}x
    </span>

    <span class='text-wrapper name' *ngIf='image?.img_details.nome'>
      <span *ngIf="!header">{{image.img_details.nome}} - </span>{{image.img_timestamp | date: "dd/MM/yy HH:mm:ss" }}
      <span *ngIf='image?.img_details && number'>
        - {{number}}
      </span>
    </span>

    <canvas id='detectionCanvas'></canvas>
  </div>
</div>