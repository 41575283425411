<app-small-loader *ngIf="this.updating" [message]="updateMessage"></app-small-loader>

<main>
  <div class="header">
    <span>Olá, {{user.getNome()}}</span>
  </div>


  <div class="chapter" id="configs-btn" (click)="handleOpenConfiguracoes()" style="display: flex; align-items: center;">
    Configurações
    <img src="assets/icons/new-icons/keyboard-arrow-down-light.svg" *ngIf="!this.configuracoesOpen" style="margin-left: 10px; height: 12px; width: 12px;"/>
    <img src="assets/icons/new-icons/keyboard-arrow-right-light.svg" *ngIf="this.configuracoesOpen" style="margin-left: 10px; height: 12px; width: 12px;"/>
  </div>

  <div [ngClass]="this.configuracoesOpen ? 'configuration-triggers' : 'hide'">
    <div style="display: flex; justify-content: flex-start; align-items: center; color: white; padding-left: 1vw;">
      <mat-slide-toggle (change)="toggleSound()" [checked]='this.user.soundDetection'>
        <img src="assets/icons/new-icons/notification-off-light.svg" *ngIf='!user.soundDetection' style="width: 18px; height: 18px; margin-left: .35vw;"/>
        <img src="assets/icons/new-icons/notification-light.svg" *ngIf='user.soundDetection' style="width: 18px; height: 18px; margin-left: .35vw;"/>
      </mat-slide-toggle>
      <span style="margin-left: .75vw;">Som</span>
    </div>

    <div style="display: flex; justify-content: flex-start; align-items: center; color: white; padding-left: 1vw;">
      <mat-slide-toggle (change)="setSendDetectionAlertsState()" [checked]='this.user.toggleNotificationTelegram' id="second-toggle">
        <img src="assets/icons/new-icons/phone-ring-off-light.svg" *ngIf='!user.toggleNotificationTelegram' style="width: 18px; height: 18px; margin-left: .35vw;"/>
        <img src="assets/icons/new-icons/phone-ring-light.svg" *ngIf='user.toggleNotificationTelegram' style="width: 18px; height: 18px; margin-left: .35vw;"/>
      </mat-slide-toggle>
      <span style="margin-left: .75vw;">Enviar detecções no Telegram</span>
    </div>

    <div style="display: flex; justify-content: flex-start; align-items: center; color: white; padding-left: 1vw;" id="third-toggle">
      <mat-slide-toggle (change)="toggleReclassDet()" [checked]='this.user.reclassifyDet'>
        <img src="assets/icons/new-icons/edit-off-light.svg" *ngIf='!user.reclassifyDet' style="width: 18px; height: 18px; margin-left: .35vw;"/>
        <img src="assets/icons/new-icons/edit-light.svg" *ngIf='user.reclassifyDet' style="width: 18px; height: 18px; margin-left: .35vw;"/>
      </mat-slide-toggle>
      <span style="margin-left: .75vw;">Reclassificação de detecções</span>
    </div>

    <div *ngIf="this.user.isUgm()" style="display: flex; justify-content: flex-start; align-items: center; color: white; padding-left: 1vw;">
      <mat-slide-toggle (change)="toggleAutoTri()" [checked]='this.user.autoTriang' id="4th-toggle">
        <img src="assets/icons/new-icons/architeture-light.svg" *ngIf='!user.autoTriang' style="width: 18px; height: 18px; margin-left: .35vw;"/>
      </mat-slide-toggle>
      <span style="margin-left: .75vw;">Triangulação automática <span style="font-style:italic">(beta)</span></span>
    </div>

    <div style="display: flex; justify-content: flex-start; align-items: center; color: white; padding-left: 1vw;">
      <mat-slide-toggle (change)="toggleUseEllipse()" [checked]='this.user.useAuxiliarEllipse' id="5th-toggle">
        <img src="assets/icons/new-icons/location-off-light.svg" *ngIf='!user.useAuxiliarEllipse' style="width: 18px; height: 18px; margin-left: .35vw;"/>
        <img src="assets/icons/new-icons/location-light.svg" *ngIf='user.useAuxiliarEllipse' style="width: 18px; height: 18px; margin-left: .35vw;"/>
      </mat-slide-toggle>
      <span style="margin-left: .75vw;">Elipse auxiliar de localização</span>
    </div>
  </div>

  
  <div class="chapter" id="atualizar-dados-btn" (click)="this.handleOpenAtualizarDados()" style="display: flex; align-items: center;">
    Dados e sincronização
    <img src="assets/icons/new-icons/keyboard-arrow-down-light.svg" *ngIf="!this.atualizarDadosOpen" style="margin-left: 10px; height: 12px; width: 12px;"/>
    <img src="assets/icons/new-icons/keyboard-arrow-right-light.svg" *ngIf="this.atualizarDadosOpen" style="margin-left: 10px; height: 12px; width: 12px;"/>
  </div>

  <button mat-menu-item [ngClass]="this.atualizarDadosOpen ? 'customize' : 'hide'" (click)="this.deleteDetections()">
    <img src="assets/icons/new-icons/trash-light.svg" style="height: 18px; width: 18px; margin-right: 1vw;"/>
    <span>Excluir detecções</span>
  </button>
  <button mat-menu-item [ngClass]="this.atualizarDadosOpen ? 'customize' : 'hide'" (click)="this.reloadInterface()">
    <img src="assets/icons/new-icons/reload-light.svg" style="height: 18px; width: 18px; margin-right: 1vw;"/>
    <span>Recarregar interface</span>
  </button>
  <button mat-menu-item [ngClass]="this.atualizarDadosOpen ? 'customize' : 'hide'" (click)="updateData()">
    <img src="assets/icons/new-icons/download-light.svg" style="height: 18px; width: 18px; margin-right: 1vw;"/>
    <span>Atualizar dados</span>
  </button>
  <button mat-menu-item [ngClass]="this.atualizarDadosOpen ? 'customize' : 'hide'" (click)="updateGeoData()">
    <img src="assets/icons/new-icons/map-light.svg" style="height: 18px; width: 18px; margin-right: 1vw;"/>
    <span>Atualizar base geográfica</span>
  </button>

  <div class="chapter" id="account-options-btn" (click)="handleOpenConta()" style="display: flex; align-items: center;">
    Conta
    <img src="assets/icons/new-icons/keyboard-arrow-down-light.svg" *ngIf="!this.contaOpen" style="margin-left: 10px; height: 12px; width: 12px;"/>
    <img src="assets/icons/new-icons/keyboard-arrow-right-light.svg" *ngIf="this.contaOpen" style="margin-left: 10px; height: 12px; width: 12px;"/>
  </div>
  <button mat-menu-item [ngClass]="this.contaOpen ? 'customize' : 'hide'"
    (click)="openConfigDialogComponentLevel()">
    <img src="assets/icons/new-icons/lock-light.svg" style="height: 18px; width: 18px; margin-right: 1vw;"/>
    <span>Alterar Senha</span>
  </button>

  <button mat-menu-item [ngClass]="this.contaOpen ? 'customize' : 'hide'" (click)="openLogoutDialogComponentLevel()">
    <img src="assets/icons/new-icons/logout-light.svg" style="height: 18px; width: 18px; margin-right: 1vw;"/>
    <span>Desconectar</span>
  </button>
</main>
