import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ReportsService } from 'src/app/services/reports.service';

@Component({
  selector: 'delete-report-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class DeleteReportDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public reports:ReportsService) { }
}