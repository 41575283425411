import { Component, OnInit, ViewChild } from '@angular/core';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

import { HttpService } from 'src/app/services/http.service';
import { GeoService } from 'src/app/services/geo.service';
import { UserService } from 'src/app/services/user.service';
import { SatelliteService } from 'src/app/services/satellite.service';

interface LegendObj {
  legend: string;
  color: string;
}

@Component({
  selector: 'app-sat',
  templateUrl: './sat.component.html',
  styleUrls: ['./sat.component.scss']
})
export class SatComponent implements OnInit {

  constructor(
    private http: HttpService,
    public sat: SatelliteService,
    public user: UserService,
    public geo: GeoService
  ) {}

  @ViewChild(MatSort) sort: MatSort;

  public lastUpdated:any;
  public loaded:boolean = false;

  public clustersDataSource;
  public clustersColumns: string[] = [
    'cluster_id',
    'newest_hotspot',
    'oldest_hotspot',
    'cluster_duration',
    'amount_of_hotspots',
    // 'confirmed'
    // 'area_ha',
  ]
  public satColorMap:LegendObj[] = [
    {legend:'30 min' , color: 'red'},
    {legend:'1 hora' , color: 'orange'},
    {legend:'3 horas' , color: 'yellow'},
    {legend:'12 horas' , color: 'gray'},
  ]


  async ngOnInit(){
    // await this.getLastUpdated()
    this.clustersDataSource = new MatTableDataSource(this.sat.clusters)
    this.clustersDataSource.sort = this.sort;

    this.loaded = true;
  }

  selectCluster(id){
    this.geo.openClusterPopUp(id)
    this.sat.selectCluster(id)
  }

  // async getLastUpdated(){
  //   let lastUpdatedRaw = await this.http.centralGet('get_last_updated')
  //   console.log('lastUpdatedRaw', lastUpdatedRaw)
  //   try{
  //     this.lastUpdated = lastUpdatedRaw.filter(x=> x.data_name == 'inpe')[0].updated_dt
  //     console.log('getLastUpdated', this.lastUpdated)
  //   }
  //   catch{
  //     console.log('error in getLastUpdated')
  //   }
  // }

}
