import L from 'leaflet';

// OFFLINE -> querendo deixar deprecated
// export const Esri_Borders_offline = new L.TileLayer(
//   'http://localhost:5000/tiles/boundaries_and_places/{z}/{y}/{x}.jpg'
// );


// new
const BaseLayersList: RawTileLayerObj[] = [
  {
    name: 'Satélite Google',
    url: 'http://mt0.google.com/vt/lyrs=s&hl=en&x={x}&y={y}&z={z}',
    options: {
      attribution: '&copy; Google Maps tile service',
      subdomains: ['mt0', 'mt1', 'mt2', 'mt3']
    }
  },
  {
    name: 'Satélite Esri',
    url: 'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}',
    options: {
      attribution: 'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community',
    }
  },
  {
    name: 'OpenStreetMap',
    url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
    options: {
      attribution: '&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
    }
  },
  {
    name: 'Topografia',
    url: 'https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png',
    options: {
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    }
  }
]

export interface LayerObj {
  legend: string;
  layer: any;
  active: boolean;
}

export interface LayerGroupObj {
  groupId: number;
  groupTitle: string;
  layersList: LayerObj[];
  icon: string;
}

interface RawTileLayerObj {
  name: string;
  url: string;
  options?: object;
  active?: boolean;
}

export function createBaseLayersList(){
  let layerList:LayerObj[] = []

  for (const obj of BaseLayersList) {
    let baseLayer = L.tileLayer(obj.url, {
      ...obj.options,
      pane:'mapPane'
    });

    layerList.push({
      'legend': obj.name,
      'layer': baseLayer,
      'active': !!obj.active
    })
  }

  return layerList
}
