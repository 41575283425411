import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class UtilsService {

  constructor() { }

  deepCopy(obj){
    return JSON.parse(JSON.stringify(obj));
  }

  sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
