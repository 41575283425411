
import { OnInit, Component, OnDestroy } from "@angular/core";
import { AmplitudeService } from "src/app/services/amplitude.service";
import { CameraService } from "src/app/services/camera.service";
import { DetectionsService } from "src/app/services/detections.service";
import { SocketService } from "src/app/services/socket.service";
import { CustomErroHandlerService } from "src/app/services/custom-erro-handler.service"
import { OnboardService } from "src/app/services/onboard.service";
import { UtilsService } from "src/app/services/utils.service";
import { UserService } from "src/app/services/user.service";
import { InterfaceTabsService } from "src/app/services/interface-tabs.service";

@Component({
    selector: 'app-cameras-sidenav',
    templateUrl: './cameras-sidenav.component.html',
    styleUrls: ['./cameras-sidenav.component.scss']
})
export class CamerasSidenavComponent implements OnInit, OnDestroy {

  public toggleAttBtn = false;

  constructor(
      public camerasService: CameraService,
      public detectionsService: DetectionsService,
      public amplitudeService: AmplitudeService,
      public socketService: SocketService,
      public customErrorHandler: CustomErroHandlerService,
      public onboardService: OnboardService,
      public utilsService: UtilsService,
      public userService: UserService,
      public interfaceTabsService: InterfaceTabsService
  ) { }
  
  ngOnInit(): void {
    // debug suzano
    console.log("CamerasSidenavComponent OnInit call", "Cameras' list current value: ", this.camerasService.cameras)
    this.customErrorHandler.sendCustomLog("Component mount", "OnInit (by CamerasSidenavComponent)")
    this.socketService.onAttDadosCliente().subscribe(this.proccessAttDadosCliente);
  }

  async handleDoubleClick(cam) {
    if (this.detectionsService.aiming) return
    
    try {
      cam.spin = false;

      if (!cam.auto) cam.auto = 1
      else cam.auto = 0

      let activate = cam.auto;

      if (activate == 1) {
        this.amplitudeService.sendEvent("Ativou Deteccao Double Click", {
          "id_camera": cam.id_cam
        })
      }
      else {
        this.amplitudeService.sendEvent("Desativou Deteccao Double Click", {
          "id_camera": cam.id_cam
        })
      }

      await this.camerasService.autoCommand(cam.id_cam, activate)
    }
    catch (err) {
      this.customErrorHandler.handleError(err);
      console.log("Erro ao alterar o estado de detecção da câmera", err)
    }
    this.socketService.sendCameras([true, [cam.id_cam], [cam.auto]])
  }

  changeToDetectionsTab() {
    this.interfaceTabsService.setSelectedTab(0)
  }

  changeToControlsTab() {
    this.interfaceTabsService.setSelectedTab(1)
  }

  changeToConfigsTab() {
    this.interfaceTabsService.setSelectedTab(2)
  }

  startCamTour() {
    this.amplitudeService.sendEvent("Clicou Ajuda Camera")
    this.changeToControlsTab();
    this.onboardService.startCamTour(false, this.camerasService.cam_options_columns);
  }

  async startDetectionTour() {
    this.amplitudeService.sendEvent("Clicou Ajuda Deteccoes")
    this.changeToDetectionsTab();
    await this.utilsService.sleep(500);
    this.onboardService.startDetectionTour();
  }

  async startConfigsTour() {
    this.amplitudeService.sendEvent("Clicou Ajuda Configuracoes")
    this.changeToConfigsTab();
    await this.utilsService.sleep(500);
    this.onboardService.startConfigsTour(false);
  }

  startWelcomeTour() {
    this.amplitudeService.sendEvent("Clicou Ajuda Novidades")
    this.onboardService.startWelcomeTour();
  }

  openZendeskTour() {
    this.amplitudeService.sendEvent("Clicou Ajuda Zendesk")
    this.onboardService.openZendeskTour();
  }

  setFirstCamera(id:number) {
    if (this.detectionsService.aiming) return
    else this.camerasService.setFirstCamera(id)
  }

  proccessReloadAfterAttDadosCliente = async () => {
    const randomParam = `?rand=${Math.random()}`; // Generate a random query parameter
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('#')[0]; // Extract the base URL without the hash
    const newUrl = baseUrl + randomParam + window.location.hash; // Include the original hash
    window.location.assign(newUrl); // Use window.location.assign() to modify the URL
    window.location.reload(); // Reload the modified URL, bypassing the cache
    this.toggleAttBtn = false;
  }

  proccessAttDadosCliente = async (msg: any) => {
    await this.userService.updateData();
    this.toggleAttBtn = true;
    this.socketService.sendAtulizaDetection(this.camerasService.getIdCamerasList())
  }

  // debug suzano
  ngOnDestroy(): void {
    console.log("CamerasSidenavComponent OnDestroy call", "Cameras' list current value: ", this.camerasService.cameras)
    this.customErrorHandler.sendCustomLog("Component unmount", "OnDestroy (by CamerasSidenavComponent)")
  }
}