<main [formGroup]="reportForm">
  <h2>Criar relatório</h2>
  <p>Ao clicar no mapa, a latitude e a longitude são preenchidas automaticamente</p>

  <div>
    <mat-form-field appearance="outline" color="accent" class="form-field">
      <mat-label>Latitude</mat-label>
      <input matInput formControlName="latitude" type="number" min="-90" max="90" id="lat"/>
    </mat-form-field>
      
    <mat-form-field appearance="outline" color="accent" class="form-field">
      <mat-label>Longitude</mat-label>
      <input matInput formControlName="longitude" type="number" min="-180" max="180" id="lon"/>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" color="accent" class="form-field">
      <mat-label>Origem Detecção</mat-label>
      <mat-select formControlName="origemDeteccao">
        <mat-option *ngFor="let o of origens" [value]="o.id_origem_deteccao">{{o.origem_deteccao}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="outline" color="accent" class="form-field">
      <mat-label>Causa</mat-label>
      <mat-select formControlName="causaIncendio">
        <mat-option *ngFor="let c of causas" [value]="c.id_causa_incendio">{{c.causa_incendio}}</mat-option>
      </mat-select>
    </mat-form-field>
  </div>

  <div>
    <mat-form-field appearance="outline" color="accent" class="form-field">
      <mat-label>Hora detecção</mat-label>
      <input matInput formControlName="horaDeteccao" type="datetime-local"/>
    </mat-form-field>
  </div>

  <div class="action-buttons">
    <button mat-raised-button color="warn" [mat-dialog-close]="false">Cancelar</button>
    <button mat-raised-button color="primary" [disabled]="reportForm.invalid" (click)="createReport()">Criar relatório</button>
  </div>
</main>
