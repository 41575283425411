import { Injectable } from '@angular/core';
import { LayerObj, LayerGroupObj } from './geo.store'
import * as gf from "./geo.format";

import L from 'leaflet';

import {environment} from '../../environments/environment'
const geoServerBaseUrl = environment.geoserverUrl;

@Injectable({
  providedIn: 'root'
})
export class GeoLayersService {

  constructor() { }

  createViewshedLayerGroup(camList):LayerGroupObj{

    let viewshedOverlays:LayerObj[] = [];

    for (var c of camList){
      let viewshed_local = L.tileLayer.wms(`${geoServerBaseUrl}/geoserver/ugm/wms`,
        {
          layers: 'ugm:viewshed_local',
          format: 'image/png',
          version: '1.1.0',
          transparent: true,
          opacity: 0.7,
          viewparams: `id_local:${c.id_local}`,
          pane: 'shadowPane'
        }
      );

      viewshedOverlays.push({
        'layer': viewshed_local,
        'legend': c.nome_local,
        'active': false,
      });
    }

    return {
      'groupId': 7,
      'groupTitle': 'Mapa de visibilidade',
      'icon': 'visibility',
      'layersList': viewshedOverlays
    }
  }

  createLimitLayerGroup(shp_layer):LayerGroupObj{
    let divisas_layer = L.tileLayer('https://services.arcgisonline.com/arcgis/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}');

    // tava estourando api
    let topografia = L.tileLayer.wms('https://ows.terrestris.de/osm/service',
    {
      version: '1.3.0',
      format: 'image/png',
      transparent: true,
      layers: `SRTM30-Contour`,
    });

    let limitsOverlays = [
      {
        'legend': 'Propriedade',
        'layer': shp_layer,
        'active': true
      },
      {
        'legend': 'Divisas',
        'layer': divisas_layer,
        'active': true
      }
    ]

    return {
      'groupId': 1,
      'groupTitle': 'Limites',
      'icon': 'map',
      'layersList': limitsOverlays
    }
  }

  createPoiLayerGroup(poiGeoJson, tipos_poi):LayerGroupObj{
    if(!poiGeoJson.features) return null

    let poiOverlay = []

    for (let poi_type of tipos_poi) {
      let poi_features = poiGeoJson.features.filter(p => p.properties?.id_tipo_poi == poi_type.id_tipo_poi) || [];

      if (!poi_features.length) continue;

      let poi_collection = {
        type:'FeatureCollection',
        features: poi_features
      }

      let layer = L.geoJSON(poi_collection, {
        pointToLayer: (feature, latlng) => {
          let icon = L.icon({
            iconSize: [28, 28],
            iconUrl: 'assets/map_icons/' + poi_type.icon_path
          });
          return L.marker(latlng, {icon: icon});
        },
        onEachFeature: (feature, layer) => {
          layer.bindPopup(gf.formatPoiInfo(feature.properties));
        }
      })

      poiOverlay.push({
        'legend':poi_type.legend,
        'layer': layer,
        'active': false
      })
    }

    if (!poiOverlay.length) return null

    return {
      'groupId': 3,
      'groupTitle': 'Pontos de interesse',
      'icon': 'push_pin',
      'layersList': poiOverlay
    }
  }


  createPorLayerGroup(pontos_referencia):LayerGroupObj {
    if(!pontos_referencia.length) return;

    let por_markers = []

    let por_icon = L.icon({
      iconSize: [28, 28],
      iconUrl: './assets/map_icons/map_pin_black.svg'
    });

    for (const por of pontos_referencia) {

      let por_marker = L.marker({lat:por.lat, lng:por.lon}, {icon: por_icon});
      por_marker.bindPopup(gf.formatPorInfo(por));
      por_marker.uuid_por = por.uuid_por;

      por_markers.push(por_marker);
    }


    let por_group = L.layerGroup(por_markers);
    return {
      'groupId': 9,
      'groupTitle': 'Pontos de calibração',
      'icon': 'pin_drop',
      'layersList': [{
        'legend':'Calibrações',
        'layer': por_group,
        'active': false
      }]
    }
  }



  private openweathermapKey = '099e2820b6f74f6fdb5e112c54a65ef3'
  createWindLayerGroup(){
    return {
      'groupId': 14,
      'groupTitle': "Clima",
      'icon': 'thermostat',
      'layersList': [{
        'legend':"Vento",
        'layer': L.tileLayer(`http://maps.openweathermap.org/maps/2.0/weather/WND/{z}/{x}/{y}?appid=${this.openweathermapKey}`),
        'active': false
      }]
    }
  }
}
