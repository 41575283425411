<main>

  <mat-dialog-content class='content'>

    <h2> Alterar Senha </h2>

    <div *ngIf='!success' class='content'>
      <h3 *ngIf="wrongPassError" style='color:red; display: flex;'>
        <img src="assets/icons/new-icons/error-outline-warn.svg" style="width: 16px; height: 16px;"/>
        Senha incorreta
      </h3>

      <mat-form-field appearance="outline" [color]="'accent'">
        <mat-label>Senha atual</mat-label>
        <span matPrefix style="margin: 0 .65vw;">
          <img src="assets/icons/new-icons/key-dark.svg" style="width: 16px; height: 16px;"/>
        </span>
        <input matInput [type]="showPass1 ? 'text' : 'password'" [(ngModel)]="pass1">
          <button mat-icon-button matSuffix (click)="showPass1 = !showPass1">
            <img *ngIf="showPass1" src="assets/icons/new-icons/visibility-dark.svg" style="width: 16px; height: 16px;"/>
            <img *ngIf="!showPass1" src="assets/icons/new-icons/visibility-off-dark.svg" style="width: 16px; height: 16px;"/>
          </button>
      </mat-form-field>

      <mat-form-field appearance="outline" [color]="'accent'">
        <mat-label>Nova senha</mat-label>
        <span matPrefix style="margin: 0 .65vw;">
          <img src="assets/icons/new-icons/key-dark.svg" style="width: 16px; height: 16px;"/>
        </span>
        <input matInput [type]="showPass2 ? 'text' : 'password'" [(ngModel)]="pass2">
          <button mat-icon-button matSuffix (click)="showPass2 = !showPass2">
            <img *ngIf="showPass2" src="assets/icons/new-icons/visibility-dark.svg" style="width: 16px; height: 16px;"/>
            <img *ngIf="!showPass2" src="assets/icons/new-icons/visibility-off-dark.svg" style="width: 16px; height: 16px;"/>
          </button>
      </mat-form-field>
    </div>

    <div *ngIf='success' class='content'>
      <h3 style='color:green; display: flex;'>
        <img src="assets/icons/new-icons/check-circle-dark.svg" style="height: 18px; width: 18px;"/>
        Senha alterada com sucesso
      </h3>
    </div>

  </mat-dialog-content>

  <mat-dialog-actions *ngIf='!success' align="center">
    <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
    <button mat-raised-button (click)='changePassword()' color='primary'>Confirmar</button>
  </mat-dialog-actions>

  <mat-dialog-actions *ngIf='success' align="center">
    <button mat-raised-button [mat-dialog-close]='true' color='primary'>Voltar</button>
  </mat-dialog-actions>

</main>
