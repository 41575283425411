import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions } from '@angular/material/tooltip';

import { CameraService } from '../../services/camera.service';
import { ReportsService } from '../../services/reports.service';
import { UserService } from '../../services/user.service';
import { StorageService } from '../../services/storage.service';
import { DetectionsService } from 'src/app/services/detections.service';
import { OnboardService } from '../../services/onboard.service';
import { HttpService } from '../../services/http.service';
import { SocketService  } from '../../services/socket.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { FireLocationService } from 'src/app/services/fire-location.service';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
import { InterfaceTabsService } from 'src/app/services/interface-tabs.service';

export const delayedToolTips: MatTooltipDefaultOptions = {
  showDelay: 500,
  hideDelay: 0,
  touchendHideDelay: 0,
};

const DEFAULT_AUTOTRI_SMOKE_PROB_TSH = 0.4

@Component({
  selector: 'app-new-interface',
  templateUrl: './new-interface.component.html',
  styleUrls: ['./new-interface.component.scss'],
  providers: [
    {provide: MAT_TOOLTIP_DEFAULT_OPTIONS, useValue: delayedToolTips}
  ]
})
export class NewInterfaceComponent implements OnInit, OnDestroy {

  constructor(
    public cams: CameraService,
    public user: UserService,
    public reports: ReportsService,
    public det: DetectionsService,
    public storage: StorageService,
    public onBoard:OnboardService,
    private http: HttpService,
    private socketService: SocketService,
    private amplitude: AmplitudeService,
    private fireLoc: FireLocationService,
    public customErrorHandler:CustomErroHandlerService,
    public interfaceTabsService: InterfaceTabsService
    )
    {}
  public loaded: boolean = false;
  public streamingType: string = "go-webrtc";
  public atualize;
  public updating: boolean = false;
  private autoTriSmokeProbTsh: number = DEFAULT_AUTOTRI_SMOKE_PROB_TSH;

  async ngOnInit() {
    try {
      this.storage.clean();
      this.socketService.joinIdPlantRoom();
      let cams_ok = await this.cams.camInit();
      if (!cams_ok) throw new Error("Erro ao inicializar câmeras...");

      console.log('%c🗼 CamList', 'background: #222; color: #bada55', this.cams.cameras);

      await this.det.getReclassTypes(); 
      
      this.socketService.onNewMessageGelocData().subscribe(this.processGelocData);

      this.streamingType = this.user.getStreamingType()

      const modelParams = await this.det.getModelParams();
      if (modelParams.smoke_tsh_zoom) this.autoTriSmokeProbTsh = modelParams.smoke_tsh_zoom;
    }
    catch (error) {
      this.customErrorHandler.handleError(error);
      console.log(error)
    }
    finally {
      // SERVICES PROCESS INIT
      await this.cams.logPtz();
      await this.det.getDetections();
      this.precisoAtualizar()

      setTimeout(() => {
        this.loaded = true;
        this.onBoard.startWelcomeTour();
      }, 500);
    }
  }

  processGelocData = async (msg: any) => {

    console.log('tamanho', msg.length);
  
    if( msg.length ) {
  
      const maximo = msg.reduce((prev, current) => (prev.smoke_prob > current.smoke_prob) ? prev : current)
  
      console.log('DEBUG3', maximo);
      console.log('AUTOTRI_SMOKE_TSH: ', this.autoTriSmokeProbTsh);

      if ( maximo.smoke_prob > this.autoTriSmokeProbTsh ) {
        await this.cams.sendAbsPTZ({
          id: maximo.positions.id_cam,
          pan: maximo.positions.pan,
          tilt: maximo.positions.tilt,
          zoom: maximo.positions.zoom
        })

        setTimeout(() => {
          this.cams.drawBboxTriAuto(maximo.model_bbox, maximo.smoke_prob,maximo.positions.id_cam,);
        }, 5000);
        
        setTimeout(() => {
          this.det.searchTriangulation(true);
        }, 5000);

      } else {
        console.log('Tentando apontar camera para localizacao estimada: ', this.fireLoc.estimatedLatLng);
        try {
          await this.fireLoc.aimCameraToLoc(Number(maximo.positions.id_cam), this.fireLoc.estimatedLatLng)
        } catch ( error ){
          this.customErrorHandler.handleError(error);
          console.warn(`Erro ao apontar camera para localizacao estimada: ${error}`)
        }    
      }

      this.det.autoTriangulando = false
  
    } else {
      this.det.autoTriangulando = false
    }
    this.det.setSecondCamAuto(0);
  }

  async handleDoubleClick(cam) {
    if (this.det.aiming) return
    
    try {
      cam.spin = false;

      if (!cam.auto) cam.auto = 1
      else cam.auto = 0

      let activate = cam.auto;

      if (activate == 1) {
        this.amplitude.sendEvent("Ativou Deteccao Double Click", {
          "id_camera": cam.id_cam
        })
      }
      else {
        this.amplitude.sendEvent("Desativou Deteccao Double Click", {
          "id_camera": cam.id_cam
        })
      }

      await this.cams.autoCommand(cam.id_cam, activate)
    }
    catch (err) {
      this.customErrorHandler.handleError(err);
      console.log("Erro ao alterar o estado de detecção da câmera", err)
    }
    this.socketService.sendCameras([true, [cam.id_cam], [cam.auto]])
  }

  async precisoAtualizar(){

    const status = await this.http.get('get_deploy_params');
    if (status['pantera_vesion'] === status['wanted_pantera_version']) {
      this.atualize = false
    }else{
      this.atualize = true
    }

    setTimeout(async () => await this.precisoAtualizar(), 1000*600);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(){
    this.cams.closeLoggers();
    this.det.closeDetectionsTimeout();
  }

  setFirstCamera(id:number) {
    if (this.det.aiming) return
    else this.cams.setFirstCamera(id)
  }

}

