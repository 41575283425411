<section class='side-nav'>
    <div class="logo-container">
      <div class='pantera-logo'></div>
    </div>

    <div class='side-btn-container'>
      <div id='select-cam-btns'>
        <div class="sidenav-header">
          <button (click)='camerasService.turnOnAllDetections()' mat-mini-fab color='warn' matTooltipPosition="above" matTooltip="Liga o modo automático em todas as câmeras">
            <img src="assets/icons/new-icons/gps-light.svg" style="height: 24px; width: 24px; vertical-align: middle;"/>
          </button>
        </div>

        <div class="cameras-btns">
          <button *ngFor="let c of camerasService.cameras"
            (dblclick)='handleDoubleClick(c)'
            class='side-btn'
            [disabled]='detectionsService.aiming && c.id_cam != detectionsService.active_detection?.id_camera_deteccao && c.id_cam != camerasService.getSecondCamId()'
            [ngClass]="camerasService.getFirstCamId() == c.id_cam ? 'selected' : ''"
            (click)='setFirstCamera(c.id_cam)'
            >
            <span *ngIf='!c.auto'>
              ⚫
            </span>
            <span *ngIf='c.auto == 1'>
              🔴
            </span>
            <span *ngIf='c.auto == 2'>
              🟡
            </span>
            {{ c.nome_local }}
          </button>
        </div>
      </div>
    </div>

    <div class="att-container" *ngIf="toggleAttBtn">
      <button mat-icon-button
        id='att-btn'
        (click)='proccessReloadAfterAttDadosCliente()'
        matTooltip="Os dados foram atualizados remotamente, clique para recarregar a interface!" matTooltipPosition="above"
        class="att-user-btn">
        <img src="assets/icons/new-icons/update-warn.svg" style="height: 24px; width: 24px;"/>
      </button>
    </div>

    <div class="help-container">
      <button mat-icon-button
        id='help-btn'
        [matMenuTriggerFor]="helpMenu"
        matTooltip="Ajuda" matTooltipPosition="above"
        class="help-user-btn">
        <img src="assets/icons/new-icons/help-light.svg" style="height: 24px; width: 24px;"/>
      </button>

      <mat-menu #helpMenu="matMenu">

        <button mat-menu-item (click)="startDetectionTour()" style="display: flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/gps-dark.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: .5vw; align-self: center;">Detecções</span>
        </button>

        <button mat-menu-item (click)="startCamTour()" style="display: flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/camera-dark.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: .5vw; align-self: center;">Uso das câmeras</span>
        </button>

        <button mat-menu-item (click)="startConfigsTour()" style="display: flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/settings-dark.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: .5vw; align-self: center;">Configurações</span>
        </button>

        <button mat-menu-item (click)="startWelcomeTour()" style="display: flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/notification-dark.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: .5vw; align-self: center;">Novidades</span>
        </button>

        <button mat-menu-item (click)="openZendeskTour()" style="display: flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/help-dark.svg" style="height: 20px; width: 20px; vertical-align: top;"/>
          <span style="margin-left: .5vw; align-self: center;">Fale conosco</span>
        </button>
      </mat-menu>
    </div>
  </section>