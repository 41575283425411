import { Component, OnInit } from '@angular/core';
import { GeoService } from 'src/app/services/geo.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { CameraService } from 'src/app/services/camera.service';
import { ReportsService } from 'src/app/services/reports.service';
import { OnboardService } from 'src/app/services/onboard.service';
import { UserService } from 'src/app/services/user.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-map-sidenav',
  templateUrl: './map-sidenav.component.html',
  styleUrls: ['./map-sidenav.component.scss']
})
export class MapSidenavComponent implements OnInit {

  constructor(
    public geo: GeoService,
    public cams: CameraService,
    public onBoard: OnboardService,  
    public reports: ReportsService,
    public sat: SatelliteService,
    public user: UserService,
    public amplitude: AmplitudeService
  )
  { }

  public show

  ngOnInit(): void {
  }

  setShow(menu){
    if (this.show == menu) {
      this.show = null
    } else {
      this.show = menu
    }
  }

  toggleCamMove(){
    if (this.show == null) {
      this.amplitude.sendEvent("Abriu Menu Direcionar Câmera Por Click No Mapa")
    }
    else {
      this.amplitude.sendEvent("Fechou Menu Direcionar Câmera Por Click No Mapa")
    }
    this.setShow('camMove')
  }

  toggleCenterOnCam(){
    if (this.show == null) {
      this.amplitude.sendEvent("Abriu Menu Centralizar Em Câmera")
    }
    else {
      this.amplitude.sendEvent("Fechou Menu Centralizar Em Câmera")
    }
    this.setShow('centerOnCam')
  }
  
  toggleSat(){
    if (this.show == null) {
      this.amplitude.sendEvent("Abriu Menu Satelital")
    }
    else {
      this.amplitude.sendEvent("Fechou Menu Satelital")
    }
    this.setShow('sat')
  }

  toggleWeather(){
    if (this.show == null) {
      this.amplitude.sendEvent("Abriu Dados Meteorologicos")
    }
    else {
      this.amplitude.sendEvent("Fechou Dados Meteorologicos")
    }
    this.setShow('weather')
  }
}
