<main *ngIf="!geo.snapping_map" id="report-pdf-find-me">
  <div class="content" id="content-wrapper">
    <div class="header">
      <div class="report-data-and-actions">
        <div class="report-main-header">
          <mat-icon>local_fire_department</mat-icon>
          <h1 *ngIf="reports.selected_report.n_relatorio_planta">
            Relatório {{ reports.selected_report.n_relatorio_planta }} | <span>{{reports.selected_report.datetime_deteccao}}</span>
          </h1>

          <h1 *ngIf="!reports.selected_report.n_relatorio_planta">
            Relatório sem número | <span>{{reports.selected_report.datetime_deteccao}}</span>
          </h1>
        </div>
        <div class="actions-row" id="to-hide">
          <button [mat-dialog-close]="false">Cancelar</button>
          <button (click)='saveReport()'>Baixar PDF</button>
          <button [mat-dialog-close]="true" (click)='finishReport()'>Finalizar relatório</button>
        </div>
      </div>

      <div class="logos">
        <img src="assets/logos/logo_ugm.svg"/>
        <img src="assets/logos/pantera_head_negative.svg"/>
        <div *ngIf="clientLogo64.length > 0" class="verticalLine"></div>
        <img *ngIf="clientLogo64.length > 0" [src]="clientLogo64 | safe: 'url'" id="client_logo">
      </div>
    </div>

    <div class="main-container">
      <div class="data-section-row">
        <div class="white-box">
          <div class="white-box-header">
            <mat-icon>location_on</mat-icon>
            <h2>Informações do local</h2>
          </div>

          <div class="data-box-content">
            <div class="data-box-column" id="loc-data">
              <div class="data-box-attribute-info" *ngIf="reports.selected_report.dados_localizacao[0].lat && reports.selected_report.dados_localizacao[0].lon">
                <span>Lat: {{reports.selected_report.dados_localizacao[0].lat}} | Lon: {{reports.selected_report.dados_localizacao[0].lon}}</span>
              </div>
              <div class="data-box-attribute-info" *ngFor="let i of reports.selected_report.dados_localizacao[0]?.dados_geo.atributos?.Local">
                <span>{{i.key}}: {{i.value}}</span>
              </div>
            </div>

            <div id="weather-data" class="data-box-column" *ngIf="reports.selected_report.dados_meteo[0]">
              <div class="data-box-attribute-info" *ngIf="reports.selected_report.dados_meteo[0]['temp']">
                <mat-icon>
                  thermostat
                </mat-icon>
                <span>
                  Temperatura: {{reports.selected_report.dados_meteo[0]['temp']}}°
                </span>
              </div>

              <div class="data-box-attribute-info" *ngIf="reports.selected_report.dados_meteo[0]['humidity']">
                <mat-icon>
                  water_drop
                </mat-icon>
                <span>
                  Umidade: {{reports.selected_report.dados_meteo[0]['humidity']}}%
                </span>
              </div>

              <div class="data-box-attribute-info" *ngIf="reports.selected_report.dados_meteo[0]['wind_speed']">
                <mat-icon>
                  air
                </mat-icon>
                <span>
                  Vel. do vento: {{reports.selected_report.dados_meteo[0]['wind_speed']*3.6|number:'.2-2'}}km/h
                </span>
              </div>

              <div class="data-box-attribute-info" *ngIf="reports.selected_report.dados_meteo[0]['wind_deg']">
                <mat-icon>
                  explore
                </mat-icon>
                <span>
                  Dir. do vento: {{reports.selected_report.dados_meteo?.[0]?.['wind_deg']}}°
                </span>
              </div>

              <div class="data-box-attribute-info" *ngIf="reports.selected_report.dados_meteo[0]['rain']">
                <mat-icon>
                  umbrella
                </mat-icon>
                <span>
                  Precipitação: {{reports.selected_report.dados_meteo?.[0]?.['rain']}}mm
                </span>
              </div>
            </div>
          </div>
        </div>

        <div class="white-box">
          <div class="white-box-header">
            <mat-icon>image</mat-icon>
            <h2>Imagens do incêndio</h2>
          </div>

          <div *ngIf="images.length <= 0">
            <h3 style="color: white;">Sem imagens</h3>
          </div>

          <div class="images-wrapper" *ngIf="images.length > 0">
            <div class="image-container" *ngIf="images[0].img_64">
              <img [src]="images[0].img_64 | safe: 'url'"/>

              <div class="metadata-image">
                <span *ngIf="images[0].img_timestamp">
                  {{images[0].img_timestamp | date: "dd/MM/yy HH:mm:ss" }}
                </span>

                <span *ngIf="images[0].img_details.pan">
                  {{images[0].img_details.pan}}° | {{images[0].img_details.zoom}}x | ~{{images[0].img_details?.dist | number: '1.2-2'}}km - {{images[0].img_details.nome}}
                </span>
              </div>
            </div>

            <div class="image-container" *ngIf="images[1].img_64">
              <img [src]="images[1].img_64 | safe: 'url'"/>

              <div class="metadata-image">
                <span *ngIf="images[1].img_timestamp">
                  {{images[1].img_timestamp | date: "dd/MM/yy HH:mm:ss" }}
                </span>
                <span *ngIf="images[1].img_details.pan">
                  {{images[1].img_details.pan}}° | {{images[1].img_details.zoom}}x | ~{{images[1].img_details?.dist | number: '1.2-2'}}km - {{images[1].img_details.nome}}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="data-section-row">
        <div class="white-box" id="third-white-box">
          <div class="white-box-header">
            <mat-icon>fire_truck</mat-icon>
            <h2>Acionamentos</h2>
          </div>

          <div *ngIf="reports.selected_report.acionamentos.length <= 0">
            <h3 style="color: white;">Sem acionamentos</h3>
          </div>

          <div class="table-wrapper" *ngIf="reports.selected_report.acionamentos.length > 0">
            <table>
              <tr>
                <th>Brigada</th>
                <th>Acionamento</th>
                <th>Combate</th>
                <th>Rescaldo</th>
              </tr>

              <tr *ngFor="let acionamento of reports.selected_report.acionamentos">
                <td>{{acionamento.nome_brigada}}</td>
                <td>{{acionamento.dt_acionamento | date:'HH:mm'}} - {{acionamento.dt_chegada | date:'HH:mm'}}</td>
                <td>{{acionamento.dt_inicio_combate | date:'HH:mm'}} - {{acionamento.dt_fim_combate | date:'HH:mm'}}</td>
                <td>{{acionamento.dt_inicio_rescaldo | date:'HH:mm'}} - {{acionamento.dt_fim_rescaldo | date:'HH:mm'}}</td>
              </tr>
            </table>
          </div>
        </div>

        <div class="white-box" id="forth-white-box">
          <div class="white-box-header">
            <mat-icon>attachment</mat-icon>
            <h2>Dados adicionais</h2>
          </div>

          <div class="row">
            <div class="sub-white-box">
              <div class="sub-white-box-header">
                <mat-icon>comments</mat-icon>
                <h3>Comentários</h3>
              </div>
              <span id="comments-data" *ngIf="reports.selected_report.comentarios">
                {{reports.selected_report.comentarios}}
              </span>
            </div>
          </div>

          <div class="row" id="second-row">
            <div class="sub-white-box">
              <div class="sub-white-box-header">
                <mat-icon>info</mat-icon>
                <h3>Informações</h3>
              </div>
              <span *ngIf="this.r.origem">
                Origem: {{ this.r.origem }}
              </span>
              <span *ngIf="this.r.causa">
                Causa: {{ this.r.causa }}
              </span>
            </div>

            <div class="sub-white-box">
              <div class="sub-white-box-header">
                <mat-icon>fire_truck</mat-icon>
                <h3>Danos</h3>
              </div>
              <span *ngFor="let damage of reports.selected_report.dados_perdas">
                {{ damage.tipo }}: {{ damage.valor }} {{ damage.unidade }}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</main>