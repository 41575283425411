<main>
  <div class='table-container'>
    <table mat-table [dataSource]="det.detections_list">
     
      <ng-container matColumnDef="camera">
        <th mat-header-cell *matHeaderCellDef> Câmera </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="element.viewed ? '' : bold_style"> {{element.name}} </td>
      </ng-container>

      <ng-container matColumnDef="hora">
        <th mat-header-cell *matHeaderCellDef> Hora </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="element.viewed ? '' : bold_style"> {{element.datetime_deteccao.slice(-8)}} </td>
      </ng-container>
    
      <ng-container matColumnDef="pan">
        <th mat-header-cell *matHeaderCellDef> Azimute </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="element.viewed ? '' : bold_style"> {{element.pan}}º </td>
      </ng-container>
    
      <ng-container matColumnDef="probabilidade">
        <th mat-header-cell *matHeaderCellDef> Probabilidade </th>
        <td mat-cell *matCellDef="let element"
          [ngClass]="pickClass(element.resultado_modelo?.smoke_prob)"
          [ngStyle]="element.viewed ? '' : bold_style"
        >
        {{element.resultado_modelo ? ((element.resultado_modelo.smoke_prob * 100).toFixed(0).toString() + "%") : "0%"}}
      </td>
      </ng-container>
    
      <ng-container matColumnDef="n_imagens">
        <th mat-header-cell *matHeaderCellDef> Nº de imagens </th>
        <td mat-cell *matCellDef="let element" [ngStyle]="element.viewed ? '' : bold_style"> {{element.images.length}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" (click)="selectDetection(row)" [ngClass]="row.selected ? 'selected' : ''"></tr>
      
    </table>
  </div>
</main>