<app-loader *ngIf="!loaded" [message]="loading_msg"></app-loader>

<main>
  <section *ngIf="loaded" class='sidenav'>
    <div class='logo-container'>
      <div class='pantera-logo'></div>
    </div>

    <div class="cams-list-wrapper">
      <button mat-mini-fab *ngFor="let c of cams.cameras" [color]="c == selectedCam ? 'warn' : 'primary'" (click)="setCam(c)">
        <span>{{ c.numero_local }}</span>
      </button>
    </div>

    <button mat-fab [matMenuTriggerFor]="menu" color='primary' matTooltip="Outros">
      <img src="assets/icons/new-icons/settings-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
    </button>

    <mat-menu #menu="matMenu">
      <button mat-menu-item style="display: flex; align-items: center; justify-content: flex-start;" (click)="deleteDetections()">
        <img src="assets/icons/new-icons/trash-dark.svg" style="width: 18px; height: 18px; margin-right: .5vw; vertical-align: middle;"/>
        <span>Deletar detecções</span>
      </button>

      <button mat-menu-item style="display: flex; align-items: center; justify-content: flex-start;" matTooltip="Ainda poderá consultar o relatório no Pantera Web" matTooltipPosition="right" (click)="deleteReport()">
        <img src="assets/icons/new-icons/trash-dark.svg" style="width: 18px; height: 18px; margin-right: .5vw; vertical-align: middle;"/>
        <span>Deletar relatório</span>
      </button>

      <button mat-menu-item style="display: flex; align-items: center; justify-content: flex-start;" (click)="deleteReports()" matTooltip="Ainda poderá consultar os relatórios no Pantera Web" matTooltipPosition="right">
        <img src="assets/icons/new-icons/trash-dark.svg" style="width: 18px; height: 18px; margin-right: .5vw; vertical-align: middle;"/>
        <span>Deletar todos os relatórios</span>
      </button>

      <button mat-menu-item style="display: flex; align-items: center; justify-content: flex-start;" (click)="user.openLogoutDialog()">
        <img src="assets/icons/new-icons/logout-dark.svg" style="width: 18px; height: 18px; margin-right: .5vw; vertical-align: middle;"/>
        <span>Desconectar</span>
      </button>
    </mat-menu>
  </section>

  <div class="content">
    <section class="left-column" *ngIf="loaded">
      <div class="camera-container">
        <app-camera [ngClass]="streamingType" [cam_id]="selectedCam.id_cam"></app-camera>
        <div *ngIf="presetPicking" class="runningpp-wrapper">Coletando imagens do preset {{ pp.presetIndex }}. Aguarde!</div>
      </div>

      <mat-tab-group [backgroundColor]="'primary'" [color]="'accent'">
        <mat-tab label="1. Offset">
          <section class="mat-tab-content" id="offset-tab">
            <div class="config-div">
              <h3>Offset</h3>
              <div class="offset-content-wrapper">
                <div class="input-wrapper">
                  <label for="offset-pan">Ângulo (°)</label>
                  <input name="offset-pan" [(ngModel)]="selectedCam.offset_pan" type="number" step="0.01" min="0" max="359.99" oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);" maxlength="6"/>
                </div>
                <button mat-fab (click)="updateOffset()" color="primary" matTooltip="Salvar offset" matTooltipPosition="above">
                  <img src="assets/icons/new-icons/save-dark.svg" style="width: 18px; height: 18px;"/>
                </button>
              </div>
            </div>
          </section>
        </mat-tab>

        <mat-tab label="2. Presets">
          <section class="mat-tab-content">
            <div class="preset-sidenav">

              <button mat-mini-fab
                (click)="updatePresets()"
                color="accent"
                matTooltip="Salvar Presets"
                matTooltipPosition="above">
                  <img src="assets/icons/new-icons/save-light.svg" style="width: 18px; height: 18px;"/>
              </button>

              <button (click)="panToPosition()"
                mat-mini-fab color="accent"
                matTooltip="Apontar câmera na direção do preset" matTooltipPosition="above">
                <img src="assets/icons/new-icons/visibility-light.svg" style="width: 18px; height: 18px;"/>
              </button>

              <button mat-mini-fab
                (click)="geo.clearPresets()"
                color="accent"
                matTooltip="Limpar presets no mapa"
                matTooltipPosition="above">
                <img src="assets/icons/new-icons/auto-fix-off-light.svg" style="width: 18px; height: 18px;"/>
              </button>

              <button mat-mini-fab
                (click)="addRow()"
                color="accent"
                matTooltip="Adicionar posição abaixo"
                matTooltipPosition="above">
                <img src="assets/icons/new-icons/add-circle-light.svg" style="width: 18px; height: 18px;"/>
              </button>

              <button mat-mini-fab
                (click)="removeSelected()"
                color="warn"
                [disabled]="!rowSelected()"
                matTooltip="Remover posição"
                matTooltipPosition="above">
                  <img src="assets/icons/new-icons/trash-light.svg" style="width: 18px; height: 18px;"/>
              </button>
            </div>

            <div class="presets-actions-wrapper">
              <div class="config-div" id="simulation">
                <div class="simulation-wrapper">
                  <h3>Simular</h3>

                  <div class="input-wrapper">
                    <label for="time-between-positions">Tempo entre posições (s)</label>
                    <input name="time-between-positions" [(ngModel)]="deltaTimePositions" type="number"/>
                  </div>
                </div>

                <div class="simulation-buttons">
                  <button (click)="previousPosition()" color="accent" matTooltip="Anterior" matTooltipPosition="above">
                    <img src="assets/icons/new-icons/navigate-before-light.svg" style="width: 16px; height: 16px;"/>
                  </button>

                  <button (click)="playTour()" mat-mini-fab id="play-btn" [color]="simulatingTour ? 'primary' : 'accent'" matTooltip="Simular todas as posições" matTooltipPosition="above">
                    <img *ngIf="simulatingTour" src="assets/icons/new-icons/play-dark.svg" style="width: 16px; height: 16px;"/>
                    <img *ngIf="!simulatingTour" src="assets/icons/new-icons/play-light.svg" style="width: 16px; height: 16px;"/>
                  </button>

                  <button (click)="nextPosition()" color="accent" matTooltip="Próxima" matTooltipPosition="above">
                    <img src="assets/icons/new-icons/navigate-next-light.svg" style="width: 16px; height: 16px;"/>
                  </button>
                </div>
              </div>

              <div class="config-div" id="generator">
                <div class="generator-header">
                  <h3>Gerador de presets</h3>

                  <button (click)="createAutoPresets()" mat-mini-fab color="accent" matTooltip="Criar os presets automaticamente">
                    <img src="assets/icons/new-icons/motion-photo-auto-light.svg" style="width: 18px; height: 18px;"/>
                  </button>
                </div>

                <div class="generator-buttons">
                  <div class="input-wrapper">
                    <label for="start-angle">Ângulo de início (°)</label>
                    <input [(ngModel)]="autoStartPan" type="number" min=0 name="start-angle"/>
                  </div>

                  <div class="input-wrapper">
                    <label for="zoom-level">Nível de zoom (x)</label>
                    <input [(ngModel)]="autoZoom" name="zoom-level" type="number" min=1 max=30 oninput="(validity.valid)||(value='');"/>
                  </div>
                </div>

                <div class="generator-buttons">
                  <div class="input-wrapper">
                    <label for="tilt">Tilt (°)</label>
                    <input name="tilt" [(ngModel)]="autoTilt" type="number" min=50/>
                  </div>

                  <div class="input-wrapper">
                    <label for="stability-time">Estabilização (s)</label>
                    <input name="stability-time" [(ngModel)]="autoSleep" type="number" min=2/>
                  </div>
                </div>
              </div>
            </div>

            <div class="presets-table-wrapper">
              <table mat-table [dataSource]="selectedCam[selectedPreset]?.filter(filterDataSource)" class="mat-elevation-z8">
                <ng-container matColumnDef="index">
                  <th mat-header-cell *matHeaderCellDef>Index</th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="selectRow(element)"
                  >
                    <button mat-mini-fab color="primary">
                      {{ element.index }}
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="pan">
                  <th mat-header-cell *matHeaderCellDef>Pan</th>
                  <td mat-cell *matCellDef="let element">
                    <input oninput="(validity.valid)||(value=0);"
                      (change)="valueUpdated(element.index);"
                      placeholder="pan"
                      [(ngModel)]="element.pan"
                      type="number"
                      min=0 max=360
                    />
                  </td>
                </ng-container>

                <ng-container matColumnDef="tilt">
                  <th mat-header-cell *matHeaderCellDef>Tilt</th>
                  <td mat-cell *matCellDef="let element">
                    <input oninput="(validity.valid)||(value=75);"
                      (change)="valueUpdated(element.index);"
                      placeholder="tilt"
                      [(ngModel)]="element.tilt"
                      type="number"
                      min=30 max=170
                    />
                  </td>
                </ng-container>

                <ng-container matColumnDef="zoom">
                  <th mat-header-cell *matHeaderCellDef>Zoom</th>
                  <td mat-cell *matCellDef="let element">
                    <input min=1 max=30 oninput="(validity.valid)||(value=1);"
                      (change)="valueUpdated(element.index);"
                      placeholder="zoom"
                      [(ngModel)]="element.zoom"
                      type="number"
                    />
                  </td>
                </ng-container>

                <ng-container matColumnDef="fov">
                  <th mat-header-cell *matHeaderCellDef>Visão</th>
                  <td mat-cell *matCellDef="let element">
                      {{ getFov(element.zoom) | number:'.2-2'}}°
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns"
                  [ngClass]="isSelectedClass(row.index)"
                ></tr>
              </table>
            </div>
          </section>
        </mat-tab>

        <mat-tab label="3. Fotos preset">
          <section class="mat-tab-content">
            <div class="preset-sidenav">
              <button (click)="runPresetPickerOne()"
                mat-mini-fab color="accent"
                matTooltip="Atualizar imagem do preset selecionado" matTooltipPosition="above">
                  <img src="assets/icons/new-icons/photo-camera-light.svg" style="width: 18px; height: 18px;"/>
              </button>

              <button (click)="runPresetPickerLista()"
                mat-mini-fab color="accent"
                matTooltip="Atualizar imagem para toda a lista de presets" matTooltipPosition="above">
                  <img src="assets/icons/new-icons/switch-camera-light.svg" style="width: 18px; height: 18px;"/>
              </button>

              <button (click)="panToPosition()"
                mat-mini-fab color="accent"
                matTooltip="Apontar câmera na direção do preset" matTooltipPosition="above">
                <img src="assets/icons/new-icons/trash-light.svg" style="width: 18px; height: 18px;"/>
              </button>
            </div>

            <div *ngIf="activeIdx" class='img-wrapper'>
              <img [src]="urlImgPreset" (load)='drawRect()'/>
              <canvas id='presetCanvas'></canvas>
            </div>

            <div class="presets-table-wrapper">
              <table mat-table [dataSource]="selectedCam[selectedPreset]?.filter(filterDataSource)" class="mat-elevation-z8">
                <ng-container matColumnDef="index2">
                  <th mat-header-cell *matHeaderCellDef>Index</th>
                  <td
                    mat-cell
                    *matCellDef="let element"
                    (click)="selectRow2(element)"
                  >
                    <button mat-mini-fab color="primary">
                      {{ element.index }}
                    </button>
                  </td>
                </ng-container>

                <ng-container matColumnDef="pan2">
                  <th mat-header-cell *matHeaderCellDef>Pan</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.pan }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="tilt2">
                  <th mat-header-cell *matHeaderCellDef>Tilt</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.tilt }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="zoom2">
                  <th mat-header-cell *matHeaderCellDef>Zoom</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.zoom }}
                  </td>
                </ng-container>

                <ng-container matColumnDef="fov2">
                  <th mat-header-cell *matHeaderCellDef>FOV</th>
                  <td mat-cell *matCellDef="let element">
                      {{ getFov(element.zoom) | number:'.2-2'}}°
                  </td>
                </ng-container>
                <ng-container matColumnDef="masks">
                  <th mat-header-cell *matHeaderCellDef>Máscaras</th>
                  <td mat-cell *matCellDef="let element">
                    {{ element.labels ? element.labels.length : 0 }}
                  </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns2"></tr>
                <tr
                  mat-row
                  *matRowDef="let row; columns: displayedColumns2"
                  [ngClass]="isSelectedClass(row.index)"
                ></tr>
              </table>
            </div>
          </section>
        </mat-tab>

      </mat-tab-group>
    </section>

    <section class="map-container">
      <div id="map"></div>
    </section>
  </div>
</main>
