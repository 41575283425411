import { Component, OnInit, OnDestroy, HostListener } from '@angular/core';
import { CameraService } from '../../services/camera.service';
import { ReportsService } from '../../services/reports.service';
import { StorageService } from '../../services/storage.service';
import { UserService } from 'src/app/services/user.service';
import { DetectionsService } from 'src/app/services/detections.service';
import { GeoService } from 'src/app/services/geo.service';
import { environment } from '../../../environments/environment';
import { OnboardService } from 'src/app/services/onboard.service';
import { UtilsService } from 'src/app/services/utils.service';
import { SocketService  } from '../../services/socket.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
import { InterfaceTabsService } from 'src/app/services/interface-tabs.service';
@Component({
  selector: 'app-single-interface',
  templateUrl: './single-interface.component.html',
  styleUrls: ['./single-interface.component.scss']
})
export class SingleInterfaceComponent implements OnInit, OnDestroy{

  constructor(
    public cams: CameraService,
    public user: UserService,
    public det: DetectionsService,
    public reports: ReportsService,
    public storage: StorageService,
    public geo: GeoService,
    public onBoard:OnboardService,
    public utils:UtilsService,
    private socketService: SocketService,
    public amplitude:AmplitudeService,
    public customErrorHandler:CustomErroHandlerService,
    public interfaceTabsService: InterfaceTabsService
    ) {}

  private check_interval:any;
  private updateClustersInterval;
  private updateBrigadesInterval;
  public loading: boolean = true;
  public loading_msg:string;
  public search_text:string='';
  public streamingType: string = "docker-ws";
  public showSat: boolean = false;

  async ngOnInit(){
    try {
      this.storage.clean();
      this.loading_msg = "Carregando Interface..."

      let cams_ok = await this.cams.camInit();
      if (!cams_ok) throw new Error("Erro ao inicializar câmeras...");
      this.cams.cameras.forEach(cam => cam.pantoOnClick = false);

      console.log('%c🗼 CamList', 'background: #222; color: #bada55', this.cams.cameras);
      
      await this.det.getReclassTypes();
      
      this.streamingType = this.user.getStreamingType()
      
      this.loading_msg = "Carregando Mapa..."
      let check_map_render = await this.geo.createMap();
      if(!check_map_render){
        this.user.openErrorDialog();
        return;
      }

    }
    catch (error) {
      this.customErrorHandler.handleError(error)
      console.log(error);
    }
    finally {
      // SERVICES PROCESS INIT
      await this.cams.logPtz();
      await this.det.getDetections();
      this.det.deactivateDetection();
      this.reports.unSelectReport()
      this.geo.drawFireReports();

      // Rotina de atualizacao do componente
      this.check_interval = setInterval( ()=>{
        this.checkRoutine()
      }, 1000)
  
      const TIMEOUT_MIN = 5
      if (this.user.hasSatellite()){
        this.geo.updateClusters()
        this.updateClustersInterval = setInterval(async () => await this.geo.updateClusters(), TIMEOUT_MIN*60*1000)
      }
  
      if (this.user.hasBrigades()){
        this.geo.updateBrigades()
        this.updateBrigadesInterval = setInterval(async () => await this.geo.updateBrigades(), TIMEOUT_MIN*60*1000)
      }
      
      this.loading = false;
    }
  }

  checkRoutine(){
    this.geo.updateRoutine();
    this.updateReports();
  }

  updateReports(){
    if (this.reports.updateListFromStorage()){
      this.geo.drawFireReports();
    };
  }

  setCam(cam_id){
    this.cams.setFirstCamera(cam_id)
  }

  toggleSearchBox(){
    this.geo.toggleSearchBox()
    if (!this.geo.showSearchBox) this.search_text = ""
  }

  toggleShowSat(){
    this.showSat = !this.showSat
  }

  async handleDoubleClick(cam) {
    if (this.det.aiming) return

    try {
      cam.spin = false;

      if (!cam.auto) cam.auto = 1
      else cam.auto = 0

      let activate = cam.auto;

      if (activate == 1) {
        this.amplitude.sendEvent("Ativou Deteccao Double Click", {
          "id_camera": cam.id_cam
        })
      }
      else {
        this.amplitude.sendEvent("Desativou Deteccao Double Click", {
          "id_camera": cam.id_cam
        })
      }

      await this.cams.autoCommand(cam.id_cam, activate)
    }
    catch (err) {
      this.customErrorHandler.handleError(err)
      console.log("Erro ao alterar o estado de detecção da câmera", err)
    }

    this.socketService.sendCameras([true, [cam.id_cam], [cam.auto]])
  }

  startCamTour() {
    this.amplitude.sendEvent("Clicou Ajuda Camera")
    this.interfaceTabsService.changeToControlsTab();
    this.onBoard.startCamTour(true, this.cams.cam_options_columns);
  }

  async startDetectionTour() {
    this.amplitude.sendEvent("Clicou Ajuda Deteccoes")
    this.interfaceTabsService.changeToDetectionsTab();
    this.det.deactivateDetection();
    await this.utils.sleep(500);
    this.onBoard.startDetectionTour(true);
  }

  async startConfigsTour() {
    this.amplitude.sendEvent("Clicou Ajuda Configuracoes")
    this.interfaceTabsService.changeToConfigsTab();
    await this.utils.sleep(500);
    this.onBoard.startConfigsTour(true);
  }

  startWelcomeTour() {
    this.amplitude.sendEvent("Clicou Ajuda Novidades")
    this.onBoard.startWelcomeTour();
  }

  openZendeskTour() {
    this.amplitude.sendEvent("Clicou Ajuda Zendesk")
    this.onBoard.openZendeskTour();
  }

  startMapTour() {
    if (this.reports.selected_report) {
      this.reports.unSelectReport();
    }
    setTimeout(() => {
      this.onBoard.startMapTour(true);
    }, 500);
  }

  startReportTour() {
    this.onBoard.startReportTour(true);
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(){
    // Certificar quais tem que close
    this.det.closeDetectionsTimeout();
    this.cams.closeLoggers();
    clearInterval(this.check_interval)
  }

  setFirstCamera(id:number) {
    if (this.det.aiming) return
    else this.cams.setFirstCamera(id)
  }
  
  toggleSecondCam() {
    if (this.det.secondCamEnabled) {
      this.cams.closeFullScreen();
    }
    this.det.toggleSecondCam()
  }

}
