<main>

  <div class="logo-container">
    <div class="pantera-black-logo"></div>
  </div>
  <span class='version'>{{version}}</span>

  <div class="login-container">
    <h3 *ngIf="showErrorMessage" style='color:red; display: flex;'>
      <img src="assets/icons/new-icons/error-outline-warn.svg" style="width: 16px; height: 16px;"/>
      {{errorMessage}}
    </h3>
    <mat-form-field appearance="outline" [color]="'accent'">
      <mat-label>Usuário</mat-label>
      <span matPrefix style="margin-left: .5vw;">
        <img src="assets/icons/new-icons/account-circle-dark.svg" style="width: 16px; height: 16px; margin-left: .5vw;"/>
      </span>
      <input matInput [(ngModel)]="username" (change)='showErrorMessage=false'>
    </mat-form-field>

    <mat-form-field appearance="outline" [color]="'accent'">
      <mat-label>Senha</mat-label>
      <span matPrefix  style="margin-left: .5vw;">
        <img src="assets/icons/new-icons/key-dark.svg" style="width: 16px; height: 16px;"/>
      </span>
      <input matInput [type]="showPass ? 'text' : 'password'" [(ngModel)]="userpass" (keyup.enter)="sign_in()">
      <button mat-icon-button matSuffix (click)="showPass = !showPass">
        <img *ngIf="showPass" src="assets/icons/new-icons/visibility-dark.svg" style="width: 16px; height: 16px;"/>
        <img *ngIf="!showPass" src="assets/icons/new-icons/visibility-off-dark.svg" style="width: 16px; height: 16px;"/>
      </button>
    </mat-form-field>

    <p class='forgot-pass' (click)='forgot_pass()'>
      Esqueci minha senha
    </p>

    <button class="login-btn" (click)='sign_in()'>Login</button>
  </div>
</main>
