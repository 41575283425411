import html2pdf from 'html2pdf.js';
import {Injectable, signal} from '@angular/core';
import {HttpService} from './http.service'
import {CameraService} from './camera.service'
import {GeoService} from './geo.service'
import {formatDate} from '@angular/common';
import {MatDialog} from '@angular/material/dialog';
import {TelegramGroupsDialog} from 'src/app/shared/dialogs/telegram-groups';
import { AddImagesDialog } from '../shared/dialogs/add-image';
import { CreateReportWithoutTowerDialog } from '../shared/dialogs/create-report-without-tower';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation';
import { InformationDialog } from 'src/app/shared/dialogs/information';
import { UserService } from './user.service';
import { FireLocationService } from './fire-location.service';
import { environment } from '../../environments/environment';
import { AmplitudeService } from './amplitude.service';
import { CLICK_ON_MAP_GEOLOC_ID, INPUT_LAT_LON_GEOLOC_ID } from './geo.service';
import { CustomErroHandlerService } from './custom-erro-handler.service';
import { DownloadReportDialog } from '../shared/dialogs/download-report';


const REPORTS_DATABASE_KEY = 'REPORTS_DATABASE';
const UPDATE_REPORT_KEY = 'UPDATE_REPORT_FROM_STORAGE';

const newReportIcon = {'src':'help_outline','color':'black'};
const earlyFireIcon = {'src':'local_fire_department','color':'red'};
const mediumFireIcon = {'src':'local_fire_department','color':'orange'};
const lateFireIcon = {'src':'local_fire_department','color':'gray'};


@Injectable({
  providedIn: 'root'
})
export class ReportsService {

  constructor(
    private http: HttpService,
    private geo: GeoService,
    private cams: CameraService,
    private dialog: MatDialog,
    private user: UserService,
    private fireLocation: FireLocationService,
    public amplitude: AmplitudeService,
    public customErrorHandler:CustomErroHandlerService
  ) {}

  public reports_list: any[] = [];
  public selected_report: any = null;

  public selected_imgs: any[] = [];
  public imgs_loaded = false;

  public showTable = false;

  public telegramGroupToSendReportAlert: any;
  public selectedReportToDelete: any = null;
  public selectedReportToGroupDetection: any = null;

  public exportPDF: boolean = true;

  public selectedImageIndex: number = 0;

  public isSendingMail = signal<boolean>(false)

  previousImage(){
    this.selectedImageIndex--;
    if (this.selectedImageIndex == -1){
      let updatedReportsList = this.getStoredReports()
      let selectedReportFromLocalStorage = updatedReportsList.filter(rep => rep.id_report == this.selected_report.id_report)[0]
      if (selectedReportFromLocalStorage.images.length != this.selected_report.images.length) {
        this.selected_report.images = selectedReportFromLocalStorage.images
      }
      this.selectedImageIndex = this.selected_report.images.length - 1;
    }
  }

  nextImage(){
    this.selectedImageIndex++;
    if (this.selectedImageIndex == this.selected_report.images.length){
      let updatedReportsList = this.getStoredReports()
      let selectedReportFromLocalStorage = updatedReportsList.filter(rep => rep.id_report == this.selected_report.id_report)[0]
      if (selectedReportFromLocalStorage.images.length != this.selected_report.images.length) {
        this.selected_report.images = selectedReportFromLocalStorage.images
      }
      else {
        this.selectedImageIndex = 0;
      }
    }
  }

  async selectReport(id_report){
    this.amplitude.sendEvent("Abriu Relatorio", {
      "id_report": id_report
    })

    this.reports_list = this.getStoredReports()
    this.sortReports();
    this.selected_report = this.reports_list.filter(r=>r.id_report == id_report)[0];
    this.selected_report.selected = true;
    console.log('...selected_report', this.selected_report);
    this.selected_imgs = await this.getReportImages();
    this.selectedImageIndex = this.getHigherZoomImage(this.selected_imgs)
    this.showTable = true;
    this.geo.openReportMarker(id_report);
    this.updateReportStorage(this.selected_report);
  }

  getHigherZoomImage(images: any[]) {
    let selectedIndex = 0;
    images.forEach((img: any, index: number) => {
      if (img.img_details.zoom > images[selectedIndex].img_details.zoom) {
        selectedIndex = index
      }
    })
    return selectedIndex;
  }

  unSelectReport(){
    this.showTable = false;
    if (this.selected_report) {
      this.selected_report.selected = false;
      this.updateReportStorage(this.selected_report);
    }
    this.selected_report = null;
    this.selected_imgs = [];
    this.imgs_loaded = false;
    this.geo.closePopup();
    this.selectedImageIndex = 0;
  }

  selectFirstReport(){
    this.unSelectReport();
    this.selectReport(this.reports_list[0].id_report)
  }

  // Funcoes de interface com os relatorios
  sortReports() {
    this.reports_list.sort((a, b) => {
      let date_a: any = new Date(a.datetime_deteccao);
      let date_b: any = new Date(b.datetime_deteccao);
      return date_b - date_a;
    });
  }

  // por enquanto usando b64 tambem
  async getReportImages() {
    let obj = {'images':this.selected_report.images}
    let images_obj  = await this.http.post('get_b64_images', obj);
    console.log('images_obj64 response', images_obj)
    if(images_obj?.images){
      this.imgs_loaded = true;
      return images_obj['images']
    } else {
      this.imgs_loaded = false;
      return []
    }
  }

  async aimReport() {
    if (!this.selected_report) return;

    try {
      let panto_obj = Object.assign({id: this.selected_report.id_camera_deteccao}, this.selected_report.images[this.selectedImageIndex].img_details);

      await this.cams.resetCameraCommand(this.selected_report.id_camera_deteccao);

      console.log('panto report', panto_obj);
      await this.cams.sendAbsPTZ(panto_obj);

      this.cams.setFirstCamera(this.selected_report.id_camera_deteccao);
    }
    catch (err) {
      this.customErrorHandler.handleError(err);
      console.log("Não foi possível visualizar a ocorrência ", err)
    }

  }

  setReportIconColor(color){
    this.selected_report.icon.color=color;
    this.updateReportStorage(this.selected_report);
    this.geo.drawFireReports();
  }

  async fillMapSnap(){
    this.showTable = false;
    this.selected_report.img_64_mapa = await this.geo.getMapSnap(this.selected_report.dados_localizacao[0]);
    this.showTable = true;
  }

  async updateMaestro(reportKey:string){
    let data = {
      'id_report':this.selected_report.id_report,
      'columns':[reportKey],
      'values': [this.selected_report[reportKey]],
    }
    console.log("data", data)
    this.http.maestroPost('update_report', data).then((response) => {console.log(`update ${reportKey}`, response); });

  }

  updateReportLocation(new_dados_loc){
    // no futuro dar push e guardar loc antiga
    // Tirar novas novos?
    // Tirar novo snap do mapa?
    // Olhar o que o confirmDetection faz-> As funcoes teriam que ficar em um servico mais generico
    this.selected_report.dados_localizacao = [new_dados_loc];
    this.selected_report.geoloc_type_id = new_dados_loc.geoloc_type_id;
    this.selected_report.localizacao_alterada = true;
    this.updateReportStorage(this.selected_report);
    this.setUpdatedStatus();
    this.geo.drawFireReports();
    this.updateMaestro('dados_localizacao').then((response) => console.log('alter dados_localizacao', response));
    this.updateMaestro('localizacao_alterada').then((response) => console.log('alter localizacao_alterada', response));
    this.updateMaestro('geoloc_type_id');
  }

  async endReport(){
    if (!this.selected_report) { return };
    let id_report = this.selected_report.id_report;

    this.selected_report.datetime_finalizado = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');

    console.log('...finishedReport', this.selected_report);

    // mandando pro Backend local pra eventualmente salvar a info em caso de nao ter internet
    this.http.post('end_report', this.selected_report).then((response) => console.log('...end_report', response));

    this.unSelectReport();
    this.reports_list = this.reports_list.filter(r => r.id_report!=id_report);
    this.removeReportFromStorage(id_report);
  }

  // REPORTS DATABASE
  getStoredReports() {
    return JSON.parse(localStorage.getItem(REPORTS_DATABASE_KEY)) || [];
  }

  setUpdatedStatus(){
    // Usado pelo mapa para saber quando desenhar novamente os reports
    localStorage.setItem(UPDATE_REPORT_KEY, 'true')
  }


  initListFromStorage() {
    // Ver no backend se ele tem todos esses reports?
    this.reports_list = this.getStoredReports();
    this.reports_list.map( (report) => {
      if (report.selected) report.selected = !report.selected
    })
    this.sortReports();
  }

  updateListFromStorage(){
    // Obs.: PODERIA ROLAR UMA updateReportsFromMaestro aqui
    let update_from_storage = JSON.parse(localStorage.getItem(UPDATE_REPORT_KEY));
    if(update_from_storage){
      this.reports_list = this.getStoredReports();
      this.sortReports();
      localStorage.removeItem(UPDATE_REPORT_KEY);
      return true;
    }
    return false;
  }

  insertReportIntoStorage(report) {
    Object.assign(report, {'selected':false, 'icon': earlyFireIcon})

    let stored_reports = this.getStoredReports();
    stored_reports.push(report);
    stored_reports.map( (report) => {
      if (report.selected) report.selected = !report.selected
    })
    localStorage.setItem(REPORTS_DATABASE_KEY, JSON.stringify(stored_reports));
    this.setUpdatedStatus();
    this.showTable = false;
  }


  updateReportStorage(up_report) {
    let all_reports = this.getStoredReports();
    for (let [i, stored_report] of all_reports.entries()) {
      if (stored_report.id_report == up_report.id_report) {
        Object.assign(stored_report, up_report);
        console.log('updated reports database')
        localStorage.setItem(REPORTS_DATABASE_KEY, JSON.stringify(all_reports));
        break;
      }
    }
  }


  removeReportFromStorage(id_report) {
    let all_reports = this.getStoredReports();
    all_reports= all_reports.filter(r => r.id_report!=id_report);
    localStorage.setItem(REPORTS_DATABASE_KEY, JSON.stringify(all_reports));
    this.setUpdatedStatus();
  }


  async sendToTelegram(){
    let telegram_groups = await this.http.get("get_telegram_groups_list")
    telegram_groups = telegram_groups.filter(g=>g.id_tipo_destinatario == 1);

    if (telegram_groups.length == 0) {
      const dialogRef = this.dialog.open(InformationDialog , {
        data: {text: "Você não possui grupos telegram cadastrados. Fale com um de nossos técnicos."}
      });

      dialogRef.afterClosed().subscribe(async(confirmation) => {
        if (confirmation){
          console.log("Sem grupos")
        }
      });
    }

    else {
      const lat = this.selected_report.dados_localizacao[0].lat
      const lon = this.selected_report.dados_localizacao[0].lon
      const mapsLink = `http://www.google.com/maps/place/${lat},${lon}`
      const id_report = this.selected_report.id_report
      const datetime_det = this.selected_report.datetime_deteccao
      const n_relatorio_planta = this.selected_report.n_relatorio_planta
      const id_planta = this.user.getIdPlanta();

      let bbox;
      let smokeProb;

      if (this.selected_report.images.length > 0) {
        var image_obj = this.selected_report.images.filter((img) => img.img_type == 4)[0]

        if (image_obj) {
          bbox = image_obj.model_result.bbox || []
          smokeProb = image_obj.model_result.smoke_prob || this.selected_report.resultado_modelo.smoke_prob
        }
        else {
          image_obj = this.selected_report.images[0]
          bbox = image_obj.model_result.bbox || []
          smokeProb = image_obj.model_result.smoke_prob || this.selected_report.resultado_modelo.smoke_prob
        }
      }

      let dados_loc = this.selected_report.dados_localizacao[0]?.dados_geo.atributos?.Local
      if (!dados_loc) {
        dados_loc = ""
      }

      if (image_obj) {
        let obj = {'image': image_obj}
        let image_base_64  = await this.http.post('convert_image_into_b64', obj);
        if (!image_base_64) {
          image_base_64 = ""
        }
      }
      else {
        image_obj = {
          "img_path": ""
        }
        bbox = ""
        smokeProb = ""
      }

      let risk_value = this.selected_report.risk
      if (!risk_value) {
        risk_value = ""
      }

      let telegram_data = {
        "datetime_det" : datetime_det,
        "link": mapsLink,
        "bbox": bbox,
        "smoke_prob": smokeProb,
        "dados_loc": dados_loc,
        "n_relatorio_planta": n_relatorio_planta,
        "weather": this.selected_report.dados_meteo[0],
        "image_path": image_obj.img_path,
        "risk": risk_value,
        "id_planta": id_planta,
        "img_id_report": id_report,
        "is_auto": false,
        "id_report": this.selected_report.id_report,
        "selected_group_id": undefined
      }

      if (telegram_groups.length == 1) {
        const dialogRef = this.dialog.open(ConfirmationDialog , {
          data: { text: 'Deseja enviar este alerta via Telegram?' }
        });

        dialogRef.afterClosed().subscribe(async (confirmation) => {
          if (confirmation){
            this.amplitude.sendEvent("Enviou Relatorio Telegram", {
              "id_report": id_report
            })
            const response = await this.http.post(`send_report_alert_via_telegram`, telegram_data);
            // TO-DO pass this set send report logic to backend
            if (response.status == 200) {
              this.http.get("set_report_sent_via_telegram", [id_report]).then(backendResponse => {
                if (backendResponse) {
                  this.selected_report.sent_via_telegram = true
                  this.updateReportStorage(this.selected_report)
                }
              })
            }
          }
        })
      }

      else {
        const dialogRef2 = this.dialog.open(TelegramGroupsDialog , {
          data: {group_list: telegram_groups}
        });

        dialogRef2.afterClosed().subscribe(async (confirmation) => {
          if (confirmation) {
            this.amplitude.sendEvent("Enviou Relatorio Telegram", {
              "id_report": id_report
            })
            telegram_data = {...telegram_data, selected_group_id: this.telegramGroupToSendReportAlert[0].chat_id}
            const response = await this.http.post(`send_report_alert_via_telegram`, telegram_data);
            if (response.status == 200) {
              this.http.get("set_report_sent_via_telegram", [id_report]).then(backendResponse => {
                if (backendResponse) {
                  this.selected_report.sent_via_telegram = true
                  this.updateReportStorage(this.selected_report)
                }
              })
            }
          }
        });
      }
    }
  }

  openAddImagesDialog() {
    this.amplitude.sendEvent("Clicou Upload Imagem Relatório")
    this.dialog.open(AddImagesDialog)
  }

  openReportDialog(){
    this.amplitude.sendEvent("Clicou Criar Relatório Sem Torre")
    this.unSelectReport()
    return this.dialog.open(CreateReportWithoutTowerDialog, {
      data: { lat: this.geo.lastClickLatLng.Lat, lon: this.geo.lastClickLatLng.Lng }
    });
  }

  async createReport(lat, lon, date, rData, inputLatLon: boolean = false){
    let geoloc_type_id = inputLatLon ? INPUT_LAT_LON_GEOLOC_ID : CLICK_ON_MAP_GEOLOC_ID;
    let dados_loc = this.fireLocation.getDadosLoc(lat, lon, this.geo.shp_layer, geoloc_type_id)

    let coords = {
      'lat': lat,
      'lon': lon,
    };

    let dados_meteo = await this.http.maestroPost('one_call_str', coords);

    let closestCameraObj = this.fireLocation.getClosestCameraToFireReportWithoutTowers(lat, lon)
    let risk_value = await this.http.get("get_risk", [closestCameraObj.camId])
    if (risk_value) {
      risk_value = risk_value.risk
    }
    else {
      risk_value = ""
    }

    let report = {
      "name": "Sem torre",
      "id_planta": this.user.getIdPlanta(),
      "id_report": formatDate(new Date(date).getTime(), 'dd-MM-yy_HH-mm-ss', 'en-US') + `_0${this.user.getIdPlanta()}`,
      "comentarios": null,
      "dados_meteo": [dados_meteo],
      "acionamentos": [],
      "dados_perdas": [],
      "dados_combate": [],
      "id_causa_incendio": rData.id_causa_incendio,
      "images": [],
      "id_origem_deteccao": rData.id_origem_deteccao,
      "resultado_modelo": null,
      "dados_localizacao": [dados_loc],
      "datetime_deteccao": formatDate(new Date(date).getTime(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      "datetime_finalizado": "",
      "datetime_confirmacao": formatDate(new Date(date).getTime(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      "software_id": 1,
      "operador": this.user.getUsername(),
      "version": environment.version,
      "geoloc_type_id": geoloc_type_id,
      "risk": risk_value
    }

    let response = await this.http.maestroPost('create_report', report)

    report["n_relatorio_planta"] = response.n_relatorio_planta

    report["selected"] = false;
    report["icon"] = {
      src: "local_fire_department",
      color: "red"
    }

    this.insertReportIntoStorage(report)
    this.geo.drawFireReports();

    return report;
  }

  setExportPDF(value: boolean) {
    this.exportPDF = value
  }

  async sendReportPdf(n_relatorio_planta: any){
    if(this.selected_report.is_test || this.selected_report.sem_risco){
      console.log('Teste ou Sem Risco -> Nao enviar email');
      return false;
    }

    console.log('sending Report Pdf');

    var element = document.getElementById('report-pdf-find-me');

    var opt = {
      margin:       1,
      filename:     `relatorio_incendio_${n_relatorio_planta}.pdf`,
      image:        { type: 'jpeg', quality: 0.98 },
      html2canvas:  { scale: 2 },
      jsPDF:        { unit: 'mm', format: 'a4', orientation: 'l' }
    };

    let fd = new FormData();
    let blob = await html2pdf().set(opt).from(element).outputPdf('blob');

    fd.append('file', blob, `relatorio_incendio_${n_relatorio_planta}.pdf`);

    let data = {
      'id_planta': this.user.getIdPlanta(),
      'n_relatorio_planta': n_relatorio_planta
    };

    fd.append('reportData', JSON.stringify(data));
    const maestroResponse = await this.http.maestroPost('send_pdf_email', fd, false);
    console.log('send_pdf_email', maestroResponse);
    return true;
  }

  async finishReportProcedures() {
    if(this.selected_report.is_test || this.selected_report.sem_risco){
      console.log('Teste ou Sem Risco -> Nao enviar email');
      this.endReport();
      return false;
    }
    this.isSendingMail.set(true);
    this.amplitude.sendEvent("Finalizou Relatorio", {
      "id_report": this.selected_report.id_report
    })

    const dialogRef = this.dialog.open(DownloadReportDialog, {
      data: { report: this.selected_report }
    })

    // waiting to load icons on UI
    await new Promise(resolve => setTimeout(resolve, 250));

    // hiding buttons
    const elementToHide = document.getElementById("to-hide");
    elementToHide.className = "hide";

    const element = document.getElementById("report-pdf-find-me");

    const opt = {
      filename:     `relatorio_incendio_${this.selected_report.n_relatorio_planta}.pdf`,
      image:        { type: "jpeg", quality: 1 },
      jsPDF:        { orientation: "landscape", format: [element.scrollWidth, element.scrollHeight], unit: "px", hotfixes: ["px_scaling"] }
    };

    const blob = await html2pdf().set(opt).from(element).outputPdf('blob');

    // setting buttons back before closing dialog
    elementToHide.className = "actions-row";
    dialogRef.close();

    let fd = new FormData();
    fd.append('file', blob, `relatorio_incendio_${this.selected_report.n_relatorio_planta}.pdf`);

    let data = {
      'id_planta': this.user.getIdPlanta(),
      'n_relatorio_planta': this.selected_report.n_relatorio_planta
    };

    fd.append('reportData', JSON.stringify(data));

    const maestroResponse = await this.http.maestroPost('send_pdf_email', fd, false);
    console.log('send_pdf_email', maestroResponse);
    this.isSendingMail.set(false);
    this.endReport();
    return true;
  }

  getReportImageURL(index: number){
    if (this.selected_report.images.length > 0) {
      let fullPath = this.selected_report.images[index].img_path
      let idReport = fullPath.split("/")[1]
      let fileName = fullPath.split("/")[2]
      return `${environment.api}/get_report_image/${idReport}/${fileName}`;
    }
    return null
  }
}
