export const environment = {
  envName: 'onlineEnv',
  version: '1.23.1b0',
  production: true,
  cloudVersion: window["env"]["cloudVersion"] || false,
  api: window["env"]["api"] || 'http://localhost:5000/',
  socketHost: window['env']['socketHost'] || 'http://localhost:3000/',
  streamCloud: window["env"]["streamCloud"] || 'stream.umgrauemeio.com',
  remote_server: 'https://maestro.umgrauemeio.com',
  bearer: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c3VhcmlvIjoidGVzdGUiLCJwYXNzd29yZCI6IiIsIm1hYyI6IiIsImlkX3BsYW50YSI6OTl9.Cv-oyYAKUWGKl4FZK2tEzlPOW6YJukTtN1kLKIZPctU',
  geoserverUrl: 'https://geo.umgrauemeio.com',
  socketCentralUrl: 'https://socketcentral.umgrauemeio.com',
  bearerSockCent: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpYXQiOjE2OTMyMjc0MjV9.8mO8HYvOtwMSXhwSRmpR62tt8jBsE0law6z7mWRAMmo',
  requestTimeout: 10000,
  extendedRequestTimeout: 60000 * 2,
  camStatusTimeout: 2000,
  vxg_api: 'https://web.skyvr.videoexpertsgroup.com:443/api/v3/',
  vxg_token: 'v3.iscdr8SEKDEKX2z',
  amplitudeKey: '3d3a14502e07351a71f8e98f3008a4ab',
  amplitudeInstance: 'prod'
}
