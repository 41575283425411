<div class="wrapper">
  <h3>Registrar danos da ocorrência</h3>
  
  <form class='form' [formGroup]='lossForm'>
  
    <mat-form-field appearance="outline" class="outlineForm" [color]="'accent'">
        <mat-label color="warn">Tipo</mat-label>
        <mat-select [(ngModel)]="data.tipo" formControlName='type'>
            <mat-option *ngFor='let p of data.tipos_danos' [value]='p.tipo_dano'>
              {{p.tipo_dano}}
            </mat-option> 
        </mat-select>
      </mat-form-field>
  
    <mat-form-field appearance="outline" class="outlineForm" [color]="'accent'">
     <mat-label>Unidade</mat-label>
     <mat-select [(ngModel)]="data.unidade" formControlName='unit'>
         <mat-option *ngFor='let u of data.unidades_danos' [value]='u.unidade_dano'>
           {{u.unidade_dano}}
         </mat-option> 
     </mat-select>
   </mat-form-field>
  
    <mat-form-field appearance="outline" class="outlineForm" [color]="'accent'">
      <mat-label>Valor</mat-label>
      <input matInput type="number" [(ngModel)]="data.valor" formControlName='value'>
      <span matSuffix>{{this.pickAbbreviation(data.unidade)}}</span>
    </mat-form-field>
    
  </form>
  
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
    <button mat-raised-button color='primary'
    [disabled]='lossForm.status=="INVALID"'
    [mat-dialog-close]="data">Confirmar</button>
  </mat-dialog-actions>
</div>