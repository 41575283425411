import { Component, OnInit, OnDestroy, HostListener} from '@angular/core';
import { trigger, transition, animate, style } from '@angular/animations'

import { GeoService } from 'src/app/services/geo.service';
import { CameraService } from 'src/app/services/camera.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UserService } from 'src/app/services/user.service';
import { OnboardService } from 'src/app/services/onboard.service';
import { SatelliteService } from 'src/app/services/satellite.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';


@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
  animations: [
    trigger('slideInOut', [
      transition(':enter', [
        style({transform: 'translateX(-100%)'}),
        animate('200ms ease-in', style({transform: 'translateX(0%)'}))
      ]),
      transition(':leave', [
        animate('200ms ease-in', style({transform: 'translateX(-100%)'}))
      ])
    ])
  ]
})


export class MapaComponent implements OnInit, OnDestroy {

  constructor(
    public geo: GeoService,
    public cams: CameraService,
    public sat: SatelliteService,
    public reports: ReportsService,
    public user: UserService,
    public onBoard:OnboardService,
    private amplitude: AmplitudeService
  ) {}

  public loaded: boolean = false;
  public showSat: boolean = false;
  public search_text: string = null;

  private updateClustersInterval;
  private updateBrigadesInterval;

  async ngOnInit() {

    let cam_ok = await this.cams.getCameras();
    this.cams.cameras.forEach(cam => cam.pantoOnClick = false);
    
    if(!cam_ok){
      this.user.openErrorDialog();
      return;
    }

    let check_map_render = await this.geo.createMap();

    if(!check_map_render){
      this.user.openErrorDialog();
      return;
    }


    // Init
    this.geo.drawFireReports();

    // Rotina de atualizacao do componente
    this.addStorageListener();
    this.reports.unSelectReport()

    // Atualizacao dos clusters
    const TIMEOUT_MIN = 5
    if (this.user.hasSatellite()){
      this.geo.updateClusters()
      this.updateClustersInterval = setInterval(async () => await this.geo.updateClusters(), TIMEOUT_MIN*60*1000)
    }

    if (this.user.hasBrigades()){
      this.geo.updateBrigades()
      this.updateBrigadesInterval = setInterval(async () => await this.geo.updateBrigades(), TIMEOUT_MIN*60*1000)
    }
    
    this.loaded = true;
  }


  addStorageListener(){
    // Ou fazer a cada 2 seg
    window.addEventListener("storage", () => {

      this.geo.updateRoutine(); 
      this.updateReports();

    }, false);
  }

  

  updateReports(){
    if (this.reports.updateListFromStorage()){
      this.geo.drawFireReports();
    };
  }


  toggleSearchBox(){
    this.geo.toggleSearchBox()
  }

  toggleShowSat(){
    this.showSat = !this.showSat
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(){
    try{
      clearInterval(this.updateClustersInterval)
      clearInterval(this.updateBrigadesInterval)
    } catch {}
  }

}
