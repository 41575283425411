import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'lat-lon-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class LatLonDialog {
  public latlngForm: UntypedFormGroup;
  public latlng = {
    lat:null,
    lng:null
  };

  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    private fb: UntypedFormBuilder,
  ) {

    this.latlngForm = this.fb.group({
      lat:['',[
        Validators.required,
        Validators.min(data.bounds.minLat),
        Validators.max(data.bounds.maxLat)
      ]],
      lng:['',[
        Validators.required,
        Validators.min(data.bounds.minLng),
        Validators.max(data.bounds.maxLng)
      ]],
    })
    console.log('LimitesPlanta', data.bounds)
  }
}
