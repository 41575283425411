import { Injectable } from '@angular/core';
import { GuidedTourService } from 'ngx-guided-tour';
import { DetectionsService } from 'src/app/services/detections.service';
import { ReportsService } from 'src/app/services/reports.service';
import { UtilsService } from './utils.service'
import { CameraService } from './camera.service';
import { UserService } from './user.service';
import { AmplitudeService } from './amplitude.service';

import { HDR_CUSTOM_OPTIONS_COLUMNS } from "./camera.service"

@Injectable({
  providedIn: 'root'
})
export class OnboardService {

  constructor(
    private guidedTourService:GuidedTourService,
    private detection:DetectionsService,
    private reports:ReportsService,
    private utils:UtilsService,
    private cams:CameraService,
    private user: UserService,
    public amplitude: AmplitudeService
  ){}

  private welcomeTour: any;
  private zendeskTour: any;
  private camTour: any;
  private detectionTour: any;
  private mapTour: any;
  private reportTour: any;
  private configsTour: any;

  scrollDownOnReport(element: any) {
    element.scrollIntoView()
  }

  startWelcomeTour() {
    this.welcomeTour = {
      tourId: 'welcome-tour',
      useOrb: false,
      steps: [
        {
          title: 'Bem vindo ao Sistema Pantera',
          content: `
            <h2>Novidades</h2>
            <h4>Versão 1.50.0</h4>
            <li>Correção do bug de risco de incêndio</li>
            <li>Correção do Bug do mapa de visibilidade</li>
          `
        },
        {
          title: 'Botões de ajuda',
          selector: '#help-btn',
          orientation:'top',
          content: `
            <p>Os botões de ajuda trazem explicações e lembretes de como operar o sistema</p>
            <br/>
            <p><b>Agora você também pode acessar o Zendesk por aqui</b><p/>
          `,
          closeAction: () => {
            this.openZendeskTour(true);
          }
        },
      ]
    };
    this.guidedTourService.startTour(this.welcomeTour);
  };

  openZendeskTour(comingFromAnotherTour: boolean = false) {
    this.zendeskTour = {
      tourId: 'zendesk-tour',
      useOrb: false,
      steps: [
        {
          title: 'Precisa de ajuda?',
          content: `
            <h2>Entre em contato pelo Zendesk</h2>
            <p>Abra um chamado para nosso time de Suporte</p>
            <b/>
            <h4><a style="color: #444444; text-decoration: none;" href="https://suporte.umgrauemeio.com/hc/pt-br" rel="noreferrer" target="_blank">Acessar Zendesk</a></h4>
          `,
          action: () => {
            if (!comingFromAnotherTour) {
              const zendeskUrl = 'https://suporte.umgrauemeio.com/hc/pt-br';
              window.open(zendeskUrl, '_blank');
            }
            return
          }
        }
      ]
    };
    this.guidedTourService.startTour(this.zendeskTour);

  }

  startConfigsTour(singleInterface: boolean = false){
    this.configsTour = {
      tourId: 'configs-tour',
      useOrb: false,
      steps: [
        {
          title: 'Habilitar funcionalidades',
          selector: '#configs-btn',
          orientation: 'right',
          content: `
            <li>Ativar <b>alertas sonoros</b> ao gerar uma detecção</li>
            <li>Habilitar o envio de detecções <b>automáticas</b> via Telegram</li>
            <li>Habilitar <b>reclassificação de detecções</b> do tipo falso positivo</li>
            <li>Habilitar <b>elipse auxiliar</b> de localização</li>
          `
        },
        {
          title: 'Atualizar dados',
          selector: '#atualizar-dados-btn',
          orientation: singleInterface ? "top": 'right',
          content: `
            <li>Recarregar interface</li>
            <li>Sincronizar configurações da conta com o banco de dados</li>
            <li>Sincronizar mapa com a base geográfica</li>
          `,
        },
        {
          title: 'Opções da conta',
          selector: '#account-options-btn',
          orientation: singleInterface ? 'top' : 'right',
          content: `
            <li>Alterar senha</li>
            <li>Encerrar sessão
          `,
        }
      ]
    };
    this.guidedTourService.startTour(this.configsTour);
  };

  async startDetectionTour(singleInterface: boolean = false){
    this.detectionTour = {
      tourId: 'detection-tour',
      useOrb: false,
      steps: [
        {
          title: 'Lista de detecções',
          selector: '.detections-table-wrapper',
          orientation:'right',
          content: 'Aqui são listadas as detecções automáticas e manuais de incêndio',
        },
        {
          title: 'Criar detecção manual',
          selector: '#camera-btn-manual-det',
          orientation:'bottom',
          action: ()=>{
            if(!this.detection.detections_list.length){
              this.detection.createManualDetection()
            }
          },
          content: 'Caso aviste um foco que não foi detectado pelo sistema, este botão permite criar uma detecção manual de incêndio<br/><br/>Se não há nenhuma detecção na tabela à esquerda, por favor <b>aguarde alguns segundos</b> para ver um exemplo ser gerado AGORA',
        },
        {
          title: 'Selecionar detecção',
          selector: '.table-container',
          orientation:'right',
          content: 'Ao selecionar uma detecção, aparecerão as imagens capturadas dos focos de incêndio'
        },
        {
          title: 'Verificando detecções',
          selector: singleInterface ? '' : ".det-btns-row",
          orientation: singleInterface ? 'top': 'bottom',
          content: 'Algumas ações são possíveis com uma detecção. Veja a seguir...',
          action: async () => {
            await this.detection.activateFirstDetection()
          }
        },
        {
          title: 'Visualizar a detecção',
          selector: singleInterface ? '#verify-det-btn-single' : '#verify-det-btn',
          orientation: singleInterface ? 'top': 'bottom',
          content: 'A câmera que detectou será selecionada na interface e apontará para o local correspondente',
          action: async () => {
            await this.detection.aimDetection()
          },
          closeAction: () => {
            if (this.user.autoTriang) this.user.toogleAutoTri();
          }
        },
        {
          title: 'Habilitar segunda câmera',
          selector: singleInterface ? "#enable-2cam-btn-single" : '#enable-2cam-btn',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Após apertar para visualizar a detecção, este botão é habilitado e podemos utilizar uma segunda câmera para auxiliar na procura do local do incêndio</li>
          <li>É necessário mover manualmente a segunda câmera nesta busca.</li>
          `,
          action: async ()=> {
            if (!singleInterface) {
              await this.detection.toggleSecondCam();
            }
          }
        },

        //single
        {
          skipStep: singleInterface ? false : true,
          title: 'Clicar no mapa para indicar o local',
          selector: '#map-click-btn-single',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Vá para a tela do mapa</li>
          <li>Habilite a geolocalização por clique</li>
          <li>Clique no local estimado da ocorrência</li>
          `
        },
        {
          skipStep: singleInterface ? false : true,
          title: 'Agrupar detecção em relatório',
          selector: '#group-on-rep-btn-single',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Selecione uma detecção</li>
          <li>Agrupe as imagens num relatório já existente </li>
          <li>Acompanhe a evolução do fogo em focos já localizados</li>
          `
        },
        {
          skipStep: singleInterface ? false : true,
          title: 'Confirmar o local da ocorrência',
          selector: '#confirm-loc-btn',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Após obter a localização aparecerá um icone no mapa</li>
          <li>Clique em confirmar local para gerar o relatório da ocorrência</li>
          `,
        },
        {
          skipStep: singleInterface ? false : true,
          title: 'Excluir a detecção',
          selector: '#delete-image-2',
          orientation: "top",
          content: 'Caso a detecção não seja um real incêndio é possivel excluí-la',
        },
        {
          skipStep: singleInterface ? false : true,
          title: 'Voltar à tabela de detecções',
          selector: '.btns-row-single-interface .btn-single-interface',
          orientation: 'top',
          content: '',
          closeAction: async ()=> {
            this.detection.deactivateDetection()
          }
        },


        //interface
        {
          skipStep: singleInterface ? true : false,
          title: 'Triangular',
          selector: '#triangulate-btn',
          orientation: "top",
          content: `
          <li>Caso aviste o foco com a segunda câmera clique em Triangular</li>
          <li>A tela do mapa indicará o local do foco</li>
          `
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Trocar a segunda câmera',
          selector: '#select-second-cam-btn',
          orientation: 'top',
          content: 'Caso queira trocar a segunda câmera, selecione uma na lista',
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Desabilitar segunda câmera',
          selector: singleInterface ? "#enable-2cam-btn-single" : '#enable-2cam-btn',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Desabilitar a câmera de triangulação</li>
          `,
          action: async ()=> {
            await this.detection.toggleSecondCam();
          }
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Click no mapa para indicar o local',
          selector: '#map-click-btn',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Vá para a tela do mapa</li>
          <li>Habilite a geolocalização por clique</li>
          <li>Clique no local estimado da ocorrência</li>
          `
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Agrupar detecção em relatório',
          selector: '#group-on-rep-btn',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Selecione uma detecção</li>
          <li>Agrupe suas imagens num relatório já existente </li>
          <li>Acompanhe a evolução do fogo em focos já localizados</li>
          `
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Confirmar o local da ocorrência',
          selector: '#confirm-loc-btn',
          orientation: singleInterface ? "top": 'bottom',
          content: `
          <li>Após obter a localização aparecerá um icone no mapa</li>
          <li>Clique em confirmar local para gerar o relatório da ocorrência</li>
          `,
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Excluir a detecção',
          selector: '#delete-image',
          orientation: singleInterface ? "top": 'bottom',
          content: 'Caso a detecção não seja um real incêndio é possivel excluí-la',
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Anular o procedimento',
          selector: '.table-container',
          orientation:'right',
          content: 'Para anular ou recomeçar o procedimento basta clicar na detecção selecionada',
          closeAction: ()=>{
            this.detection.deactivateDetection();
          }
        }
      ]
    }
    this.guidedTourService.startTour(this.detectionTour);
  };

  async startCamTour(singleInterface: boolean, camOptionsColumns: any){
    if (singleInterface) {
      this.detection.deactivateDetection();
      await this.utils.sleep(100);
    }
    switch (camOptionsColumns) {
      case HDR_CUSTOM_OPTIONS_COLUMNS:
        var cameraModesContent = `
          <li>Detecção automática</li>
          <li>Giro 360°</li>
          <li>Modo HDR</li>
          <li>Modo noturno</li>
          <li>Limpeza da lente</li>
          <li>Reiniciar</li>
        `
        break;
      default:
        var cameraModesContent = `
          <li>Detecção automática</li>
          <li>Giro 360°</li>
          <li>Modo noturno</li>
          <li>Limpeza da lente</li>
          <li>Reiniciar</li>
        `
        break;
    }

    this.camTour = {
      tourId: 'select-cam-tour',
      useOrb: false,
      steps: [
        {
          title: 'Seleção de câmera',
          selector: singleInterface ? '.cameras-btns' : '.side-btn-container',
          orientation: singleInterface ? 'right' : 'right',
          content: `Selecione a câmera que aparece na interface.<br/><br/>Duplo clique ativa/desativa o modo de detecção automática.`,
        },
        {
          title: 'Movimentação da câmera',
          selector: '#first-camera',
          orientation: singleInterface ? 'right'  : 'left',
          content: `Ao clicar em qualquer local da imagem a câmera aponta para o local`,
        },
        {
          title: 'Controles da câmera',
          selector: '.cam-ctrl-btns-container',
          orientation:'bottom',
          content: `
            <li>Criar detecção manual</li>
            <li>Habilita botões para movimentação: esquerda, direita, cima, baixo</li>
            <li>Desenha uma área retangular para zoom da câmera</li>
            <li>Zoom mais e menos</li>
            <li>Reseta o zoom</li>
            <li>Abre o vídeo em tela cheia</li>
          `,
        },
        {
          title: 'Modos da câmera',
          selector: '.cam-options-wrapper',
          orientation:'right',
          content: cameraModesContent
        },
        {
          title: 'Ativar modo automático',
          selector: '.sidenav-header',
          orientation:'right',
          content: `Esse botão ativa o modo automático em todas as câmeras`,
          action: ()=>{
            this.cams.turnOnAllDetections();
          }
        },
      ]
    };
    this.guidedTourService.startTour(this.camTour);
  };

  startMapTour(singleInterface:boolean = false){
    this.amplitude.sendEvent("Clicou Ajuda Mapa")
    this.mapTour = {
      tourId: 'map-tour',
      useOrb: false,
      steps: [
        {
          title: 'Controles do mapa',
          selector: '#map-ctrl-bar',
          orientation:'right',
          content: `
          <li>Camadas</li>
          <li>Visibilidade</li>
          <li>Focos de calor detectados via satélite</li>
          `,
        },
        {
          title: 'Camadas',
          selector: '#map-layers-btn',
          orientation: 'bottom',
          content: `Selecione o mapa de fundo, os limites da região, pontos de interesse etc`,
        },
        {
          title: 'Visibilidade',
          selector: '#map-visibility-btn',
          orientation: 'bottom',
          content: `Ative as camadas de visibilidade das torres e os pontos de calibração`,
        },
        {
          title: 'Clusters de focos de calor',
          selector: '#map-satellite-btn',
          orientation: 'bottom',
          content: `Visualize os clusters de focos de calor detectados via satélite`,
        },
        {
          title: 'Medir distâncias no mapa',
          selector: '#map-controls-canvas',
          orientation: 'left',
          content: `
          <li>Clique uma vez para ativar a régua</li>
          <li>Marque pontos no mapa e verá a distância entre eles</li>
          <li>Dê 2 cliques para desativar a régua</li>`,
        },
        {
          title: 'Pesquisa de local',
          selector: '#map-search-btn',
          orientation:'left',
          content: `Realize a busca de um local dentro da sua propriedade`,
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Direcionamento de câmeras por click no mapa',
          selector: '#map-move-cam',
          orientation:'left',
          content: `
          <li>Ao selecionar uma torre, o icone correspondente fica amarelo no mapa.</li>
          <li>Ao clicar em qualquer ponto no mapa, as câmeras selecionadas apontam na direção do local desejado.</li>
          `,
        },
        {
          skipStep: singleInterface ? true : false,
          title: 'Centralizar torre no mapa',
          selector: '#map-center-cam',
          orientation:'left',
          content: `Ao clicar em um botão desta lista, o mapa é centralizado na torre selecionada`,
        },
        {
          title: 'Detecções satelitais',
          selector: singleInterface ? '#map-sat-btn-single' : '#map-sat-btn',
          orientation: singleInterface ? '' : 'left',
          content: `Acesse a lista de detecções via satélite`,
        },
        {
          title: 'Dados meteorológicos',
          selector: singleInterface ? '#map-weather-btn-single' : '#map-weather-btn',
          orientation: singleInterface ? '' : 'left',
          content: `Acesse os dados meteorológicos na região`,
        }
      ]
    };
    this.guidedTourService.startTour(this.mapTour);
  };

  startReportTour(singleInterface: boolean = false){
    this.amplitude.sendEvent("Clicou Ajuda Relatorios")
    this.reportTour = {
      tourId: 'report-tour',
      useOrb: false,
      steps: [
        {
          title: 'Lista de relatórios',
          selector: '.report-btns-container',
          orientation: singleInterface ? 'left' : 'right',
          content: 'Aqui estão os relatórios de ocorrências abertos no sistema',
        },
        {
          title: 'Seleção de relatório',
          selector: '.report-btns-container',
          orientation: singleInterface ? 'left' : 'right',
          content: 'Ao clicar em um relatório, é aberta a aba para inserção de dados',
          action: ()=> {
            this.reports.selectFirstReport()
          }
        },
        {
          title: 'Ações no relatório',
          selector: '#report-actions-btn',
          orientation:'bottom',
          content: `
          <li>Fechar relatório</li>
          `
        },
        {
          title: 'Alterar localização da ocorrência',
          selector: '#change-loc-btn',
          orientation:'bottom',
          content: `<b>Para corrigir a localização de uma ocorrência, é possivel:</b>
          <br/>
          <br/>
          <li>Clicar no mapa no local da ocorrência</li>
          <li>Digitar valores de latitude e longitude informados pela equipe de campo</li>
          `
        },
        {
          title: 'Visualizar ocorrência',
          selector: '#aim-report-btn',
          orientation:'top',
          content: 'Aponte a câmera para verificar a ocorrência na interface',
        },
        {
          title: 'Enviar relatório via Telegram',
          selector: '#telegram-btn',
          orientation:'top',
          content: 'Envia o relatório para o grupo Telegram da sua planta',
        },
        {
          title: 'Finalizar',
          selector: '#finish-report-btn',
          orientation:'top',
          content: 'Finaliza o registro de ocorrência e, caso não seja um teste e ofereça risco, envia um PDF do relatório por e-mail'
        },
        {
          title: 'Localização da detecção',
          selector: '#loc-data',
          orientation:'top',
          content: "Identifique na sua propriedade onde ocorreu o incêndio"
        },
        {
          title: 'Dados meteorológicos da detecção',
          selector: '#meteo-data',
          orientation:'top',
          content: "Saiba como estava o clima no momento do incêndio"
        },
        {
          title: 'Informações da detecção',
          selector: '.cause-and-origin-row',
          orientation:'top',
          content: `
          <li>Selecione a causa do incêndio</li>
          <li>Selecione a origem da detecção</li>
          `,
        },
        {
          title: 'Registrar acionamento de recurso',
          selector: '.brigades-row',
          orientation:'top',
          content: 'Registre os acionamentos de cada brigada para verificar, combater ou rescaldar os incêndios',
          closeAction: ()=> {
            singleInterface ? null : document.getElementById("end-of-report").scrollIntoView()
          }
        },
        {
          title: 'Comentários',
          selector: '.comments-row',
          orientation:'top',
          content: 'Adicione comentários ao relatório',
          closeAction: ()=> {
            const element = document.getElementById("report-container")
            element.scrollTop = element.scrollHeight;
          }
        },
        {
          title: 'Registrar perdas',
          selector: '.damage-row',
          orientation:'top',
          content: 'Registre os danos causados pela ocorrência',
          closeAction: ()=> {
            singleInterface ? document.getElementById("end-of-report").scrollIntoView() : null
          }
        },
        {
          title: 'Informações adicionais',
          selector: '.test-and-risk-row',
          orientation:'top',
          content: `
          <li>Permite registrar quando a ocorrência não oferece risco</li>
          <li>Permite registrar quando a ocorrência é teste</li>
          `,
          closeAction: ()=> {
            this.reports.unSelectReport();
          }
        }
      ]
    };
    this.guidedTourService.startTour(this.reportTour);
  }
}
