<main>
  <section class='sidenav'>
    <div class="logo-container">
      <div class='pantera-logo'></div>
    </div>

    <div class='btns-container'>
      <button mat-fab
        (click)='toggleCamMove()'
        id="map-move-cam"
        [color]="show == 'camMove' ? 'accent' : 'primary'"
        matTooltip="Direcionamento de câmera por click no mapa">
        <img *ngIf="show != 'camMove'" src="assets/icons/new-icons/mouse-dark.svg" style="width: 24px; height: 24px;"/>
        <img *ngIf="show == 'camMove'" src="assets/icons/new-icons/mouse-light.svg" style="width: 24px; height: 24px;"/>
      </button>

      <button mat-fab
        id="map-center-cam"
        (click)='toggleCenterOnCam()'
        [color]="show == 'centerOnCam' ? 'accent' : 'primary'"
        matTooltip="Centralizar em torre no mapa">
        <img *ngIf="show != 'centerOnCam'" src="assets/icons/new-icons/radio-tower-dark.svg" style="width: 24px; height: 24px;"/>
        <img *ngIf="show == 'centerOnCam'" src="assets/icons/new-icons/radio-tower-light.svg" style="width: 24px; height: 24px;"/>
      </button>

      <button mat-fab
        (click)='toggleSat()'
        id='map-sat-btn'
        [matBadge]='sat.clustersLength'
        [matBadgeHidden]='!sat.clustersLength'
        [disabled]='!user.hasSatellite()'
        [color]="show == 'sat' ? 'accent' : 'primary'"
        matTooltip="Detecção Satelital">
        <img *ngIf="show != 'sat'" src="assets/icons/new-icons/satellite-dark.svg" style="width: 24px; height: 24px;"/>
        <img *ngIf="show == 'sat'" src="assets/icons/new-icons/satellite-light.svg" style="width: 24px; height: 24px;"/>
      </button>

      <button mat-fab
        (click)='toggleWeather()'
        id='map-weather-btn'
        [color]="show == 'weather' ? 'accent' : 'primary'"
        matTooltip="Meteorologia">
        <img *ngIf="show != 'weather'" src="assets/icons/new-icons/thermostat-dark.svg" style="width: 24px; height: 24px;"/>
        <img *ngIf="show == 'weather'" src="assets/icons/new-icons/thermostat-light.svg" style="width: 24px; height: 24px;"/>
      </button>

      <div style="margin-bottom: 10px; margin-top: auto;">
        <button mat-fab
          color='#000000'
          [matMenuTriggerFor]="mapHelpMenu"
          matTooltip="Ajuda"
          matTooltipPosition="above">
          <img src="assets/icons/new-icons/help-light.svg" style="width: 24px; height: 24px; margin-right: 10px; vertical-align: middle;"/>
        </button>

        <mat-menu #mapHelpMenu="matMenu">
          <button mat-menu-item
            (click)="onBoard.startMapTour()">
            <img src="assets/icons/new-icons/map-dark.svg" style="width: 20px; height: 20px; margin-right: 10px; vertical-align: middle;"/>
            <span>Funcionalidades do mapa</span>
          </button>
          <button mat-menu-item
            [disabled]='!reports.reports_list.length'
            (click)="onBoard.startReportTour()">
            <img src="assets/icons/new-icons/fire-dark.svg" style="width: 20px; height: 20px; margin-right: 10px; vertical-align: middle;"/>
            <span>Preenchimento de relatórios</span>
          </button>
          <button mat-menu-item
            style="display: flex; align-items: center; justify-content: flex-start;"
            (click)="onBoard.openZendeskTour()">
            <img src="assets/icons/new-icons/help-dark.svg" style="width: 20px; height: 20px; margin-right: 10px; vertical-align: middle;"/>
            <span>Fale conosco</span>
          </button>
        </mat-menu>
      </div>
    </div>
  </section>

  <section class='content-wrapper' *ngIf="show">
    <div class='cam-btns-wrapper' *ngIf="show == 'camMove' ">
      <div class="side-map-header">
        <img src="assets/icons/new-icons/mouse-dark.svg" style="width: 18px; height: 18px;"/>
        Direcionamento de câmera por click no mapa
      </div>

      <button
        *ngFor="let c of cams.cameras"
        [ngClass]="c.pantoOnClick ? 'selected' : ''"
        (click)='geo.highlightCamera(c)'
        color="c.pantoOnClick ? 'accent' : 'primary'"
      >
        <img *ngIf='c.pantoOnClick' src="assets/icons/new-icons/mouse-dark.svg" style="width: 18px; height: 18px;"/>
        {{c.nome_local}}
      </button>
    </div>

    <div class='cam-btns-wrapper' *ngIf="show == 'centerOnCam'">
      <div class="side-map-header">
        <img src="assets/icons/new-icons/radio-tower-dark.svg" style="width: 18px; height: 18px;"/>
        Centralizar torre no mapa
      </div>

      <button
        *ngFor="let c of cams.cameras"
        (click)='geo.centerOnCamera(c)'
      >
        {{c.nome_local}}
      </button>
    </div>

    <app-sat *ngIf="show == 'sat' "></app-sat>
    <app-weather *ngIf="show == 'weather' "></app-weather>
  </section>
</main>
