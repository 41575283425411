import { Component, ViewChild, ElementRef, OnInit} from '@angular/core';

import jspdf from 'jspdf';
import html2canvas from 'html2canvas';

import { ReportsService } from 'src/app/services/reports.service';
import { UserService } from 'src/app/services/user.service';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { GeoService } from 'src/app/services/geo.service';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
@Component({
  selector: 'download-report-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class DownloadReportDialog implements OnInit{

  @ViewChild('content', {static:false}) el:ElementRef;

  constructor(
    public reports: ReportsService,
    public user: UserService,
    private http: HttpService,
    private utils: UtilsService,
    public amplitude:AmplitudeService,
    public geo: GeoService,
    public customErrorHandler:CustomErroHandlerService
    ) {}

  public clientLogo64 = '';
  public r;
  public images;

  async ngOnInit(){
    let report_params = await this.http.get('get_report_params')
    let origens = report_params.origens_deteccao
    let causas = report_params.causas_incendio

    try {
      let response = await this.http.get('logo_cliente_64')
      this.clientLogo64 = response['logo']
    }
    catch (err) {
      this.customErrorHandler.handleError(err);
      this.clientLogo64 = ''
      console.log("Erro na rota logo_cliente_64: ", err)
    }
    
    this.images = await this.reports.getReportImages();
    console.log(this.images)
    this.r = this.utils.deepCopy(this.reports.selected_report);

    if (this.r.id_origem_deteccao) {
      this.r.origem = origens.filter(origem => this.r.id_origem_deteccao == origem.id_origem_deteccao)[0].origem_deteccao
    }

    if (this.r.id_causa_incendio) {
      this.r.causa = causas.filter(causa => this.r.id_causa_incendio == causa.id_causa_incendio)[0].causa_incendio
    }

    this.geo.snapping_map = false;
  }

  saveReport() {
    const contentWrapper = document.getElementById("content-wrapper");
    const elementToHide = document.getElementById("to-hide");

    contentWrapper.style.height = "auto";
    elementToHide.className = "hide";

    html2canvas(contentWrapper, {
      scrollY: -window.scrollY,
      scale: 5,
      backgroundColor: '#262626'
    }).then(canvas => {
      const imgData = canvas.toDataURL("image/png");      
      const link = document.createElement('a');
      link.download = `relatorio_${this.reports.selected_report.n_relatorio_planta}.png`;
      link.href = imgData;
      link.click();      
      elementToHide.className = "actions-row";
    });

    this.amplitude.sendEvent('Baixou PDF Relatório')
    elementToHide.className = "actions-row"
  }

  finishReport() {
    this.reports.finishReportProcedures()
  }
}
