<mat-dialog-content style="width: 30vw; max-height: 50vh; overflow-y: auto;">
    <div>
        <h2>Escolha um relatório para deletar</h2>
    </div>
    <div>
      <mat-selection-list #report_list [multiple]="false" [(ngModel)]="this.reports.selectedReportToDelete">
        <mat-list-option *ngFor="let rep of data.list" [value]="rep">
          {{rep.n_relatorio_planta}} - {{rep.name}}
        </mat-list-option>
      </mat-selection-list>

      <br/>

      <p>
        Relatório selecionado: {{report_list.selectedOptions.selected[0]?.value.n_relatorio_planta}} - {{report_list.selectedOptions.selected[0]?.value.name}}
      </p>
    </div>
</mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
  <button mat-raised-button [mat-dialog-close]="true" [style]="{'background': '#dc3333', 'color': 'white'}">Confirmar</button>
</mat-dialog-actions>
