<app-loader *ngIf="!loaded" [message]="'Carregando Mapa...'"></app-loader>

<app-small-loader *ngIf="geo.snapping_map" [message]="'Gerando relatório'"></app-small-loader>
  
<app-small-loader *ngIf="reports.isSendingMail()" [message]="'Enviando relatório'"></app-small-loader>

<main>

  <app-reports *ngIf="loaded"></app-reports>

  <section class="map-container">

    <div id="map"></div>

    <div *ngIf="loaded">

      <app-map-ctrl id="map-ctrl-bar" class="ctrl-box over-map"></app-map-ctrl>

      <button mat-mini-fab
        id='map-search-btn'
        class='over-map search-btn'
        [color] = "this.geo.showSearchBox? 'accent' : 'primary'"
        (click)="toggleSearchBox()"
        matTooltip="Pesquisar local" matTooltipPosition="above">
          <img *ngIf="geo.showSearchBox" src="assets/icons/new-icons/search-light.svg" style="width: 16px; height: 16px;"/>
          <img *ngIf="!geo.showSearchBox" src="assets/icons/new-icons/search-dark.svg" style="width: 16px; height: 16px;"/>
      </button>

      <div class="search-box-right over-map" *ngIf='this.geo.showSearchBox'>

        <div class="search-header">
          <input class="filter-input" type="text" placeholder="Busca" [(ngModel)]="search_text"/>
        </div>

        <cdk-virtual-scroll-viewport *ngIf="search_text" itemSize="50" class="white-bg search-list">
          <mat-card appearance="outlined" *cdkVirtualFor="let l of geo.layer_keys | textSearch: search_text"
          class="search-card"
          (click)="geo.focusOnLayer(l.id)">
          {{l.name}}
          </mat-card>
        </cdk-virtual-scroll-viewport>

      </div>

      <div id='map-controls-canvas'></div>

      <app-geoloc-menu *ngIf='geo.searchingFireLoc'></app-geoloc-menu>

      <div class="create-report-wrapper">
        <div class="lat-lon-wrapper" *ngIf="geo.lastClickLatLng.lat">
          <span>Lat: {{geo.lastClickLatLng.lat | number:'.5-5'}}</span>
          <span>Lon: {{geo.lastClickLatLng.lng | number:'.5-5'}}</span>
        </div>



        <button mat-mini-fab matTooltip="Criar relatório"  (click)="reports.openReportDialog()" [color]="'primary'">
           <img *ngIf="!geo.showSearchBox" src="assets/icons/new-icons/add-circle-warn.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
        </button>
      </div>

    </div>

  </section>

  <app-map-sidenav></app-map-sidenav>

</main>
