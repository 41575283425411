<mat-dialog-content >
  <div>
    <h3><b>Confirmar relatório de incêndio</b></h3>
    <p>
      Certifique-se que as câmeras estão visando na base do incêndio
    </p>
    <p>
      Deseja confirmar o local da ocorrência?
    </p>
  </div>
  </mat-dialog-content>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>
      <img src="assets/icons/new-icons/cancel-dark.svg" style="width: 18px; height: 18px; vertical-align: middle; margin-right: .5vw;"/>
      Cancelar
    </button>
    <button mat-raised-button [mat-dialog-close]="true" color='warn'>
      <img src="assets/icons/new-icons/fire-light.svg" style="width: 18px; height: 18px; vertical-align: middle; margin-right: .5vw;"/>
      <span style="color: white;">Confirmar</span>
    </button>
  </mat-dialog-actions>
