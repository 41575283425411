<main>

  <div class="side-map-header">
    <img src="assets/icons/new-icons/satellite-dark.svg" style="width: 24px; height: 24px;"/>
    Detecção Satelital
  </div>

  <table mat-table matSort [dataSource]="clustersDataSource" class="mat-elevation-z8 custom-table" *ngIf='sat.clustersLength'>

    <ng-container matColumnDef="cluster_id">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> N° detecção</th>
      <td mat-cell *matCellDef="let element">
        <span *ngIf='element.recent'>🔴</span>
        {{element.cluster_id? element.cluster_id: element.pk_mv}}
      </td>
    </ng-container>

    <ng-container matColumnDef="oldest_hotspot">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="Horario do primeiro foco identificado para este cluster"> Foco mais antigo </th>
      <td mat-cell *matCellDef="let element">
        {{element.oldest_hotspot | date:'HH:mm -- dd/MM'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="newest_hotspot">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="Horario do último foco identificado para este cluster"> Foco mais recente </th>
      <td mat-cell *matCellDef="let element">
        {{element.newest_hotspot | date:'HH:mm -- dd/MM'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="cluster_duration">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="Diferença entre o horario do ultimo foco e o primeiro foco"> Duração </th>
      <td mat-cell *matCellDef="let element"> {{element.cluster_duration}}</td>
    </ng-container>

    <ng-container matColumnDef="amount_of_hotspots">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="Número de focos de calor identificado no período"> Quantidade de focos </th>
      <td mat-cell *matCellDef="let element"> {{element.amount_of_hotspots}}</td>
    </ng-container>

    <ng-container matColumnDef="area_ha">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="Área do cluster"> area_ha</th>
      <td mat-cell *matCellDef="let element"> {{element.area_ha}}</td>
    </ng-container>

    <ng-container matColumnDef="confirmed">
      <th mat-header-cell *matHeaderCellDef mat-sort-header
      matTooltip="Cluster foi confirmado com relatório de incêndio"> Confirmado</th>
      <td mat-cell *matCellDef="let element"> {{element.cluster_id? '✔️': null}}</td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="clustersColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: clustersColumns;"
      class='table-row'
      (click)="selectCluster(row.pk_mv)"></tr>
  </table>

  <mat-card appearance="outlined" *ngIf='!sat.clustersLength'>
    Não há detecções satelitais no momento
  </mat-card>
  <div style='padding:1rem'>
    <p>
      Tempo desde a detecção
    </p>
    <div *ngFor="let obj of satColorMap" style="display: flex; justify-content: flex-start;">
      <i [ngStyle]="{'background': obj.color}"></i> {{obj.legend}}
    </div>
  </div>
</main>
