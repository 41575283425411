<app-loader *ngIf="loading" [message]="loading_msg"></app-loader>
<app-small-loader *ngIf="det.creating_report" [message]="'Confirmando relatório'"></app-small-loader>
<app-small-loader *ngIf="geo.snapping_map" [message]="'Gerando relatório'"></app-small-loader>

<main>
  <div *ngIf="cams.expanded_screen || cams.expanded_second_screen" class="opacity-layer"></div>

  <section class='side-nav'>
    <div class="logo-container">
      <div class='pantera-logo'></div>
    </div>

    <div class='side-btn-container'>
      <div id='select-cam-btns'>
        <div class="sidenav-header">
          <button (click)='cams.turnOnAllDetections()' mat-mini-fab color='warn' matTooltip="Liga o modo automático em todas as câmeras" matTooltipPosition="above">
              <img src="assets/icons/new-icons/gps-light.svg" style="height: 24px; width: 24px; vertical-align: middle;"/>
          </button>
        </div>

        <div class="cameras-btns">
          <button *ngFor="let c of cams.cameras"
            (dblclick)='handleDoubleClick(c)'
            class='side-btn'
            [disabled]='det.aiming && c.id_cam != det.active_detection.id_camera_deteccao && c.id_cam != cams.getSecondCamId()'
            [ngClass]="cams.getFirstCamId() == c.id_cam ? 'selected' : ''"
            (click)='setFirstCamera(c.id_cam)'
            mat-stroked-button>
            <span *ngIf='!c.auto'>
              ⚫
            </span>
            <span *ngIf='c.auto == 1'>
              🔴
            </span>
            <span *ngIf='c.auto == 2'>
              🟡
            </span>

            {{c.nome_local}}
          </button>
        </div>
      </div>
    </div>

    <div class="help-container">
      <button mat-icon-button
        id='help-btn'
        [matMenuTriggerFor]="helpMenu"
        matTooltip="Ajuda" matTooltipPosition="above"
        class="help-user-btn">
          <img src="assets/icons/new-icons/help-light.svg" style="height: 24px; width: 24px;"/>
      </button>

      <mat-menu #helpMenu="matMenu">
        <button mat-menu-item (click)="startDetectionTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/gps-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Detecções</span>
        </button>

        <button mat-menu-item (click)="startCamTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/camera-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Uso das câmeras</span>
        </button>

        <button mat-menu-item (click)="startConfigsTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/settings-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Configurações</span>
        </button>

        <button mat-menu-item (click)="startMapTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/map-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Funcionalidades do mapa</span>
        </button>

        <button mat-menu-item [disabled]='!reports.reports_list.length' (click)="startReportTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/fire-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Preenchimento de relatórios</span>
        </button>

        <button mat-menu-item (click)="startWelcomeTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/notification-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Novidades</span>
        </button>

        <button mat-menu-item (click)="openZendeskTour()" style="display:flex; justify-content: flex-start; align-items: center;">
          <img src="assets/icons/new-icons/help-dark.svg" style="height: 18px; width: 18px; margin-right: .5vw; vertical-align: middle;"/>
          <span>Fale conosco</span>
        </button>
      </mat-menu>

    </div>

  </section>

  <section class="interface-col" *ngIf="!loading">
    <div class='streaming-row'>
      <div [ngClass]="cams.expanded_screen ? 'camera-wrapper-expanded' : 'camera-wrapper'" id="first-camera">
        <app-camera [ngClass]="streamingType" [singleInterface]="true" [cam_id]="cams.getFirstCamId()"></app-camera>
      </div>
    </div>

    <div class='controls-row'>
      <mat-tab-group class="tabs-group" [color]="'primary'" [(selectedIndex)]="interfaceTabsService.selectedTab" *ngIf="!det.secondCamEnabled" (selectedTabChange)="det.deactivateDetection()">
        <mat-tab id="det">
          <ng-template mat-tab-label>
            <img src="assets/icons/new-icons/gps-light.svg" style="height: 20px; width: 20px; vertical-align: middle;"/>
            <span style="margin-left: 5px; color: white;">Detecções</span>
          </ng-template>

          <div class="detections-img-wrapper">
            <app-detections [singleInterface]="true"></app-detections>
          </div>

          <div class="detections-table-wrapper" *ngIf="!det.active_detection">
            <app-detections-table></app-detections-table>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/icons/new-icons/camera-light.svg" style="height: 20px; width: 20px; vertical-align: middle;"/>
            <span style="margin-left: 5px; color: white;">Controles</span>
          </ng-template>
          <div class="tab-content-container">
            <app-cam-options class='cam-options-wrapper'></app-cam-options>
          </div>
        </mat-tab>

        <mat-tab>
          <ng-template mat-tab-label>
            <img src="assets/icons/new-icons/manage-light.svg" style="height: 20px; width: 20px; vertical-align: middle;"/>
            <span style="margin-left: 5px; color: white;" id="configs-tab">Configurações</span>
          </ng-template>
          <div class="tab-content-container">
            <app-client-config class='cam-options-wrapper'></app-client-config>
          </div>
        </mat-tab>
      </mat-tab-group>
    </div>

    <div class="streaming-row" *ngIf='det.secondCamEnabled'>
      <div [ngClass]="cams.expanded_second_screen ? 'camera-wrapper-expanded' : 'camera-wrapper'" id="second-camera">
        <app-camera [ngClass]="streamingType" [singleInterface]="true" [cam_id]="cams.getSecondCamId()" [isSecondCam]="true"></app-camera>

        <div class="second-camera-btns">
          <button mat-fab color="primary"
            class="custom-fab"
            [matMenuTriggerFor]="triCamMenu"
            matTooltip="Selecionar câmera" matTooltipPosition="above" id="select-cam-btn-single">
            <img src="assets/icons/new-icons/switch-video-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
          </button>

          <mat-menu #triCamMenu="matMenu">
            <ng-container *ngFor="let c of cams.cameras">
              <button mat-menu-item
                *ngIf = 'cams.getFirstCamId() != c.id_cam'
                (click)="det.switchTriTower(c.id_cam)">
                <span>{{c.nome_local}}</span>
              </button>
            </ng-container>
          </mat-menu>

          <button mat-fab
            color="primary"
            [ngClass]="'custom-fab'"
            id='enable-2cam-btn'
            (click)="toggleSecondCam()"
            [matTooltip]="det.secondCamEnabled? 'Desabilitar segunda câmera' : 'Habilitar segunda câmera'" matTooltipPosition="above">
              <img *ngIf="det.secondCamEnabled" src="assets/icons/new-icons/camera-dark.svg" style="height: 22px; width: 22px;"/>
              <img *ngIf="!det.secondCamEnabled" src="assets/icons/new-icons/camera-off-dark.svg" style="height: 22px; width: 22px;"/>
          </button>

          <button mat-fab
            *ngIf="det.autoTriangulando && det.showStopTriButton"
            color="warn"
            [ngClass]="'custom-fab'"
            (click)="det.pararAutoTri(cams.getSecondCamId())"
            matTooltip="Parar triangulação automática" matTooltipPosition="above">
              <img src="assets/icons/new-icons/cancel-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
          </button>

          <button mat-fab
            color="primary"
            [ngClass]="'custom-fab'"
            id='triangulate-btn'
            [disabled] = "!det.secondCamEnabled"
            (click)="det.searchTriangulation()"
            matTooltip="Triangular manualmente" matTooltipPosition="above"
            *ngIf="!det.autoTriangulando">
              <img src="assets/icons/new-icons/architeture-dark.svg" style="width: 18px; height: 18px; vertical-align: middle;"/>
          </button>

          <button mat-fab
            color="warn"
            [ngClass]="'custom-fab'"
            id='confirm-loc-btn'
            [disabled] = "!det.active_detection?.dados_localizacao.length"
            (click)="det.openConfirmFireDialog()"
            matTooltip="Confirmar relatório" matTooltipPosition="above">
              <img *ngIf="!det.active_detection?.dados_localizacao.length" src="assets/icons/new-icons/fire-dark.svg" style="height: 22px; width: 22px;"/>
              <img *ngIf="det.active_detection?.dados_localizacao.length" src="assets/icons/new-icons/fire-light.svg" style="height: 22px; width: 22px;"/>
          </button>
        </div>
      </div>
    </div>
  </section>

  <section class='map-col'>
    <div class='map-container'>
      <div id="map"></div>

      <app-map-ctrl id="map-ctrl-bar" class="ctrl-box" *ngIf="!reports.selected_report"></app-map-ctrl>

      <button mat-mini-fab
        id='map-search-btn'
        class='search-btn'
        [color] = "geo.showSearchBox? 'accent' : 'primary'"
        (click)="toggleSearchBox()"
        matTooltip="Pesquisar local" matTooltipPosition="above">
          <img *ngIf="geo.showSearchBox" src="assets/icons/new-icons/search-light.svg" style="width: 16px; height: 16px;"/>
          <img *ngIf="!geo.showSearchBox" src="assets/icons/new-icons/search-dark.svg" style="width: 16px; height: 16px;"/>
      </button>

      <div class="search-box-right" *ngIf='geo.showSearchBox'>
        <div class="search-header">
          <input class="filter-input" type="text" placeholder="Busca" [(ngModel)]="search_text"/>
        </div>

        <cdk-virtual-scroll-viewport *ngIf="search_text" itemSize="50" class="white-bg search-list">
          <mat-card appearance="outlined" *cdkVirtualFor="let l of geo.layer_keys | textSearch: search_text"
          class="search-card"
          (click)="geo.focusOnLayer(l.id)">
          {{l.name}}
          </mat-card>
        </cdk-virtual-scroll-viewport>
      </div>

      <div id='map-controls-canvas'></div>

      <app-geoloc-menu [isSingle]="true" *ngIf='geo.searchingFireLoc'></app-geoloc-menu>

      <div class="create-report-wrapper" *ngIf="!reports.selected_report">
        <div class="lat-lon-wrapper" *ngIf="geo.lastClickLatLng">
          <span>Lat: {{geo.lastClickLatLng.lat | number:'.5-5'}}</span>
          <span>Lon: {{geo.lastClickLatLng.lng | number:'.5-5'}}</span>
        </div>

        <span *ngIf="!geo.lastClickLatLng" style="color: white; margin-bottom: 1rem;">Criar relatório</span>

        <button mat-mini-fab matTooltip="Criar relatório"  (click)="reports.openReportDialog()" [color]="'primary'">
          <img src="assets/icons/new-icons/add-circle-warn.svg" style="width: 16px; height: 16px;"/>
        </button>
      </div>
    </div>

    <app-reports *ngIf="!loading" [singleInterface]='true' [contentOnRight]='false'></app-reports>
  </section>
</main>
