
  
<main>
  
  <h2>Recuperação de senha</h2>
  <h3>Favor inserir o usuário para a recuperação de senha</h3>
  
  <h3 *ngIf="showErrorMessage" style='color:red; display: flex; align-items: center;'>
    <img src="assets/icons/new-icons/error-outline-warn.svg" style="height: 18px; width: 18px;"/>
    Usuário inexistente
  </h3>

  <mat-form-field appearance="outline" [color]="'accent'">
    <mat-label>Usuário</mat-label>
    <span matPrefix style="margin: 0 .5vw;">
      <img src="assets/icons/new-icons/account-circle-dark.svg" style="height: 18px; width: 18px;"/>
    </span>
    <input matInput [(ngModel)]="username" (change)='showErrorMessage=false'>
  </mat-form-field>
    
  
  <div *ngIf="sucess">
    <h3 style='color:green; display: flex; align-items: center;'>
      <img src="assets/icons/new-icons/check-circle-dark.svg" style="height: 18px; width: 18px;"/>
      Email de recuperação enviado para {{email_recipient}}
    </h3>
  </div>
  
  <mat-dialog-actions>
    <button mat-raised-button [mat-dialog-close]='false'>Voltar</button>
    <button mat-raised-button class="login-btn" color='primary' (click)='reset_pass()' *ngIf='!sucess'>Resetar senha</button>
  </mat-dialog-actions>
</main>