export function tableRow(key, value, color='white'){
  if (typeof value == "boolean") {
    value = value? '☑️':''
  }
  return `
    <tr style="background-color:${color}">
      <td>${key}</td>
      <td>${value}</td>
    </tr>`
}

export function tableHeader(title, bgcolor='white', color='black'){
  return `
    <tr style="background-color:${bgcolor}; color:${color}; text-align:center;">
      <td colspan="2"><strong>${title}</strong></td>
    </tr>`;
}

const polygon_attribute_keys= ['Local', 'Outros', 'Area_poligono']
export function formatGeoInfo(feature) {
  let obj = feature.properties.atributos;
  let infoHTML = '<table>'

  // infoHTML+= tableHeader(`${feature.properties.nome_geom}`, '#c4c4c4')

  let ct = 0;
  for (let k of polygon_attribute_keys) {
    if (k in obj) {
      for (let i of obj[k]) {
        let col = (ct++ % 2) === 0 ? 'white' : '#cce2ed';
        infoHTML+= tableRow(i.key, i.value, col)
      }
    }
  }
  return infoHTML+'</table>';
}

export function formatLocationInfo(dados_localizacao, dados_meteo=null, n_relatorio_planta=null){
  let infoHTML = '<table>'

  if(n_relatorio_planta){
    infoHTML+= tableHeader(`N° ${n_relatorio_planta}`, '#d93b3b')
  }

  if (dados_localizacao.distancia_area) {
    infoHTML+= tableHeader(`Distancia da propriedade ${dados_localizacao.distancia_area} Km`, '#c4c4c4')
  } else {
    infoHTML+= tableHeader(`Localização`, '#c4c4c4')
  }

  let obj = dados_localizacao.dados_geo.atributos;
  let ct = 0;
  for (let k of polygon_attribute_keys) {
    if (k in obj) {
      for (let i of obj[k]) {
        let col = (ct++ % 2) === 0 ? 'white' : '#cce2ed';
        infoHTML+= tableRow(i.key, i.value, col)
      }
    }
  }

  if (dados_meteo){
    infoHTML+= tableHeader(`Dados meteorológicos`, '#c4c4c4')
    infoHTML+= tableRow('Temperatura', `${dados_meteo['temp']} ºC`, '#cce2ed')
    infoHTML+= tableRow('Umidade', `${dados_meteo['humidity']} %`, 'white')
    infoHTML+= tableRow('Velocidade do vento', `${dados_meteo['wind_speed']} m/s`, '#cce2ed')
    infoHTML+= tableRow('Direção do vento', `${dados_meteo['wind_deg']} º`, 'white')
    infoHTML+= tableRow('Precipitação', `${dados_meteo['rain']} mm`, '#cce2ed')
  }

  let mapsLink = `http://www.google.com/maps/place/${dados_localizacao.lat},${dados_localizacao.lon}`
  infoHTML +=
  `<tr style="background-color:lightgray; text-align:center;">
    <td colspan="2"><a target="_blank" "fullscreen=no" rel="noopener noreferrer" href="${mapsLink}" style="color:green">LinkGoogleMaps</a></td>
    </tr>`

  return infoHTML+'</table>';
}

export function formatPoiInfo(properties){
  let infoHTML = '';
  infoHTML+= `<div style="background-color:lightgray"><strong>${properties.nome_poi}</strong></div>`
  if (properties.obs){
    infoHTML+= `<div>${properties.obs}</div>`
  }
  return infoHTML
}

export function formatPorInfo(por){
  let infoHTML = `<div style="background-color:lightgray"><b>${por.nome_por}</b></div>`

  let mapsLink = `http://www.google.com/maps/place/${por.lat},${por.lon}`
  infoHTML +=
  `<div style="text-align:center;">
    <a target="_blank" "fullscreen=no" rel="noopener noreferrer" href="${mapsLink}" style="color:green">GoogleMaps</a>
  </div>`

  return infoHTML+'</table>';
}