import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UserService } from './user.service';
import * as L from 'leaflet';
import {tableHeader, tableRow} from "./geo.format";
import { formatDate } from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class BrigadesService {

  constructor(
    private http: HttpService,
    private user: UserService,
  ) { }

    public brigadeIconMap = {
      1: '/assets/map_icons/motorcycle.png',
      2: '/assets/map_icons/car.png',
      3: '/assets/map_icons/pickup.png',
      4: '/assets/map_icons/van.png',
      5: '/assets/map_icons/quad.png',
      11: '/assets/map_icons/firetruck.svg',
      12: '/assets/map_icons/bus.png',
      13: '/assets/map_icons/tractor.png',
      14: '/assets/map_icons/pickup-truck.png',
      21: '/assets/map_icons/helicopter.png',
      31: '/assets/map_icons/boat.png'
    }

   async createBrigadeGeoJson(){
    
    let geojson_brigadas = await this.http.maestroGet(`get_brigadas_geojson/${this.user.getIdPlanta()}`)
    
    let layer = L.geoJSON(geojson_brigadas, {
      pointToLayer: (feature, latlng) => {
        let icon = L.icon({iconUrl: this.brigadeIconMap[`${feature.properties.id_tipo_brigada}`], iconSize: [32,32]});
        let marker = L.marker(latlng, {icon: icon});
        marker.bindPopup(this.formatBrigadeInfo(feature.properties));
        return marker
      }
    });

    return {
      'groupId': 8,
      'groupTitle': 'Brigadas',
      'icon': 'fire_truck',
      'layersList': [{
        'legend':'Brigadas',
        'layer': layer,
        'active': true
      }]
    }

  }

  formatBrigadeInfo(properties){
    let infoHTML = '<table>'
    infoHTML+= tableHeader(`${properties.nome_brigada}`, '#c4c4c4')
    infoHTML+= tableRow('Ultima atualização', formatDate(properties.last_updated, 'HH:mm dd/MM/yyyy', 'en-US'))
    let ct = 1
    if (properties.placa){
      let col = (ct++ % 2) === 0 ? 'white' : '#cce2ed';
      infoHTML+= tableRow('Placa', properties.placa, col)
    }
    if (properties.obs_brigada){
      for (const [key, value] of Object.entries(properties.obs_brigada)) {
        let col = (ct++ % 2) === 0 ? 'white' : '#cce2ed';
        infoHTML+= tableRow(key, value, col)
      }
    }
    return infoHTML
  }
}
