<main>
  <table mat-table [dataSource]="cams.cameras" class="mat-elevation-z8">

    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef
        matTooltip="Lista de torres" matTooltipPosition="above">
        <div class="tower-header-container">
          <img src="assets/icons/new-icons/camera-dark.svg" style="height: 32px; width: 32px;"/>
          <span>Funções</span>
        </div>
      </th>

      <td mat-cell *matCellDef="let element" class="tower-id"> {{element.name}} </td>
    </ng-container>

    <ng-container matColumnDef="auto">
      <th mat-header-cell *matHeaderCellDef
        (click)='cams.turnOnAllDetections()'
        matTooltip="Detecção Automática" matTooltipPosition="above">
        <div class="icon-header-container">
          <img src="assets/icons/new-icons/gps-warn.svg" style="height: 24px; width: 24px;"/>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="tower-radio-btn">
        <mat-slide-toggle color="warn" [checked]="element.auto != 0" (click)="setCameraStatus(element, 'auto')"></mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="spin">
      <th mat-header-cell *matHeaderCellDef
        matTooltip="Modo giro 360º" matTooltipPosition="above">
        <div class="icon-header-container">
          <img src="assets/icons/new-icons/360-dark.svg" style="height: 24px; width: 24px;"/>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="tower-radio-btn">
        <mat-slide-toggle color="warn" [checked]="element.spin" (click)="setCameraStatus(element, 'spin')"></mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="hdr">
      <th mat-header-cell *matHeaderCellDef
        matTooltip="Modo HDR" matTooltipPosition="above">
        <div class="icon-header-container">
          <img src="assets/icons/new-icons/hdr-dark.svg" style="height: 24px; width: 32px;"/>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="tower-radio-btn">
        <mat-slide-toggle color="warn" [checked]="element.hdr" (click)="setCameraStatus(element, 'hdr')"></mat-slide-toggle>
      </td>
    </ng-container>

    <ng-container matColumnDef="night">
      <th mat-header-cell *matHeaderCellDef
        matTooltip="Modo noturno" matTooltipPosition="above">
        <div class="icon-header-container">
          <img src="assets/icons/new-icons/moon-dark.svg" style="height: 24px; width: 24px;"/>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="tower-radio-btn">
        <button class="night-control-btn" (click)="setCameraStatus(element, 'night')" *ngIf="!element.night && element.id_equipamento != this.DAHUA_ID">OFF</button>
        <button class="night-control-btn-active" (click)="setCameraStatus(element, 'night')" *ngIf="element.night && element.id_equipamento != this.DAHUA_ID">AUTO</button>
        <button mat-icon-button color="" (click)="setCameraStatus(element, 'night')" *ngIf="element.id_equipamento == this.DAHUA_ID" [disabled]="true">
          <img src="assets/icons/new-icons/radio-button-unchecked-dark.svg" style="height: 18px; width: 18px;"/>
        </button>
      </td>
    </ng-container>

    <ng-container matColumnDef="clean">
      <th mat-header-cell *matHeaderCellDef
        matTooltip="Limpar a lente. ATENÇÃO: usar somente em caso de chuva" matTooltipPosition="above">
        <div class="icon-header-container">
          <img src="assets/icons/new-icons/broom-dark.svg" style="height: 24px; width: 24px;"/>
        </div>
      </th>
      <td mat-cell *matCellDef="let element" class="tower-radio-btn">

        <button mat-icon-button
          color=""
          [disabled]="element.clean"
          (click)="clean(element)">
          <img src="assets/icons/new-icons/radio-button-unchecked-dark.svg" style="height: 18px; width: 18px;"/>
        </button>

      </td>
    </ng-container>

    <ng-container matColumnDef="reboot">

      <th mat-header-cell *matHeaderCellDef
        matTooltip="Reiniciar Câmera" matTooltipPosition="above">
        <div class="icon-header-container">
          <img src="assets/icons/new-icons/power-dark.svg" style="height: 24px; width: 24px;"/>
        </div>
      </th>

      <td mat-cell *matCellDef="let element" class="tower-radio-btn">
        <button mat-icon-button
          color=""
          [disabled]="element.reboot"
          (click)="reboot(element)">
          <img src="assets/icons/new-icons/radio-button-unchecked-dark.svg" style="height: 18px; width: 18px;"/>
        </button>
      </td>

    </ng-container>

    <tr mat-header-row *matHeaderRowDef="cams.cam_options_columns; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: cams.cam_options_columns;"></tr>
  </table>
</main>
