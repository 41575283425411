import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit, OnDestroy } from '@angular/core';

@Component({
  selector: 'app-streamer-webrtc',
  templateUrl: './streamer-webrtc.component.html',
  styleUrls: ['./streamer-webrtc.component.scss']
})
export class StreamerWebRTCComponent implements OnInit, AfterViewInit {
  @Input() public cam_id: number;
  @Input() public stream_host: string;
  @Input() public stream_port: number;
  @Input() public requestType = 'http';

  @ViewChild('removeteVideoVXG') private removeteVideoVXG: ElementRef;

  public url: string;

  constructor() {}

  async ngOnInit(){}

  ngAfterViewInit(){
    this.url = `${this.requestType}://${this.stream_host}:${this.stream_port}/stream/player/${this.cam_id}`;
    this.removeteVideoVXG.nativeElement.src = this.url;
  }

}
