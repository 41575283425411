import { Component } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { HttpService } from 'src/app/services/http.service';
import { formatDate } from '@angular/common';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'add-img-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class AddImagesDialog{
  constructor(
    private http: HttpService,
    private reports: ReportsService,
    private user: UserService,
  ) {}
  
  public fileToUpload: File | null = null;
  public imageTypes = [
    {
      'id':20,
      'type':'Terrestre'
    },
    { 
    'id':30,
    'type':'Aérea'
    }
  ]

  handleFileInput(event){
    this.fileToUpload = event.target.files.item(0);
  }

  // TODO MELHORAR DETALHES
  async postImgData(){
    let fd = new FormData();
    let imgData = {
      'id_report': this.reports.selected_report.id_report,
      'id_planta': this.user.getIdPlanta(),
      'img_type': 20,
      'img_timestamp': formatDate(new Date().getTime(), 'yyyy-MM-dd HH:mm:ss', 'en-US'),
      'img_details': {}
    }
    fd.append('file', this.fileToUpload, this.fileToUpload.name);
    fd.append('imgData', JSON.stringify(imgData));
    let response = await this.http.maestroPost('add_report_image', fd)
    console.log('postImgData', response)
  }
  
}

