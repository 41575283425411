import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { io, Socket } from 'socket.io-client';
import { environment } from 'src/environments/environment'
import { AmplitudeService } from './amplitude.service';
import { UserService } from './user.service';
@Injectable()
export class SocketService {
  private socket: Socket;
  private socketCentral: Socket;

  urlSocket = environment.socketHost;

  constructor(
    private amplitude: AmplitudeService,
    public user: UserService,
  ) {
    this.socket = io(this.urlSocket, { transports: ["websocket"] });
  }

  public socketDownValue = false;
  public reconnectAttempts = 0
  public maxReconnectAttempts = 5

  // EMITTER example
  sendCameras(msg) {
    this.socket.emit('cameras',  msg );
  }

  sendDetections(msg) {
    this.socket.emit('detections',  msg );
  }

  sendAutoTriCam(msg) {
    this.socket.emit('autoTriCam',  msg );
  }

  sendAutoTriStop(msg) {
    console.log(msg);
    this.socket.emit('STOP_CAM_TRI',  msg );
  }

  sendAtulizaDetection(msg) {
    console.log(msg);
    this.socket.emit('att_detections',  msg );
  }

  // HANDLER example
  onNewMessageCam() {
    return new Observable(observer => {
      this.socket.on('newMessageCam', msg => { // sync cameras status multi fronts
        observer.next(msg);
      });
    });
  }

  onNewMessageDet() {
    return new Observable(observer => {
      this.socket.on('newMessageDet', msg => { // sync lista dets multi fronts
        observer.next(msg);
      });
    });
  }

  onNewMessageGelocData() {
    return new Observable(observer => {
      this.socket.on('gelocData', msg => {    // recebe result autoTri
        observer.next(msg);
      });
    });
  }

  onSatSocketDisconnect() {
    return new Observable<String>(observer => {
      this.socketCentral.on('disconnect', msg => {
        observer.next(msg);
      });
    });
  }

  onAttDadosCliente() {
    return new Observable<Boolean>(observer => {
      this.socketCentral.on('trigger_att_dados_planta', msg => {
        observer.next(msg);
      });
    });
  }

  onSatSocketDown() {
    return new Observable<String>(observer => {
      this.socketCentral.on('connect_error', (error: any) => {
        this.reconnectAttempts++
        if (this.reconnectAttempts == this.maxReconnectAttempts) {
          console.log('Não foi possível se conectar ao servidor socket!')
          observer.next('true')
        }
      });
    });
  }

  joinIdPlantRoom() {
    this.reconnectAttempts = 0
    this.socketCentral = io(environment.socketCentralUrl + '/sat_update', { 
      reconnectionAttempts: this.maxReconnectAttempts,
      auth: { token: environment.bearerSockCent },
      transports: ["websocket"]
    },);
    
    this.socketCentral.on("send_room", (attempt) => {
      console.log(this.user.getIdPlanta())
      this.reconnectAttempts = 0;
      this.socketCentral.emit('join', parseInt(this.user.getIdPlanta(), 10)  ); 
    });
  }

  getImagesComplementManual(dict_info) {
    this.socket.emit("set_images_complements",  dict_info);
  };
}
