import { Component, Input} from '@angular/core';
import { CameraService } from 'src/app/services/camera.service';
import { DetectionsService } from 'src/app/services/detections.service';
import { StorageService } from 'src/app/services/storage.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-detections',
  templateUrl: './detections.component.html',
  styleUrls: ['./detections.component.scss']
})
export class DetectionsComponent {

  @Input() singleInterface:boolean=false;
  @Input() newInterface:boolean=false;
  
  constructor(
    public det:DetectionsService,
    public cams:CameraService,
    public user:UserService,
    public storage: StorageService
  ) { }

  public relabelling_msg = "Deletando detecção..."
  public tooltip_manual_tri = "Habilitar segunda câmera"
  public tooltip_auto_tri = "Triangular automaticamente"
}
