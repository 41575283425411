import { Injectable } from '@angular/core';
import { ErrorHandler } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {timeout} from 'rxjs/operators';
import {environment} from '../../environments/environment';
import { UserService } from './user.service';

@Injectable({
  providedIn: 'root'
})
export class CustomErroHandlerService {


  private postHeader = new HttpHeaders({
    'content-type': 'application/json',
    'Authorization': 'Bearer ' + environment.bearer
  });

  private routeFluent = `https://fluentd.umgrauemeio.com/pcig.frontend`;

  constructor(
    private http: HttpClient,
    private user: UserService
  ) {}

  handleError(error: any): void {
    this.logERROR('Custom Error Handler:', error);
  }

  logERROR(msg, error=undefined){
    try {
      let errorParsed = this.parseError(error);
      this.http
      .post(
        this.routeFluent,
        {
          log_level: 'ERROR',
          id_planta: this.user.getIdPlanta(),
          user: this.user.getUsername(),
          msg: errorParsed.fileAndLine,
          func: errorParsed.atFile,
          pcig_v: environment.version
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
        )
        .toPromise()
        .then(
          res => {return},
          err => {console.log(`Error fluentD logging: ${err}`)},
      );
    } catch (error) {
      console.log('Error fluentD logging', error);
    }
  }

  parseError(error){

    let fileAndLine, atFile = 'undefined';
    try {
      if (error==undefined) {
        return {fileAndLine, atFile}
      }else{
        const stackTrace = error.stack;
        fileAndLine = stackTrace.split('\n')[0].trim();
        atFile = stackTrace.split('\n')[1].trim();
        return {fileAndLine, atFile}
      }
    } catch (err) {
      this.logERROR(`logging:parseError ${err}`,err);
      return {fileAndLine, atFile}
    }
  }

  sendCustomLog(msg: string, funcName: string){
    try {
      this.http
      .post(
        this.routeFluent,
        {
          log_level: 'ERROR',
          id_planta: this.user.getIdPlanta(),
          user: this.user.getUsername(),
          func: funcName,
          pcig_v: environment.version,
          msg
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
        )
        .toPromise()
        .then(
          res => {return},
          err => {console.log(`Error fluentD logging: ${err}`)},
      );
    } catch (error) {
      console.log('Error fluentD logging', error);
    }
  }

}