<form [formGroup]='brigadeForm' (ngSubmit)="onSubmit()">
  
  <h3>👨‍🚒 Registrar o acionamento de equipe para combate</h3>
  
  <div class="form">
    <mat-form-field class="top-form-field" appearance="outline" [color]="'accent'">
      <mat-label>Brigada</mat-label>
      <input matInput
             aria-label="Brigada"
             [matAutocomplete]="auto"
             formControlName="nome_brigada">
      <mat-autocomplete #auto="matAutocomplete">
        <mat-option *ngFor="let brigade of filteredBrigades | async" [value]="brigade.name">
          <img class="option-img" aria-hidden [src]="brigade.typeIcon" height="25">
          <span>{{brigade.name}}</span> |
          <small>{{brigade.category}}</small>
          <small *ngIf="brigade.plate"> | {{brigade.plate}}</small>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <mat-form-field appearance="outline" class="top-form-field" [color]="'accent'">
      <mat-label>Pessoas</mat-label>
      <input matInput type="number" formControlName="contagem_equipe">  
    </mat-form-field>

    <div style="display: flex; justify-content: space-between;">
  
        <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm" [color]="'accent'">
          <mat-label>Acionamento</mat-label>
          <input matInput type="datetime-local" formControlName='dt_acionamento'>
        </mat-form-field>
          <mat-form-field appearance="outline" class="outlineForm" [color]="'accent'">
            <mat-label>Chegada</mat-label>
            <input matInput type="datetime-local" formControlName='dt_chegada'>
            <mat-hint *ngIf="brigadeForm.errors?.erroDtAcionamento">
                Hora de chegada anterior à hora de partida
            </mat-hint>
          </mat-form-field>
    </div>
  </div>
  
    <div>
    <mat-slide-toggle formControlName="combate" (change)="onCombateChange($event)" style="margin-top: 2.5vh;">Registrar combate</mat-slide-toggle>
    <div class="combate" *ngIf="combateChecked">
      <div style="display: flex; justify-content: space-between;">
    
        <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm" [color]="'accent'">
          <mat-label>Início Combate</mat-label>
          <input matInput type="datetime-local" formControlName='dt_inicio_combate'>
          <mat-hint *ngIf="brigadeForm.errors?.erroDtChegadaCombate">Hora de início do combate anterior à chegada</mat-hint>
        </mat-form-field>
    
        <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm" [color]="'accent'">
          <mat-label>Fim Combate</mat-label>
          <input matInput type="datetime-local" formControlName='dt_fim_combate'>
          <mat-hint *ngIf="brigadeForm.errors?.erroDtCombate">Hora de fim anterior à hora de início</mat-hint>
        </mat-form-field>
      </div>
    </div>
    </div>
    <div>
    <div class="hint-divider" *ngIf="brigadeForm.errors?.erroDtChegadaCombate"></div>
    <mat-slide-toggle formControlName="rescaldo" (change)="onRescaldoChange($event)" style="margin-top: 2.5vh;">Registrar rescaldo</mat-slide-toggle>
    <div class="rescaldo" *ngIf="rescaldoChecked">

      <div style="display: flex; justify-content: space-between;">
    
        <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm" [color]="'accent'">
          <mat-label>Início Rescaldo</mat-label>
          <input matInput type="datetime-local" formControlName='dt_inicio_rescaldo'>
          <mat-hint *ngIf="brigadeForm.errors?.erroDtChegadaRescaldo">Hora de início do rescaldo anterior à chegada</mat-hint>
        </mat-form-field>
    
        <mat-form-field class="bottom-form-field" appearance="outline" class="outlineForm" [color]="'accent'">
          <mat-label>Fim Rescaldo</mat-label>
          <input matInput type="datetime-local" formControlName='dt_fim_rescaldo'>
          <mat-hint *ngIf="brigadeForm.errors?.erroDtRescaldo">Hora de fim anterior à hora de início</mat-hint>
        </mat-form-field>
      </div>
      <div class="hint-divider" *ngIf="brigadeForm.errors?.erroDtChegadaRescaldo"></div>
    </div>
    </div>

  <div class="hint-futuro" *ngIf="brigadeForm.errors?.erroDtFuturo">
    <mat-hint>As datas não podem ser no futuro</mat-hint>
  </div>
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
    <button mat-raised-button color='primary'
      [disabled]='brigadeForm.status=="INVALID"'
      type="submit" 
      [mat-dialog-close]="brigadeForm.value">Confirmar</button>
  </mat-dialog-actions>

</form>