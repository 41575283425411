<main>

  <div id="logo-container">
    <div class="head-logo"></div>
  </div>
  
  <p>Versão: {{version}}</p>
  
  <p> Bem vindo {{user.getNome()}} </p>
  
  <p> {{message}} </p>

  <div class="progress-bar-wrapper">
    <mat-progress-bar mode="indeterminate" color="accent"></mat-progress-bar>
  </div>
  
</main>

