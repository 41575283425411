import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {environment} from '../../environments/environment';
import {timeout} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    private http: HttpClient
    ) {}

  public SERVER_ADDRESS: string = environment.api;
  public REMOTE_SERVER: string = environment.remote_server;

  private postHeader = new HttpHeaders({
    'content-type': 'application/json',
    'Authorization': 'Bearer ' + environment.bearer
  });

  private authHeader = new HttpHeaders({
    'Authorization': 'Bearer ' + environment.bearer
  });
  private authHeader2 = new HttpHeaders({
    'content-type': 'application/json',
    'Authorization': 'Bearer ' + environment.bearer
  });
  async post(route: string, obj, extended = false) {
    let url = this.SERVER_ADDRESS + route;
    const timeout_value = (extended ? environment.extendedRequestTimeout : environment.requestTimeout);
    let response = await this.http.post(url, obj, {headers: this.postHeader})
      .pipe(timeout(timeout_value)).toPromise()
      .then(x => x, err => {
        console.log(err);
        return null
      })
    return response;
  }

  async get(route: string, ordered_args: string[] = [], extended = false) {
    let url = this.SERVER_ADDRESS + route;
    if (ordered_args.length > 0) url += '/';
    url += ordered_args.join('/');
    const timeout_value = (extended ? environment.extendedRequestTimeout : environment.requestTimeout);
    let response = await this.http.get(url, {headers: this.authHeader}).pipe(timeout(timeout_value)).toPromise().then(x => x, err => null);
    return response;
  }

  async getPromise(route: string, ordered_args: string[] = [], extended = false) {
    let url = this.SERVER_ADDRESS + route;
    if (ordered_args.length > 0) url += '/';
    url += ordered_args.join('/');
    const timeout_value = (extended ? environment.extendedRequestTimeout : environment.requestTimeout);
    return await this.http.get(url, {headers: this.authHeader2, responseType: 'blob'} ).pipe(timeout(timeout_value)).toPromise();
  }

  async maestroGet(route:string, token:string=null){
    if (token){
      var header = new HttpHeaders({
        'Authorization': 'Bearer ' + token
      });
    }
    else {
      var header = this.authHeader
    }
    return await this.http.get(`${this.REMOTE_SERVER}/${route}`,
    {headers: header}).pipe(timeout(100000000000)).toPromise().then(data => data, err => null);
  }

  async maestroPost(route:string, object, json=true){
    // let header = json? this.postHeader : this.authHeader
    return await this.http.post(`${this.REMOTE_SERVER}/${route}`, object,
     {headers: this.authHeader, withCredentials: true}).toPromise().then(data => data, err => null);
  }
}
