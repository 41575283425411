import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';
import { UntypedFormBuilder, UntypedFormGroup, Validators, UntypedFormControl } from "@angular/forms";
import { formatDate } from '@angular/common';
import { DatetimeValidators } from '../../validators/reports-dialog.validator';
import { BrigadesService } from '../../../services/brigades.service';

export interface Brigade {
  type: string;
  typeIcon: string;
  name: string;
  category: string;
  plate: string;
  filterString: string;
  id_brigada: string;
}

@Component({
  selector: 'select-brigade-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class BrigadesDisplacementDialog {

  public brigadeForm: UntypedFormGroup;
  brigadeCtrl = new UntypedFormControl('', [Validators.required]);
  filteredBrigades: Observable<Brigade[]>;

  public brigades: Brigade[] = [];
  
  public tipos_brigada:string[];
  public categorias_brigada:string[];
  public nomes_brigada: string[];

  combateChecked: boolean;
  rescaldoChecked: boolean;
  
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public fb: UntypedFormBuilder,
    private brigadesService: BrigadesService,
    private dtValidators: DatetimeValidators
  ) {

    this.combateChecked = false;
    this.rescaldoChecked = false;

    for (let b of data.brigadas){
      this.brigades.push({
        type: b['tipo_brigada'],
        typeIcon: this.brigadesService.brigadeIconMap[b['id_tipo_brigada']],
        name: b['nome_brigada'],
        category: b['categoria_brigada'],
        plate: b['placa'],
        filterString: b['nome_brigada'] + b['placa'] + b['categoria_brigada'],
        id_brigada: b['id_brigada']
      })
    }

    this.brigades.sort((a, b) => a.name.localeCompare(b.name));

    if (data.row === null){
      this.filterBrigades('');
    } else {
      this.filterBrigades(data.row['nome_brigada']);
    }

    let id_acionamento = `${data.id_report}_${data.len_acionamentos}`

    this.initForm(data.row, id_acionamento);
  }

  filterBrigades(nome_brigada) {
    this.filteredBrigades = this.brigadeCtrl.valueChanges.pipe(
      startWith(nome_brigada),
      map(brigade => (brigade ? this._filterBrigades(brigade) : this.brigades.slice())),
      );
    }
    
  private _filterBrigades(value: string): Brigade[] {
    const filterValue = value.toLowerCase();
    return this.brigades.filter(brigade => brigade.filterString.toLowerCase().includes(filterValue)).sort((a, b) => a.name.localeCompare(b.name));
  }
  
  initForm(row, id_acionamento){
    let datetime_now = formatDate(new Date(), 'yyyy-MM-ddTHH:mm', 'en-US');
    let dt_midnight = this.setMidnight();

    let dt_validators = [
      this.dtValidators.dtAcionamento(),
      this.dtValidators.dtCombate(),
      this.dtValidators.dtRescaldo(),
      this.dtValidators.dtChegadaCombate(),
      this.dtValidators.dtChegadaRescaldo(),
      this.dtValidators.dtFuturo(dt_midnight)
    ]
    
    if (row === null){
      this.brigadeForm = this.fb.group({
        nome_brigada: this.brigadeCtrl,
        contagem_equipe:['1',[Validators.required, Validators.min(1)]],
        dt_acionamento:[`${datetime_now}`,[Validators.required]],
        dt_chegada:[null],
        combate: false,
        dt_inicio_combate:[null],
        dt_fim_combate:[null],
        rescaldo: false,
        dt_inicio_rescaldo:[null],
        dt_fim_rescaldo:[null],
        id_acionamento:[id_acionamento],
        id_brigada: [null]
      },{
        validators: dt_validators,
      });
    } else {
      if (row['dt_inicio_combate'] !== '' && row['dt_inicio_combate'] !== null){
        this.combateChecked = true;
      };
      if (row['dt_inicio_rescaldo'] !== '' && row['dt_inicio_rescaldo'] !== null){
        this.rescaldoChecked = true;
      };
      this.brigadeForm = this.fb.group({
        nome_brigada: this.brigadeCtrl,
        contagem_equipe:[row['contagem_equipe'],[Validators.required, Validators.min(1)]],
        dt_acionamento:[row['dt_acionamento'],[Validators.required]],
        dt_chegada:[row['dt_chegada']],
        combate: this.combateChecked,
        dt_inicio_combate:[row['dt_inicio_combate']],
        dt_fim_combate:[row['dt_fim_combate']],
        rescaldo: this.rescaldoChecked,
        dt_inicio_rescaldo: [row['dt_inicio_rescaldo']],
        dt_fim_rescaldo: [row['dt_fim_rescaldo']],
        id_acionamento: [row['id_acionamento']],
        id_brigada: [row['id_brigada']]
      },{
        validators: dt_validators,
      });
      this.brigadeForm.patchValue({nome_brigada: row['nome_brigada']})
    }
  }

  setMidnight(){
    let midnight = new Date();
    midnight.setDate(midnight.getDate() + 1);
    midnight.setHours(0,0,0,0);
    let dt_midnight = formatDate(midnight, 'yyyy-MM-ddTHH:mm', 'en-US')
    return dt_midnight;
  }

  onCombateChange($event) {
    this.combateChecked = $event['checked'];
  }

  onRescaldoChange($event) {
    this.rescaldoChecked = $event['checked'];
  }
  
  onSubmit() {
    console.log('form submit:'  , this.brigadeForm.value);
  }

}
