import { Injectable } from '@angular/core';

const REPORTS_DATABASE_KEY = 'REPORTS_DATABASE';
const WAITING_TRI_KEY = 'WAITING_TRIANGULATION';
const SEARCHING_LOC_ON_MAP_KEY = 'WAITING_MAP_GEOLOC';
const FIRE_LOCATION_KEY = 'FIRE_LOCATION';
const WAITING_MAP_KEY = 'WAITING_MAP';
const CLEAR_MAP_KEY = 'CLEAR_MAP'
const MAP_RESULT_KEY = 'MAP_RESULT';
const AIMING_DETECTION_KEY = 'CENTER_CAMERA_ID';
const SOUND_KEY = 'SOUND';
const RECLASSIFY_DET_KEY = 'RECLASSIFY_DET';
const AUTO_TRIANGULACAO_KEY = 'AUTO_TRIANGULACAO'
const USE_ELLIPSE_KEY = 'USE_ELLIPSE'

// const VIDEOS_WIN_KEY = 'VIDEOS_WIN';

@Injectable({
  providedIn: 'root'
})
export class StorageService {

  constructor() { }

  clean(){
    localStorage.removeItem(WAITING_TRI_KEY);
    localStorage.removeItem(SEARCHING_LOC_ON_MAP_KEY);
    localStorage.removeItem(FIRE_LOCATION_KEY);

    localStorage.removeItem(WAITING_MAP_KEY);
    localStorage.removeItem(MAP_RESULT_KEY);
  }

  // WAITING FINDING ON MAP

  setSearchingLocOnMap(){
    localStorage.setItem(SEARCHING_LOC_ON_MAP_KEY, 'true');
  }

  getSearchingLocOnMap(){
    return JSON.parse(localStorage.getItem(SEARCHING_LOC_ON_MAP_KEY));
  }

  rmSearchingLocOnMap(){
    localStorage.removeItem(SEARCHING_LOC_ON_MAP_KEY);
  }

  // WAITING TRIANGULATION

  setWaitingTriangulation(){
    localStorage.setItem(WAITING_TRI_KEY, 'true');
  }

  getWaitingTriangulation(){
    return JSON.parse(localStorage.getItem(WAITING_TRI_KEY));
  }

  rmWaitingTriangulation(){
    localStorage.removeItem(WAITING_TRI_KEY);
  }

  // FIRE LOCATION RESULT

  setStoredFireLocation(dados_localizacao){
    localStorage.setItem(FIRE_LOCATION_KEY, JSON.stringify(dados_localizacao));
    console.log('...setStoredFireLocation');
  }

  getFireLocation(){
    return JSON.parse(localStorage.getItem(FIRE_LOCATION_KEY));
  }

  rmFireLocation(){
    localStorage.removeItem(FIRE_LOCATION_KEY);
  }

  // WAITING MAP

  setWaitingMap(coords){
    localStorage.setItem(WAITING_MAP_KEY, JSON.stringify(coords));
  }

  getWaitingMap(){
    return JSON.parse(localStorage.getItem(WAITING_MAP_KEY));
  }

  rmWaitingMap(){
    localStorage.removeItem(WAITING_MAP_KEY);
  }

  // Clear Map Loc
  setClearMapLoc(){
    localStorage.setItem(CLEAR_MAP_KEY, JSON.stringify(true));
  }

  getClearMapLoc(){
    return JSON.parse(localStorage.getItem(CLEAR_MAP_KEY));
  }

  rmClearMapLoc(){
    localStorage.removeItem(CLEAR_MAP_KEY);
  }


  // GetReports
  getStoredReports() {
    return JSON.parse(localStorage.getItem(REPORTS_DATABASE_KEY)) || [];
  }

  setStoredReports(report_list) {
    return localStorage.setItem(REPORTS_DATABASE_KEY, JSON.stringify(report_list))
  }

  // Center on Camera
  getWaitingAimingDetection(){
    return JSON.parse(localStorage.getItem(AIMING_DETECTION_KEY));
  }

  setWaitingAimingDetection(aiming_detection) {
    localStorage.setItem(AIMING_DETECTION_KEY, JSON.stringify(aiming_detection));
  }

  rmWaitingAimingDetection(){
    localStorage.removeItem(AIMING_DETECTION_KEY);
  }

  // SOUND
  getStoredSoundValue() {
    return JSON.parse(localStorage.getItem(SOUND_KEY));
  }

  setStoredSoundValue(value: boolean) {
    localStorage.setItem(SOUND_KEY, JSON.stringify(value));
  }


  getStoredReClassValue() {
    return JSON.parse(localStorage.getItem(RECLASSIFY_DET_KEY));
  }

  setStoredReClassValue(value: boolean) {
    localStorage.setItem(RECLASSIFY_DET_KEY, JSON.stringify(value));
  }

  getStoredAutoTriValue() {
    return JSON.parse(localStorage.getItem(AUTO_TRIANGULACAO_KEY));
  }

  setStoredUseEllipseValue(value: boolean) {
    localStorage.setItem(USE_ELLIPSE_KEY, JSON.stringify(value));
  }

  getStoredUseEllipseValue() {
    return JSON.parse(localStorage.getItem(USE_ELLIPSE_KEY));
  }

  
  setStoredAutoTriValue(value: boolean) {
    localStorage.setItem(AUTO_TRIANGULACAO_KEY, JSON.stringify(value));
  }
}
