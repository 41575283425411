import {Injectable} from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation';
import { InformationDialog } from 'src/app/shared/dialogs/information';
import { ChangePasswordDialog } from 'src/app/shared/dialogs/change-password'
import { ErrorDialog } from 'src/app/shared/dialogs/error';
import {environment} from 'src/environments/environment';
import {Router} from '@angular/router';
import {HttpService} from './http.service'
import { StorageService } from './storage.service';

const USER_TOKEN_KEY = 'USER_TOKEN';
const MONITORES_PANTERA_KEY = 'MONITORES_PANTERA';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public client_logo = {
    'background': `url(${environment.api + '/logo_cliente'}) center no-repeat`,
    'background-size': 'contain',
    'height': '100%',
    'width': '100%'
  };

  getLogoUrl(){
    return `${environment.api}/logo_cliente`;
  }

  constructor(
    private dialog:MatDialog,
    private router:Router,
    private http:HttpService,
    private storage: StorageService
  ) {}

  private userData;
  public soundDetection:boolean;
  public reclassifyDet:boolean;
  public autoTriang:boolean;
  public toggleNotificationTelegram: boolean;
  public useAuxiliarEllipse: boolean;


  signIn(signInResponse){
    let user_data = signInResponse.user_data

    user_data.login_dt = new Date();

    this.setData(user_data)
    this.setMonitoresPantera(signInResponse.monitores_pantera)
    this.setUserToken(signInResponse.crypt_data)
  }

  async signOut(){
    const username = this.getUsername();
    await this.http.post("sign_out", { username })
    localStorage.removeItem(USER_TOKEN_KEY);
  }

  async tokenAuth(){
    let storedToken = this.getUserToken()
    if (storedToken){
      let user_data = await this.http.post('user_token_auth', {
        'user_token' : storedToken
      });

      if(user_data){
        this.setData(user_data);

        this.soundDetection = true;
        this.storage.setStoredSoundValue(true)

        this.reclassifyDet = true;

        this.initUseEllipseValue();

        this.autoTriang = this.storage.getStoredAutoTriValue() != null ? this.storage.getStoredAutoTriValue() : false;

        this.toggleNotificationTelegram = await this.getSendDetectionAlertsState();

        if(environment.version == user_data.version){
          console.log(`%cPanteraVersion ${environment.version}`, 'background: green;');
        } else {
          console.log(`%c⚠️BackendVersion ${user_data.version} != FrontendVersion ${environment.version}⚠️`, 'background: red;');
        }
        return true
      }
    }
    await this.signOut();
    return false
  }

  setUserToken(userToken){
    localStorage.setItem(USER_TOKEN_KEY, JSON.stringify(userToken));
  }

  getUserToken(){
    return JSON.parse(localStorage.getItem(USER_TOKEN_KEY)) || '';
  }

  setData(userData){
    this.userData = userData;
  }

  getData(){
    return this.userData;
  }

  toogleSound(){
    this.soundDetection = !this.soundDetection;
    this.storage.setStoredSoundValue(this.soundDetection)
  }

  toogleReclassDet(){
    this.reclassifyDet = !this.reclassifyDet;
    this.storage.setStoredReClassValue(this.reclassifyDet)
  }
  toogleAutoTri(){
    this.autoTriang = !this.autoTriang;
    this.storage.setStoredAutoTriValue(this.autoTriang)
  }

  initUseEllipseValue() {
    if (this.storage.getStoredUseEllipseValue() == null) {
      this.useAuxiliarEllipse = true;
      this.storage.setStoredUseEllipseValue(this.useAuxiliarEllipse)
    }
    else {
      this.useAuxiliarEllipse = this.storage.getStoredUseEllipseValue();
    }
  }

  toggleUseEllipse() {
    this.useAuxiliarEllipse = !this.useAuxiliarEllipse
    this.storage.setStoredUseEllipseValue(this.useAuxiliarEllipse)
  }

  setMonitoresPantera(monitoresPantera){
    localStorage.setItem(MONITORES_PANTERA_KEY, JSON.stringify(monitoresPantera));
  }

  getMonitoresPantera(){
    let monitores = JSON.parse(localStorage.getItem(MONITORES_PANTERA_KEY)) || {};
    return monitores
  }

  getUsername(){
    return this.userData?.username
  }

  getNome(){
    return this.userData?.name
  }

  getIdRole() {
    return this.userData?.id_role;
  }

  getIdPlanta(){
    return this.userData?.id_planta
  }

  hasSatellite(){
    return this.userData?.satellite
  }

  hasFireManagement(){
    return this.userData?.fire_mngmt
  }

  hasBrigades(){
    return this.userData?.brigades_position
  }

  hasRisk(){
    return this.userData?.risk_map
  }

  getStreamingType(){
    let streamType = this.userData?.streaming_type || 'docker-ws'
    if (environment.cloudVersion) { streamType = 'go-webrtc'; }
    return streamType
  }

  isUgm(){
    return this.getUsername() == 'ugm-teste' || this.getUsername() == 'assistencia-ugm'
  }

  openConfigDialog(){
    const dialogRef = this.dialog.open(ChangePasswordDialog , {
      data: {username:this.getUsername()}
    });

    dialogRef.afterClosed().subscribe(c => {
      if (c){
      }
    });
  }

  async updateData(){
    let response = await this.http.get('atualizar_dados_cliente');
    const dialogRef = this.dialog.open(InformationDialog , {
      data: { text: response.msg }
    });
  }

  async updateGeoData(){
    let response = await this.http.get('atualizar_dados_geo');
    const dialogRef = this.dialog.open(InformationDialog , {
      data: { text: response.msg }
    });
  }


  openLogoutDialog(){
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: {text:'Tem certeza que deseja sair?'}
    });

    dialogRef.afterClosed().subscribe(async (c) => {
      if (c){
        // o timestamp de signout poderia ser salvo no backend ou maestro
        await this.signOut()
        this.router.navigate(['/login'])
        window.close()
      }
    });
  }

  openErrorDialog(){
    const dialogRef = this.dialog.open(ErrorDialog);

    dialogRef.afterClosed().subscribe(c => {
      window.close();
    });
  }

  openInfoDialog(dialogContent: string) {
    const dialogRef = this.dialog.open(InformationDialog , {
      data: { text: dialogContent }
    });
  }

  async setSendDetectionAlertsState() {
    let response = await this.http.post("set_send_detection_alerts", {
      state: !this.toggleNotificationTelegram
    })
    if (response) {
      this.toggleNotificationTelegram = !this.toggleNotificationTelegram
    }
  }

  async getSendDetectionAlertsState() {
    let status = await this.http.get("get_send_detection_alerts")
    return status
  }
}
