import { Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class InterfaceTabsService {

  public selectedTab = 0;

  constructor() { }

  public setSelectedTab(value: number) {
    this.selectedTab = value
  }

  changeToDetectionsTab() {
    if (this.selectedTab != 0) this.setSelectedTab(0);
  }

  changeToControlsTab() {
    if (this.selectedTab != 1) this.setSelectedTab(1);
  }

  changeToConfigsTab() {
    if (this.selectedTab != 2) this.setSelectedTab(2);
  }
}