
<mat-dialog-content>
  <div>
    <h3 align="center"> Busca Automática com 2a Camera </h3>
  </div>
  <div>
    <li>A 2a câmera irá percorrer a linha de visada da camera da detecção</li>
    <li>Em cada ponto será aplicado o modelo de detecção de fumaça</li>
    <li>Ao final do procedimento, algoritmo seleciona região mais provavel para triangulação</li>
    <li>E a camera é direcionada para esta região</li>
  </div>
</mat-dialog-content>

<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
  <button mat-raised-button [mat-dialog-close]="true" color='warn'>Confirmar</button>
</mat-dialog-actions>


