// ver qm importa isso aqui tbm
export const micSpecs = {
  minFocalDistance_mm : 4.3,
  // BOSH dataSheet sensor 1/2.8"
  sensorWidth_mm : 5.3
}

// CLUKE TODO pegar o json do backend
export function getCamAngularViewDeg(zoom:number, minFocalDistance: number, sensorWidth: number) {
  let f = zoom * minFocalDistance
  return 2*Math.atan(sensorWidth/(2*f)) * (180/Math.PI)
}

function decimalToHexString(number){
  return number.toString(16).toUpperCase();
}

export function zoom2GradientColor(zoom:number, maxZoom=10){
  let red_value = decimalToHexString(Math.round(zoom*255/maxZoom))
  let green_value = decimalToHexString(Math.round((maxZoom-zoom)*255/maxZoom))
  let blue_value = ""

  red_value = red_value.padStart(2,'0');
  green_value = green_value.padStart(2,'0');
  blue_value = blue_value.padStart(2,'0');

  return `#${red_value}${green_value}${blue_value}`;
}

// TODO - Linear Gradient - Opacidade tbm?
export function zoom2Color(zoom:number){
  switch (true) {
    case zoom <= 1:
      return "#ffffff";
    case zoom > 1 && zoom <= 4:
      return "#0000ff";
    case zoom > 4 && zoom <= 10:
      return "#00ff00";
    case zoom >10:
      return "#ffff00";
  }
}

export function zoom2DistancePreset(zoom:number){
  let start = 0.32*(zoom)**1.25;
  let end = Math.min(1.68*(zoom) + 7.86, 25);
  
  return {
    start:start,
    end:end
  }
}

export function zoom2DistanceFov(zoom:number, minFocalDistance:number, sensorWidth: number){
  const ratio = minFocalDistance / sensorWidth

  let start = (0.3*ratio*(zoom-1))
  let end = (5.75 + 0.3*(ratio)*zoom)
  
  return {
    start:start,
    end:end
  }
}

export const DAHUA_EQUIPMENT_ID = 50;
export const PETRIBU_PLANT_ID = 9