<mat-dialog-content >
    <div>
      <h2 *ngIf="this.data.type == 'insert'">Inserção do local da ocorrência</h2>
      <h2 *ngIf="this.data.type == 'update'">Atualização do local de ocorrência</h2>
      <h3>Insira a latitude e longitude para alterar a localização</h3>
  
      <form [formGroup]='latlngForm' style="display: flex; flex-direction: column; align-items: center;">
  
        <mat-form-field appearance="outline" [color]="'accent'">
          <mat-label [style]="{'color': '#3a3a3a'}">Latitude</mat-label>
          <input matInput [(ngModel)]="latlng.lat" [style]="{'color': '#3a3a3a'}"
          formControlName='lat'
          type="number"
          step="0.0001"
          [min]='data.bounds.minLat'
          [max]='data.bounds.maxLat'/>
          <span matSuffix>°</span>
          <mat-error *ngIf="latlngForm.get('lat').errors">Latitude fora dos limites permitidos</mat-error>
        </mat-form-field>
  
        <mat-form-field appearance="outline" [color]="'accent'">
          <mat-label>Longitude</mat-label>
          <input matInput [(ngModel)]="latlng.lng"
          formControlName='lng'
          type="number"
          step="0.0001"
          [min]='data.bounds.minLng'
          [max]='data.bounds.maxLng'/>
          <span matSuffix>°</span>
          <mat-error *ngIf="latlngForm.get('lng').errors">Longitude fora dos limites permitidos</mat-error>
        </mat-form-field>
  
      </form>
  
    </div>
  </mat-dialog-content>
  
  <mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
    <button mat-raised-button color='warn'
      [mat-dialog-close]='latlng'
      [disabled]='latlngForm.status=="INVALID"'>
      Confirmar
    </button>
  </mat-dialog-actions>