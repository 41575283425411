import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from "@angular/forms";

@Component({
  selector: 'register-damage-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class RegisterDamageDialog {
  
  lossForm:UntypedFormGroup; 

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public fb: UntypedFormBuilder) { 
    this.lossForm = this.fb.group({
      type:['',[Validators.required]],
      unit:['',[Validators.required]],
      value:['',[Validators.required, Validators.min(0.01)]]
    })
  }

  pickAbbreviation(unit: string) {
    const chosen = this.data.unidades_danos.filter(elm => elm.unidade_dano == unit)[0]
    return chosen?.abreviacao_unidade_dano || ""
  }
}
