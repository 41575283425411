<main>
  <section class="ctrl-bar">
    <button mat-mini-fab
      matTooltip="Camadas"
      [color]="show == 'base' ? 'accent' : 'primary'"
      (click)="toggleBase()"
      id="map-layers-btn">
        <img *ngIf="show != 'base'" src="assets/icons/new-icons/layers-dark.svg" style="width: 18px; height: 18px;"/>
        <img *ngIf="show == 'base'" src="assets/icons/new-icons/layers-light.svg" style="width: 18px; height: 18px;"/>
    </button>

    <button mat-mini-fab
      (click)="toggleVisibility()"
      [color]="show == 'visibility' ? 'accent' : 'primary'"
      matTooltip="Mapa de visibilidade"
      id="map-visibility-btn">
      <img *ngIf="show != 'visibility'" src="assets/icons/new-icons/visibility-dark.svg" style="width: 18px; height: 18px;"/>
      <img *ngIf="show == 'visibility'" src="assets/icons/new-icons/visibility-light.svg" style="width: 18px; height: 18px;"/>
    </button>

    <button mat-mini-fab
      (click)="toggleSat()"
      [color]="show == 'sat' ? 'accent' : 'primary'"
      matTooltip="Detecção satelital"
      id="map-satellite-btn">
      <img *ngIf="show != 'sat'" src="assets/icons/new-icons/satellite-dark.svg" style="width: 18px; height: 18px;"/>
      <img *ngIf="show == 'sat'" src="assets/icons/new-icons/satellite-light.svg" style="width: 18px; height: 18px;"/>
    </button>
  </section>

  <section class='content-wrapper' *ngIf='show'>
    <div *ngIf="show == 'base' ">
      <div class='panel-title'>
        <img src="assets/icons/new-icons/layers-dark.svg" style="width: 18px; height: 18px; vertical-align: top; margin-right: .25vw;"/>
        Mapa de fundo
      </div>
      
      <mat-radio-group (change)="selectBaseLayer($event)">
        <div *ngFor='let baseL of geo.baseLayersList' class='layer'>
          <mat-radio-button [value]="baseL" [checked]="baseL.active" color="warn">
            {{baseL.legend}}
          </mat-radio-button>
        </div>
      </mat-radio-group>

      <mat-divider style="margin: 8px 0;"></mat-divider>

    </div>

    <span *ngIf="baseList.length == 0">Não há detecções satelitais no momento</span>

    <div *ngFor='let overlayGroup of baseList'>
      <div class='panel-title'>
        <!-- se for incluir camadas, lembrar que a imagem em assets precisa ter o mesmo nome de overlayGroup.icon -->
        <img [src]="'assets/icons/new-icons/' + overlayGroup.icon + '-dark.svg'" style="width: 18px; height: 18px; vertical-align: top; margin-right: .25vw;"/>
        {{overlayGroup.groupTitle}}
      </div>
      
      <div *ngFor='let layerObj of overlayGroup.layersList' class='layer'>
        <mat-checkbox (change)="toogleLayer($event, layerObj.layer)" [(ngModel)]='layerObj.active' color="warn">
          {{layerObj.legend}}
        </mat-checkbox>
      </div>

      <mat-divider style="margin: 8px 0;"></mat-divider>
    
    </div>

    <div *ngIf="show=='visibility'">
      <p>
        Representa a altura que uma coluna de fumaça deve atingir
        para que seja visível pelas câmeras de monitoramento
      </p>
      
      <img src="https://geo2.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&LAYER=ugm:viewshed_local&LEGEND_OPTIONS=fontAntiAliasing:true" />
    </div>
  </section>

</main>