
import {Component, Inject, ViewChildren, QueryList} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {MatFormField} from "@angular/material/form-field";
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'forgot-password-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class ForgotPasswordDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private http : HttpService) { }

  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;
  
  username :string;
  email_recipient :string;
  showErrorMessage:boolean= false;
  sucess:boolean= false;
  
  message :string;

  async reset_pass(){
    let response = await this.http.post('reset_password', {'username':this.username})
    if (response.status){
      this.sucess = true;
      this.email_recipient = this.username;
    } else {
      this.showErrorMessage = true;
    }
  }

}