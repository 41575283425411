// inspirado de:
// https://offering.solutions/blog/articles/2020/05/03/cross-field-validation-using-angular-reactive-forms/
// https://github.com/FabianGosebrink/angular-reactive-forms-validation

// TO DO: Refatorar pra não ficar um monte de função repetida

import { Injectable } from '@angular/core';
import { UntypedFormGroup, ValidatorFn } from '@angular/forms';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
@Injectable({ providedIn: 'root' })
export class DatetimeValidators {
  constructor(
    public customErrorHandler:CustomErroHandlerService
  ) { }
  public dtAcionamento(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const dtAcionamentoCtrl = formGroup.get('dt_acionamento');
      const dtChegadaCtrl = formGroup.get('dt_chegada');

      if (!dtAcionamentoCtrl || !dtChegadaCtrl){
        return null;
      }

      const dt_acionamento = dtAcionamentoCtrl.value;
      const dt_chegada = dtChegadaCtrl.value;

      if (!dt_chegada || dt_chegada >= dt_acionamento || !dt_acionamento ){
        return null;
      }
      if (dt_chegada < dt_acionamento){
        return { erroDtAcionamento: true };
      }
      return null;
    };
  }

  public dtCombate(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const dtInicioCombateCtrl = formGroup.get('dt_inicio_combate');
      const dtFimCombateCtrl = formGroup.get('dt_fim_combate');

      if (!dtInicioCombateCtrl || !dtFimCombateCtrl){
        return null;
      }

      const dt_inicio_combate = dtInicioCombateCtrl.value;
      const dt_fim_combate = dtFimCombateCtrl.value;

      if (!dt_fim_combate || dt_fim_combate >= dt_inicio_combate || !dt_inicio_combate ){
        return null;
      }
      if (dt_fim_combate < dt_inicio_combate){
        return { erroDtCombate: true };
      }
      return null;
    };
  }

  public dtRescaldo(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const dtInicioRescaldoCtrl = formGroup.get('dt_inicio_rescaldo');
      const dtFimRescaldoCtrl = formGroup.get('dt_fim_rescaldo');

      if (!dtInicioRescaldoCtrl || !dtFimRescaldoCtrl){
        return null;
      }

      const dt_inicio_rescaldo = dtInicioRescaldoCtrl.value;
      const dt_fim_rescaldo = dtFimRescaldoCtrl.value;

      if (!dt_fim_rescaldo || dt_fim_rescaldo >= dt_inicio_rescaldo || !dt_inicio_rescaldo ){
        return null;
      }
      if (dt_fim_rescaldo < dt_inicio_rescaldo){
        return { erroDtRescaldo: true };
      }
      return null;
    };
  }

  public dtChegadaCombate(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const dtChegadaCtrl = formGroup.get('dt_chegada');
      const dtInicioCombateCtrl = formGroup.get('dt_inicio_combate');

      if (!dtInicioCombateCtrl || !dtChegadaCtrl){
        return null;
      }

      const dt_chegada = dtChegadaCtrl.value;
      const dt_inicio_combate = dtInicioCombateCtrl.value;

      if (!dt_chegada || dt_chegada <= dt_inicio_combate || !dt_inicio_combate ){
        return null;
      }
      if (dt_chegada > dt_inicio_combate){
        return { erroDtChegadaCombate: true };
      }
      return null;
    };
  }

  public dtChegadaRescaldo(): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const dtChegadaCtrl = formGroup.get('dt_chegada');
      const dtInicioRescaldoCtrl = formGroup.get('dt_inicio_rescaldo');

      if (!dtInicioRescaldoCtrl || !dtChegadaCtrl){
        return null;
      }

      const dt_chegada = dtChegadaCtrl.value;
      const dt_inicio_rescaldo = dtInicioRescaldoCtrl.value;

      if (!dt_chegada || dt_chegada <= dt_inicio_rescaldo || !dt_inicio_rescaldo ){
        return null;
      }
      if (dt_chegada > dt_inicio_rescaldo){
        return { erroDtChegadaRescaldo: true };
      }
      return null;
    };
  }

  public dtFuturo(midnight): ValidatorFn {
    return (formGroup: UntypedFormGroup) => {
      const dtAcionamentoCtrl = formGroup.get('dt_acionamento');
      const dtChegadaCtrl = formGroup.get('dt_chegada');
      const dtInicioCombateCtrl = formGroup.get('dt_inicio_combate');
      const dtFimCombateCtrl = formGroup.get('dt_fim_combate');
      const dtInicioRescaldoCtrl = formGroup.get('dt_inicio_rescaldo');
      const dtFimRescaldoCtrl = formGroup.get('dt_fim_rescaldo');

      try {
        const dt_acionamento = dtAcionamentoCtrl.value;
        const dt_chegada = dtChegadaCtrl.value;
        const dt_inicio_combate = dtInicioCombateCtrl.value;
        const dt_fim_combate = dtFimCombateCtrl.value;
        const dt_inicio_rescaldo = dtInicioRescaldoCtrl.value;
        const dt_fim_rescaldo = dtFimRescaldoCtrl.value;

        for (let dt of [dt_acionamento, dt_chegada, dt_inicio_combate, dt_fim_combate, dt_inicio_rescaldo, dt_fim_rescaldo]){
          if (dt >= midnight){
            return { erroDtFuturo: true };
          }
        }
        return null;
      }
      catch (error) {
        this.customErrorHandler.handleError(error);
        return null;
      }
    };
  }

}