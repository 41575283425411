import { Component, OnDestroy, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { HttpService } from '../../services/http.service'
import JSMpeg from '@cycjimmy/jsmpeg-player';

@Component({
  selector: 'app-streame-dockerr',
  templateUrl: './streamer-docker.component.html',
  styleUrls: ['./streamer-docker.component.scss']
})
export class StreamerDockerComponent implements AfterViewInit, OnDestroy {

  @Input() public cam_id: number;
  @Input() public stream_host = 'localhost';
  @Input() public stream_port: number;

  @ViewChild('streamingcanvas') private streamingcanvas: ElementRef;

  private player:JSMpeg.Player;

  public url: string;
  public tag_id: string;
  private b64Timeout:number = 50;
  private timeout_obj;

  constructor(private http: HttpService) { }

  ngAfterViewInit() {

    this.tag_id = `img-${this.cam_id}`;
    this.rtspStream()
    // else{
    //   console.log('b64_streamg', this.url)
    //   this.b64Stream()
    // }
  }

  rtspStream(){
    let ws_url = `ws://${this.stream_host}:${this.stream_port}/`;

    console.log(`%c🎥 ${ws_url}`, 'background: #bada55; color: blue');

    this.player = new JSMpeg.Player(ws_url, { canvas: this.streamingcanvas.nativeElement});
  }

  b64Stream(){
    this.timeout_obj = setTimeout(async () => {
      let b64 = await this.http.get('b64_streaming',[`${this.cam_id}`]);
      let img_element: any = document.getElementById(this.tag_id);
      if(img_element && b64){
        img_element.src = b64;
      }
      this.b64Stream();
    }, this.b64Timeout);
  }

  ngOnDestroy(){
    clearTimeout(this.timeout_obj);
  }
}
