import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { HttpService } from 'src/app/services/http.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-weather',
  templateUrl: './weather.component.html',
  styleUrls: ['./weather.component.scss']
})
export class WeatherComponent implements OnInit {

  constructor(
    public http: HttpService,
    private user: UserService
  ) { }
    
  public loaded = false;
  
  public meteo_data:any;
  public displayedColumns: string[] = [
    'nome_local',
    'icon',
    'risk',
    'day',
    'rain',
    'wind_speed',
    'humidity',
  ];

  async ngOnInit(){
    
    let search_date = formatDate(new Date(), 'yyyy-MM-dd ', 'en-US')
    console.log('get_towers_data', [search_date, `${this.user.getIdPlanta()}`]);
    this.meteo_data = await this.http.maestroGet(`get_towers_data/${search_date}/${this.user.getIdPlanta()}`, this.user.getUserToken())
    
    this.loaded = true
  }

  getRiskColor(risk){
    if (risk<0.2){
      return '#2b83ba'
    }
    if (risk<0.4){
      return '#abdda4'
    }
    if (risk<0.6){
      return '#ffffbf'
    }
    if (risk<0.8){
      return '#fdae61'
    }
    else {
      return '#d7191c'
    }
  }

}
