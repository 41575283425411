import { Component, OnInit, Input} from '@angular/core';
import { environment } from 'src/environments/environment'
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
@Component({
  selector: 'app-report-img',
  templateUrl: './report-img.component.html',
  styleUrls: ['./report-img.component.scss']
})
export class ReportImgComponent implements OnInit {

  constructor(
    public customErrorHandler:CustomErroHandlerService
  ) { }
  @Input() public image;
  @Input() public b64: boolean=false;
  @Input() public number = null;
  @Input() public header = true;

  private canvas_el: any;
  private canvas_context: any;

  public src;

  ngOnInit(): void {
    console.log('rep-img', this.image);
    this.src = environment.api;
  }


  drawRect(){
    try{
      this.canvas_context.clearRect(0, 0, this.canvas_el.width, this.canvas_el.height);
    } catch (error) {
      this.customErrorHandler.handleError(error)
    }
    try {
      let resultado_modelo = this.image.model_result

      if (!resultado_modelo) return;
      
      this.canvas_el = document.getElementById('detectionCanvas');

      this.canvas_el.width = this.canvas_el.getBoundingClientRect().width;
      this.canvas_el.height = this.canvas_el.getBoundingClientRect().height;

      let width = this.canvas_el.width;
      let height = this.canvas_el.height;
      let bbox = resultado_modelo.bbox
  
      let x1 = bbox[0]*width
      let y1 = bbox[1]*height
      let rect_width = bbox[2]*width - x1
      let rect_height = bbox[3]*height - y1
  
      this.canvas_context = this.canvas_el.getContext('2d');
      
      this.canvas_context.lineWidth = '1';
      this.canvas_context.strokeStyle = 'red';
      
      this.canvas_context.font = "16px Arial";
      this.canvas_context.fillStyle = "red";
      // Daria pra colocar um BG
      
      let yText = y1>15? y1-5: y1+15
      this.canvas_context.fillText(`${(resultado_modelo.smoke_prob * 100).toFixed(0)} %`, x1, yText);

      this.canvas_context.beginPath();
      this.canvas_context.rect(x1, y1, rect_width, rect_height);
      this.canvas_context.stroke();
      this.canvas_context.closePath();

    } catch (error) {
      this.customErrorHandler.handleError(error)
      console.error('erro:', error);
    }
  }

}
