import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot } from '@angular/router';
import { HttpService } from './http.service';
import { UserService } from './user.service'

@Injectable({
  providedIn: 'root'
})
export class RouteGuardsService  {

  constructor(
    private router: Router,
    private http: HttpService,
    private user: UserService
  ) {}

  async canActivate(route: ActivatedRouteSnapshot) {

    let tokenAuthenticated = await this.user.tokenAuth()
    
    if(tokenAuthenticated){
      return true
      
    } else {
      this.router.navigateByUrl('/login');
      console.log('Invalid token');
      return false
    }
  }
}
