import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
    selector: 'triangulation-dialog',
    templateUrl: './index.html',
    styleUrls: ['./styles.scss']
  })
  export class TriangulationDialog {
    constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }
  }