<main>
  <h3>Upload de imagem</h3>
  <input type="file" id="file" (change)="handleFileInput($event)">

  <div style="display: flex; justify-content: space-evenly;">
    <button mat-raised-button color='primary' style="margin-top: 15px;" [disabled] = '!fileToUpload' [mat-dialog-close]="true" (click)='postImgData()'>
      Enviar
    </button>
    <button mat-raised-button color='primary' style="margin-top: 15px;" [mat-dialog-close]="false">
      Cancelar
    </button>
  </div>
</main>
