import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import {HttpService} from './http.service';
import {StorageService} from './storage.service'
import {CameraService} from './camera.service'
import {UtilsService} from './utils.service'
import {UserService} from './user.service'
import {ReportsService} from './reports.service'
import {FireLocationService} from './fire-location.service';
import { ConfirmFireDialog } from 'src/app/shared/dialogs/confirm-fire';
import { InformationDialog } from '../shared/dialogs/information';
import { GroupDetectionOnReportDialog } from '../shared/dialogs/group-detection-on-report';
import { ConfirmDetectionGroupingOnReportDialog } from 'src/app/shared/dialogs/confirm-detection-grouping-on-report';
import { formatDate } from '@angular/common';
import { SocketService  } from './socket.service';
import { AmplitudeService } from './amplitude.service';
import { AUTOTRI_GEOLOC_ID, AUTO_GEOLOC_ID } from './geo.service';
import { CustomErroHandlerService } from './custom-erro-handler.service';

const DETECTIONS_CHECK_TIMEOUT = 4000;

@Injectable({
  providedIn: 'root'
})
export class DetectionsService {

  constructor(
    private http: HttpService,
    private cams: CameraService,
    private storage: StorageService,
    private utils:UtilsService,
    private user:UserService,
    private dialog:MatDialog,
    private fireLoc: FireLocationService,
    private reports:ReportsService,
    private socketService: SocketService,
    private amplitude: AmplitudeService,
    private customErrorHandler: CustomErroHandlerService
  ) {}

  private getDetectionsLogger: any;
  private detectionsIdSet: Set<string> = new Set();

  public detections_list: any[] = [];
  public active_detection: any = null;

  public aiming:boolean = false;
  public secondCamEnabled:boolean = false;
  public gettingLocOnMap:boolean = false;

  public autoTriangulando = false;

  public creating_report:boolean = false;
  public creating_manual_detection:boolean = false;
  public loadedDetectionImg:boolean = false;

  public reclassificandoDet = false;
  public id_object = 1;

  public images = [];
  public img_idx = 0;

  public relabelling:boolean = false;

  public secondCamId: number;

  public reclass_types;

  public showStopTriButton: boolean = false;

  public shouldCenterOnMap: boolean = true;

  // Buscar deteccoes no backend -> TimeoutRecursivo
  async getDetections() {
    let db_detections = await this.http.get('get_detections') || []

    for(let db_det of db_detections){

      if (!this.detectionsIdSet.has(db_det.id_report)){
        // deteccao nova
        this.addDetection(db_det);

        if(!this.active_detection){
          this.activateFirstDetection();
        }
      }
    }
    // adicionando imagens da mesma deteccao sem atualizar a tela
    // pensar em formas de implementar isso sem ter que esperar a get_detection executar (3/4 segundos)
    for(let front_det of this.detections_list){
      try {
        let db_det = db_detections.filter(d=>d.id_report == front_det.id_report)[0];
        if(front_det.images.length != db_det.images.length){
          front_det.images = db_det.images
          this.playAudio()
        }

      } catch (err) {
        this.customErrorHandler.handleError(err);
        console.log("Erro ao verificar se as imagens estao atualizadas: " + err)
      }
    }

    this.getDetectionsLogger = setTimeout(async () => await this.getDetections(), DETECTIONS_CHECK_TIMEOUT);
  }

  closeDetectionsTimeout() {
    if (this.getDetectionsLogger) clearTimeout(this.getDetectionsLogger);
  }

  // Adicionar deteccoes
  addDetection(detection) {

    if(this.user.soundDetection) this.playAudio();

    this.detectionsIdSet.add(detection.id_report);
    this.detections_list.push(detection);

    // testar sem
    this.renderDetections();
    console.log(`%c🔥 detection : ${detection.id_report}`, 'background: #222; color: #eb4c34')
  }

  // testar sem
  renderDetections() {
    this.detections_list = [...this.detections_list];
  }

  // Manual Detection
  async createManualDetection(){
    // let start = performance.now()
    this.creating_manual_detection=true;
    try {
      let cam_id = this.cams.getFirstCamId();
      this.amplitude.sendEvent("Clicou Deteccao Manual", {
        "camera_id": cam_id
      })
      await this.cams.resetCameraCommand(cam_id);

      let cam_log = this.cams.getCamLog(cam_id) || null;

      if (!cam_log) {
        console.log("Erro ao obter log da camera");
        const dialogRef = this.dialog.open(InformationDialog , {
          data: { text: 'Erro ao obter posição da câmera. Tente novamente' }
        });
        await this.utils.sleep(2000)
      }
      else {
        let manual_detection = await this.http.post('add_manual_detection', cam_log, true) || null;
        console.log("manual detection: ", manual_detection);
        if (manual_detection == null) {
          this.dialog.open(InformationDialog, {
            data: {text:"Não foi possível criar uma detecção manual. Tente novamente em alguns segundos..."}
          });
        }
      };
    }
    catch (error) {
      this.customErrorHandler.handleError(error);
      console.log('add_manual_detection', error)
    }

    await this.utils.sleep(2500)

    this.creating_manual_detection=false;
    await this.getDetectionImage();

    // let end = performance.now()
    // console.log('Com detecção manual',end - start)
  }


  async aimDetection() {
    
    if (!this.active_detection) return;

    this.amplitude.sendEvent("Apontou Câmera Detecção", {
      "id_camera": this.active_detection.id_camera_deteccao,
      "id_report": this.active_detection.id_report
    })

    await this.cams.resetCameraCommand(this.active_detection.id_camera_deteccao);

    let img = this.active_detection.images[this.img_idx]

    let panto_obj = Object.assign(
      {
        id: this.active_detection.id_camera_deteccao},
        img.img_details
      );
    console.log('🎯aimDetection', panto_obj)

    this.setDetectionViewed(this.active_detection)

    const active_image_path = img.img_path.split("\\")[2]
    this.setImageViewed(active_image_path, this.active_detection.id_report)

    await this.cams.sendAbsPTZ(panto_obj);

    let geoloc = this.active_detection.geoloc

    let aiming = {
      id_camera: this.active_detection.id_camera_deteccao,
      lat: geoloc.lat,
      lon: geoloc.lon,
      d_km: geoloc.distance,
      pan: geoloc.pan,
      a_km: geoloc.a,
      b_km: geoloc.b,
      should_center_on_map: this.shouldCenterOnMap
    }
    console.log('aiming obj: ', aiming);

    this.fireLoc.estimatedDistance = geoloc.distance
    this.fireLoc.estimatedLatLng = {
      lat: geoloc.lat,
      lng: geoloc.lon
    }

    this.storage.setWaitingAimingDetection(aiming)
    this.shouldCenterOnMap = false;

    // desenhar momentaneamente um retangulo no video?
    this.cams.setFirstCamera(this.active_detection.id_camera_deteccao);

    setTimeout( async()=>{
      this.aiming = true;
    }, 1500)

  }

  setDetectionViewed(detection) {
    this.http.get('set_detection_viewed', [detection.id_report]).then(response => {
      if (response) {
        this.active_detection.viewed = true
      }
    })
    .catch(error => {
      console.log(error);
      this.customErrorHandler.handleError(error);
    });
  }

  setImageViewed(img_path: string, id_report: string) {
    this.http.get('set_image_viewed', [img_path, id_report])
  }

  // Ativando e desativando deteccoes da lista
  async activateDetection(d) {
    if (this.active_detection) {
      if (d.id_report == this.active_detection.id_report) {
        this.deactivateDetection();
        return;
      }
      this.deactivateDetection();
    };
    d.selected = true;
    this.active_detection = d;
    this.shouldCenterOnMap = true;

    console.log('active_detection', this.active_detection);

    await this.getDetectionImage();
  }

  deactivateDetection() {
    if (!this.active_detection) return;
    this.cams.rmSecondCamera();

    if (this.autoTriangulando) this.pararAutoTri(this.secondCamId)

    this.reclassificandoDet = false;
    this.resetSearch();

    this.active_detection.selected = false;
    this.active_detection.dados_localizacao = [];

    this.autoTriangulando = false;
    this.showStopTriButton = false;
    this.secondCamId = null

    this.loadedDetectionImg = false;

    this.renderDetections();
    this.storage.setClearMapLoc();
    this.active_detection = null;
    this.shouldCenterOnMap = false;
  }

  resetSearch(){
    this.aiming = false;
    this.secondCamEnabled = false;
    this.gettingLocOnMap = false;
  }

  async activateFirstDetection() {
    this.deactivateDetection();
    this.active_detection = this.detections_list[0];
    this.active_detection.selected = true;
    await this.getDetectionImage();
  }

  async activateLastDetection(){
    this.active_detection = this.detections_list[this.detections_list.length-1];
    this.active_detection.selected = true;
    this.reclassificandoDet = false;
    await this.getDetectionImage();
  }

  changeDetObject(evento){
    this.id_object = evento
    this.removeDetFun(this.id_object)
    this.reclassificandoDet = false;
  }


  async removeDetection() {
    this.amplitude.sendEvent("Clicou Deletar Deteccao", {
      "id_report": this.active_detection.id_report,
      "id_camera_deteccao": this.active_detection.id_camera_deteccao
    })
    if (!this.active_detection) return;

    if (this.user.reclassifyDet && this.active_detection.deteccao_modelo) {
      this.reclassificandoDet = true;
    }
     else {
      this.removeDetFun('null')
    }
  }

  removeDetFun(id_object){
    let i;
    let active_id = this.active_detection.id_report;
    let username = this.user.getUsername()

    for (i = 0; i < this.detections_list.length; ++i) {
      if (this.detections_list[i].id_report == active_id) {
        this.detections_list.splice(i, 1)[0];
        break;
      }
    }

    this.amplitude.sendEvent("Clicou Reclassificar Deteccao", {
      "id_report": this.active_detection.id_report,
      "id_camera_deteccao": this.active_detection.id_camera_deteccao,
      "id_object": id_object
    })

    this.socketService.sendDetections(active_id)

    this.active_detection.object_correted  = id_object

    this.relabelling = true;

    let obj = {
      'id_report': active_id,
      'id_object': id_object,
      'username': username,
    }

    if (this.active_detection.geoloc) {
      obj['geoloc'] = this.active_detection.geoloc;
    }

    this.http.post('v2/delete_detection', obj).then((response) => console.log('delete_detection', response));

    this.deactivateDetection();

    if (this.detections_list.length > i) {
      this.activateDetection(this.detections_list[i]);
    }

    this.relabelling = false;

  }

  async getDetectionImage() {
    this.loadedDetectionImg = false;

    try{
      this.images = this.active_detection.images || [];
      this.img_idx = 0;

      let zoom_image_exists = this.images.findIndex((img) => img.img_type == 4);
      if (zoom_image_exists != -1) {
        this.img_idx = zoom_image_exists
      }

      console.log('detection images', this.images)
    }
    catch (error) {
      this.customErrorHandler.handleError(error);
      console.log(error)
    }

    this.loadedDetectionImg = true;
    return;
  };

  previousImage(){
    this.img_idx--;
    if (this.img_idx == -1){
      this.img_idx = this.images.length - 1;
    }
  }

  nextImage(){
    this.img_idx++;
    if (this.img_idx == this.images.length){
      this.img_idx = 0;
    }
  }

  async handleSaveImagesWithDistances() {
    let id_first = this.cams.getFirstCamId();
    let distanceToDetectionCamera = this.active_detection.dados_localizacao[0].distancia_torres[id_first];
    
    for (let img of this.active_detection.images) {
      img.img_details.dist = distanceToDetectionCamera.toFixed(2);
    }

    let confirmationDetectionCameraImageDetails = this.cams.getCamLog(id_first)?.ptz
    confirmationDetectionCameraImageDetails.nome = this.cams.getCameraName(id_first);
    confirmationDetectionCameraImageDetails.dist = distanceToDetectionCamera
    // TO-DO nao precisar esperar a set image acabar pra add a imagem a deteccao (active detection ta zuando o role, se pa add imagem direto no report)
    let confirmationImageResponse = await this.setImage(this.active_detection.id_report, id_first, 2, confirmationDetectionCameraImageDetails)
    if (!confirmationImageResponse.success) {
      console.log(`Erro ao salvar imagem de confirmação da câmera de detecção: ${confirmationImageResponse.error}`);
    }
    
    let id_second = this.cams.getSecondCamId();
    if (id_second){
      let distanceToTriangulationCamera = this.active_detection.dados_localizacao[0].distancia_torres[id_second];
      let confirmationTriangulationCameraImageDetails = this.cams.getCamLog(id_second)?.ptz
      confirmationTriangulationCameraImageDetails.nome = this.cams.getCameraName(id_second);
      confirmationTriangulationCameraImageDetails.dist = distanceToTriangulationCamera.toFixed(2)
      let triangulationImageResponse = await this.setImage(this.active_detection.id_report, id_second, 3, confirmationTriangulationCameraImageDetails)
      if (!triangulationImageResponse.success) {
        console.log(`Erro ao salvar imagem de confirmação da câmera de detecção: ${confirmationImageResponse.error}`);
      }
    }
    delete this.active_detection.dados_localizacao[0].distancia_torres
  }

  handleSaveGeolocTypeOnDetection() {
    this.active_detection.geoloc_type_id = this.active_detection.dados_localizacao[0].geoloc_type_id;
    delete this.active_detection.dados_localizacao[0].geoloc_type_id;
  }

  async confirmDetection() {
    if (!this.active_detection) return;
    this.resetSearch();
    this.handleSaveGeolocTypeOnDetection();
    await this.handleSaveImagesWithDistances();

    let getMeteoData = await this.fillMeteoData();
    if (!getMeteoData.success) {
      console.log("Erro ao buscar dados meteorológicos", getMeteoData.error)
      this.active_detection.dados_meteo = {}
    }

    let confirmed_detection = this.utils.deepCopy(this.active_detection);

    confirmed_detection.datetime_confirmacao = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');
    confirmed_detection.operador = this.user.getUsername();

    let active_id = confirmed_detection.id_report;

    let response = await this.http.post('confirm_detection', confirmed_detection, true)
    
    if (!response) {
      console.log("Erro ao criar relatório")
      this.dialog.open(InformationDialog , {
        data: { text: 'Erro ao confirmar detecção. Tente novamente ou abra um chamado para o suporte.' }
      });
      this.creating_report = false;
      this.deactivateDetection();
      return false
    }

    this.detections_list = this.detections_list.filter(d => d.id_report!=active_id)

    this.amplitude.sendEvent("Confirmou Deteccao", {
      "geoloc_type_id": confirmed_detection.geoloc_type_id,
      "id_report": confirmed_detection.id_report,
      "operador": confirmed_detection.operador,
      "software_id": confirmed_detection.software_id
    })

    if (confirmed_detection.images[0]?.img_type === 5 && !confirmed_detection.images.some(imgDict => imgDict.img_type === 1)) {
      let imgConfirmDict = confirmed_detection.images.find(imgDict => imgDict.img_type === 5);
  
      if (imgConfirmDict) {
          let infoImgComplement = {
              img_details: imgConfirmDict.img_details,
              id_cam: imgConfirmDict.id_cam,
              id_report: imgConfirmDict.id_report,
              id_planta: confirmed_detection.id_planta
          };

          this.socketService.getImagesComplementManual(infoImgComplement)
      }
  }

    // TO-DO pegar o n relatorio planta do relatorio mais recente nos reports quando cair no catch
    try{
      confirmed_detection.n_relatorio_planta = response.n_relatorio_planta
    } catch (err) {
      this.customErrorHandler.handleError(err);
      console.log('não conseguiu n_relatorio_planta')
    }

    let riskValue = undefined
    if (response.risk) {
      riskValue = response.risk
    }

    const lat = confirmed_detection.dados_localizacao[0].lat
    const lon = confirmed_detection.dados_localizacao[0].lon
    const googleMapsLink = `http://www.google.com/maps/place/${lat},${lon}`
    
    this.sendReportAutomaticallyToTelegram(
      confirmed_detection.datetime_deteccao,
      confirmed_detection.n_relatorio_planta,
      confirmed_detection.dados_localizacao[0].dados_geo.atributos?.Local,
      googleMapsLink,
      confirmed_detection.dados_meteo[0],
      confirmed_detection.images,
      confirmed_detection.resultado_modelo,
      riskValue,
      confirmed_detection.id_report
    )

    this.socketService.sendDetections(active_id)

    this.deactivateDetection()

    return confirmed_detection;
  }

  async confirmDetectionWithAutoGeoloc() {
    if (!this.active_detection) return;

    this.resetSearch();

    let id_first = this.cams.getFirstCamId()

    const estimatedDistanceFromTower = this.active_detection.geoloc.distance

    for (let img of this.active_detection.images) {
      img.img_details.dist = estimatedDistanceFromTower;
    }

    this.active_detection.geoloc_type_id = AUTO_GEOLOC_ID

    let img1_details = this.cams.getCamLog(id_first)?.ptz
    img1_details.nome = this.cams.getCameraName(id_first);
    img1_details.dist = estimatedDistanceFromTower
    await this.setImage(this.active_detection.id_report, id_first, 2, img1_details)

    await this.fillMeteoData();

    let confirmed_detection = this.utils.deepCopy(this.active_detection);

    confirmed_detection.datetime_confirmacao = formatDate(new Date(), 'yyyy-MM-dd HH:mm:ss', 'en-US');
    confirmed_detection.operador = this.user.getUsername();

    let active_id = confirmed_detection.id_report;

    this.detections_list = this.detections_list.filter(d => d.id_report!=active_id)

    this.amplitude.sendEvent("Confirmou Deteccao Auto Geoloc", {
      "geoloc_type_id": confirmed_detection.geoloc_type_id,
      "id_report": confirmed_detection.id_report,
      "operador": confirmed_detection.operador,
      "software_id": confirmed_detection.software_id
    })
    
    const lat = this.active_detection.geoloc.destination.geometry.coordinates[1];
    const lon = this.active_detection.geoloc.destination.geometry.coordinates[0];
    confirmed_detection.dados_localizacao = [{ lat, lon }]
    
    let response = await this.http.post('confirm_detection', confirmed_detection, true)
    
    console.log('confirmed detection', response);

    confirmed_detection.dados_localizacao = response.dados_loc_detailed
    
    try{
      confirmed_detection.n_relatorio_planta = response.n_relatorio_planta
    } catch (error) {
      this.customErrorHandler.handleError(error);
      console.log('não conseguiu n_relatorio_planta')
    }

    const googleMapsLink = `http://www.google.com/maps/place/${lat},${lon}`
    let riskValue = undefined

    this.sendReportAutomaticallyToTelegram(
      confirmed_detection.datetime_deteccao,
      confirmed_detection.n_relatorio_planta,
      confirmed_detection.dados_localizacao[0].dados_geo.atributos?.Local,
      googleMapsLink,
      confirmed_detection.dados_meteo[0],
      confirmed_detection.images,
      confirmed_detection.resultado_modelo,
      riskValue,
      confirmed_detection.id_report
    )

    this.socketService.sendDetections(active_id)

    this.deactivateDetection()

    return confirmed_detection;
  }

  async sendReportAutomaticallyToTelegram(
      datetime_det,
      n_relatorio_planta,
      location_dict,
      google_maps_link,
      weather,
      images,
      detection_model_result,
      risk,
      id_report
    ) {
      let telegram_groups = await this.http.get("get_telegram_groups_list")
      telegram_groups = telegram_groups.filter(g=>g.id_tipo_destinatario == 3);

      if (telegram_groups.length == 0) return

      let bbox;
      let smokeProb;
      let riskValue;
      
      if (images.length > 0) {
        var image_obj = images.filter((img) => img.img_type == 4)[0]
        
        if (image_obj) {
          bbox = image_obj.model_result.bbox || []
          smokeProb = image_obj.model_result.smoke_prob || detection_model_result.smoke_prob
        }
        else {
          image_obj = images[0]
          bbox = image_obj.model_result.bbox || []
          smokeProb = image_obj.model_result.smoke_prob || detection_model_result.smoke_prob
        }
      }

      let obj = {'image': image_obj}
      let image_base_64  = await this.http.post('convert_image_into_b64', obj);
      if (!image_base_64) {
        image_base_64 = ""
      }

      if (risk) {
        riskValue = risk
      }

      const id_planta = this.user.getIdPlanta();

      let telegram_data = {
        "datetime_det" : datetime_det,
        "link": google_maps_link,
        "bbox": bbox,
        "smoke_prob": smokeProb,
        "dados_loc": location_dict,
        "n_relatorio_planta": n_relatorio_planta,
        "weather": weather,
        "image_path": image_obj.img_path,
        "risk": riskValue,
        "is_auto": true,
        "id_planta": id_planta,
        "id_report": id_report
      }

      let response = await this.http.post(`send_report_alert_via_telegram`, telegram_data)
      console.log('telegram response', response)
    }

  async fillFireLocationFromStorage(isAutoTri: boolean = false){

    while (true){

      if(!this.active_detection) return;

      let dados_localizacao = this.storage.getFireLocation();

      if(dados_localizacao){
        if (isAutoTri) dados_localizacao.geoloc_type_id = AUTOTRI_GEOLOC_ID;

        this.active_detection.dados_localizacao = [dados_localizacao];

        console.log('fillFireLocationFromStorage', dados_localizacao);
        this.storage.rmFireLocation();

        return true;
      }

      await this.utils.sleep(500)
    }

  }

  async setImage(id_report, id_camera, img_type, img_details){
    let img_data = {
      'id_report' : id_report,
      'id_camera' : id_camera,
      'img_type' : img_type,
      'img_details' : img_details,
    }

    try {
      let img_obj = await this.http.post('set_report_image', img_data, true);
      
      if(img_obj.img_path){
        this.active_detection.images.push(img_obj)
        return {success: true, error: null}
      }
    }
    catch (err) {
      this.customErrorHandler.handleError(err);
      return {success: false, error: err}
    }
  }

  // pensar o que fazer se nao tiver net
  async fillMeteoData(){
    let lat;
    let lon;

    if (!this.active_detection.dados_localizacao[0] && !this.active_detection.geoloc) {
      console.log('Sem coordenadas para obter dados meteorologicos');
      return
    }
    else {
      if (this.active_detection.dados_localizacao[0]?.lat && this.active_detection.dados_localizacao[0]?.lon) {
        lat = this.active_detection.dados_localizacao[0].lat,
        lon = this.active_detection.dados_localizacao[0].lon
      }
      else if (this.active_detection.geoloc) {
        lat = this.active_detection.geoloc.destination.geometry.coordinates[1],
        lon = this.active_detection.geoloc.destination.geometry.coordinates[0]
      }
      let coords = { "lat": lat, "lon": lon };

      try {
        let dados_meteo = await this.http.maestroPost('one_call_str', coords);
        if (dados_meteo["temp"]) {
          this.active_detection.dados_meteo.push(dados_meteo);
          return {success: true, error: null}
        }
        throw new Error("Erro ao buscar dados meteorológicos no Maestro");
      }
      catch (err) {
        this.customErrorHandler.handleError(err);
        return {success: false, error: err}
      }
    }
  }

  async pararAutoTri(camId: number){
    await this.setSecondCamAuto(0);
    this.amplitude.sendEvent("Parou Triangulação Automática");

    this.socketService.sendAutoTriStop(camId);
    this.autoTriangulando = false;
    this.showStopTriButton = false;
    this.secondCamId = null;
  }

  async toggleSecondCam(){
    this.amplitude.sendEvent("Habilitou Segunda Câmera", {
      "id_camera": this.secondCamId
    })
    console.log('toggleSecondCam - secondCamId: ', this.secondCamId);
    this.active_detection.dados_localizacao = [];

    if (this.secondCamEnabled){
      this.disableSecondCam();
      return
    }
    this.enableSecondCam();
  }
  
  async enableSecondCam(){
    const closestObj = this.fireLoc.getClosestCameraObj();

    if (closestObj.distance > 50){
      console.log('Muito longe pra triangular');
    }
  
    if (this.user.autoTriang){
      this.launchAutoTri(closestObj.cam_id);
    }

    await this.setTriTower(closestObj.cam_id);
    this.secondCamEnabled = true;
  
    if (!this.user.autoTriang){ // if auto tri desabilitada -> apontar pro centro da elipse
      this.cams.sendAbsPan({
        id: closestObj.cam_id,
        pan: closestObj.pan
      })
    }
  }

  async disableSecondCam(){
    const secondCamId = this.cams.getSecondCamId();
    this.secondCamEnabled = false;
    this.showStopTriButton = false;
    this.cams.rmSecondCamera();
    
    if (this.user.autoTriang) {
      this.secondCamId = secondCamId;
      await this.setSecondCamAuto(0);
    }

    if (this.autoTriangulando) this.socketService.sendAutoTriStop(this.secondCamId);
    this.secondCamId = null;
  }

  async setTriTower(camId: number){
    this.secondCamId = camId;
    this.cams.setSecondCamera(this.secondCamId);

    this.setSecondCamAuto(this.user.autoTriang ? 2 : 0);
    this.disableSecondCamSpin();
  }

  async switchTriTower(camId: number){
    if (this.secondCamId == camId) return
    this.socketService.sendAutoTriStop(this.secondCamId);
    
    this.setSecondCamAuto(0);
    this.disableSecondCamSpin();

    this.setTriTower(camId);

    // sem autotri quando troca de torre
    this.setSecondCamAuto(0);
    this.autoTriangulando = false;
    this.showStopTriButton = false;
  }

  launchAutoTri(camId: number){
    this.autoTriangulando = true;
    let autoTriData = this.fireLoc.getDataAutoTri(camId);
    this.socketService.sendAutoTriCam(autoTriData);

    const setShowStopTriButton = () => {
      this.showStopTriButton = true;
    }

    this.amplitude.sendEvent("Triangulou Automaticamente")
    setTimeout(setShowStopTriButton, 1500)
  }

  async setSecondCamAuto(auto: number){
    console.log('setSecondCamAuto: ', auto, this.secondCamId);
    if (this.secondCamId == null) return
    let secondCam = this.cams.getCameraObj(this.secondCamId);
    if (secondCam) secondCam.auto = auto;
    await this.cams.autoCommand(this.secondCamId, auto);
    let sock_status = auto == 1 ? true : false
    this.socketService.sendCameras([true, [this.secondCamId], [sock_status]])
  }

  async disableSecondCamSpin(){
    let secondCam = this.cams.getCameraObj(this.secondCamId);
    if (secondCam){
      if (secondCam.spin){
        await this.cams.spinCommand(this.secondCamId, 0);
        secondCam.spin = false;
      }
    }
  }

  async searchLocationOnMap(){
    this.amplitude.sendEvent("Clicou Indicar Mapa")

    this.active_detection.dados_localizacao = [];

    this.gettingLocOnMap = true;

    let best_img = this.active_detection.images.filter(img => img.img_type == 4)[0]

    if (!best_img) {
      let greater_zoom_img = this.active_detection.images[0]

      // FALTA PEGAR A QUE TEM MAIOR ZOOM (PROVAVELMENTE TA FUNFANDO MAS NAO CONSIGO TESTAR PQ A CAMERA NAO PARA NO SHOW ROOM KKKKK)
      best_img = this.active_detection.images.forEach(function (img, index) {
        if (img.img_details.zoom > greater_zoom_img.img_details.zoom) {
          greater_zoom_img = img
        }
      });

      best_img = greater_zoom_img;
    }

    let { pan, tilt, zoom } = best_img.img_details;
    let { bbox } = best_img.model_result || ""

    let data = { "id_camera": this.active_detection.id_camera_deteccao, "img_data": { pan, tilt, zoom }, "img_bbox": bbox }

    localStorage.setItem("ACTIVE_DETECTION_IMAGE_DATA", JSON.stringify(data))

    this.storage.rmFireLocation();
    this.storage.setSearchingLocOnMap();

    let location_ok = await this.fillFireLocationFromStorage();
    // se ele for fazer outra coisa nesse meio tempo?
    this.gettingLocOnMap = false;
  }

  async searchTriangulation(isAutoTri: boolean = false){
    if (!isAutoTri) this.amplitude.sendEvent("Clicou Triangular");
    this.active_detection.dados_localizacao = [];
    this.storage.rmFireLocation();
    this.storage.setWaitingTriangulation();

    let location_ok = await this.fillFireLocationFromStorage(isAutoTri);
  }

  checkSameCameraAvailableReportsToGroupDetection(id_camera) {
    let reports = this.storage.getStoredReports();
    let available = reports.filter((report) => {
      return(
        report.id_camera_deteccao == id_camera
      );
    })
    return available
  }
  
  getGreaterZoomImage(images) {
    let greater = images[0];
    images.map((img) => {
      if (img.img_details.zoom > greater.img_details.zoom) greater = img;
    })
    return greater;
  }

  subtractPans(minuend, subtrahend) {
    let diff = Math.abs(minuend - subtrahend);
    if (diff >= 180) {
      diff = 360 - diff;
    }
    return diff;
  }

  getReportWithLowerPanDifference(detectionAzimute, reportsList) {
    let currentDifference = this.subtractPans(detectionAzimute, this.getGreaterZoomImage(reportsList[0].images).img_details.pan);
    let winner = reportsList[0];
    for (let i = 1; i < reportsList.length; i++) {
      let newDifference = this.subtractPans(detectionAzimute, this.getGreaterZoomImage(reportsList[i].images).img_details.pan);
      if (newDifference < currentDifference) {
        currentDifference = newDifference;
        winner = reportsList[i];
      }
    }
    if (currentDifference < 5) return winner
    else return null
  }

  async openConfirmGroupOnReportDialog(active_detection) {
    this.amplitude.sendEvent("Clicou Agrupar Detecção Relatório", {
      "id_camera": this.active_detection.id_camera_deteccao,
      "datetime_deteccao": this.active_detection.datetime_deteccao 
    })
    let sameCameraReports = this.checkSameCameraAvailableReportsToGroupDetection(this.active_detection.id_camera_deteccao)

    if (sameCameraReports.length == 0) {
      this.openGroupOnReportDialog();
      return;
    }
    else {
      let detectionGreaterZoomImageAzimute = this.getGreaterZoomImage(this.active_detection.images).img_details.pan
      var winner = this.getReportWithLowerPanDifference(detectionGreaterZoomImageAzimute, sameCameraReports)
    }

    if (winner) {
      const dialogRef = this.dialog.open(ConfirmDetectionGroupingOnReportDialog, {
        data: {
          report: {
            n_relatorio_planta: winner.n_relatorio_planta, reportImages: winner.images
          },
          detectionImages: active_detection.images,
          isSuggestion: true
        }
      })

      dialogRef.afterClosed().subscribe(async(confirmation) => {
        if (confirmation == 0) {
          return;
        }
        else if (confirmation == 1) {
          this.reports.selectedReportToGroupDetection = null;
          this.openGroupOnReportDialog()
        }          
        else {
          let data = {
            detection_id_report: active_detection.id_report,
            detection_images: active_detection.images,
            target_id_report: winner.id_report
          }
          
          let response = await this.http.post('group_detection_on_report', data)
          console.log("group_detection_on_report", response)
          
          if (response.success === true) {
            this.detections_list = this.detections_list.filter(d => d.id_report!=this.active_detection.id_report)
            let reports = this.storage.getStoredReports();

            let adjustedImages = data.detection_images.map((img) => {
              let filename = img.img_path.split("/")[2]
              img.id_report = data.target_id_report
              img.img_path = `images/${data.target_id_report}/${filename}`
              return img
            })

            const updatedReportsList = []
            reports.forEach(rep => {
              if (rep.id_report == data.target_id_report) {
                rep.images = [...rep.images, ...adjustedImages]
              }
              updatedReportsList.push(rep)
            });

            this.storage.setStoredReports(updatedReportsList);
            this.reports.reports_list = updatedReportsList;

            this.socketService.sendDetections(active_detection.id_report);
            this.deactivateDetection();
          }
          else {
            this.dialog.open(InformationDialog , {
              data: { text: 'Erro ao agrupar a detecção no relatório. Tente novamente ou abra um chamado para o suporte.' }
            });
          }
  
          this.reports.selectedReportToGroupDetection = null;
        }
      })
    }
    else {
      this.openGroupOnReportDialog()
    }
  }

  async openGroupOnReportDialog() {
    let report_list = this.storage.getStoredReports()

    const dialogRef = this.dialog.open(GroupDetectionOnReportDialog, {
      data: { list: report_list }
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      if (confirmation){
        if (!this.reports.selectedReportToGroupDetection){
          this.dialog.open(InformationDialog , {
            data: { text: 'Erro: escolha um relatório.' }
          });
        }
        else {
          const dialogRef = this.dialog.open(ConfirmDetectionGroupingOnReportDialog, {
            data: {
              report: {
                n_relatorio_planta: this.reports.selectedReportToGroupDetection[0].n_relatorio_planta,
                reportImages: this.reports.selectedReportToGroupDetection[0].images
              },
              detectionImages: this.active_detection.images,
              isSuggestion: false
            }
          })

          dialogRef.afterClosed().subscribe(async(confirmation) => {
            if (confirmation == 0) {
              return;
            }
            else if (confirmation == 1) {
              this.openGroupOnReportDialog()
            }
            else {
              let data = {
                detection_id_report: this.active_detection.id_report,
                detection_images: this.active_detection.images,
                target_id_report: this.reports.selectedReportToGroupDetection[0].id_report
              }

              let response = await this.http.post('group_detection_on_report', data)
              console.log("group_detection_on_report", response)
              
              if (response.success === true) {
                this.detections_list = this.detections_list.filter(d => d.id_report!=this.active_detection.id_report)
                let reports = this.storage.getStoredReports();

                let adjustedImages = data.detection_images.map((img) => {
                  let filename = img.img_path.split("/")[2]
                  img.id_report = data.target_id_report;
                  img.img_path = `images/${data.target_id_report}/${filename}`
                  return img
                })
      
                const updatedReportsList = []
                reports.forEach(rep => {
                  if (rep.id_report == data.target_id_report) {
                    let images = [...rep.images, ...adjustedImages]
                    rep.images = images
                  }
                  updatedReportsList.push(rep)
                });

                this.storage.setStoredReports(updatedReportsList);
                this.reports.reports_list = updatedReportsList;

                this.socketService.sendDetections(this.active_detection.id_report);                
                this.deactivateDetection();
              }
              else {
                this.dialog.open(InformationDialog , {
                  data: { text: 'Erro ao agrupar a detecção no relatório. Tente novamente ou abra um chamado para o suporte.' }
                });
              }
      
              this.reports.selectedReportToGroupDetection = null;
            }
          })
        }
      }
      else {
        this.reports.selectedReportToGroupDetection = null;
      }
    });
  }

  async openConfirmFireDialog() {
    this.amplitude.sendEvent("Clicou Confirmar Detecção", {
      "id_report": this.active_detection.id_report
    })
    const dialogRef = this.dialog.open(ConfirmFireDialog);

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      if (confirmation){
        this.creating_report = true;

        let confirmed_detection = await this.confirmDetection();
        
        if (confirmed_detection){
          this.reports.insertReportIntoStorage(confirmed_detection)
        }
        
        this.creating_report = false;
        localStorage.removeItem("GEOLOC_TYPE_ID");
      }
    });
  }

  async confirmFireWithAutoGeolocDialog() {
    const dialogRef = this.dialog.open(ConfirmFireDialog);

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      if (confirmation){
        this.creating_report = true;

        let confirmed_detection = await this.confirmDetectionWithAutoGeoloc();

        this.reports.insertReportIntoStorage(confirmed_detection)

        this.creating_report = false;

        localStorage.removeItem("GEOLOC_TYPE_ID");
      }
    });
  }

  async getReclassTypes() {
    this.reclass_types = await this.http.get("get_reclass_types")
    if (!this.reclass_types) throw new Error("Erro ao buscar os tipos de reclassificação no backend.");

    return this.reclass_types
  }

  async getModelParams() {
    const modelParams = await this.http.get('get_model_params');
    if (!modelParams) throw new Error("Erro ao buscar os parâmetros do modelo no backend.");
    
    return modelParams;
  }

  playAudio(){
    let audio = new Audio();
    audio.src = 'assets/sound/mixkit-elevator-tone-2863.wav';
    // audio.src = "https://assets.mixkit.co/sfx/preview/mixkit-elevator-tone-2863.mp3";
    audio.load();
    audio.play();
  }

  async createReportWithAutoGeoloc() {
    if (this.active_detection.geoloc) {
      this.confirmFireWithAutoGeolocDialog()            
    }
    else {
      this.dialog.open(InformationDialog, {
        data: {text:"Essa detecção não possui uma geolocalização automática vinda do backend."}
      });
    }
  }
}