import { Injectable } from '@angular/core';
import { HttpService } from './http.service'
import { UserService } from './user.service';
import { CustomErroHandlerService } from './custom-erro-handler.service';
@Injectable({
  providedIn: 'root'
})
export class PresetpickerService {

  constructor(
	private http: HttpService,
	private user: UserService,
	private customErrorHandler:CustomErroHandlerService
  ) { }

  public presetIndex = null
  async updateAllPresetsImgCam(idCam, presetList, presetType) {
	return new Promise<void>(async (resolve) => {for (var preset of presetList) {
		await this.camPositionsAsync(idCam, preset, presetType);
		if(presetList.indexOf(preset)===presetList.length-1){resolve()}
	}
	});
  }

  camPositionsAsync(idCam, preset, presetType) {
	return new Promise<void>((resolve) => {
		this.presetIndex = preset.index
		this.requestPTZPosition(preset,idCam)
		.then(() => {
			setTimeout(() => {
				this.requestSnapshotRetry(idCam)
				.then((snapshot) => {
					this.sendSnapshotToBackend(idCam, snapshot, preset, presetType)
					.then(() => {
						console.info('Sucesso ao salvar imagem para:', preset);
						resolve();
						})
				})
				.catch((error) => {
					this.customErrorHandler.handleError(error);
					console.error('Error no preset:', preset, error);
					resolve();
				});
			}, preset.sleep*1000);
			});
		});
	}
	requestPTZPosition(preset, idCam) {
		return new Promise<void>(async (resolve) => {
			let data = {
				'id': idCam,
				'pan': preset.pan,
				'tilt': preset.tilt,
				'zoom': preset.zoom
			}
			this.http.post(`absolute_ptz`,data)
			.then(()=>resolve())
		});
	}
	requestSnapshot(idCam) {
		return new Promise((resolve,reject) => {
			this.http.getPromise(`get_bytes_frame/${idCam}`)
			.then((imgBytes: any)=>{

				resolve(imgBytes)
			})
			.catch((error) => {
				this.customErrorHandler.handleError(error);
				console.error('Error:', error);
				reject(false);
			});
		});
	}


	requestSnapshotRetry(idCam, maxRetries = 3, delayBetweenRetries = 1000) {
		return new Promise(async (resolve, reject) => {
		  let retries = 0;

		  while (retries < maxRetries) {
			try {
			  const imgBytes = await this.http.getPromise(`get_bytes_frame/${idCam}`);
			  resolve(imgBytes);
			  return; // Saia da função se a solicitação for bem-sucedida
			} catch (error) {
			  this.customErrorHandler.handleError(error);
			  console.error('Error:', error);
			  retries++;

			  if (retries < maxRetries) {
				console.log(`Retrying in ${delayBetweenRetries / 1000} seconds...`);
				await new Promise(resolve => setTimeout(resolve, delayBetweenRetries));
			  } else {
				console.log('Max retries reached. Giving up.');
				reject(false);
			  }
			}
		  }
		});
	  }

	sendSnapshotToBackend(idCam, snapshot, preset, presetType) {
		return new Promise<boolean>((resolve, reject) => {
			const currentDate = new Date();
			const currentDateTimeString = currentDate.toISOString();
			const typePreset = presetType == 'presets';
			let post_data = {
				'uuid': preset.uuid,
				'id_planta': this.user.getIdPlanta(),
				'id_cam': idCam,
				'index': preset.index,
				'datetime': currentDateTimeString,
				'type': typePreset,
			}
			let formData: FormData = new FormData();
			// formData.append('file', new Blob([snapshot])); // Create a Blob from the byte frame data and append it to the FormData
			formData.append('file', snapshot, `${preset.index}`);
			formData.append('imgData', JSON.stringify(post_data));
			this.http.maestroPost('add_preset_image', formData)
			.then(()=>{
				resolve(true);
			})
			.catch((error) => {
				this.customErrorHandler.handleError(error);
				console.error('Error:', error);
				reject(false);
			});
		});
	}

}
