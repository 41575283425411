import { Injectable } from '@angular/core';
import {CameraService} from './camera.service';
import * as turf from '@turf/turf';
import { CustomErroHandlerService } from './custom-erro-handler.service';
@Injectable({
  providedIn: 'root'
})
export class FireLocationService {

  constructor(
    public cams: CameraService,
    public customErrorHandler:CustomErroHandlerService
  ) {}

  public estimatedDistance: number | undefined;
  public estimatedLatLng : {
    lat: number;
    lng: number;
  }

  camPanToPoint(point, cam_id:number) {
    let cam_coords = this.cams.getCameraLatLon(cam_id);

    var point1 = turf.point([cam_coords.lon, cam_coords.lat]);
    var point2 = turf.point([point.lng, point.lat]);

    var bearing = turf.bearing(point1, point2);

    if (bearing < 0) {
      bearing = (bearing + 360) % 360
    }
    console.log('bearing', bearing)
    return bearing;
  }

  camZoomToPoint(point, cam_id: number) {
    let dist = this.getCamDistToPoint(point.lat, point.lng, cam_id)
    const cameraObj = this.cams.getCameraObj(cam_id)
    const w0 = cameraObj.sensor_width_mm
    const f0 = cameraObj.min_focal_distance_mm
    const zoom_max = 30

    let zoom = (w0/f0)*(0.801 + (dist/2.483 + 0.104)**2)
    return Math.min(Math.max(zoom, 1), zoom_max)
  }

  getCamsTriangulationPoint() {
    let id_first = this.cams.getFirstCamId();
    let id_second = this.cams.getSecondCamId();

    let log_first = this.cams.getCamLog(id_first);
    let log_second = this.cams.getCamLog(id_second);

    let pan_1 = log_first.ptz.pan;
    let pan_2 = log_second.ptz.pan;

    let c1 = this.cams.getCameraObj(id_first);
    let c2 = this.cams.getCameraObj(id_second);

    return this.triangulate(c1.lat, c1.lon, pan_1, c2.lat, c2.lon, pan_2)
  }


  triangulate(lat1, lon1, bearing1, lat2, lon2, bearing2){
    const MAX_TRI_DIST_KM = 77

    let d1 = turf.destination([lon1, lat1], MAX_TRI_DIST_KM, bearing1);
    let d2 = turf.destination([lon2, lat2], MAX_TRI_DIST_KM, bearing2);

    let l1 = turf.lineChunk(turf.lineString([[lon1, lat1], d1.geometry.coordinates]), 5);
    let l2 = turf.lineChunk(turf.lineString([[lon2, lat2], d2.geometry.coordinates]), 5);

    let intersects = turf.lineIntersect(l1, l2);
    let coordinates = intersects.features[0].geometry.coordinates;

    return {
      lon:coordinates[0],
      lat:coordinates[1],
    };
  }


  async setFireLocation(latlng, shp_layer, geoloc_type_id){

    let id_first = this.cams.getFirstCamId();
    let id_second = this.cams.getSecondCamId();

    await this.aimCameraToLoc(id_first, latlng);
    if(id_second){
      await this.aimCameraToLoc(id_second, latlng);
    }

    let dados_loc = this.getDadosLoc(latlng.lat, latlng.lng, shp_layer, geoloc_type_id);

    return dados_loc

  }

  async aimCameraToLoc(id_cam:number, latlng){

    let camPan = this.camPanToPoint(latlng, id_cam);

    let absPtz = {
      id: id_cam,
      pan: camPan
    }
  
    await this.cams.sendAbsPan(absPtz);
    return true;
  }

  getDadosLoc(lat, lon, shp_layer, geoloc_type_id, uncertainty_m=100){

    let id_first = this.cams.getFirstCamId();
    let id_second = this.cams.getSecondCamId();

    let dist_1 = this.getCamDistToPoint(lat, lon, id_first);

    let dist_2 = null
    try {
      dist_2 = this.getCamDistToPoint(lat, lon, id_second);
    } catch (error) {
      this.customErrorHandler.handleError(error);
      console.log('...getDadosLoc: Sem segunda câmera')
    }

    // Colocar alguma distancia maxima de torre?
    // if (Math.abs(dist_1) > max_range || Math.abs(dist_2) > max_range) {
    //   console.log(`Triangulation error: dist > ${max_range}km`);
    //   return null;
    // }

    let closestShp = this.getClosestLocProperties(lat, lon, shp_layer);

    let dados_loc = {
      lat: lat,
      lon: lon,

      dados_geo : closestShp.properties,
      nome_geom : closestShp.properties.nome_geom,
      distancia_area: closestShp.intersect ? 0 : Math.round(closestShp.properties.distanceToPoint*100)/100,

      torre1: this.cams.getCameraName(id_first),
      torre2: id_second ? this.cams.getCameraName(id_second):null,
      dist_t1: Math.round(dist_1*100)/100,
      dist_t2 : Math.round(dist_2*100)/100,

      uncertainty_m: uncertainty_m,
      // usado em detection fillCamParams, depois apagado
      distancia_torres: {
        [id_first] : Math.round(dist_1*100)/100,
        [id_second] : Math.round(dist_2*100)/100
      },
      geoloc_type_id: geoloc_type_id

    };

    return dados_loc;
  }

  getCamDistToPoint(lat, lon, id_cam){
    let camLatLon = this.cams.getCameraLatLon(id_cam);
    return turf.distance(turf.point([lon, lat]), turf.point([camLatLon.lon, camLatLon.lat]), {units: 'kilometers'});
  }

  getClosestLocProperties(lat, lon, shp_layer){

    let pt_tri = turf.point([lon, lat]);

    let intersect = null;
    shp_layer.eachLayer(x => {
      if (turf.booleanPointInPolygon(pt_tri, x.toGeoJSON())) {
        intersect = x.feature;
      }
    });

    if (intersect){

      return {
        'intersect':true,
        'properties':intersect.properties
      }

    } else {

      let vertices = turf.explode(shp_layer.toGeoJSON());
      let closest = turf.nearestPoint(pt_tri, vertices);

      return {
        'intersect':false,
        'properties':closest.properties
      }

    }

  }


  getDataAutoTri(cam_id){

    let [id_first, log_first, cLatLon] = [... this.getLogFirstCamera()]

    console.log('cam_id', cam_id);


    if (!log_first) {
      console.log('sem log da camera')
      return null;
    }

    let pan = log_first.ptz.pan;

    let distAutoTri = [1,2,5,8,13,21]
    let pansAutoTri = []
    let zoomAutoTri = []

    for (let index = 0; index < distAutoTri.length; index++) {
      let presumed_fire_det = turf.transformTranslate(turf.point([cLatLon.lon, cLatLon.lat]), distAutoTri[index], pan, {'units':'kilometers'})
      let presumed_fire_latlng_det = {
        lng:presumed_fire_det.geometry.coordinates[0],
        lat:presumed_fire_det.geometry.coordinates[1]
      }
      pansAutoTri.push(this.camPanToPoint(presumed_fire_latlng_det, cam_id))
      zoomAutoTri.push(this.camZoomToPoint(presumed_fire_latlng_det, cam_id))

    }

    return {
      cam_id: cam_id,
      pansAutoTri: pansAutoTri,
      zoomAutoTri: zoomAutoTri
      // presumed_fire: presumed_fire.geometry.coordinates.reverse(),
      // closest: towerCandidates[0].latlon
    }

  }

  getLogFirstCamera(){
    let id_first = this.cams.getFirstCamId();
    let log_first = this.cams.getCamLog(id_first);
    let cLatLon = this.cams.getCameraLatLon(id_first);
    return [ id_first, log_first, cLatLon ]
  }

  getClosestCameraObj(){
    try{
      let [id_first, log_first_camera, cLatLon] = [... this.getLogFirstCamera()]

      if (!log_first_camera) {
        console.log('sem log da camera')
        return null;
      }

      let pan = log_first_camera.ptz.pan;
      let presumed_distance = this.getEstimateDistanceKm();
      let presumed_fire = turf.transformTranslate(turf.point([cLatLon.lon, cLatLon.lat]), presumed_distance, pan, {'units':'kilometers'})

      let presumed_fire_latlng = {
        lng:presumed_fire.geometry.coordinates[0],
        lat:presumed_fire.geometry.coordinates[1]
      }

      let towerCandidates = []

      this.cams.cameras.forEach(c => {
        if (c.id_cam != id_first){
          towerCandidates.push({
            id_camera : c.id_cam,
            latlon : [c.lat, c.lon],
            distance : turf.distance(presumed_fire, turf.point([c.lon, c.lat]), {'units':'kilometers'})
          })
        }
      })

      towerCandidates.sort((t1, t2) => {
        return t1.distance - t2.distance;
      })

      console.log(`Closest tower : ${towerCandidates[0].id_camera} -> ${Math.round(towerCandidates[0].distance * 100) / 100}km`, towerCandidates)
      let closest_pan = this.camPanToPoint(presumed_fire_latlng, towerCandidates[0].id_camera)

      return {
        cam_id: towerCandidates[0].id_camera,
        distance: towerCandidates[0].distance,
        pan: closest_pan,
      }
    }
    catch(err) {
      this.customErrorHandler.handleError(err);
      console.warn('Erro ', err)
      return null
    }
  }

  getEstimateDistanceKm(){
    try {
      if (!!this.estimatedDistance && typeof(this.estimatedDistance) === 'number') return this.estimatedDistance;
      return 8
    } catch(error){
      this.customErrorHandler.handleError(error);
      console.log(error)
      return 8
    }
  }

  getClosestCameraToFireReportWithoutTowers(lat: number, lng: number) {
    let firePoint = turf.point([lng, lat])
    
    let camerasObjs = this.cams.cameras;
    
    let closest = {
      "camId": "",
      "distanceToFire": 99999
    }

    camerasObjs.forEach(cam => {
      let cameraLatLonObj = this.cams.getCameraLatLon(cam.id_cam)
      let cameraPoint = turf.point([cameraLatLonObj.lon, cameraLatLonObj.lat])
      let distance = turf.distance(firePoint, cameraPoint, { units: 'kilometers' })
      
      if (distance < closest.distanceToFire) {
        closest.camId = cam.id_cam,
        closest.distanceToFire = parseFloat(distance.toFixed(2))
      }
    });
    
    return closest
  }

}
