<mat-dialog-content style="width: 65vw; max-height: 60vh;">
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center;">
        <h2 *ngIf="data.isSuggestion" style="text-align: center;">Sugestão: talvez este foco de incêndio já tenha sido detectado no relatório {{data.report.n_relatorio_planta}}. Deseja agrupar?</h2>
        <h2 *ngIf="!data.isSuggestion" style="text-align: center;">Antes de confirmar, compare as imagens da detecção selecionada com o relatório {{data.report.n_relatorio_planta}}</h2>

        <div style="display: flex; justify-content: space-between; width: 100%; margin: 1vh 0 2vh 0;">
            <div style="width: 50%; display: flex; justify-content: center; align-items: center;">
                <button style="margin-right: .25vw; background: none; display: flex; justify-content: center; align-items: center; border: none; cursor: pointer;" [disabled]="data.detectionImages.length < 2" (click)="this.prevDetectionImage()">
                    <img src="assets/icons/new-icons/navigate-before-dark.svg" style="height: 18px; width: 18px; vertical-align: middle;"/>
                </button>
                
                <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center; width: 25vw">
                    <img style="width: 100%;" [src]="api.concat(data.detectionImages[this.detectionIndex].img_path)" />
                    <span style="margin-top: 1vh;"><b>Câmera:</b> {{data.detectionImages[this.detectionIndex].img_details.nome}}</span>
                    <span *ngIf="data.detectionImages[this.detectionIndex].img_timestamp"><b>Data/hora:</b> {{data.detectionImages[this.detectionIndex].img_timestamp}}</span>
                    <span *ngIf="data.detectionImages[this.detectionIndex].img_details"><b>Direção:</b> {{data.detectionImages[this.detectionIndex].img_details.pan}}° | {{data.detectionImages[this.detectionIndex].img_details.zoom}}x</span>
                </div>

                <button style="margin-left: .25vw; background: none; display: flex; justify-content: center; align-items: center; border: none; cursor: pointer;" [disabled]="data.detectionImages.length < 2" (click)="this.nextDetectionImage()">
                    <img src="assets/icons/new-icons/navigate-next-dark.svg" style="height: 18px; width: 18px; vertical-align: middle;"/>
                </button>
            </div>
            <div style="width: 50%; display: flex; justify-content: center; align-items: center;">
                <button style="margin-right: .25vw; background: none; display: flex; justify-content: center; align-items: center; border: none; cursor: pointer;" [disabled]="data.report.reportImages.length < 2" (click)="this.prevReportImage()">
                    <img src="assets/icons/new-icons/navigate-before-dark.svg" style="height: 18px; width: 18px; vertical-align: middle;"/>
                </button>

                <div style="display: flex; flex-direction: column; align-items: flex-start; justify-content: center; width: 25vw">
                    <img style="width: 100%" [src]="api.concat(data.report.reportImages[this.reportIndex].img_path)" />
                    <span style="margin-top: 1vh;"><b>Câmera:</b> {{data.report.reportImages[this.reportIndex].img_details.nome}}</span>
                    <span *ngIf="data.report.reportImages[this.reportIndex].img_timestamp"><b>Data/hora:</b> {{data.report.reportImages[this.reportIndex].img_timestamp}}</span>
                    <span *ngIf="data.report.reportImages[this.reportIndex].img_details"><b>Direção:</b> {{data.report.reportImages[this.reportIndex].img_details.pan}}° | {{data.report.reportImages[this.reportIndex].img_details.zoom}}x</span>
                </div>
                
                <button style="margin-left: .25vw; background: none; display: flex; justify-content: center; align-items: center; border: none; cursor: pointer;" [disabled]="data.report.reportImages.length < 2" (click)="this.nextReportImage()">
                    <img src="assets/icons/new-icons/navigate-next-dark.svg" style="height: 18px; width: 18px; vertical-align: middle;"/>
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>
    
<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="0">Cancelar</button>
    <button mat-raised-button [mat-dialog-close]="1">Escolher outro relatório</button>
    <button mat-raised-button [mat-dialog-close]="2" [style]="{'background': '#dc3333', 'color': 'white'}">Confirmar</button>
</mat-dialog-actions>