<mat-dialog-content style="width: 65vw; max-height: 60vh;">
    <div style="width: 100%; height: 100%; display: flex; flex-direction: column; align-items: center;">
        <div style="display: flex; justify-content: space-between; width: 100%; margin: 1vh 0 2vh 0;">
            <div style="width: 100%; display: flex; justify-content: center; align-items: center;">
                <button (click)="previousImage()"
                    style="margin-right: .5vw; background: none; display: flex; justify-content: center; align-items: center; border: none; cursor: pointer;"
                    [disabled]="data.pointOfCalibrationImages.length < 2">
                        <img src="assets/icons/new-icons/navigate-before-light.svg" style="height: 18px; width: 18px; border: 1px solid red;"/>
                </button>

                <img style="width: 30vw;" [src]="data.pointOfCalibrationImages[this.imgIndex].img_url" />

                <button (click)="nextImage()"
                    style="margin-left: .5vw; background: none; display: flex; justify-content: center; align-items: center; border: none; cursor: pointer;"
                    [disabled]="data.pointOfCalibrationImages.length < 2">
                        <img src="assets/icons/new-icons/navigate-next-light.svg" style="height: 18px; width: 18px; border: 1px solid red;"/>
                </button>
            </div>
        </div>
    </div>
</mat-dialog-content>
    
<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="true" [style]="{'background': '#dc3333', 'color': 'white'}">Fechar</button>
</mat-dialog-actions>