import { Component, Inject } from '@angular/core';
import { ReportsService } from 'src/app/services/reports.service';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

@Component({
  selector: 'finish-report.dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class FinishReportDialog {
  
  r:any;
  error_msg:string;
  info_msgs:string[] = [];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    public reports: ReportsService
  ) {
    this.r = data.report;
    let acionamentos = this.r.acionamentos;

    let combates_finalizados = acionamentos.map(a => [a.dt_inicio_combate, a.dt_fim_combate]).every(
      (dts) => (dts.every((dt) => dt === null) || dts.every((dt) => dt !== null))
      );

    if (!combates_finalizados){
      this.error_msg = 'Favor registrar a hora de final de combate'
    }

    let erroChegada = acionamentos.map(a => a.dt_chegada).some((dt) => dt === null);

    if (erroChegada){
      this.error_msg = 'Favor registrar a hora de chegada da equipe'
    }

    if(!acionamentos.length){
      this.info_msgs.push('Não há registro de acionamento de brigada')
    }

    let semCombate = acionamentos.map(a => a.dt_inicio_combate).every((dt) => dt === null);

    if(semCombate){
      this.info_msgs.push('Não há registro de combate')
    }

    if(!this.r.dados_perdas.length){
      this.info_msgs.push('Não há registro perdas')
    }

    if(!this.r.dados_meteo.length) {
      this.info_msgs.push("Não há dados meteorológicos")
    }
  }

  toggleExport(event) {
    if (event.checked) {
      this.reports.setExportPDF(true)
      return
    }
    else {
      this.reports.setExportPDF(false)
      return
    }
  }
}