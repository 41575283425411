import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'confirm-detection-grouping-on-report-dialog',
  templateUrl: './index.html',
  styleUrls: ['styles.scss']
})
export class ConfirmDetectionGroupingOnReportDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  public reportIndex = 0;
  public detectionIndex = 0;
  public api = environment.api;

  nextReportImage() {
    if (this.reportIndex < this.data.report.reportImages.length - 1) this.reportIndex++;
    else this.reportIndex = 0;
  }

  prevReportImage() {
    if (this.reportIndex > 0) this.reportIndex--;
    else this.reportIndex = this.data.report.reportImages.length - 1;
  }

  nextDetectionImage() {
    if (this.detectionIndex < this.data.detectionImages.length - 1) this.detectionIndex++;
    else this.detectionIndex = 0;
  }

  prevDetectionImage() {
    if (this.detectionIndex > 0) this.detectionIndex--;
    else this.detectionIndex = this.data.detectionImages.length - 1;
  }
}