import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'see-point-of-calibration-images-dialog',
  templateUrl: './index.html',
  styleUrls: ['styles.scss']
})
export class SeePointOfCalibrationImagesDialog {
  constructor(@Inject(MAT_DIALOG_DATA) public data: any) { }

  public api = environment.api;
  public imgIndex = 0;

  nextImage() {
    if (this.imgIndex < this.data.pointOfCalibrationImages.length - 1) this.imgIndex++;
    else this.imgIndex = 0;
  }

  previousImage() {
    if (this.imgIndex > 0) this.imgIndex--;
    else this.imgIndex = this.data.pointOfCalibrationImages.length - 1;
  }
}