import { Component, OnInit } from '@angular/core';
import { CameraService } from 'src/app/services/camera.service';
import { DetectionsService } from 'src/app/services/detections.service';
import { SocketService  } from '../../services/socket.service';

@Component({
  selector: 'app-detections-table',
  templateUrl: './detections-table.component.html',
  styleUrls: ['./detections-table.component.scss']
})
export class DetectionsTableComponent implements OnInit {

  public cameras = this.cams.cameras;
  public displayedColumns = ['camera', 'hora', 'pan', 'probabilidade', 'n_imagens'];

  public bold_style = {
    'font-weight': 'bold'
  };

  constructor(
    public det:DetectionsService,
    public cams:CameraService,
    private socketService: SocketService,
  ) { }

  ngOnInit(): void {
    this.socketService.onNewMessageDet().subscribe((id_report: string)=> {
      if (this.det.active_detection?.id_report == id_report) {
        this.det.deactivateDetection();
      }

      // QUANDO EXCLUI OU CONFIRMA UMA DETECÇÃO, ELA É REMOVIDA DO DETECTION SERVICE E VIRA UM RELATÓRIO
      this.det.detections_list = this.det.detections_list.filter((obj) => {
        return obj.id_report != id_report
      })
    });
  }

  selectDetection (row) {
    this.det.activateDetection(row);
  }

  pickClass(smoke_prob: number) {
    if (!smoke_prob) return "";

    let myClass: string = "";
    let prob = smoke_prob * 100;

    if (prob > 90) {
      myClass = "full-danger";
    }
    else if (prob > 70) {
      myClass = "alert";
    }
    return myClass;
  }
}
