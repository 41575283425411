<mat-dialog-content style="overflow-y: hidden;">
    Tem certeza que deseja finalizar este relatório?
    <h3 *ngIf='!!error_msg'>{{error_msg}}</h3>
    <div *ngIf='!error_msg'>
      <ul>
        <li *ngFor='let m of info_msgs'>{{m}}</li>
      </ul>
    </div>

    <mat-checkbox (change)="this.toggleExport($event)" [(ngModel)]='this.reports.exportPDF' color="warn" [checked]="true">
        Gerar arquivo para download
    </mat-checkbox>
</mat-dialog-content>
<mat-dialog-actions align="center">
    <button mat-raised-button [mat-dialog-close]="true" color="primary">
        Sim
    </button>
    <button mat-raised-button [mat-dialog-close]="false" color="primary">
        Não
    </button>
</mat-dialog-actions>
