import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { RouteGuardsService } from './services/route-guards.service'

import { LoginComponent } from './pages/login/login.component';
import { MapaComponent } from './pages/mapa/mapa.component';
import { NewInterfaceComponent } from './pages/new-interface/new-interface.component'
import { SingleInterfaceComponent } from './pages/single-interface/single-interface.component';
import { ConfigComponent } from './pages/config/config.component';
import { StreamerWebRTCComponent } from './components/streamer-webrtc/streamer-webrtc.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: LoginComponent },
  { path: 'streamer', component: StreamerWebRTCComponent },
  { path: 'interface', component: NewInterfaceComponent, canActivate: [RouteGuardsService]},
  { path: 'singleinterface', component: SingleInterfaceComponent, canActivate: [RouteGuardsService]},
  { path: 'mapa', component: MapaComponent, canActivate: [RouteGuardsService]},
  { path: 'config', component: ConfigComponent, canActivate: [RouteGuardsService]}
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
