<main>
   <mat-dialog-content style="overflow-y: hidden;">
     <div>
       <h3>{{data.text}}</h3>
     </div>
 
     <mat-form-field appearance="outline" class="outlineForm"  [color]="'accent'">
       <textarea matInput [(ngModel)]="data.comentarios"></textarea>
     </mat-form-field>
   </mat-dialog-content>
 
   <mat-dialog-actions align="center">
     <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
     <button mat-raised-button [mat-dialog-close]="data.comentarios" color='primary'>Confirmar</button>
   </mat-dialog-actions>
</main>