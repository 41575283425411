import { Component, OnInit, Inject } from '@angular/core';
import { UserService } from '../../services/user.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { DOCUMENT } from '@angular/common';
import { SocketService } from 'src/app/services/socket.service';
import { CameraService } from 'src/app/services/camera.service';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation';
import { HttpService } from 'src/app/services/http.service';

@Component({
  selector: 'app-client-config',
  templateUrl: './client-config.component.html',
  styleUrls: ['./client-config.component.scss']
})
export class ClientConfigComponent implements OnInit {

  constructor(
    public user:UserService,
    public amplitude: AmplitudeService,
    public socket:SocketService,
    public cameraService:CameraService,
    private dialog: MatDialog,
    public http: HttpService,
    public customErrorHandler:CustomErroHandlerService
  ) { }

  public updating:boolean = false;
  public updateMessage:string;
  public configuracoesOpen = true;
  public atualizarDadosOpen = false;
  public contaOpen = false;

  ngOnInit(): void {
  }

  async updateData() {
    try {
      this.amplitude.sendEvent("Clicou Atualizar Dados")
      this.updateMessage = 'Atualizando dados...'
      this.updating = true;
      await this.user.updateData();
      this.updating = false;

      this.socket.sendAtulizaDetection(this.cameraService.getIdCamerasList())
    }
    catch (err) {
      this.customErrorHandler.handleError(err)
      console.log("Erro ao atualizar dados ", err)
    }
  }

  async updateGeoData() {
    try {
      this.amplitude.sendEvent("Clicou Atualizar Dados Geo")
      this.updateMessage = 'Atualizando base geográfica...'
      this.updating = true;
      await this.user.updateGeoData();
      this.updating = false;
    }
    catch (err) {
      this.customErrorHandler.handleError(err)
      console.log("Erro ao atualizar dados ", err)
    }
  }

  handleOpenAtualizarDados() {
    if (!this.atualizarDadosOpen) this.reset()
    this.atualizarDadosOpen = !this.atualizarDadosOpen;
  }

  handleOpenConfiguracoes() {
    if (!this.configuracoesOpen) this.reset()
    this.configuracoesOpen = !this.configuracoesOpen;
  }

  handleOpenConta() {
    if (!this.contaOpen) this.reset()
    this.contaOpen = !this.contaOpen;
  }

  reset() {
    this.atualizarDadosOpen = false;
    this.configuracoesOpen = false;
    this.contaOpen = false;
  }

  toggleSound() {
    this.user.soundDetection ? this.amplitude.sendEvent("Desativou Som") : this.amplitude.sendEvent("Ativou Som")
    this.user.toogleSound()
  }

  setSendDetectionAlertsState() {
    this.user.toggleNotificationTelegram ? this.amplitude.sendEvent("Desabilitou Deteccoes Telegram") : this.amplitude.sendEvent("Habilitou Deteccoes Telegram")
    this.user.setSendDetectionAlertsState()
  }

  toggleReclassDet() {
    this.user.reclassifyDet ? this.amplitude.sendEvent("Desabilitou Reclassificacao") : this.amplitude.sendEvent("Habilitou Reclassificacao")
    this.user.toogleReclassDet()
  }

  toggleAutoTri() {
    this.user.autoTriang ? this.amplitude.sendEvent("Desabilitou Triangulacao Automatica") : this.amplitude.sendEvent("Habilitou Triangulacao Automatica")
    this.user.toogleAutoTri()
  }

  toggleUseEllipse() {
    this.user.useAuxiliarEllipse ? this.amplitude.sendEvent("Desabilitou Elipse Auxiliar") : this.amplitude.sendEvent("Habilitou Elipse Auxiliar")
    this.user.toggleUseEllipse()
  }

  reloadInterface() {
    this.amplitude.sendEvent("Clicou Atualizar Interface")
    const randomParam = `?rand=${Math.random()}`; // Generate a random query parameter
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('#')[0]; // Extract the base URL without the hash
    const newUrl = baseUrl + randomParam + window.location.hash; // Include the original hash
    window.location.assign(newUrl); // Use window.location.assign() to modify the URL
    window.location.reload(); // Reload the modified URL, bypassing the cache
  }

  openConfigDialogComponentLevel() {
    this.amplitude.sendEvent("Clicou Alterar senha")
    return this.user.openConfigDialog();
  }

  openLogoutDialogComponentLevel() {
    this.amplitude.sendEvent("Clicou Sair")
    return this.user.openLogoutDialog();
  }

  deleteDetections(){
    let dialogText = `ATENÇÃO: Tem certeza que deseja deletar todas as deteccoes?`
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: dialogText }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation){
        this.http.get('delete_detections').then((response) => {
          this.reloadInterface()
        });
      }
    });
  }
}
