<main id="camera-parent" *ngIf="cam_obj">

  <div class="cam-loader" *ngIf="!cam_log">
    <mat-progress-spinner mode="indeterminate" value=75>
    </mat-progress-spinner>
    <span style='margin-top: 10px;'>
      Tentando Reconectar...
    </span>
  </div>

  <!-- Deprecated -->
  <!-- <div class="cam-loader" *ngIf="live_loader">
    <mat-progress-spinner mode="indeterminate" value=75>
    </mat-progress-spinner>
    <span style='margin-top: 10px;'>
      Realizando detecção da imagem da camera..
    </span>
  </div> -->

  <!-- StreamComponent -->
  <ng-template #streamTemplate></ng-template>

  <!-- Infos  -->
  <img src="assets/icons/new-icons/gps-warn.svg" style="height: 12px; width: 12px;" class="center-icon"/>

  <div *ngIf="cam_log" class="info-tag">
    <div>
      <span class='cam-name'>
        {{cam_obj.nome_local}}
      </span>
      <span>
        {{cam_log?.datetime | date:'HH:mm:ss'}}
      </span>
    </div>
    <div>
      <span>
        {{cam_log?.ptz?.pan}}° | {{tipLogTilt()}} | {{cam_log?.ptz?.zoom}} X | Dist: {{tipLogDist()[0]}} ~ {{tipLogDist()[1]}} Km
      </span>
    </div>
  </div>

  <div class="cam-ctrl-btns-container">
    <button
      mat-mini-fab
      [color]="'primary'"
      id="camera-btn-manual-det"
      (click)="createManualDetection()"
      [disabled]="detections.creating_manual_detection"
      matTooltip="Detecção Manual"
      *ngIf="!isSecondCam"
    >
      <img src="assets/icons/new-icons/fire-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      mat-mini-fab
      color="primary"
      matTooltip="Habilitar controle por botões"
      [color]="show_arrows ? 'accent' : 'primary'"
      (click)='toggleShowArrowsAndStopAutomaticDetection()'
    >
      <img src="assets/icons/new-icons/gamepad-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      mat-mini-fab
      color="primary"
      matTooltip="Zoom em região"
      [color]="draw ? 'accent' : 'primary'"
      (click)='toggleDraw()'
    >
      <img src="assets/icons/new-icons/picture-in-picture-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      mat-mini-fab
      color="primary"
      matTooltip="Zoom +"
      (click)="this.camZoomIn()"
    >
      <img src="assets/icons/new-icons/zoom-in-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      mat-mini-fab
      color="primary"
      matTooltip="Zoom -"
      (click)="this.camZoomOut()"
    >
      <img src="assets/icons/new-icons/zoom-out-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      mat-mini-fab
      color="primary"
      matTooltip="Reiniciar zoom"
      (click)="this.camResetZoom()"
    >
      <img src="assets/icons/new-icons/reset-zoom-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      *ngIf="(!this.cam.expanded_screen && this.cam_id == this.cam.getFirstCamId()) || (!this.cam.expanded_second_screen && this.cam_id == this.cam.getSecondCamId())" mat-mini-fab color="primary"
      matTooltip="Expandir"
      (click)="this.cam.openFullScreen(this.cam_id == this.cam.getFirstCamId() ? 1 : 2)"
    >
      <img src="assets/icons/new-icons/fullscreen-dark.svg" style="height: 18px; width: 18px;"/>
    </button>

    <button
      *ngIf="(this.cam.expanded_screen && this.cam_id == this.cam.getFirstCamId()) || (this.cam.expanded_second_screen && this.cam_id == this.cam.getSecondCamId())"
      mat-mini-fab
      color="primary"
      matTooltip="Fechar"
      (click)="this.cam.closeFullScreen()"
    >
      <img src="assets/icons/new-icons/fullscreen-exit-dark.svg" style="height: 18px; width: 18px;"/>
    </button>
  </div>

  <div class='cam-logo-container'>
    <div [ngStyle]="user.client_logo"></div>
  </div>

  <div class='pantera-logo-container'>
    <div class='pantera-logo'></div>
  </div>

  <canvas
    [id]="canvasId"
    [ngClass]="draw ? 'draw-canvas draw-activated' : 'draw-canvas'"
    (click)="sendClickCoordinates($event)"
    (wheel)="wheelZoom($event)"
    (mousedown)="drawStart($event)"
    (mousemove)="drawUpdate($event)"
    (mouseup)="drawFinish()">
  </canvas>

  <button mat-raised-button class="arrow" id="up" (click)="this.camMoveUp()" *ngIf='show_arrows'>
    <img src="assets/icons/new-icons/arrow-up-dark.svg" style="height: 12px; width: 12px;"/>
  </button>

  <button mat-raised-button class="arrow" id="right" (click)="this.camMoveRight()" *ngIf='show_arrows'>
    <img src="assets/icons/new-icons/arrow-right-dark.svg" style="height: 12px; width: 12px;"/>
  </button>

  <button mat-raised-button class="arrow" id="down" (click)="this.camMoveDown()" *ngIf='show_arrows'>
    <img src="assets/icons/new-icons/arrow-down-dark.svg" style="height: 12px; width: 12px;"/>
  </button>

  <button mat-raised-button class="arrow" id="left" (click)="this.camMoveLeft()" *ngIf='show_arrows'>
    <img src="assets/icons/new-icons/arrow-left-dark.svg" style="height: 12px; width: 12px;"/>
  </button>

</main>
