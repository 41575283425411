import { Injectable } from '@angular/core';
import { Injector } from '@angular/core';

import { HttpService } from './http.service'
import { GeoService } from './geo.service'
import { UserService } from './user.service'
import { formatDate } from '@angular/common';

import * as L from 'leaflet';
import {tableHeader, tableRow} from "./geo.format";
import { AmplitudeService } from './amplitude.service';
import { CustomErroHandlerService } from './custom-erro-handler.service';

const cluster_color_map = {
  1:'red',
  2:'orange',
  3:'yellow',
  4:'gray',
}

@Injectable({
  providedIn: 'root'
})
export class SatelliteService {

  constructor(
    private http: HttpService,
    private user: UserService,
    private injector: Injector,
    public amplitude: AmplitudeService,
    public customErrorHandler:CustomErroHandlerService
    ) { }
    
  public clusters;
  public clustersLength: number = 0;
  public selectedCluster;
  // FAZER
  public lastUpdated;

  updateClustersLength(newLength) {
    this.clustersLength = newLength;
  }

  async createClusters(){
    try {
      let rawClusters = await this.http.maestroGet(`get_clusters_geojson/${this.user.getIdPlanta()}`)

      this.lastUpdated = new Date()

      this.initClusters(rawClusters)

      let clustersLeaftletGeoJson = L.geoJSON(rawClusters, {
        pane:'shadowPane',
        onEachFeature: (feature, layer) => {
          layer.bindPopup(formatSatPopUp(feature.properties, 'Detecção satelital'));
          let cluster_style = {
            "weight": 2,
            "color": "black",
            "opacity": 1,
            "fillColor":cluster_color_map[feature.properties.symbology_class],
            "fillOpacity": 0.4  
          };

          // Se for um cluster confirmado
          if(feature.properties.cluster_id){
            cluster_style.weight = 3
            cluster_style.color = 'red'
          }
          layer.setStyle(cluster_style)
          
          // Para click e ser ativado pela tabela de clusters
          layer.on('click', e => {
            console.log('clickedCluster', feature);
            this.injector.get(GeoService).mapOnClick(e);
          });
          layer.pk_mv = feature.properties.pk_mv
          
          // Criando marker do cluster
          // let clusterCenter = layer.getBounds().getCenter();
          // let marker = L.marker(clusterCenter, {icon: this.getSatIcon(feature.properties.symbology_class)});
          // clusterMarkers.push(marker)
        }
      
      });
      
      // clusterMarkers = L.layerGroup(clusterMarkers)
      return {
        'groupId': 4,
        'groupTitle': 'Detecção Satelital',
        'icon': 'satellite_alt',
        'layersList': [{
          'legend':'Grupos de focos de calor',
          'layer': clustersLeaftletGeoJson,
          'active': true
        }]
      }
      
    } catch (error){
      this.customErrorHandler.handleError(error);
      console.log('Error in createClusters', error)
      return null
    }
  }

  initClusters(clusters_geojson){
    if (!clusters_geojson.features){
      this.clusters = []
      this.updateClustersLength(0)
      return
    }

    let minutesToRecent = 30

    this.clusters = clusters_geojson.features.map(
      feature=> Object.assign(
        {
          'recent': +new Date() - +new Date(feature.properties.newest_hotspot) < minutesToRecent * 60 * 1000
        },
        feature.properties
      )
    );
    this.updateClustersLength(this.clusters.length)
    this.clusters.sort((c1, c2) => +new Date(c2.newest_hotspot) - +new Date(c1.newest_hotspot))
    console.log('this.clusters', this.clusters)
  }

  selectCluster(id){
    this.amplitude.sendEvent("Clicou Deteccao Satelital")
    this.selectedCluster = this.clusters.filter(c=>c.pk_mv == id)[0]
    this.selectedCluster.recent = false
    console.log("selectedCluster", this.selectedCluster)
  }
}

// latest_hotspot virou oldest_hotspot -> alterar função no banco quando todos os clientes passarem da v1.13.108
const clusterCols = [
  // 'area_ha',
  // 'pk_mv',
  'oldest_hotspot',
  'newest_hotspot',
  'cluster_duration',
  'amount_of_hotspots',
]

function formatFeaturePopUp(properties, title, filter_cols=[]){
  let infoHTML = '<table>'
  infoHTML+= tableHeader(title, '#ff7070')
  
  let ct = 0;

  for (const [key, value] of Object.entries(properties)) {
    if (!filter_cols.length || filter_cols.includes(key)){
      let col = (ct++ % 2) === 0 ? 'white' : '#cce2ed';
      infoHTML+= tableRow(key, value, col)
    } 
  } 
  return infoHTML
}


function formatSatPopUp(properties, title){
  let infoHTML = '<table>'
  infoHTML+= tableHeader(title, '#ff7070')
  infoHTML+= tableRow('Foco mais recente', formatDate(new Date(properties['newest_hotspot']), 'HH:mm  yyyy-MM-dd', 'en-US'), 'white')
  infoHTML+= tableRow('Foco mais antigo', formatDate(new Date(properties['oldest_hotspot']), 'HH:mm  yyyy-MM-dd', 'en-US'), '#cce2ed')
  infoHTML+= tableRow('Número de focos de calor', properties['amount_of_hotspots'], 'white')
  infoHTML += '</table>'
  return infoHTML
}

