import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { UtilsService } from './utils.service';
import { environment } from 'src/environments/environment'
import { SocketService  } from './socket.service';
import { AmplitudeService } from './amplitude.service';
import { UserService } from './user.service';
import { CustomErroHandlerService } from './custom-erro-handler.service';
import { PETRIBU_PLANT_ID } from './cam.store';

const FIRST_CAMERA_KEY = 'FIRST_CAMERA_ID';
const SECOND_CAMERA_KEY = 'SECOND_CAMERA_ID';
const STATUS_KEY = 'CAMERAS_STATUS';
const STATUS_TIMEOUT = environment.camStatusTimeout;

const DEFAULT_OPTIONS_COLUMNS = ['name', 'auto', 'spin', 'night', 'clean', 'reboot'];
export const HDR_CUSTOM_OPTIONS_COLUMNS = ['name', 'auto', 'spin', 'hdr', 'night', 'clean', 'reboot'];

@Injectable({
  providedIn: 'root'
})
export class CameraService {

  constructor(
    public http: HttpService,
    public utils: UtilsService,
    private socketService: SocketService,
    public amplitude: AmplitudeService,
    public user: UserService,
    public customErrorHandler:CustomErroHandlerService
  ) { }

  public cameras: any = [];
  private status_logger: any;
  public oldLog = [];
  public oldLog2 = []
  public countLog = 0 ;
  public logEntry = 0;
  public allowedToClick: boolean = true;

  public expanded_screen = false;
  public expanded_second_screen = false;

  public cam_options_columns: string[] = DEFAULT_OPTIONS_COLUMNS;

  async setClickRateLimit() {
    this.allowedToClick = false;
    setTimeout(() => {
      this.allowedToClick = true;
    }, 800);
  }

  /*
  Obtem a lista de objetos de cameras com o Backend
  */
  async getCameras(){
    try {
      const response = await this.http.get('cameras_table');
      if (!!response) this.cameras = response
      if(this.cameras.length > 0){
        // debug suzano
        console.log("this.cameras.length > 0 --- this.cameras = ", this.cameras)

        // feito no backend
        this.cameras.sort((c1, c2) => {
          return c1.numero_local - c2.numero_local;
        })
  
        let stream_port = 8000;
  
        this.cameras.forEach(cam => {
          cam.name = cam.nome_local;
          cam.stream_port = stream_port++;
          cam.clean = false;
          cam.reboot = false;
  
          if (cam.id_planta == PETRIBU_PLANT_ID) {
            this.cam_options_columns = HDR_CUSTOM_OPTIONS_COLUMNS;
            return true;
          }
        });
        return true;
      }
      console.log("this.cameras.length <= 0 --- cameras_table route's response:", response)
      return false;
    }
    catch (error) {
      console.log('%c Error in getting cameras', 'background: #222; color: red', error);
      this.customErrorHandler.sendCustomLog("Error in getCameras: ", error)
      return false;
    }
  }

  /*
  Cam Init
  */
  async camInit(){
    this.closeLoggers();

    try{
      await this.getCameras();
      this.setFirstCamera(this.cameras[0].id_cam);

      this.cameras.map( cam => {
        if (cam.auto == 2) {
          this.stopAuto(cam.id_cam)
          cam.auto = 0
        }
      })

      return true
    }
    catch (error) {
      this.customErrorHandler.handleError(error);
      console.log('Error in CamInit', error)
      return false
    }
  }

  /*
  Atualizacao da posicao PTZ de cada camera
  */

  drawRectangle(canvasContext, x0, y0, x1, y1, color='white'){
    // top-left & bottom-right


    let end_x = x1 - x0;
    let end_y = y1 - y0;

    canvasContext.lineWidth = 2;
    canvasContext.strokeStyle = color;

    canvasContext.beginPath();
    canvasContext.rect(x0, y0, end_x, end_y);
    canvasContext.stroke();
    canvasContext.closePath();
  }

  async drawBboxTriAuto(bbox, smokeProb, cam_id){
    let canvasElement = <HTMLCanvasElement> document.getElementById(`canvas-${cam_id}-half-height`);

    canvasElement.width = canvasElement.getBoundingClientRect().width;
    canvasElement.height = canvasElement.getBoundingClientRect().height;

    let canvasDimensions = canvasElement.getBoundingClientRect();
    let canvasContext = canvasElement.getContext('2d');


    let width = canvasDimensions.width;
    let height = canvasDimensions.height;

    canvasContext.font = "12px Arial";
    canvasContext.fillStyle = "red";

    this.drawRectangle(canvasContext, bbox[1]*width,bbox[0]*height,bbox[3]*width,bbox[2]*height, 'red')

    let yText = bbox[0]<0.1 ? bbox[0]*height+5: bbox[0]*height-5

    canvasContext.fillText(`${(smokeProb * 100).toFixed(2)} %`, bbox[1]*width+2  , yText);

    setTimeout(() => {
      canvasContext.clearRect(0, 0, canvasElement.width, canvasElement.height);
    }, 10000);

  }

  // deprecated
  comparaDuasListas(jsont1, jsont2){
    try {
      const lista1 = jsont1.map((value) => value.datetime);
      const lista2 = jsont2.map((value) => value.datetime);
      this.logEntry = 0;
      for (let index = 0; index < lista2.length; index++) {
        if (lista1[index] === lista2[index]) {
          this.countLog += 1;
        }
      }
    }
    catch(err) {
      this.customErrorHandler.handleError(err);
      console.log("...comparaDuasListas", err)
    }
  }

  async logPtz(){
    const cams_log = await this.http.get('cameras', ['log']);
    if (Array.isArray(cams_log)){
      localStorage.setItem(STATUS_KEY, JSON.stringify(cams_log));
      }
    this.status_logger = setTimeout(async () => await this.logPtz(), STATUS_TIMEOUT);
  }

  /*
  Limpeza dos loggers
  */
  closeLoggers(){
    if (this.status_logger) { clearTimeout(this.status_logger); }
    localStorage.removeItem(STATUS_KEY);
    this.rmSecondCamera();
  }

  // MOVEMENT
  stopCommand(cam_id){
    this.http.get('stop', [cam_id]);
  }

  rightCommand(cam_id){
    this.http.get('move_right', [cam_id]);
  }

  leftCommand(cam_id){
    this.http.get('move_left', [cam_id]);
  }

  upCommand(cam_id){
    this.http.get('move_up', [cam_id]);
  }

  downCommand(cam_id){
    this.http.get('move_down', [cam_id]);
  }

  async sendAbsPTZ(ptz_obj: { id: number; pan: number, tilt:number, zoom:number }) {
    return await this.http.post('absolute_ptz', ptz_obj);
  }

  async sendAbsPan(data_obj: { id: number, pan: number }) {
    return await this.http.post('absolute_pan', data_obj);
  }

  // ZOOM
  zoomInCommand(cam_id){
    this.http.get('zoom_in', [cam_id]);
  }

  zoomOutCommand(cam_id){
    this.http.get('zoom_out', [cam_id]);
  }

  resetZoomCommand(cam_id){
    this.http.get('reset_zoom', [cam_id]);
  }

  // ROI
  async centerInRoi(id, rel_x, rel_y){
    let obj = {bbox: [rel_x, rel_y], id: id};
    let res = await this.http.post('center_in_roi', obj);
    console.log('%c👆 move','', obj, res);
  }

  async zoomInRoi(id, bbox){
    let obj = { bbox: bbox, id: id};
    console.log('...ROI', obj)
    let res = await this.http.post('zoom_in_roi', obj);
    return res
  }

  // OPTIONS
  async autoCommand(cam_id, activate=1){
    await this.http.get('set_auto', [cam_id, activate]);
  }

  async spinCommand(cam_id, activate=1){
    await this.http.get('set_spin', [cam_id, activate]);
  }

  async nightCommand(cam_id, activate=1){
    await this.http.get('set_night_mode', [cam_id, activate]);
  }

  async hdrCommand(cam_id, activate=1){
    await this.http.get('set_hdr_mode', [cam_id, activate]);
  }

  async rebootCommand(cam_id){
    await this.http.get('restart', [cam_id]);
  }

  async cleanCommand(cam_id){
    await this.http.get('wiper', [cam_id]);
  }

  // OPTIONS


  async turnOnAllDetections(){
    this.amplitude.sendEvent("Ativou Deteccoes Automaticas")
    this.cameras.forEach(c => {
      c.auto = 1;
      c.spin = false;
    })
    let response = await this.http.get('turn_on_all_detections');
    console.log('turn_on_all_detections', response)
    let cameraIds = this.cameras.map(object => object.id_cam)
    let cameraAutoStatus = this.cameras.map(object => object.auto)
    this.socketService.sendCameras([true, cameraIds, cameraAutoStatus])
  }

  async resetCommands(){
    this.cameras.forEach(async (cam) => {
      await this.spinCommand(cam.id_cam, 0)
      await this.autoCommand(cam.id_cam, 0)
    });

    let cameraIds = this.cameras.map(object => object.id_cam)
    let cameraAutoStatus = this.cameras.map(object => object.auto)
    this.socketService.sendCameras([true, cameraIds, cameraAutoStatus])
  }

  async resetCameraCommand(cam_id){
    try {
      if (!this.cameras) {
        await this.getCameras()
        // debug suzano
        this.customErrorHandler.sendCustomLog("chamou getCameras", "resetCameraCommand")
      }
      
      this.cameras.forEach(async (camera) => {
        if (camera.id_cam == cam_id) {
          if (camera.auto) {
            await this.autoCommand(cam_id, 0)
            camera.auto = 0
          }

          if (camera.spin) {
            await this.spinCommand(cam_id, 0)
            camera.spin = false
          }
        }
      });
      this.socketService.sendCameras([true, [cam_id], [0]])
    }
    catch (error) {
      console.log("resetCameraCommand error", error)
    }
  }

  async stopAuto(cam_id) {
    let response = await this.http.get('set_auto', [cam_id, 0]);
    for (var i = 0; i < this.cameras.length; i++){
      if (this.cameras[i].id_cam == cam_id){
        this.cameras[i].auto = 0
      }
    }
    this.socketService.sendCameras([true, [cam_id], [0]])
    return response;
  }

  /*
  Getters de cameras
  */
  getCameraObj(cam_id){
    return this.cameras.filter(x => x.id_cam === cam_id)[0];
  }

  getCameraName(cam_id){
    return this.getCameraObj(cam_id).nome_local;
  }

  getCameraLatLon(cam_id){
    let c = this.getCameraObj(cam_id);
    return {
      lat:c.lat,
      lon:c.lon,
    }
  }

  getCameraHeight(cam_id){
    let c = this.getCameraObj(cam_id);
    return c.altura_m
  }

  getCameraIdEquipamento(cam_id){
    return this.getCameraObj(cam_id).id_equipamento;
  }

  /*
  Getters e Setters no Local Storage
  */

  getStatus(){
    return JSON.parse(localStorage.getItem(STATUS_KEY)) || [];
  }

  getCamLog(cam_id:number){
    let cams_status = JSON.parse(localStorage.getItem(STATUS_KEY));
    try{
      return cams_status.filter(cam_status => cam_status?.id_camera === cam_id)[0];
    }
    catch (err) {
      this.customErrorHandler.handleError(err);
      return null
    }
  }

  setFirstCamera(id){
    localStorage.setItem(FIRST_CAMERA_KEY, id.toString());
  }

  setSecondCamera(id){
    localStorage.setItem(SECOND_CAMERA_KEY, id.toString());
  }

  getFirstCamId(){
    return parseInt(localStorage.getItem(FIRST_CAMERA_KEY));
  }

  getSecondCamId(){
    return parseInt(localStorage.getItem(SECOND_CAMERA_KEY));
  }

  rmSecondCamera(){
    localStorage.removeItem(SECOND_CAMERA_KEY);
  }

  openFullScreen(cam_index: number){
    this.amplitude.sendEvent("Clicou Abrir Tela cheia")
    if (cam_index == 1) this.expanded_screen = true;
    else if (cam_index == 2) this.expanded_second_screen = true;
    else return
  }

  closeFullScreen(){
    this.amplitude.sendEvent("Clicou Fechar Tela cheia")
    this.expanded_screen = false;
    this.expanded_second_screen = false;
  }

  getIdCamerasList(){
    let idsCameras: number[] = this.cameras.map((item) => item.id_cam);
    return idsCameras
  }

  toggleControllingCameraOnMap(id_cam: number) {
    return this.cameras.filter(cam => {
      if (cam.id_cam == id_cam) {
        cam.pantoOnClick = !cam.pantoOnClick;
      }
      return cam.id_cam == id_cam
    })[0]
  }
}
