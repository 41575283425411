import { Component, OnInit } from '@angular/core';
import { CameraService } from '../../services/camera.service';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmationDialog} from 'src/app/shared/dialogs/confirmation';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { SocketService  } from '../../services/socket.service';
import { DAHUA_EQUIPMENT_ID } from 'src/app/services/cam.store';

@Component({
  selector: 'app-cam-options',
  templateUrl: './cam-options.component.html',
  styleUrls: ['./cam-options.component.scss']
})
export class CamOptionsComponent implements OnInit {

  public DAHUA_ID = DAHUA_EQUIPMENT_ID
  
  constructor(
    public cams: CameraService,
    private dialog: MatDialog,
    private socketService: SocketService,
    private amplitude: AmplitudeService
  ) { }

  ngOnInit(): void {
    this.socketService.onNewMessageCam().subscribe(async ()=> {
      await this.cams.getCameras()
    });
  }

  async setCameraStatus(element, col_name){
    setTimeout(async () => {
      element[col_name] = !element[col_name];
      let activate = element[col_name];

      switch(col_name){
        case 'spin':
          await this.cams.spinCommand(element.id_cam, activate ? 1 : 0);
          if(activate) {
            element.auto = false;
            await this.cams.autoCommand(element.id_cam, 0);
            this.amplitude.sendEvent("Ativou Modo Rotacao", {
              "id_camera": element.id_cam
            })
          }
          else {
            this.amplitude.sendEvent("Desativou Modo Rotacao", {
              "id_camera": element.id_cam
            })
          }
          break;
        case 'auto':
          await this.cams.autoCommand(element.id_cam, activate == 1 ? 1 : 0);
          if(activate == 1) {
            element.spin = false;
            await this.cams.spinCommand(element.id_cam, 0);
            this.amplitude.sendEvent("Ativou Deteccao Automatica Camera", {
              "id_camera": element.id_cam
            })
          }
          else {
            this.amplitude.sendEvent("Desativou Deteccao Automatica Camera", {
              "id_camera": element.id_cam
            })
          }
          break;
        case 'night':
          await this.cams.nightCommand(element.id_cam, activate ? 1 : 0);
          if (activate) {
            this.amplitude.sendEvent("Ativou Modo Noturno Auto", {
              "id_camera": element.id_cam
            })
          }
          else {
            this.amplitude.sendEvent("Desativou Modo Noturno Auto", {
              "id_camera": element.id_cam
            })
          }
          break;
        case 'hdr':
          await this.cams.hdrCommand(element.id_cam, activate ? 1 : 0);
          break;
      }
      this.socketService.sendCameras([true, [element.id_cam], [element.auto]])
    }, 5);
  }

  clean(element){

    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: 'ATENÇÃO: Tem certeza que deseja limpar a lente? Fazer somente em caso de chuva' }
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {

      if (confirmation){
        element['clean'] = true;
        await this.cams.cleanCommand(element.id_cam);
        element.spin = false;
        element.auto = false;

        this.amplitude.sendEvent("Clicou Wiper", {
          "id_camera": element.id_cam
        })

        setTimeout(() => {
          element['clean'] = false;
        }, 60000);

      }

    })
  }

  reboot(element){

    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: 'ATENÇÃO: Tem certeza que deseja reiniciar a câmera?' }
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {

      if (confirmation){
        element['reboot'] = true;
        await this.cams.rebootCommand(element.id_cam);
        element.spin = false;
        element.auto = false;

        this.amplitude.sendEvent("Clicou Reboot Camera", {
          "id_camera": element.id_cam
        })

        setTimeout(() => {
          element['reboot'] = false;
        }, 60000);

      }

    })
  }
}