import { Component, OnInit, OnDestroy, HostListener, ChangeDetectorRef } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { CameraService } from '../../services/camera.service';
import { HttpService } from '../../services/http.service';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation';
import { InformationDialog } from 'src/app/shared/dialogs/information';
import { GeoService } from 'src/app/services/geo.service';
import { UserService } from 'src/app/services/user.service';
import { getCamAngularViewDeg } from 'src/app/services/cam.store';
import { StorageService } from 'src/app/services/storage.service';
import { DeleteReportDialog } from 'src/app/shared/dialogs/delete-report';
import { ReportsService } from 'src/app/services/reports.service';
import { PresetpickerService } from 'src/app/services/presetpicker.service';
import { SocketService } from 'src/app/services/socket.service';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
export interface preset {
  index: number,
  pan: number,
  tilt: number,
  zoom: number,
  sleep: number,
}

export type presetTypes = 'presets';


@Component({
  selector: 'app-config',
  templateUrl: './config.component.html',
  styleUrls: ['./config.component.scss']
})

export class ConfigComponent implements OnInit, OnDestroy{

  constructor(
    public cams: CameraService,
    public geo: GeoService,
    public user:UserService,
    private report:ReportsService,
    private storage:StorageService,
    private http:HttpService,
    private dialog:MatDialog,
    public pp:PresetpickerService,
    public socket:SocketService,
    private cd: ChangeDetectorRef,
    public customErrorHandler:CustomErroHandlerService
    ) { }

  public loaded:boolean=false;

  public selectedCam:any;
  public currentCamIndex = 0;
  public selectedPreset:presetTypes = "presets"
  public displayedColumns:string[] = ['index', 'pan', 'tilt', 'zoom', 'fov'];
  public displayedColumns2:string[] = ['index2', 'pan2', 'tilt2', 'zoom2', 'fov2', 'masks'];
  public statusPrests:string[] = ['noChanges', 'changed', 'deleted', 'new', 'indexChange'];
  public activeIdx:number;
  public imagesUrl:string[]

  public simulatingTour:boolean = false;
  public deltaTimePositions:number = 5;

  public urlImgPreset = ''
  public autoStartPan:number = 0;
  public autoZoom:number = 1;
  public autoTilt:number = 85;
  public autoSleep:number = 3;
  public streamingType: string = "docker-ws";

  public loading_msg:string;
  public presetPicking = false;

  private canvas_el: any;
  private canvas_context: any;
  public src;

  public pointOfCalibrationImages: any[] = [];

  public search = {
    pan:0,
    tilt:90,
    zoom:1,
  }

  public newPor ={
    'id_planta' : this.user.getIdPlanta(),
		'nome_por' : null,
		'lat' : null,
		'lon' : null
  }

  private check_interval;

  async ngOnInit() {

    this.loading_msg = "Carregando Interface..."
    await this.cams.getCameras();
    await this.cams.logPtz();
    this.criarStatusPrest()
    console.log('cameras',this.cams.cameras);

    this.selectedCam = this.cams.cameras[0]
    this.streamingType = this.user.getStreamingType()

    this.loading_msg = "Carregando Mapa..."
    await this.geo.createMap();

    await this.getPresetImages();

    this.check_interval = setInterval( ()=>{
      this.checkRoutine()
    }, 1000)

    this.loaded = true;

    if (this.selectedCam.presets[0]) this.selectRow2(this.selectedCam.presets[0])

    this.geo.poisUpdated.subscribe((pois) => {
      this.geo.pontos_referencia = pois;
      this.cd.detectChanges();
    });
  }

  criarStatusPrest(){
    for(var i=0; i<this.cams.cameras.length; i++){
      this.cams.cameras[i].presets = this.cams.cameras[i].presets ? this.cams.cameras[i].presets.map(this.geradorStatusPresets.bind(this)) : []
    }
  }

  geradorStatusPresets(elemento){
    elemento['status'] = this.statusPrests[0]
    return elemento
  }

  checkRoutine(){
    this.geo.updateCamDirection();
  }

  async setCam(c){
    this.unselectRow();
    this.selectedCam = c
    this.currentCamIndex = this.cams.cameras.indexOf(c)
    await this.getPresetImages();
  }

  async setPresetType(type:presetTypes){
    this.selectedPreset = type;
    await this.getPresetImages();

  }


  async setPresetType2(type:presetTypes){
    this.selectedPreset = type;
    await this.getPresetImages();

  }

  // Table functions

  selectRow(preset){
    if (this.activeIdx === preset.index){
      this.activeIdx =  null
    } else {
      this.activeIdx =  preset.index;
    }
  }
  selectRow2(preset){
    if (this.activeIdx === preset.index){
      this.activeIdx =  null
    } else {
      this.activeIdx =  preset.index;
      if (preset.img_path === undefined) {this.urlImgPreset = ''}
      else {this.urlImgPreset = this.imagesUrl[preset.index-1]}
    }
  }
  unselectRow(){
    this.activeIdx = null;
  }

  rowSelected(){
    return this.activeIdx != null;
  }

  isSelectedClass(index){
    return index === this.activeIdx ? 'selected-row' : '';
  }

  removeSelected(){
    if (!this.activeIdx) { return; }

    if (this.selectedCam[this.selectedPreset][this.activeIdx - 1].status === 'new'){
      this.selectedCam[this.selectedPreset].splice(this.activeIdx - 1, 1);
    }else{
      this.selectedCam[this.selectedPreset][this.activeIdx - 1].status = this.statusPrests[2];
      this.selectedCam[this.selectedPreset].push(this.selectedCam[this.selectedPreset].splice(this.activeIdx - 1, 1)[0]);
    }

    this.renderTable();
  }

  deletedsToEnd(){
    const noDeletes = this.selectedCam[this.selectedPreset].filter((value) => value?.status !== 'deleted');
    const deleteds = this.selectedCam[this.selectedPreset].filter((value) => value?.status === 'deleted');
    noDeletes.push(...deleteds);
    this.selectedCam[this.selectedPreset] = noDeletes;
  }

  addRow(){

    let new_row = {index: null, pan: 0, tilt: 90, zoom: 1, sleep: 3};

    if(!this.activeIdx){
      this.selectedCam[this.selectedPreset].push(new_row);
    } else {
      this.selectedCam[this.selectedPreset].splice(this.activeIdx, 0, new_row);
    }
    let indexNew = this.selectedCam[this.selectedPreset].indexOf(new_row)
    this.selectedCam[this.selectedPreset][indexNew]['status'] = this.statusPrests[3];
    this.selectedCam[this.selectedPreset][indexNew]['pp'] = true;


    this.renderTable();
  }



  renderTable(){
    // correcting indexes
    for(let i = 0; i < this.selectedCam[this.selectedPreset].length; ++i){
      let changed = this.selectedCam[this.selectedPreset][i].index != i+1
      let status = this.selectedCam[this.selectedPreset][i].status

      this.selectedCam[this.selectedPreset][i].index = i+1;
      if(changed && ((status != 'new') && (status != 'deleted'))){
        this.selectedCam[this.selectedPreset][i].status = this.statusPrests[4];
      }

    }
    // render
    this.selectedCam[this.selectedPreset] = [...this.selectedCam[this.selectedPreset]];
    console.log(this.selectedCam[this.selectedPreset]);
    this.getPresetImages()

  }

  filterDataSource(value){
    return value?.status != 'deleted';
  }


  // Position Functions
  async panToPosition(){
    if (!this.activeIdx) {
      return this.dialog.open(InformationDialog, {
        data: {
          text: "Selecione um preset"
        }
      })
    }

    let preset_reference = this.selectedCam[this.selectedPreset][this.activeIdx -1];

    let panto_obj = {
      id : this.selectedCam.id_cam,
      pan : preset_reference.pan,
      tilt : preset_reference.tilt,
      zoom : preset_reference.zoom
    }

    let response = await this.cams.sendAbsPTZ(panto_obj);
    console.log(`panto position ${this.activeIdx}`,response);
  }

  async searchPosition(){
    let panto_obj = {
      id : this.selectedCam.id_cam,
      pan : this.search.pan,
      tilt : this.search.tilt,
      zoom : this.search.zoom
    }
    let response = await this.cams.sendAbsPTZ(panto_obj);
    console.log(response);
  }

  async getPresetImages(){
    //this.imagesUrl = await this.http.maestroGet(`get_presets_images_url/${this.user.getIdPlanta()}/${this.selectedCam.id_cam}/${this.selectedPreset}`);
    let presetsData = await this.http.maestroGet(`v2/get_presets_images_url/${this.selectedCam.id}`);
    // console.log(presetsData)
    this.imagesUrl = presetsData.map((p)=>p.img_url)
    let preset = this.selectedCam[this.selectedPreset][this.activeIdx - 1]
    if (preset===undefined || preset.img_path === undefined) {this.urlImgPreset = ''}
    else {this.urlImgPreset = this.imagesUrl[preset.index-1]}
  }

  getCamPosition(){
    let preset_reference = this.selectedCam[this.selectedPreset][this.activeIdx -1];
    console.log('old', preset_reference);

    let cam_status = this.cams.getCamLog(this.selectedCam.id_cam).ptz;

    preset_reference.pan = cam_status.pan;
    preset_reference.tilt = cam_status.tilt;
    preset_reference.zoom = cam_status.zoom;

    console.log('new_status', preset_reference);
  }

  checkInitActiveIndex(){
    if (!this.activeIdx){
      this.activeIdx = 0;
    }
  }

  valueUpdated(index){
    let newObj = this.selectedCam[this.selectedPreset][index-1]['status'] == 'new'
    this.selectedCam[this.selectedPreset][index-1]['status'] = newObj ?  this.statusPrests[3] : this.statusPrests[1];
    this.selectedCam[this.selectedPreset][index-1]['pp'] = true;
  }

  drawPresets(){
    this.selectedCam[this.selectedPreset].forEach(p => {
      const minFocalDistance = this.selectedCam.min_focal_distance_mm
      const sensorWidth = this.selectedCam.sensor_width_mm;
      this.geo.drawPreset(this.selectedCam, p, this.getMaxZoom(), minFocalDistance, sensorWidth)
    });
  }

  getMaxZoom(){
    let zoomList = this.selectedCam[this.selectedPreset].map(p => p.zoom)
    return Math.max(...zoomList) || 30
  }

  getFov(zoom){
    if (zoom == 0) zoom = 1;
    const minFocalDistance = this.selectedCam.min_focal_distance_mm;
    const sensorWidth = this.selectedCam.sensor_width_mm;
    return getCamAngularViewDeg(zoom, minFocalDistance, sensorWidth)
  }

  playTour(){
    this.checkInitActiveIndex();

    this.simulatingTour =! this.simulatingTour

    let pantoRow = () => {
      if (!this.simulatingTour){
        console.log('stoppedTour');
        return
      };

      this.nextPosition();

      setTimeout(pantoRow, 1000*this.deltaTimePositions);
    }

    console.log('playTour', this.simulatingTour)
    pantoRow();
  }


  nextPosition(){
    this.checkInitActiveIndex();
    this.activeIdx++;
    if (this.activeIdx > this.selectedCam[this.selectedPreset].length){
      this.activeIdx = 1;
    }
    const minFocalDistance = this.selectedCam.min_focal_distance_mm
    const sensorWidth = this.selectedCam.sensor_width_mm;
    this.geo.drawPreset(this.selectedCam, this.selectedCam[this.selectedPreset][this.activeIdx-1], this.getMaxZoom(), minFocalDistance, sensorWidth)
    this.panToPosition();
  }

  previousPosition(){
    this.checkInitActiveIndex();
    this.activeIdx--;
    if (this.activeIdx == 0){
      this.activeIdx = this.selectedCam[this.selectedPreset].length;
    }
    this.panToPosition();
  }

  createAutoPresets(){
    console.log('autoStartPan', this.autoStartPan);
    console.log('autoZoom', this.autoZoom);

    const minFocalDistance = this.selectedCam.min_focal_distance_mm;
    const sensorWidth = this.selectedCam.sensor_width_mm;
    let fov = getCamAngularViewDeg(this.autoZoom, minFocalDistance, sensorWidth)
    console.log('fov', fov);
    let nPresets = Math.ceil(360/fov);
    console.log('nPresets', nPresets);
    let deltaPresets = Math.round(360/nPresets);
    console.log('deltaPresets', deltaPresets);

    let rawPan = [...Array(nPresets).keys()];
    let autoPreset:preset[] = rawPan.map( n => {
      return{
        'index':n+1,
        'pan':(this.autoStartPan + n*deltaPresets) % 360,
        'tilt':this.autoTilt,
        'zoom':this.autoZoom,
        'sleep':this.autoSleep,
        'status':this.statusPrests[3],
        'pp': true
      }
    })
    console.log('autoPreset', autoPreset);
    console.log(this.selectedCam[this.selectedPreset])
    this.selectedCam[this.selectedPreset] == null ? this.selectedCam[this.selectedPreset] = [...autoPreset] : this.selectedCam[this.selectedPreset].push(...autoPreset);
    this.deletedsToEnd();
    this.renderTable();
  }

  async updatePresets(){
    let update_text = ""
    // this.selectedCam = this.cams.cameras[0]
    console.log('selected Cam', this.selectedCam);

    try {
      let config_cam = {
        'id' : this.selectedCam.id_cam,
        'id_planta': this.user.getIdPlanta(),
        'presets' : this.selectedCam.presets || [],
      };
      // pp:DEPRECATED
      // let resposta = await this.http.maestroGet(`set_preset_picker_status/${this.selectedCam.id_cam}/true`);

      // Atualizar Maestro
      let response = await this.http.maestroPost('v3/update_config_cameras_pantera', config_cam)
      // Atualizando Backend Local
      const filteredObjects = config_cam[this.selectedPreset].filter(obj => obj.pp );

      if (response.status){
        response = await this.http.get('atualizar_dados_cliente');

        if (filteredObjects.length !== 0) {
          this.presetPicking = true;
          this.pp.updateAllPresetsImgCam(this.selectedCam.id_cam, filteredObjects, this.selectedPreset)
          .then(()=>{
            this.presetPicking = false
            this.getPresetImages()
          });
        }
        update_text = `Sucesso ao atualizar os presets da "${this.selectedCam.nome_local}"`
      }
      else{
        update_text = `Erro ao atualizar os presets da "${this.selectedCam.nome_local}"`
      }

    } catch (error) {
      this.customErrorHandler.handleError(error)
      update_text = `Erro ao atualizar os presets da "${this.selectedCam.nome_local}"`
    } finally {

      const dialogRef = this.dialog.open(InformationDialog , {
        data: { text: update_text }
      });

      await this.cams.getCameras();
      this.selectedCam = this.cams.cameras[0]

      this.criarStatusPrest()
      this.selectedCam = this.cams.cameras[this.currentCamIndex]
      this.renderTable();
      this.socket.sendAtulizaDetection([this.selectedCam.id_cam])

   }

  }


  runPresetPickerLista(){
    let idCam = this.selectedCam.id_cam;
    let listaPresets = this.selectedCam[this.selectedPreset]?.filter(this.filterDataSource);
    this.presetPicking = true;
    this.pp.updateAllPresetsImgCam(idCam, listaPresets, this.selectedPreset)
    .then(()=>{
      this.presetPicking = false
      this.http.get('atualizar_dados_cliente')
      this.getPresetImages()
      this.renderTable();
    });
  }

  runPresetPickerOne(){
    let idCam = this.selectedCam.id_cam
    let preset = this.selectedCam[this.selectedPreset]?.filter(this.filterDataSource)[this.activeIdx-1]
    this.presetPicking = true;
    this.pp.updateAllPresetsImgCam(idCam, [preset], this.selectedPreset)
    .then(()=>{
      this.presetPicking = false
      this.http.get('atualizar_dados_cliente')
      this.getPresetImages()
      this.renderTable();
    });
  }


  async updateOffset(){
    const userInput = this.selectedCam.offset_pan;

    if (userInput < 0) {
      return this.dialog.open(InformationDialog, {
        data: { text: "Digite apenas valores positivos" }
      })
    }
    else if (userInput >= 360) {
      return this.dialog.open(InformationDialog, {
        data: { text: "Digite apenas valores entre 0 e 359,99" }
      })
    }
    else if (!userInput) {
      return this.dialog.open(InformationDialog, {
        data: { text: "Digite valores entre 0 e 359,99 para atualizar o offset" }
      })
    }

    let config_cam = {
      'id' : this.selectedCam.id_cam,
      'offset_pan' : this.selectedCam.offset_pan,
    };

    let dialog_text = ""

    let maestroResponse = await this.http.maestroPost('update_offset', config_cam);
    console.log(maestroResponse)

    if (maestroResponse.status) {
      this.http.get('restart_logs').then(response => console.log("restart_logs response", response));
      this.http.post('update_camera_configs', config_cam).then(response => console.log("update_camera_configs (backend)", response));
      dialog_text = `Sucesso ao atualizar o offset da ${this.selectedCam.nome_local} para ${this.selectedCam.offset_pan} °`
    }
    else{
      dialog_text = `Erro ao atualizar o offset da câmera ${this.selectedCam.nome_local}... Tente novamente em alguns minutos.`
      console.log(`Erro ao atualizar o offset da câmera ${this.selectedCam.nome_local} - ${maestroResponse.msg}`)
    }

    this.socket.sendAtulizaDetection([this.selectedCam.id_cam])
    this.dialog.open(InformationDialog , {
      data: { text: dialog_text }
    });
  }


  selectPoi(poi){
    console.log(poi)
  }

  poiSelected(){
    return true;
  }

  centerPoiOnMap(poi){
    // Atentar que venha sempre nessa ordem
    this.geo.setMapView(
      poi.lat,
      poi.lon
    )
  }

  
  async syncDataWithMaestro(){
    await this.http.get('atualizar_dados_cliente')
    return
  }


  deleteDetections(){

    let dialogText = `ATENÇÃO: Tem certeza que deseja deletar todas as deteccoes?`
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: dialogText }
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation){
        this.http.get('delete_detections').then((response) => console.log('delete_detections', response));
      }
    });
  }

  async deleteReport() {
    let dialogText = "Qual relatório deseja excluir?";
    let report_list = this.storage.getStoredReports()

    const dialogRef = this.dialog.open(DeleteReportDialog, {
      data: { text: dialogText, list: report_list }
    });

    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if(confirmation) {
        let reportToDelete = this.report.selectedReportToDelete[0];
        await this.http.get(`delete_report/${reportToDelete.id_report}`)
        report_list = report_list.filter(rep => rep.id_report != reportToDelete.id_report)
        this.storage.setStoredReports(report_list);
        this.report.selectedReportToDelete = null;
      }
    })
  }

  async deleteReports(){
    let dialogText = `ATENÇÃO: Tem certeza que deseja deletar todos os RELATÓRIOS?`
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: dialogText }
    });

    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (confirmation){
        await this.http.get('delete_reports').then((response) => console.log('delete_reports', response));
        this.storage.setStoredReports([])
      }
    });
  }


  drawRect(){
    try{
      this.canvas_context.clearRect(0, 0, this.canvas_el.width, this.canvas_el.height);
    } catch (err) {
      this.customErrorHandler.handleError(err)
    }
    try {
      let mascaras = this.selectedCam[this.selectedPreset]?.filter(this.filterDataSource)[this.activeIdx-1]['labels']
      if (!mascaras) return;
      console.log('mascaras',mascaras);

      this.canvas_el = document.getElementById('presetCanvas');
      this.canvas_el.width = this.canvas_el.getBoundingClientRect().width;
      this.canvas_el.height = this.canvas_el.getBoundingClientRect().height;
      let width = this.canvas_el.width;
      let height = this.canvas_el.height;

      mascaras.forEach(element => {
        if (element.class == 'preset_mask') {
          let bbox = element.bbox;
          let x1 = bbox[0]*width;
          let y1 = bbox[1]*height;
          let rect_width = bbox[2]*width - x1;
          let rect_height = bbox[3]*height - y1;
          this.canvas_context = this.canvas_el.getContext('2d');
          this.canvas_context.lineWidth = '1';
          this.canvas_context.strokeStyle = 'red';
          this.canvas_context.font = "16px Arial";
          this.canvas_context.fillStyle = 'rgba(255,0,255,0.35)';
          let yText = y1>15? y1-5: y1+15
          this.canvas_context.fillText(`mascara`, x1, yText);
          this.canvas_context.beginPath();

          this.canvas_context.rect(x1, y1, rect_width, rect_height);
          this.canvas_context.fill();
          this.canvas_context.stroke();
          this.canvas_context.closePath();
        }
      });
    } catch (error) {
      this.customErrorHandler.handleError(error)
      console.error('erro:', error);
    }
  }

  async takePhoto(name: string, uuid: string){
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: `
      Você está prestes a salvar uma imagem para o ponto de calibração ${name}.
      Certifique-se que a câmera ${this.cams.getCameraName(this.cams.getFirstCamId())} está olhando para este ponto
      ` }
    });

    dialogRef.afterClosed().subscribe(async(confirmation) => {
      if (confirmation){
        let id_cam = this.cams.getFirstCamId()
        let obj = {
          'uuid_por': uuid,
          'id_cam': id_cam,
          'img_details': this.cams.getCamLog(id_cam).ptz
        };

        let response = await this.http.post('set_por_image', obj, true);
        if (response.status) {
          this.dialog.open(InformationDialog, {
            data: {
              text: "Imagem salva com sucesso"
            }
          })
        }
      }
    });
  }

  @HostListener('window:beforeunload')
  ngOnDestroy(){
    this.cams.closeLoggers();
    clearInterval(this.check_interval)
  }
}

