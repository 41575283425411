import { Component, ViewChild, AfterViewInit } from '@angular/core';
import { DetectionsService } from 'src/app/services/detections.service';

@Component({
  selector: 'app-del-det',
  templateUrl: './del-det.component.html',
  styleUrls: ['./del-det.component.scss']
})
export class DelDetComponent implements AfterViewInit {

  public dataReclassify = this.det.reclass_types;

  @ViewChild('mySelect') mySelect;

  constructor(
    public det:DetectionsService,
  ) { }

  ngAfterViewInit(): void {
    this.mySelect.open()
  }

  openedCh (opened: boolean) {
    if (!opened) {
      this.det.reclassificandoDet = false;
    }
  }

  trackByFn(index, item) {
    return item.object_id;
  }
}
