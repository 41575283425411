import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ReportsService } from '../../../services/reports.service';
import { GeoService } from '../../../services/geo.service';
import { HttpService } from 'src/app/services/http.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { formatDate } from '@angular/common';

@Component({
  selector: 'create-report-without-tower-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class CreateReportWithoutTowerDialog implements OnInit {
  reportForm: FormGroup;
  origens: any[];
  causas: any[];

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<CreateReportWithoutTowerDialog>,
    private fb: FormBuilder,
    private reports: ReportsService,
    public geo: GeoService,
    private http: HttpService
  ) {}

  ngOnInit(): void {
    this.reportForm = this.fb.group({
      latitude: [this.geo.lastClickLatLng.lat, [Validators.required, Validators.min(-90), Validators.max(90)]],
      longitude: [this.geo.lastClickLatLng.lng, [Validators.required, Validators.min(-180), Validators.max(180)]],
      origemDeteccao: [undefined],
      causaIncendio: [undefined],
      horaDeteccao: [formatDate(new Date(), 'yyyy-MM-ddTHH:mm', 'en-US'), Validators.required]
    });

    this.loadReportParams();
  }

  async loadReportParams(): Promise<void> {
    const reportParams = await this.http.get('get_report_params');
    this.origens = reportParams.origens_deteccao;
    this.causas = reportParams.causas_incendio;
  }

  createReport(): void {
    if (this.reportForm.valid) {
      const reportData = {
        latitude: this.reportForm.value.latitude,
        longitude: this.reportForm.value.longitude,
        origemDeteccao: this.reportForm.value.origemDeteccao,
        causaIncendio: this.reportForm.value.causaIncendio,
        horaDeteccao: this.reportForm.value.horaDeteccao
      };

      this.reports.createReport(
        reportData.latitude,
        reportData.longitude,
        reportData.horaDeteccao,
        { id_origem_deteccao: reportData.origemDeteccao, id_causa_incendio: reportData.causaIncendio },
        true
      );
      
      this.dialogRef.close(true);
    }
  }
}
