import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import amplitude from 'amplitude-js';
import { UserService } from './user.service';
import { CustomErroHandlerService } from './custom-erro-handler.service';

@Injectable({
  providedIn: 'root'
})
export class AmplitudeService {

  constructor(
    public user: UserService,
    public customErrorHandler:CustomErroHandlerService
  ) { }

  private instance: string;

  sendEvent(event_name: string, event_properties?){
    this.instance = environment.amplitudeInstance;
    let instanciate = amplitude.getInstance(this.instance).init(environment.amplitudeKey, this.user.getUsername());
    this.set_user_properties()
    if (event_properties){
      amplitude.getInstance(this.instance).logEvent(event_name, event_properties);
    } else {
      amplitude.getInstance(this.instance).logEvent(event_name);
    }
  }

  set_user_properties(){
    let user_properties = {};
    try {
      user_properties = {
        'Usuário': this.user.getUsername(),
        'ID Planta': this.user.getIdPlanta(),
        'ID Tipo Usuário': this.user.getIdRole()
      }
      console.log("...user_properties", user_properties)
    } catch (err) {
      this.customErrorHandler.handleError(err);
      console.log('Erro Amplitude:', err);
    }
    amplitude.getInstance(this.instance).setUserProperties(user_properties);
  }
}
