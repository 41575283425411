<mat-card appearance="outlined" class='over-map info-on-map main-card' id="main-card" *ngIf="!isSingle">
    <span style="padding: 2vh 3vw;">
      Clique sobre o mapa no <b>local estimado do incêndio</b>
    </span>
</mat-card>

<!-- esse card tem width menor -->
<mat-card appearance="outlined" class='over-map info-on-map main-card' *ngIf="isSingle" id="main-card-single">
  <span style="padding: 2vh 3vw;">
    Clique sobre o mapa no <b>local estimado do incêndio</b>
  </span>
</mat-card>