interface Scripts {
  name: string;
  src: string;
}
export const ScriptStore: Scripts[] = [
  {name: 'leaflet-ruler', src: 'assets/scripts/leaflet-ruler.js'},
  {name: 'adapterLatest', src: 'assets/scripts/adapter-latest.js'},
  // {name: 'jqueryVXG', src: 'assets/scripts/vxg/js/jquery-3.1.0.min.js'},
  // {name: 'pooperVXG', src: 'assets/scripts/vxg/js/popper.min.js'},
  // {name: 'bootstrapVXG', src: 'assets/scripts/vxg/js/bootstrap.min.js'},
  // {name: 'videoVXG', src: 'assets/scripts/vxg/vxgwebsdk/video.min.js'},
  // {name: 'adapterVXG', src: 'assets/scripts/vxg/vxgwebsdk/webrtc-adapter-latest.js'},
  // {name: 'CloudSDKVXG', src: 'assets/scripts/vxg/vxgwebsdk/CloudSDK.min.js'},
  // {name: 'CloudSDKVXG', src: 'assets/scripts/vxg/vxgwebsdk/CloudSDK.min.js'},
];
