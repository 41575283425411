import { Component, Input } from '@angular/core';
import { UserService } from '../../../services/user.service';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent {
  constructor(public user:UserService){}
  
  @Input() public message: string;
  version = environment.version;
}
