
<mat-dialog-content>
    <div>
        <h2>Escolha um grupo</h2>
    </div>
    <div>
      <mat-selection-list #groups [multiple]="false" [(ngModel)]="reports.selectedGroup">
        <mat-list-option *ngFor="let group of data.group_list" [value]="group">
          {{group.nome_grupo}}
        </mat-list-option>
      </mat-selection-list>

      <br/>

      <p>
        Grupo selecionado: {{groups.selectedOptions.selected[0]?.value.nome_grupo}}
      </p>
    </div>
</mat-dialog-content>
  
<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]='false'>Cancelar</button>
  <button mat-raised-button [mat-dialog-close]="true" [style]="{'background': '#dc3333', 'color': 'white'}">Confirmar</button>
</mat-dialog-actions>  