import { Component, OnInit, Input} from '@angular/core';
import { MatDialog, MatDialogConfig } from '@angular/material/dialog';
import { BrigadesDisplacementDialog } from 'src/app/shared/dialogs/brigades-displacements';
import { RegisterDamageDialog } from 'src/app/shared/dialogs/register-damage';
import { AddCommentDialog } from 'src/app/shared/dialogs/add-comment';
import { LatLonDialog} from 'src/app/shared/dialogs/lat-lon';
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation';
import { InformationDialog } from 'src/app/shared/dialogs/information';
import { FinishReportDialog } from 'src/app/shared/dialogs/finish-report';
import { ReportsService } from 'src/app/services/reports.service';
import { StorageService } from 'src/app/services/storage.service';
import { HttpService } from 'src/app/services/http.service';
import { UtilsService } from 'src/app/services/utils.service';
import { GeoService } from 'src/app/services/geo.service';
import { UserService } from 'src/app/services/user.service';
import { DownloadReportDialog } from 'src/app/shared/dialogs/download-report';
import { OnboardService } from 'src/app/services/onboard.service';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { SatelliteService } from 'src/app/services/satellite.service';
import { CameraService } from 'src/app/services/camera.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';
import { CustomErroHandlerService } from 'src/app/services/custom-erro-handler.service';
@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  constructor(
    public reports: ReportsService,
    private utils: UtilsService,
    private http: HttpService,
    private storage: StorageService,
    private dialog: MatDialog,
    public geo: GeoService,
    public onBoard: OnboardService,
    public user: UserService,
    public sat: SatelliteService,
    public cams: CameraService,
    public amplitude:AmplitudeService,
    public customErrorHandler:CustomErroHandlerService
  ) {}

  public acionamentoColumns = [
    'brigada',
    'acionamento',
    'combate'
  ]

  @Input() public singleInterface:boolean = false;
  @Input() public contentOnRight:boolean = true;

  public causas
  public origens
  public tipos_danos
  public unidades_danos
  public show


  public canvasContext: any;
  public canvasElement: any;

  public locationBoxExpanded: boolean = false;
  public triggeringsBoxExpanded: boolean = false;
  public damageBoxExpanded: boolean = false;
  public commentsBoxExpanded: boolean = false;

  async ngOnInit() {
    this.reports.initListFromStorage();

    let report_params = await this.http.get('get_report_params')
    this.origens = report_params.origens_deteccao
    this.causas = report_params.causas_incendio
    this.tipos_danos = report_params.danos.tipos
    this.unidades_danos = report_params.danos.unidades
  }



  selectReport(id_report) {
    if (this.reports.selected_report?.id_report == id_report){
      this.reports.unSelectReport();
      return
    }
    this.reports.unSelectReport()
    this.reports.selectReport(id_report)
  }

  setShow(menu){
    if (this.show == menu) {
      this.show = null
    } else {
      this.show = menu
    }
  }

  toggleSat(){
    if (this.show == null) {
      this.amplitude.sendEvent("Abriu Menu Satelital")
    }
    else {
      this.amplitude.sendEvent("Fechou Menu Satelital")
    }
    this.setShow('sat')
  }

  toggleWeather(){
    if (this.show == null) {
      this.amplitude.sendEvent("Abriu Dados Meteorologicos")
    }
    else {
      this.amplitude.sendEvent("Fechou Dados Meteorologicos")
    }
    this.setShow('weather')
  }

  async addAcionamento(row=null){
    let brigadas = await this.http.get('get_brigades') || [];

    if (!brigadas.length){
      this.dialog.open(InformationDialog, {
        data: {text:"Não existem brigadas cadastradas no sistema"}
      });
    }

    else {
      let dialogConfig: MatDialogConfig = {
        data: {
          row: row,
          brigadas : brigadas,
          id_report : this.reports.selected_report.id_report,
          len_acionamentos : this.reports.selected_report.acionamentos.length,
        },
        panelClass: 'dialog-responsive',
        width: '500px',
        maxWidth: '100vw',
        autoFocus: false
      }
      const dialogRef = this.dialog.open(BrigadesDisplacementDialog , dialogConfig);

    dialogRef.afterClosed().subscribe(async(acionamento) => {
      if (acionamento){
        for (let b of brigadas){
          if (acionamento['nome_brigada'] === b['nome_brigada']){
            acionamento['id_brigada'] = b['id_brigada']
          }
        }
        acionamento['id_report'] = this.reports.selected_report.id_report;

        for (let c of ['combate', 'rescaldo']){
          if (!acionamento[c]){
            acionamento[`dt_inicio_${c}`] = null;
            acionamento[`dt_fim_${c}`] = null;
          }
        }
        if (acionamento['dt_chegada'] === ''){
          acionamento['dt_chegada'] = null;
        };

        if (row === null){
          this.reports.selected_report.acionamentos.push(acionamento);
          this.reports.selected_report.acionamentos.sort((a, b) => a.dt_acionamento.localeCompare(b.dt_acionamento));
        } else {
          let rowIndex = this.reports.selected_report.acionamentos.indexOf(row);
          this.reports.selected_report.acionamentos[rowIndex] = acionamento;
        }
        this.reports.selected_report.acionamentos = [...this.reports.selected_report.acionamentos]

        let acionamentos = this.reports.selected_report.acionamentos
        if (!acionamentos.length || acionamentos.map(a => a.dt_inicio_combate).every((dt) => dt === null)){
          this.reports.setReportIconColor('red');
        } else if (acionamentos.map(a => a.dt_fim_combate).every((dt) => dt!== null)){
          this.reports.setReportIconColor('grey');
        } else {
          this.reports.setReportIconColor('orange');
        }

        let response = await this.http.maestroPost('upsert_acionamento', acionamento)
        console.log('addAcionamento', response, acionamento);
      }
    });

  }

}

  addPerdas() {
    const dialogRef = this.dialog.open(RegisterDamageDialog , {
      data: {
        tipos_danos: this.tipos_danos,
        unidades_danos: this.unidades_danos
      }
    });


    dialogRef.afterClosed().subscribe(async (perdas) => {
      if (perdas){
        delete perdas.tipos_danos
        delete perdas.unidades_danos
        console.log("perdas",perdas)
        this.reports.selected_report.dados_perdas.push(perdas)
        await this.reports.updateMaestro('dados_perdas')
        this.reports.updateReportStorage(this.reports.selected_report);
      }
    });

  }

  addComments() {
    let data = {
      text: 'Comentários',
      comentarios: this.reports.selected_report.comentarios || ""
    }

    const dialogRef = this.dialog.open(AddCommentDialog , {
      data: data
    });

    dialogRef.afterClosed().subscribe(async (comments) => {

      if ((typeof(comments) == "boolean") && (comments == false)) { } // comments == false -> quando aperta em cancelar
      else if ((typeof(comments) == "object") && (comments == null)) { // comments == null -> quando confirma o texto vazio
        this.reports.selected_report.comentarios = "";
        await this.reports.updateMaestro('comentarios')
        this.reports.updateReportStorage(this.reports.selected_report);
      }
      else if (typeof(comments) == "string") {
        this.reports.selected_report.comentarios = comments;
        await this.reports.updateMaestro('comentarios')
        this.reports.updateReportStorage(this.reports.selected_report);
      }
    });
  }

  updateIconColor() {
    if (this.reports.selected_report.is_test) {
      this.reports.selected_report.icon.color = "black"
      return
    }
    if (this.reports.selected_report.sem_risco) {
      this.reports.selected_report.icon.color = "blue"
      return
    }
    this.reports.selected_report.icon.color = "red"
    return
  }

  async toggleRisk (ob: MatSlideToggleChange) {
    this.reports.selected_report.sem_risco = ob.checked
    this.updateIconColor()

    try {
      await this.reports.updateMaestro('sem_risco');
      this.reports.updateReportStorage(this.reports.selected_report);
    }
    catch (err) {
      this.customErrorHandler.handleError(err)
      console.log("Erro:", err)
    }
    this.geo.drawFireReports()
  }

  async toggleIsTest (ob: MatSlideToggleChange) {
    this.reports.selected_report.is_test = ob.checked
    this.updateIconColor()

    try {
      await this.reports.updateMaestro('is_test')
      this.reports.updateReportStorage(this.reports.selected_report);
    }
    catch (err) {
      this.customErrorHandler.handleError(err)
      console.log("Erro: ", err)
    }
    this.geo.drawFireReports()
  }


  async finishReport(){
    this.amplitude.sendEvent("Clicou Encerrar", {
      "id_report": this.reports.selected_report.id_report
    })

    const finishReportDialogRef = this.dialog.open(FinishReportDialog, {
      data: {
        report: this.reports.selected_report
      }
    })

    finishReportDialogRef.afterClosed().subscribe(async (confirmation) => {
      if (confirmation) {
        if (!this.reports.exportPDF) {
          this.reports.finishReportProcedures();
          return
        }

        if (!this.reports.selected_report.img_64_mapa){
          await this.reports.fillMapSnap();
          this.reports.updateReportStorage(this.reports.selected_report);
        }

        return this.dialog.open(DownloadReportDialog, {
          data: { report: this.reports.selected_report }
        })
      }
    })
  }

  changeLocationByCoords(){
    let bounds = this.geo.getMaxLatLng()
    let type = "update";

    const dialogRef = this.dialog.open(LatLonDialog , {
      data: { bounds, type }
    });

    dialogRef.afterClosed().subscribe(async(latlng) => {
      if (latlng){
        console.log('sendToGeo', latlng);
        let new_dados_loc = await this.geo.getDadosLocByCoords(latlng);

        console.log('new_dados_loc', new_dados_loc);

        this.geo.setView({lat:new_dados_loc.lat, lng:new_dados_loc.lon})

        this.geo.drawLocation(new_dados_loc);

        this.updateLocationDialog(new_dados_loc);
      }
    });

  }

  async changeLocationByClick(){
    console.log('changeLocationByClick')

    this.storage.setSearchingLocOnMap();
    let new_dados_loc = await this.getFireLocationFromStorage();
    console.log('getFireLocationFromStorage', new_dados_loc);

    this.updateLocationDialog(new_dados_loc);

  }

  updateLocationDialog(new_dados_loc){
    // TODO - Falar distancia do local original
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: 'Tem certeza que deseja alterar o local do relatório?'}
    });

    dialogRef.afterClosed().subscribe(confirmation => {
      if (confirmation){
        this.reports.updateReportLocation(new_dados_loc)
      }
    });
  }

  // Almost identical indetection service
  async getFireLocationFromStorage(){

    while (true){

      if(!this.reports.selected_report) return;

      let dados_localizacao = this.storage.getFireLocation();

      if(dados_localizacao){

        this.storage.rmFireLocation();

        return dados_localizacao;
      }

      await this.utils.sleep(500)
    }
  }

  sendToTelegram() {
    if (this.reports.selected_report.sent_via_telegram) {
      const dialogRef = this.dialog.open(InformationDialog , {
        data: { text: 'Você já enviou esse relatório via Telegram.' }
      });

      dialogRef.afterClosed().subscribe(confirmation => {
        this.reports.sendToTelegram();
      });
    }
    else {
      this.reports.sendToTelegram();
    }
  }

  toggleExpandLocationBox() {
    this.locationBoxExpanded = !this.locationBoxExpanded;
  }

  toggleExpandTriggeringsBox() {
    if (this.reports.selected_report.acionamentos.length) {
      this.triggeringsBoxExpanded = !this.triggeringsBoxExpanded;
    }
    return
  }

  toggleExpandDamageBox() {
    if (this.reports.selected_report.dados_perdas.length) {
      this.damageBoxExpanded = !this.damageBoxExpanded;
    }
    return
  }

  toggleExpandCommentsBox() {
    if (this.reports.selected_report.comentarios) {
      this.commentsBoxExpanded = !this.commentsBoxExpanded;
    }
    return
  }

  deleteDamage(damageRow) {
    const dialogRef = this.dialog.open(ConfirmationDialog , {
      data: { text: 'Deseja excluir esse registro de dano?' }
    });

    dialogRef.afterClosed().subscribe(async (confirmation) => {
      if (confirmation){
        let perdas = this.reports.selected_report.dados_perdas.filter(p => p != damageRow)
        this.reports.selected_report.dados_perdas = perdas
        await this.reports.updateMaestro('dados_perdas')
        this.reports.updateReportStorage(this.reports.selected_report);
      }
    });
  }

  drawBboxOnReportImage() {
    if (this.canvasContext && this.canvasElement) {
        this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height)
    }

    this.canvasElement = document.getElementById('detection-image-canvas')
    this.canvasElement.width = this.canvasElement.getBoundingClientRect().width
    this.canvasElement.height = this.canvasElement.getBoundingClientRect().height

    let bbox = undefined;
    if (this.reports.selected_imgs[this.reports.selectedImageIndex].model_result) {
      bbox = this.reports.selected_imgs[this.reports.selectedImageIndex].model_result.bbox || undefined
    }

    if (bbox) {
        let width = this.canvasElement.width
        let height = this.canvasElement.height

        let x1 = bbox[0]*width
        let y1 = bbox[1]*height
        let rectangleWidth = bbox[2]*width - x1
        let rectangleHeight = bbox[3]*height - y1

        this.canvasContext = this.canvasElement.getContext('2d')

        this.canvasContext.lineWidth = '1'
        this.canvasContext.strokeStyle = '#dc3333'

        this.canvasContext.beginPath()
        this.canvasContext.rect(x1, y1, rectangleWidth, rectangleHeight)
        this.canvasContext.stroke()
        this.canvasContext.closePath()
    }
  }
}
