<main *ngIf='loaded'>

  <div class="side-map-header">
    <img src="assets/icons/new-icons/thermostat-dark.svg" style="width: 24px; height: 24px;"/>
    Dados Meteorológicos
  </div>

  <div class="table-container">
    <table mat-table [dataSource]="meteo_data" class="mat-elevation-z8">
      <ng-container matColumnDef="nome_local">
        <th mat-header-cell *matHeaderCellDef> Local </th>
        <td mat-cell *matCellDef="let element">
          {{element.nome_local}}
        </td>
      </ng-container>

      <ng-container matColumnDef="day">
        <th mat-header-cell *matHeaderCellDef style="padding-right: .5%; padding-left: .5%;">Temperatura<br>Média<br>(°C)</th>
        <td mat-cell *matCellDef="let element">
          {{element.daily[0].temp.day|number:'.1-1'}}
        </td>
      </ng-container>

      <ng-container matColumnDef="icon">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
          <img src='https://openweathermap.org/img/w/{{element.daily[0].weather[0].icon}}.png'>
        </td>
      </ng-container>

      <ng-container matColumnDef="risk">
        <th mat-header-cell *matHeaderCellDef style="padding-right: .5%;">Risco<br>Incêndio<br>(%)</th>
        <td mat-cell *matCellDef="let element" style='vertical-align: center;'>
          <div id="content">
          <span style='width: 45%; float: left' class="dot" [ngStyle]="{'background-color': getRiskColor(element.fire_risk)}"></span>
          <div style='width: 45%; float: right'>{{element.fire_risk * 100 | number:'.0-0'}}</div>
          </div>

        </td>
      </ng-container>

      <ng-container matColumnDef="rain">
        <th mat-header-cell *matHeaderCellDef style="padding-right: .5%; padding-left: .5%;vertical-align: top;">Chuva<br>(mm)</th>
        <td mat-cell *matCellDef="let element"> {{element.daily[0].rain || 0}} </td>
      </ng-container>

      <ng-container matColumnDef="wind_speed">
        <th mat-header-cell *matHeaderCellDef style="padding-right: .5%; padding-left: .5%;">Velocidade<br>Vento<br>(km/h)</th>
        <td mat-cell *matCellDef="let element"> {{element.daily[0].wind_speed * 3.6|number:'.1-1'}} </td>
      </ng-container>

      <ng-container matColumnDef="wind_deg">
        <th mat-header-cell *matHeaderCellDef style="padding-right: .5%; padding-left: .5%;">Direção<br>Vento<br>(°)</th>
        <td mat-cell *matCellDef="let element"> {{element.daily[0].wind_deg}} </td>
      </ng-container>

      <ng-container matColumnDef="humidity">
      <th mat-header-cell *matHeaderCellDef style="padding-left: .5%;vertical-align: top;">Umidade<br>(%)</th>
      <td mat-cell *matCellDef="let element"> {{element.daily[0].humidity}} </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>

  <mat-card appearance="outlined" class='risk-legend' style="padding: 1vh 1vw;">
    <h3>Risco de incêndio</h3>
    <img style="width: 20%;" src='https://geo.umgrauemeio.com/geoserver/wms?REQUEST=GetLegendGraphic&VERSION=1.0.0&FORMAT=image/png&TRANSPARENT=true&WIDTH=20&HEIGHT=20&LAYER=risk:28_danger_d0'>
  </mat-card>
</main>
