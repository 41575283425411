import {Component, OnInit} from '@angular/core';
import {HttpService} from '../../services/http.service'
import {UserService} from '../../services/user.service'
import {Router} from '@angular/router';
import {MatDialog} from '@angular/material/dialog';
import { ForgotPasswordDialog } from 'src/app/shared/dialogs/forgot-password';
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit {

  constructor(
    private http: HttpService,
    private user: UserService,
    private router: Router,
    private dialog: MatDialog
  ) {}
  
  public version:string = environment.version;
  public username: string;
  public userpass: string;
  public showErrorMessage: boolean = false;
  public errorMessage = "Usuário ou senha inválidos";
  public showPass: boolean = false;

  ngOnInit() {
    let user_token = this.user.getUserToken();

    if (user_token) {
      this.loginRedirect()
    }
  }

  async sign_in() {
    const trimmedUsername = this.username.trim()
    const trimmedPassword = this.userpass.trim()

    let signInResponse = await this.http.post('sign_in', { 'username': trimmedUsername, 'userpass': trimmedPassword })
    console.log('sign_in', signInResponse);

    if(!signInResponse){
      this.user.openErrorDialog();
      this.user.signOut();
      return
    }

    if (!signInResponse.status) {
      this.showErrorMessage = true;
      this.user.signOut();
    }
    else {
      console.log('✔️Autenticado')
      this.user.signIn(signInResponse)
      this.loginRedirect()
    }
  }

  loginRedirect(){
    let monitores = this.user.getMonitoresPantera()
    if (monitores.mapa == null) {
      this.router.navigate(['/singleinterface'])
    } else {
      this.router.navigate(['/interface'])
    }
  }

  forgot_pass() {
    this.dialog.open(ForgotPasswordDialog)
  }

}

