import { Component, OnInit } from '@angular/core';
import { GeoService } from '../../services/geo.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

@Component({
  selector: 'app-map-ctrl',
  templateUrl: './map-ctrl.component.html',
  styleUrls: ['./map-ctrl.component.scss']
})
export class MapCtrlComponent implements OnInit {

  constructor(
    public geo: GeoService,
    private amplitude: AmplitudeService
  ) { }
  
  public baseList = [];
  private filterList;
  public show = null;

  ngOnInit(): void {
  }

  selectBaseLayer(event){
    this.geo.setBaseMap(event.value.layer)
  }

  updateList(){
    this.baseList = this.geo.layerGroupList.filter(g=>this.filterList.includes(g.groupId))
    this.baseList.sort((g1, g2) => {
      return g1.groupId - g2.groupId;
    })
  }

  toogleLayer(event, layer){
    this.geo.setLayer(layer, event.checked)
  }

  toggleBase(){
    this.amplitude.sendEvent("Clicou Abrir Camadas Mapa")
    this.setShow('base')
    this.filterList = [
      1,
      2,
      3,
      8,
      14
    ]
    this.updateList()
  }

  setShow(legendGroup){
    if (this.show == legendGroup) {
      this.show = null
    } else {
      this.show = legendGroup
    }
  }

  toggleVisibility(){
    this.amplitude.sendEvent("Clicou Abrir Camadas Visibilidade")
    this.setShow('visibility')
    this.filterList = [
      7,
      9,
    ]
    this.updateList()
  }

  toggleSat(){
    this.amplitude.sendEvent("Clicou Abrir Camadas Satélite")
    this.setShow('sat')
    this.filterList = [
      4,
    ]
    this.updateList()
  }

}
