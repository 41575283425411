
import {Component, Inject, ViewChildren, QueryList} from '@angular/core';
import {MAT_DIALOG_DATA} from '@angular/material/dialog';
import {HttpService} from '../../../services/http.service';
import {MatFormField} from "@angular/material/form-field";

@Component({
  selector: 'change-password-dialog',
  templateUrl: './index.html',
  styleUrls: ['./styles.scss']
})
export class ChangePasswordDialog {
  
  public pass1:string;
  public pass2:string;
  public showPass1:boolean = false;
  public showPass2:boolean = false;

  public wrongPassError:boolean = false;
  public success:boolean = false;
  
  constructor(@Inject(MAT_DIALOG_DATA) public data: any, public http:HttpService) { }
  
  @ViewChildren(MatFormField) formFields: QueryList<MatFormField>;

  async changePassword(){
    let obj = {
      username : this.data.username,
      oldpass : this.pass1,
      newpass : this.pass2,
    }
    let response = await this.http.post('update_password', obj)
    if (!response){
      this.wrongPassError = true;
    }
    else{
      this.success = true;
    }
  }
}
