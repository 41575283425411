import { Component, Input, OnChanges, OnDestroy, SimpleChanges, HostListener, AfterViewInit, Output, EventEmitter } from '@angular/core';
import { ViewChild, ViewContainerRef, ComponentFactoryResolver } from '@angular/core';
import { CameraService } from '../../services/camera.service';
import { UserService } from '../../services/user.service';
import { UtilsService } from '../../services/utils.service';
import { SocketService  } from '../../services/socket.service';
import { environment } from 'src/environments/environment'
import { StreamerDockerComponent } from '../streamer-docker/streamer-docker.component';
import { StreamerWebRTCComponent } from '../streamer-webrtc/streamer-webrtc.component';
import { DetectionsService } from 'src/app/services/detections.service';
import { FireLocationService } from 'src/app/services/fire-location.service';
import { AmplitudeService } from 'src/app/services/amplitude.service';

const MAX_SIZE_BBOX_ZOOM_IN_ROI = 0.03

@Component({
  selector: 'app-camera',
  templateUrl: './camera.component.html',
  styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements AfterViewInit, OnChanges, OnDestroy {

  @Input() public cam_id: number;
  @Input() public screen_id:number = 0; // Deprecated
  @Input() public singleInterface: boolean = false;
  @Input() public isSecondCam:boolean = false;
  @ViewChild('streamTemplate', { read: ViewContainerRef }) private streamRef: ViewContainerRef;

  constructor(
    public cam: CameraService,
    public user: UserService,
    public utils: UtilsService,
    private cfr:ComponentFactoryResolver,
    public detections:DetectionsService,
    public fireLocationService: FireLocationService,
    private amplitude: AmplitudeService
  ) { }

  public cam_obj: any;

  private camInterval: any;
  private logTimeout:number = environment.camStatusTimeout;
  public cam_log: any = null;

  public show_arrows:boolean=false;
  private move_like_digifort=false;
  private is_drawing: boolean = false;
  public live_loader: boolean = false;
  public canvasId: string;
  private canvasElement: any;
  private canvasContext: any;
  private startX: number = 0;
  private startY: number = 0;
  private deltaX: number = 0;
  private deltaY: number = 0;
  public streamingType: string = "go-webrtc";
  private wheel_zooming: boolean = false;
  public draw: boolean = false;

  public lastUpdateTime = 0;
  public throttleInterval = 1000; 

  /*
  Iniciando o componente e a rotina de atualizacao do status das cameras
  */

  ngAfterViewInit(){
    if(this.cam_id){
      this.logStream();
      this.selectStream()
    }
  }

  logStream(){
    this.camInterval = setInterval(
      () => {
        this.cam_log = this.cam.getCamLog(this.cam_id);
      },
      this.logTimeout
    );
  }

  selectStream(){
    console.log('cloudVersion', environment.cloudVersion);
    this.streamingType = environment.cloudVersion ? 'cloud-ws' : this.user.getStreamingType()
    console.log(this.streamingType);

    if (this.streamingType === 'cloud-ws') {
      this.setStreamCloud();
    } else if (this.streamingType === 'docker-ws'){
      this.setStreamDocker();
    }else {
      this.setStreamWebrtc();
    }
  }

  setStreamWebrtc(){
    if (this.streamRef){
      this.streamRef.clear();
      const streamFactory = this.cfr.resolveComponentFactory(StreamerWebRTCComponent);
      const componentRef = this.streamRef.createComponent(streamFactory);
      componentRef.instance.cam_id = this.cam_id;
      componentRef.instance.stream_host = this.cam_obj.stream_host || 'localhost';
      componentRef.instance.stream_port =  8083;
    }
  }

  setStreamDocker(){
    if (this.streamRef){
      this.streamRef.clear();
      const streamFactory = this.cfr.resolveComponentFactory(StreamerDockerComponent);
      const componentRef = this.streamRef.createComponent(streamFactory);
      componentRef.instance.cam_id = this.cam_id;
      componentRef.instance.stream_host = this.cam_obj.stream_host || 'localhost';
      componentRef.instance.stream_port = this.cam_obj.stream_port || 8000;
    }
  }

  setStreamCloud(){
    if (this.streamRef){
      this.streamRef.clear();
      const streamFactory = this.cfr.resolveComponentFactory(StreamerWebRTCComponent);
      const componentRef = this.streamRef.createComponent(streamFactory);
      componentRef.instance.cam_id = this.cam_id;
      componentRef.instance.requestType = 'https';
      componentRef.instance.stream_host = environment.streamCloud;
      componentRef.instance.stream_port = 8443;
    }
  }

  ngOnChanges(ch: SimpleChanges){
    if('cam_id' in ch){
      this.setCam();
    }
  }

  ngOnDestroy(){
    clearInterval(this.camInterval);
    this.cam_obj = null;
    this.draw = false;
  }

  setCam(){
    if(!this.cam.cameras) return false;
    this.cam_obj = this.cam.cameras.filter(x => x.id_cam === this.cam_id)[0];
    this.canvasId = `canvas-${this.cam_id}-half-height`;
    this.canvasElement = null;
    this.canvasContext = null;
    this.draw = false;
    this.selectStream()

    return true;
  }

  /*
  Funcoes de click e zoom na tela
  */
  async sendClickCoordinates(e){
    if(!this.cam.cameras || !this.cam.allowedToClick) return false;
    this.cam_obj = this.cam.cameras.filter(x => x.id_cam === this.cam_id)[0];

    if(this.draw){ return };
    if (this.move_like_digifort){ return };

    if(!this.canvasElement){
      this.canvasElement = document.getElementById(this.canvasId);
    }

    this.cam.resetCameraCommand(this.cam_id);

    let canvasDimensions = this.canvasElement.getBoundingClientRect();
    let relX = e.layerX / canvasDimensions.width;
    let relY = e.layerY / canvasDimensions.height;

    this.cam.setClickRateLimit();

    await this.cam.centerInRoi(this.cam_id, relX, relY)
  }

  camMoveUp() {
    if (this.cam.allowedToClick) this.cam.upCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }

  camMoveRight() {
    if (this.cam.allowedToClick) this.cam.rightCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }

  camMoveDown() {
    if (this.cam.allowedToClick) this.cam.downCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }

  camMoveLeft() {
    if (this.cam.allowedToClick) this.cam.leftCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }

  camZoomIn() {
    this.cam.resetCameraCommand(this.cam_id);
    if (this.cam.allowedToClick) this.cam.zoomInCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }
  
  camZoomOut () {
    this.cam.resetCameraCommand(this.cam_id);
    if (this.cam.allowedToClick) this.cam.zoomOutCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }
  
  camResetZoom() {
    this.cam.resetCameraCommand(this.cam_id);
    if (this.cam.allowedToClick) this.cam.resetZoomCommand(this.cam_id)
    this.cam.setClickRateLimit();
  }

  async wheelZoom(e){
    if (this.wheel_zooming) return;

    this.wheel_zooming = true;
    this.cam.resetCameraCommand(this.cam_id);
    if (e.wheelDelta>0){
      await this.cam.zoomInCommand(this.cam_id);
    } else {
      await this.cam.zoomOutCommand(this.cam_id);
    }

    // TESTAR SEM ISSO
    // setTimeout(async() => {
    //   await this.cam.stopCommand(this.cam_id);
    // }, 500);

    setTimeout(() => {
      this.wheel_zooming = false;
    }, 500);
  }

  toggleShowArrows(){
    this.show_arrows = !this.show_arrows;
  }

  toggleShowArrowsAndStopAutomaticDetection() {
    this.amplitude.sendEvent("Clicou Habilitar Controles Câmeras Botões")
    if (this.show_arrows) {
      this.toggleShowArrows()
    }
    else {
      this.cam.resetCameraCommand(this.cam_id);
      this.toggleShowArrows()
    }
  }

  /*
  Funcoes de desenho no canvas
  */

  toggleDraw(){
    this.draw ? this.amplitude.sendEvent("Clicou Desabilitar Controles Câmeras Botões") : this.amplitude.sendEvent("Clicou Habilitar Controles Câmeras Botões")
    this.draw = !this.draw
    if (this.draw) {
      this.cam.resetCameraCommand(this.cam_id);
    }
  }

  clearCanvas(){
    if(this.canvasContext){
      this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
    }
  }

 drawRectangle(x0, y0, x1, y1, color='white'){
    // top-left & bottom-right

    this.clearCanvas();

    let end_x = x1 - x0;
    let end_y = y1 - y0;

    this.canvasContext.lineWidth = 2;
    this.canvasContext.strokeStyle = color;

    this.canvasContext.beginPath();
    this.canvasContext.rect(x0, y0, end_x, end_y);
    this.canvasContext.stroke();
    this.canvasContext.closePath();
  }

  getBbox(){
    if(this.startX === 0 && this.startY === 0) return;

    let canvasDimensions = this.canvasElement.getBoundingClientRect();

    let xs = [this.startX, this.deltaX];
    let ys = [this.startY, this.deltaY];

    let x_left   = Math.min(...xs) / canvasDimensions.width;
    let y_top    = Math.min(...ys) / canvasDimensions.height;
    let x_right  = Math.max(...xs) / canvasDimensions.width;
    let y_bottom = Math.max(...ys) / canvasDimensions.height;

    return [x_left, y_top, x_right, y_bottom];
  }

  canvasInit(){
    this.canvasElement = document.getElementById(this.canvasId)
    this.canvasElement.width = this.canvasElement.getBoundingClientRect().width;
    this.canvasElement.height = 0.5625 * this.canvasElement.width
    this.canvasContext = this.canvasElement.getContext('2d');
  }

  drawStart(e){

    this.canvasInit();

    if(this.canvasContext){
      this.clearCanvas();
    }else{
      this.canvasContext = this.canvasElement.getContext('2d');
    }

    // in Px
    this.startX = e.layerX;
    this.startY = e.layerY;

    if(!this.draw) {
      this.move_like_digifort = true;
      return;
    }

    this.is_drawing = true;
  }

  async drawUpdate(e){

    this.deltaX = e.layerX;
    this.deltaY = e.layerY;

    let oldX = 0
    let oldY = 0

    if(!this.canvasElement){
      this.canvasElement = document.getElementById(this.canvasId);
    }
    let canvasDimensions = this.canvasElement.getBoundingClientRect();
    let relX = this.deltaX / canvasDimensions.width;
    let relY = this.deltaY / canvasDimensions.height;
    let color = 'yellow';
    // if (this.move_like_fort && (relX != oldX || relY != oldY)){
    if (this.move_like_digifort){
      const now = Date.now();
      
      if (now - this.lastUpdateTime >= this.throttleInterval) {
        this.cam.setClickRateLimit();
        this.cam.centerInRoi(this.cam_id, relX, relY)
        this.lastUpdateTime = now;
      }
    }

    if (this.is_drawing) {
      this.drawRectangle(this.startX, this.startY, this.deltaX, this.deltaY, color);
    }
  }

  drawFinish(){
    if (this.is_drawing) {

      if(this.canvasContext){
        let bbox = this.getBbox();
        if (((bbox[2] - bbox[0]) <= MAX_SIZE_BBOX_ZOOM_IN_ROI && (bbox[3] - bbox[1]) <= MAX_SIZE_BBOX_ZOOM_IN_ROI)) {
          this.cam.centerInRoi(this.cam_id, bbox[2], bbox[3])
        }
        else {
          this.cam.zoomInRoi(this.cam_id, bbox)
        }
        this.canvasContext.clearRect(0, 0, this.canvasElement.width, this.canvasElement.height);
      }
      this.startX = 0;
      this.startY = 0;
      this.deltaX = 0;
      this.deltaY = 0;
      this.is_drawing = false;
    }
    else{
      this.move_like_digifort = false;
    }
  }

  /*
  Tratamento do display das cameras
  */

  tipLogDist(){
    if(!this.cam_log) return;
    let zoom = this.cam_log.ptz.zoom || 1;
    // TODO: Câmeras diferentes vão ter zoom ranges diferentes
    // Não se pode mais usar só o zoom, tem que usar uma função que dependa de
    // (f0*zoom/w0) (f0 min focal dist, w0 sensor width)
    // Pegar (f0/w0) do backend? vai depender da câmera
    const f0 = 4.3
    const w0 = 5.3

    let dist1 = (0.3*(f0/w0)*(zoom-1)).toFixed(2)
    let dist2 = (5.75 + 0.3*(f0/w0)*zoom).toFixed(2)

    return [dist1, dist2];
  }


  tipLogTilt(){
    if(!this.cam_log) return;
    let tilt = null || this.cam_log.ptz.tilt;
    // tilt -= 143 - 73;
    return (tilt > 0 ? '+' : '') + tilt?.toFixed(2);
   }
  
  createManualDetection() {
    if(this.singleInterface) {
      this.detections.deactivateDetection()
    }
    this.detections.createManualDetection()
  }

}
