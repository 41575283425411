<mat-dialog-content>
  <h2>Não foi possível iniciar o Sistema Pantera</h2>
  <ol>
    <li>Caso tenha acabado de ligar o computador, espere 3 minutos e tente novamente</li>
    <li>Verique se o programa <b>Docker</b> está ativo, e tente novamente</li>
    <li>Se o problema persistir entre em contato com nossa assistência</li>
  </ol>
  </mat-dialog-content>
<mat-dialog-actions align="center">
  <button mat-raised-button [mat-dialog-close]="true" color="primary">
    Sair
  </button>
</mat-dialog-actions>
