import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppComponent } from './app.component';
import { LoginComponent } from './pages/login/login.component';
import { MapaComponent } from './pages/mapa/mapa.component';
import { SingleInterfaceComponent } from './pages/single-interface/single-interface.component';
import { ConfigComponent } from './pages/config/config.component';
import { NewInterfaceComponent } from './pages/new-interface/new-interface.component';

import { CameraComponent } from './components/camera/camera.component';
import { StreamerDockerComponent } from './components/streamer-docker/streamer-docker.component';
import { StreamerWebRTCComponent } from './components/streamer-webrtc/streamer-webrtc.component';
import { ReportsComponent } from './components/reports/reports.component';
import { ReportImgComponent } from './components/report-img/report-img.component'
import { DetectionsComponent } from './components/detections/detections.component';
import { CamOptionsComponent } from './components/cam-options/cam-options.component';
import { SatComponent } from './components/sat/sat.component';
import { WeatherComponent } from './components/weather/weather.component';
import { MapSidenavComponent } from './components/map-sidenav/map-sidenav.component';
import { DetectionsTableComponent } from './components/detections-table/detections-table.component';
import { GeolocMenuComponent } from './components/geoloc-menu/geoloc-menu.component';
import { MapCtrlComponent } from './components/map-ctrl/map-ctrl.component';
import { ClientConfigComponent } from './components/client-config/client-config.component';
import { DelDetComponent } from './components/del-det/del-det.component';
import { LoaderComponent} from './shared/loaders/loader';
import { SmallLoaderComponent } from './shared/loaders/small-loader';

import { InformationDialog } from 'src/app/shared/dialogs/information'
import { FinishReportDialog } from 'src/app/shared/dialogs/finish-report'
import { DownloadReportDialog } from 'src/app/shared/dialogs/download-report'
import { ConfirmationDialog } from 'src/app/shared/dialogs/confirmation';
import { ErrorDialog } from 'src/app/shared/dialogs/error';
import { TriangulationDialog } from 'src/app/shared/dialogs/triangulation';
import { LatLonDialog } from 'src/app/shared/dialogs/lat-lon';
import { AddImagesDialog } from 'src/app/shared/dialogs/add-image';
import { DeleteReportDialog } from 'src/app/shared/dialogs/delete-report';
import { TelegramGroupsDialog } from 'src/app/shared/dialogs/telegram-groups';
import { ConfirmFireDialog } from 'src/app/shared/dialogs/confirm-fire';
import { GroupDetectionOnReportDialog } from 'src/app/shared/dialogs/group-detection-on-report';
import { ConfirmDetectionGroupingOnReportDialog } from 'src/app/shared/dialogs/confirm-detection-grouping-on-report';
import { AddCommentDialog } from 'src/app/shared/dialogs/add-comment';
import { RegisterDamageDialog } from 'src/app/shared/dialogs/register-damage';
import { BrigadesDisplacementDialog } from 'src/app/shared/dialogs/brigades-displacements';
import { CreateReportWithoutTowerDialog } from 'src/app/shared/dialogs/create-report-without-tower';
import { ForgotPasswordDialog } from 'src/app/shared/dialogs/forgot-password';
import { ChangePasswordDialog } from 'src/app/shared/dialogs/change-password';
import { SeePointOfCalibrationImagesDialog } from './shared/dialogs/see-point-of-calibration-images';

import { TextSearchPipe, SafePipe } from './pipes/filters.pipe';

import { ScriptService } from './services/scripts.service';
import { SocketService } from './services/socket.service';
import { RouteGuardsService } from './services/route-guards.service';
import { GuidedTourModule, GuidedTourService } from 'ngx-guided-tour';

import { ScrollingModule } from '@angular/cdk/scrolling';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatTableModule } from '@angular/material/table';
import { MatRadioModule } from '@angular/material/radio';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatCardModule } from '@angular/material/card';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDividerModule } from '@angular/material/divider';
import { MatDialogModule } from '@angular/material/dialog';
import { MatBadgeModule } from '@angular/material/badge';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatSortModule } from '@angular/material/sort';
import { ErrorHandler } from '@angular/core';
import { CustomErroHandlerService } from './services/custom-erro-handler.service';
import { CamerasSidenavComponent } from './components/cameras-sidenav/cameras-sidenav.component';


@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        MapaComponent,
        LoaderComponent,
        SmallLoaderComponent,
        CameraComponent,
        CamerasSidenavComponent,
        StreamerDockerComponent,
        StreamerWebRTCComponent,
        ConfigComponent,
        ReportsComponent,
        DetectionsComponent,
        CamOptionsComponent,
        SingleInterfaceComponent,
        WeatherComponent,
        SatComponent,
        MapSidenavComponent,
        SatComponent,
        ErrorDialog,
        ConfirmationDialog,
        ConfirmFireDialog,
        TriangulationDialog,
        BrigadesDisplacementDialog,
        RegisterDamageDialog,
        TelegramGroupsDialog,
        CreateReportWithoutTowerDialog,
        DeleteReportDialog,
        GroupDetectionOnReportDialog,
        ConfirmDetectionGroupingOnReportDialog,
        AddImagesDialog,
        AddCommentDialog,
        ChangePasswordDialog,
        DownloadReportDialog,
        FinishReportDialog,
        ForgotPasswordDialog,
        InformationDialog,
        SeePointOfCalibrationImagesDialog,
        LatLonDialog,
        TextSearchPipe,
        SafePipe,
        ReportImgComponent,
        NewInterfaceComponent,
        DetectionsTableComponent,
        MapCtrlComponent,
        GeolocMenuComponent,
        ClientConfigComponent,
        DelDetComponent
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatButtonModule,
        MatButtonToggleModule,
        MatTableModule,
        MatRadioModule,
        FormsModule,
        MatCheckboxModule,
        MatCardModule,
        MatProgressSpinnerModule,
        MatProgressBarModule,
        ScrollingModule,
        MatAutocompleteModule,
        MatIconModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatSidenavModule,
        MatToolbarModule,
        MatInputModule,
        MatFormFieldModule,
        MatSelectModule,
        MatSlideToggleModule,
        MatButtonModule,
        MatDividerModule,
        MatDialogModule,
        MatBadgeModule,
        MatListModule,
        MatMenuModule,
        MatTabsModule,
        GuidedTourModule,
        MatSortModule
    ],
    providers: [
        { provide: ErrorHandler, useClass: CustomErroHandlerService },
        ScriptService,
        SocketService,
        RouteGuardsService,
        GuidedTourService,
    ],
    bootstrap: [
        AppComponent
    ]
})
export class AppModule { }
