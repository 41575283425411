<app-small-loader *ngIf="this.det.relabelling" [message]="relabelling_msg"></app-small-loader>

<main>

  <section class='logo-wrapper' *ngIf="!det.active_detection && !singleInterface">
    <div class="logo-container">
      <div class="pantera-logo"></div>
    </div>
  </section>

  <section [ngClass]="singleInterface? 'content-wrapper-single' : 'content-wrapper'" *ngIf="det.active_detection">

    <div *ngIf="!det.loadedDetectionImg" class="progress-spiner-wrapper">
      <mat-progress-spinner mode="indeterminate" value=75></mat-progress-spinner>
    </div>

    <div [ngClass]="singleInterface ? 'img-container-single' : 'img-container'" *ngIf="det.loadedDetectionImg">

      <app-report-img [image]='det.images[det.img_idx]' [number]='det.img_idx+1' [header]="false"></app-report-img>

      <button id='delete-image' (click)="det.removeDetection()" *ngIf="!singleInterface" matTooltip="Excluir detecção" matTooltipPosition="above" mat-icon-button>
        <mat-icon>delete</mat-icon>
      </button>

      <!-- single interface btns -->
      <button
        color='primary'
        id='auto-report-btn'
        (click)="det.createReportWithAutoGeoloc()"
        *ngIf="!singleInterface && user.isUgm() && det.active_detection.id_origem_deteccao == 1 && det.active_detection.geoloc"
        matTooltip="Gerar relatório automaticamente" matTooltipPosition="above">
          <img src="assets/icons/new-icons/check-circle-dark.svg" style="height: 18px; width: 18px;"/>
      </button>

      <div *ngIf="singleInterface" class='btns-row-single-interface'>
        <button mat-fab
          color='primary'
          class='btn-single-interface'
          id='btn-back'
          (click)="det.deactivateDetection()"
          matTooltip="Voltar para a tabela" matTooltipPosition="above">
          <img src="assets/icons/new-icons/reply-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button mat-fab
          color='primary'
          class='btn-single-interface'
          id='verify-det-btn-single'
          (click)="det.aimDetection()"
          matTooltip="Verificar detecção" matTooltipPosition="above">
            <img src="assets/icons/new-icons/visibility-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button mat-fab
          color="primary"
          class='btn-single-interface'
          id='enable-2cam-btn-single'
          [disabled]='!det.aiming'
          (click)="det.toggleSecondCam()"
          [matTooltip]="det.secondCamEnabled? 'Desabilitar segunda câmera' : this.user.autoTriang ? tooltip_auto_tri : tooltip_manual_tri" matTooltipPosition="above"
          *ngIf="cams.cameras.length > 1">
            <img *ngIf="det.secondCamEnabled" src="assets/icons/new-icons/camera-dark.svg" style="height: 22px; width: 22px;"/>
            <img *ngIf="!det.secondCamEnabled" src="assets/icons/new-icons/camera-off-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button mat-fab
          [color]="det.gettingLocOnMap ? 'accent' : 'primary'"
          class='btn-single-interface'
          [disabled]='!det.aiming'
          id='map-click-btn-single'
          (click)="det.searchLocationOnMap()"
          matTooltip="Geolocalizar utilizando o mapa" matTooltipPosition="above">
          <img src="assets/icons/new-icons/edit-loc-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button mat-fab
          *ngIf="singleInterface"
          [color]="'primary'"
          class='btn-single-interface'
          id='group-on-rep-btn-single'
          [disabled]="!storage.getStoredReports() || storage.getStoredReports().length == 0"
          (click)="det.openConfirmGroupOnReportDialog(det.active_detection)"
          matTooltip="Agrupar em relatório" matTooltipPosition="above">
            <img src="assets/icons/new-icons/input-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button mat-fab
          color="warn"
          class='btn-single-interface'
          id='confirm-loc-btn'
          [disabled] = "!det.active_detection?.dados_localizacao.length"
          (click)="det.openConfirmFireDialog()"
          matTooltip="Confirmar Relatório" matTooltipPosition="above">
          <img src="assets/icons/new-icons/fire-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button mat-fab
          color='primary'
          class='btn-single-interface'
          id='delete-image-2'
          (click)="det.removeDetection()"
          matTooltip="Excluir detecção" matTooltipPosition="above">
            <img src="assets/icons/new-icons/trash-dark.svg" style="height: 22px; width: 22px;"/>
        </button>
      </div>

      <div id="classifica-det" *ngIf="det.reclassificandoDet">
        <app-del-det></app-del-det>
      </div>

      <div *ngIf="det.images.length > 1">
        <button class='btn-left' color='accent' (click)="det.previousImage()">
          <img src="assets/icons/new-icons/navigate-before-light.svg" style="height: 16px; width: 16px;"/>
        </button>

        <button class='btn-right' color='accent' (click)="det.nextImage()">
          <img src="assets/icons/new-icons/navigate-next-light.svg" style="height: 16px; width: 16px;"/>
        </button>
      </div>
    </div>

    <div class="det-btns-container">

      <div class="det-btns-row" *ngIf="det.loadedDetectionImg && !singleInterface">
        <button
          *ngIf="!singleInterface"
          color='primary'
          id='verify-det-btn'
          (click)="det.aimDetection()"
          matTooltip="Verificar detecção" matTooltipPosition="above">
          <img src="assets/icons/new-icons/visibility-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button
          id='enable-2cam-btn'
          [disabled]='!det.aiming'
          (click)="det.toggleSecondCam()"
          [matTooltip]="det.secondCamEnabled? 'Desabilitar segunda câmera' : this.user.autoTriang ? tooltip_auto_tri : tooltip_manual_tri" matTooltipPosition="above"
          *ngIf="cams.cameras.length > 1 && !singleInterface">
            <img *ngIf="det.secondCamEnabled" src="assets/icons/new-icons/camera-dark.svg" style="height: 22px; width: 22px;"/>
            <img *ngIf="!det.secondCamEnabled" src="assets/icons/new-icons/camera-off-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button
          *ngIf="!singleInterface"
          [disabled]='!det.aiming'
          id='map-click-btn'
          (click)="det.searchLocationOnMap()"
          matTooltip="Geolocalizar utilizando o mapa" matTooltipPosition="above">
            <img src="assets/icons/new-icons/edit-loc-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button
          *ngIf="!singleInterface"
          id='group-on-rep-btn'
          [disabled]="!storage.getStoredReports() || storage.getStoredReports().length == 0"
          (click)="det.openConfirmGroupOnReportDialog(det.active_detection)"
          matTooltip="Agrupar em relatório" matTooltipPosition="above">
            <img src="assets/icons/new-icons/input-dark.svg" style="height: 22px; width: 22px;"/>
        </button>

        <button
          *ngIf="!singleInterface"
          id='confirm-loc-btn'
          [disabled] = "!det.active_detection?.dados_localizacao.length"
          (click)="det.openConfirmFireDialog()"
          matTooltip="Confirmar Relatório" matTooltipPosition="above">
            <img *ngIf="!det.active_detection?.dados_localizacao.length" src="assets/icons/new-icons/fire-dark.svg" style="height: 22px; width: 22px;"/>
            <img *ngIf="det.active_detection?.dados_localizacao.length" src="assets/icons/new-icons/fire-light.svg" style="height: 22px; width: 22px;"/>
        </button>
      </div>
    </div>
  </section>
</main>
